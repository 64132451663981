<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div
      class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style="background-image: url(/assets/progress-hd.png)"
    >
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column-fluid p-6 p-sm-10 pb-lg-20">
        <b-overlay :show="loading" rounded="sm">
          <div
            class="w-lg-500px w-sm-400px w-100 bg-white rounded shadow-sm mx-auto position-relative"
          >
            <div class="icon-box success" v-if="isSuccess">
              <i class="material-icons">done</i>
            </div>
            <div class="icon-box danger" v-if="!loading && !isSuccess">
              <i class="material-icons">close</i>
            </div>
            <div class="d-flex flex-column flex-column-fluid text-center p-10">
              <!--begin::Wrapper-->
              <div class="pt-lg-16">
                <!--begin::Logo-->
                <h1 class="fw-bolder fs-2qx text-dark mb-7" v-if="isSuccess">
                  Thank You for registration
                </h1>
                <h1
                  class="fw-bolder fs-2qx text-dark mb-7"
                  v-if="!loading && !isSuccess"
                >
                  Can't verify Email
                </h1>
                <!--end::Logo-->
                <!--begin::Message-->
                <div class="fs-3 fw-bold text-gray-400 mb-10">
                  {{ message }}
                </div>
                <!--end::Message-->
                <!--begin::Action-->
                <div class="text-center mb-10">
                  <router-link
                    to="/login"
                    class="btn btn-lg btn-primary fw-bolder btn-block"
                    >Go to Login</router-link
                  >
                </div>
                <!--end::Action-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
<script>
import { VERIFY_EMAIL_AUTH } from "@/core/services/store/auth.module";
import { WEBSITE } from "@/config";

export default {
  created() {
    document.title = `ยืนยันตัวตน | ${WEBSITE}`;
    this.do_verify();
  },
  data() {
    const { code } = this.$route.query;
    return {
      code,
      isSuccess: false,
      loading: true,
      message: ""
    };
  },
  methods: {
    async do_verify() {
      this.loading = true;
      this.$store
        .dispatch(VERIFY_EMAIL_AUTH, {
          code: this.code
        })
        .then(
          ({ code, message }) => {
            this.loading = false;
            if (code === 200) {
              this.isSuccess = true;
              this.message = message;
            } else {
              this.isSuccess = false;
              this.message = message;
            }
          },
          error => {
            this.isSuccess = false;
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
    }
  }
};
</script>
