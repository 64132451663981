import { gql } from "@apollo/client";

export const CREATE_TAOBAO = gql`
  mutation($input: TaobaoInput) {
    createTaobao(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_TAOBAO_LIST = gql`
  query($page: Int!, $pageSize: Int!, $search: String, $type: String) {
    taobaos(page: $page, pageSize: $pageSize, search: $search, type: $type) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        taobao_id
        taobao_no
        account_name
        account_desc
        cash
        cash_cn
        rate
        remark
        admin_remark
        typeTaobao
        detail {
          _id
          price
          cancel_remark
          status
          statusShow
          approve_date
        }
        created_at
      }
    }
  }
`;

export const GET_TAOBAO_ACCOUNTS = gql`
  query {
    taobao_accounts {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        taobaoAccountId
        account_name
        account_desc
        created_at
      }
    }
  }
`;

export const GET_SETTING_SERVICE_TAOBAO = gql`
  query {
    settingServiceTaobao {
      service_taobao
    }
  }
`;
