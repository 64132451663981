<template>
  <div class="address-title-container">
    <template v-if="isEdit">
      <div v-if="isError" class="text-danger">กรุณากรอกข้อมูล</div>
      <div class="w-100">
        <div class="input-field-form-left">
          <input
            class="form-control"
            :placeholder="placeholder"
            v-model="val"
            v-if="isEdit"
            required
          />
        </div>
        <div class="input-field-form-right">
          <button
            class="btn btn-block btn-primary"
            type="button"
            @click="onSave"
          >
            บันทึก
          </button>
        </div>
      </div>
    </template>
    <template v-if="!isEdit">
      <i class="la la-phone text-primary mr-1" />
      <span class="address-title">
        {{ value }}
      </span>
      <span class="address-edit" v-if="!isEdit" @click="isEdit = !isEdit">
        แก้ไข
      </span>
    </template>
  </div>
</template>
<script>
export default {
  props: ["value", "onChange", "placeholder"],
  data() {
    return {
      isEdit: false,
      val: this.value,
      isError: false
    };
  },
  methods: {
    onSave() {
      if (!this.val) {
        this.isError = true;
        return;
      }
      this.isError = false;
      this.isEdit = false;
      this.onChange(this.val);
    }
  },
  watch: {
    value: function(newVal) {
      this.val = newVal;
    }
  }
};
</script>
