import gql from "graphql-tag";

export const CREATE_WITHDRAW = gql`
  mutation($amount: Float!, $bank: ID!) {
    createWithdraw(input: { amount: $amount, bank: $bank }) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_WITHDRAWS = gql`
  query($page: Int!, $pageSize: Int!, $type: String) {
    withdrawList(page: $page, pageSize: $pageSize, type: $type) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        withdraw_id
        withdraw_no
        amount
        bank_name
        bank_img
        status
        statusShow
        approve_date
        created_at
      }
    }
  }
`;

export const GET_WITHDRAW = gql`
  query($id: Int!) {
    withdrawDetail(id: $id) {
      _id
      withdraw_id
      withdraw_no
      amount
      bank_name
      account_no
      account_name
      bank_img
      status
      statusShow
      file_attachment
      cancel_remark
      remarkSystem
      transactions {
        transaction_id
        amount
        reason
        doc_id
        created_at
      }
      approve_date
      created_at
    }
  }
`;
