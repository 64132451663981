<template>
  <div class="order-seller" v-if="data">
    <div class="card-style web-card">
      <div class="col-12 manage-header" v-if="canCancel">
        <div class="c-body text-right">
          <button
            v-if="canCancel"
            class="btn btn-danger btn-lg"
            @click="cancelOrder(data)"
          >
            ยกเลิกใบสั่งซื้อ
          </button>
        </div>
      </div>
      <div style="margin: 0" class="col-8">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-hashtag" /></div>
          <div class="name">หมายเลขคำสั่งซื้อ</div>
        </div>
        <div class="c-body">
          <div>#{{ data.sellerOrderId }}</div>
        </div>
      </div>
      <div class="col-8 mt-6">
        <div class="c-header">
          <div class="icon text-primary">
            <span class="la" :class="getStatusIcon(data.status)" />
          </div>
          <div class="name">สถานะ</div>
        </div>
        <div class="c-body">
          <span
            class="label label-lg label-inline"
            v-bind:class="`label-light-${getStatusColor(data.status)}`"
          >
            {{ data.statusShow }}
          </span>
        </div>
      </div>
      <div class="col-8 mt-6">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-map-marker" /></div>
          <div class="name">ที่อยู่ในการจัดส่ง</div>
        </div>
        <div class="c-body">
          <div>
            <div v-if="data.address">
              {{ data.address.name }}, {{ data.address.phone }}
            </div>
            <div v-if="data.address">
              {{ renderAddress(data.address) }}
            </div>
          </div>
        </div>
        <div class="c-header mt-6">
          <div class="icon text-primary">
            <span class="la la-shipping-fast" />
          </div>
          <div class="name">ขนส่งโดย</div>
        </div>
        <div class="c-body">
          <div v-if="data.logistics">
            {{ data.logistics.logisticsName }}
          </div>
          <div v-if="!data.logistics">-</div>
        </div>
      </div>
    </div>
    <div
      class="card-style web-card mt-6"
      v-for="vendor in data.vendors"
      :key="vendor._id"
    >
      <div class="col-12">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-truck" /></div>
          <div class="name">ข้อมูลการขนส่ง</div>
        </div>
        <div class="c-body">
          <div v-if="vendor.transports.length === 0">
            ยังไม่มีข้อมูลการขนส่ง
          </div>
          <div
            class="text-danger mt-2"
            v-if="vendor.status === -10 && vendor.cancel_remark"
          >
            ยกเลิกเนื่องจาก {{ vendor.cancel_remark }}
          </div>
          <div
            class="row-transport d-flex justify-content-between align-items-center"
            v-for="item in vendor.transports"
            :key="item._id"
          >
            <div class="flex-fill">
              <div class="w-50">
                <span class="font-weight-bolder">รหัสพัสดุ</span>
                {{ item.transportNumber }}
              </div>
              <div
                class="mt-2 history"
                v-if="item.timeline_list && item.timeline_list.length"
              >
                <div
                  class="lg-log"
                  :class="i === 0 ? 'first' : ''"
                  v-for="(line, i) in item.timeline_list"
                  :key="line.name"
                >
                  <div class="legend">
                    <div class="icon"></div>
                    <div class="line"></div>
                  </div>
                  <div class="status-log">
                    <div class="status">
                      {{ line.name }}
                    </div>
                    <div class="time">
                      <span>{{ format_datetime(line.date) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8" v-if="vendor.can_receive">
            <button
              class="btn btn-success btn-lg"
              @click="confirmReceive(vendor)"
            >
              ฉันได้ตรวจสอบและยอมรับสินค้า
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mt-6">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-money-bill" /></div>
          <div class="name">รายละเอียดการชำระเงิน</div>
        </div>
        <div class="product-list mt-4">
          <table>
            <thead>
              <tr class="product-list-head">
                <th
                  class="text-left font-weight-bolder font-size-lg"
                  colspan="2"
                >
                  {{ vendor.vendor }}
                </th>
                <th class="price">ราคาต่อชิ้น</th>
                <th class="qty">จำนวน</th>
                <th class="price">ราคาขายสุทธิ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in vendor.items" :key="item._id">
                <td class="no">{{ index + 1 }}</td>
                <td>
                  <div class="product-item product">
                    <div
                      class="product-image"
                      :style="`background-image: url('${item.mainImage}');`"
                    ></div>
                    <div class="product-detail">
                      <div
                        title="หนังสือมังงะภาษาจีน อยู่ๆ ฉันก็กลายเป็นเจ้าหญิง"
                        class="product-name"
                      >
                        {{ item.productName }}
                      </div>
                      <div class="product-meta">
                        <div v-if="item.properties.length">
                          ตัวเลือกสินค้า :
                          <div
                            v-for="(prop, index) in item.properties"
                            :key="prop.id"
                            v-frag
                          >
                            <span v-if="!prop.image">
                              {{ prop.title }}
                              <div
                                v-frag
                                v-if="index < item.properties.length - 1"
                              >
                                ,
                              </div>
                            </span>
                            <img :src="prop.image" v-if="prop.image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="price">{{ format_number(item.price, 2) }}</td>
                <td class="qty">{{ item.amount_buy }}</td>
                <td class="price">
                  {{ format_number(item.amount_buy * item.price, 2) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-4" v-if="vendor.remark">
            หมายเหตุ : {{ vendor.remark }}
          </div>
        </div>
        <div class="income-container">
          <div class="income-label-column">
            <div class="income-item">
              <div class="income-label">รวมค่าสินค้า</div>
            </div>
            <div class="income-item">
              <div class="income-label">จัดส่ง</div>
            </div>
            <div class="income-item">
              <div class="income-label">ค่าขนส่งระหว่างประเทศ</div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-label">ค่าแพ็คสินค้า</div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-label">จัดส่งในประเทศของท่าน</div>
            </div>
            <div class="income-item total">
              <div class="income-label">ยอดชำระเงิน</div>
            </div>
          </div>
          <div class="income-value-column">
            <div class="income-item">
              <div class="income-value">
                {{ format_number(vendor.total_product, 2, true) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                {{ format_number(vendor.shipping_price, 2, true) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                {{ format_number(vendor.forwarder_price, 2, true) }}
              </div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-value">
                ฿{{ format_number(data.logistics.logisticsServicePrice, 2) }}
              </div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-value">
                ฿{{ format_number(data.logistics.price, 2) }}
              </div>
            </div>
            <div class="income-item total">
              <div class="income-value big-total">
                ฿{{
                  format_number(
                    vendor.total_pay +
                      (data.logistics
                        ? data.logistics.logisticsServicePrice +
                          data.logistics.price
                        : 0),
                    2
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-style web-card mt-6" v-if="data.vendors.length > 1">
      <div style="margin: 0" class="col-12">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-receipt" /></div>
          <div class="name">การชำระเงิน</div>
        </div>
        <div class="income-container">
          <div class="income-label-column">
            <div class="income-item">
              <div class="income-label">รวมค่าสินค้า</div>
            </div>
            <div class="income-item">
              <div class="income-label">ค่าจัดส่ง</div>
            </div>
            <div class="income-item">
              <div class="income-label">ค่าขนส่งระหว่างประเทศ</div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-label">ค่าแพ็คสินค้า</div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-label">จัดส่งในประเทศของท่าน</div>
            </div>
            <div class="income-item total">
              <div class="income-label">ยอดคำสั่งซื้อทั้งหมด</div>
            </div>
          </div>
          <div class="income-value-column">
            <div class="income-item">
              <div class="income-value">
                ฿{{ format_number(data.total_product, 2) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                ฿{{ format_number(data.shipping_price, 2) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                ฿{{ format_number(data.forwarder_price, 2) }}
              </div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-value">
                ฿{{ format_number(data.logistics.logisticsServicePrice, 2) }}
              </div>
            </div>
            <div class="income-item" v-if="data.logistics">
              <div class="income-value">
                ฿{{ format_number(data.logistics.price, 2) }}
              </div>
            </div>
            <div class="income-item total">
              <div class="income-value big-total">
                ฿{{ format_number(data.total, 2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  SELLER_ORDER,
  SELLER_ORDER_CANCEL,
  SELLER_ORDER_RECEIVE
} from "@/graphql/user/seller_order";
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import Swal from "sweetalert2";

export default {
  created() {
    document.title = `หมายเลขคำสั่งซื้อ | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `คำสั่งซื้อของฉัน`, route: "/seller/sale/order" },
      { title: `หมายเลขคำสั่งซื้อ` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      data: null,
      canCancel: false
    };
  },
  methods: {
    renderAddress(address) {
      const isBankok = address.province === "กรุงเทพมหานคร";
      const district =
        address.district && `${isBankok ? "แขวง" : "ตำบล"}${address.district}`;
      const amphoe =
        address.amphoe && `${isBankok ? "เขต" : "อำเภอ"}${address.amphoe}`;
      const province = address.province;
      const postcode = address.postcode && `${address.postcode}`;
      const address1 = address.address;
      const arr = [];
      this.checkPush(arr, address1);
      this.checkPush(arr, district);
      this.checkPush(arr, amphoe);
      this.checkPush(arr, province);
      this.checkPush(arr, postcode);
      return arr.join(" ");
    },
    checkPush(arr, val) {
      if (val) {
        arr.push(val);
      }
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    async cancelOrder(item) {
      const confirm = await Swal.fire({
        title: "ต้องการยกเลิกคำสั่งซื้อ ?",
        icon: "question",
        html: `ยกเลิกคำสั่งซื้อ #${item.sellerOrderId} หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        input: "textarea",
        inputLabel: "Message",
        inputPlaceholder: "เหตุผลในการยกเลิก",
        inputAttributes: {
          "aria-label": "Type your message here"
        }
      });
      if (!confirm.isConfirmed) {
        return;
      }
      const remark = confirm.value;
      const {
        data: { user_seller_order_cencel }
      } = await this.$apollo.mutate({
        mutation: SELLER_ORDER_CANCEL,
        variables: { _id: this.data._id, remark: remark }
      });
      const { isSuccess, message } = user_seller_order_cencel;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.user_seller_order.refetch();
      }
    },
    async confirmReceive(vendor) {
      const confirm = await Swal.fire({
        title: "ยืนยันได้รับสินค้า ?",
        icon: "question",
        html: `ได้รับสินค้าแล้ว`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.isConfirmed) {
        return;
      }
      const {
        data: { confirm_receive }
      } = await this.$apollo.mutate({
        mutation: SELLER_ORDER_RECEIVE,
        variables: { _id: this.data._id, sellerId: vendor._id }
      });
      const { isSuccess, message } = confirm_receive;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.user_seller_order.refetch();
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case -10:
          return "la-close";
        case -2:
          return "la-clock-o";
        case 0:
          return "la-box";
        case 10:
          return "la-truck";
        case 20:
          return "la-truck";
        case 90:
          return "la-check-circle-o";
        default:
          return "primary";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 40:
        case 90:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    user_seller_order: {
      query: SELLER_ORDER,
      variables() {
        return {
          id: this.id
        };
      },
      result({ loading, data }) {
        if (!loading && data) {
          const { isSuccess, message } = data.user_seller_order;
          if (!isSuccess) {
            this.$message.error(message);
          } else {
            this.data = data.user_seller_order.data;
            this.canCancel = data.user_seller_order.canCancel;
          }
        }
      }
    }
  }
};
</script>
