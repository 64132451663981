<template>
  <div class="row mt-4">
    <div class="col-lg-8">
      <div class="card card-custom card-stretch">
        <div class="card-body" v-if="payment && payment.record">
          <div class="form-group row mb-0 align-items-center fs-4">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >เลขที่รายการ</label
            >
            <div class="col-form-label col-lg-5 col-8">
              {{ payment.record.paymentDoc }}
            </div>
          </div>
          <div class="form-group row mb-0 align-items-center">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >ยอดเงินที่โอน</label
            >
            <div class="col-form-label col-lg-5 col-8 fs-2">
              {{ format_number(payment.record.amount, 2, true, true) }}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >วันที่สร้าง</label
            >
            <div class="col-form-label col-lg-5 col-8">
              {{ format_datetime(payment.record.created_at) }}
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >สถานะ</label
            >
            <div class="col-form-label col-lg-5 col-8">
              <span
                class="label label-lg label-inline"
                v-bind:class="
                  `label-light-${getStatusColor(payment.record.status)}`
                "
              >
                {{ payment.record.statusShow }}
              </span>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4">
              รูปธนาคาร
            </label>
            <div class="col-lg-5 col-8">
              <div class="uppy" id="kt_uppy_5">
                <div class="uppy-list">
                  <div v-if="payment.record.file_attachment">
                    <div
                      v-viewer
                      class="border symbol symbol-200 flex-shrink-0 mr-4"
                    >
                      <img
                        style="object-fit: cover"
                        :src="imageUrlApi(payment.record.file_attachment)"
                        :key="payment.record.file_attachment"
                        height="250"
                        width="250"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4">
              หลักฐานการโอนเงิน
            </label>
            <div class="col-lg-5 col-8">
              <div class="uppy" id="kt_uppy_5">
                <div class="uppy-list">
                  <div v-if="payment.record.pay_slip">
                    <div
                      v-viewer
                      class="border symbol symbol-200 flex-shrink-0 mr-4"
                    >
                      <img
                        style="object-fit: cover"
                        :src="imageUrlApi(payment.record.pay_slip)"
                        :key="payment.record.pay_slip"
                        height="250"
                        width="250"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label
              class="col-form-label text-right col-xl-3 col-lg-2 col-sm-2 col-3"
            ></label>
            <div class="col-form-label col-xl-5 col-lg-6 col-8">
              <div class="dashed"></div>
              <div class="my-4 text-right fs-4">
                เรทเงิน (หยวน) :
                {{ payment.record.rate }}
              </div>
              <div class="bg-light p-2 mb-4 rounded text-right fs-2">
                ยอดรวม :
                {{ " " }}
                {{
                  payment.record.baht.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                }}
                บาท
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { GET_TRANSFER } from "@/graphql/payment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { imageUrlApi, WEBSITE } from "@/config";
import bigDecimal from "js-big-decimal";

export default {
  name: "PaymentDetail",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "โอนเงินไปจีน", route: "/payments" },
      { title: `โอนเงินไปจีน #${this.id}` }
    ]);
  },
  created() {
    document.title = `โอนเงินไปจีน | ${WEBSITE}`;
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      guarantee: false,
      valid: false
    };
  },
  methods: {
    imageUrlApi,
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        default:
          return "primary";
      }
    },
    format_number(val, decimal = 0, format = false, yuan = false) {
      const currency = format ? (yuan ? "¥ " : "฿ ") : "";
      if (format) {
        if (val >= 0)
          return `${currency}${Number(
            bigDecimal.round(val, decimal)
          ).toLocaleString(undefined, {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal
          })}`;
        return `-${currency}${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    payment: {
      query: GET_TRANSFER,
      variables() {
        return {
          id: this.id
        };
      }
    }
  }
};
</script>
