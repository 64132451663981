<template>
  <div class="card card-custom card-stretch gutter-b card-shadowless">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        <div class="symbol symbol-60px mr-5">
          <span class="symbol-label" :class="bg_color">
            <i
              class="h-50 align-self-center icon-2x"
              :class="`${title_class} ${icon_color}`"
            />
          </span>
        </div>
        {{ title }}
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <table class="table table-borderless table-vertical-center">
        <thead>
          <tr>
            <th>ประเภทสินค้า</th>
            <th>฿/ปริมาตร</th>
            <th>฿/กิโล</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" v-bind:key="item._id">
            <td>
              {{ item.userLevelDetailName }}
            </td>
            <td>
              {{ item.userLevelDetailPriceCube }}
            </td>
            <td>
              {{ item.userLevelDetailPriceKg }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
export default {
  props: ["list", "title", "title_class", "icon_color", "bg_color"]
};
</script>
