<template>
  <div class="card card-custom card-stretch gutter-b card-shadowless">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        {{ title }}
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <table class="table table-borderless table-vertical-center">
        <thead>
          <tr>
            <th>พัสดุ</th>
            <th class="text-center">ขนส่ง</th>
            <th class="text-center">ราคา</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" v-bind:key="item._id">
            <td>
              <router-link :to="`forwarder/${item.transportId}`">
                {{ item.transportNumber }}
              </router-link>
            </td>
            <td class="text-center">
              <span
                class="label label-lg label-inline"
                v-bind:class="
                  `label-light-${item.ship_by === 'ek' ? 'success' : 'primary'}`
                "
              >
                {{ item.ship_by === "ek" ? "รถ" : "เรือ" }}
              </span>
            </td>
            <td class="text-center">
              {{ item.trackingPrice ? item.trackingPrice.priceValue : 0 }}฿
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
export default {
  props: ["title", "list"]
};
</script>
