<template>
  <div class="w-lg-550px bg-white rounded p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <b-form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      novalidate="novalidate"
      @submit.stop.prevent="onSubmit"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">Change Login Password</h1>
        <!--end::Title-->
      </div>
      <!--begin::Heading-->
      <!--begin::Input group-->
      <div
        class="mb-10 fv-row fv-plugins-icon-container"
        data-kt-password-meter="true"
      >
        <!--begin::Wrapper-->
        <div class="mb-1">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6"
            >Old Password</label
          >
          <!--end::Label-->
          <!--begin::Input wrapper-->
          <div class="position-relative mb-3">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              :type="!showPassword ? 'password' : 'text'"
              placeholder=""
              v-model="form.password_current"
              :state="validateState('password_current')"
              autocomplete="off"
            />
            <span
              class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-20 me-n2"
              @click="togglePassword"
            >
              <i
                class="la la-eye-slash fs-2"
                :class="{
                  'd-none': showPassword
                }"
              ></i>
              <i
                class="la la-eye fs-2"
                :class="{
                  'd-none': !showPassword
                }"
              ></i>
            </span>
          </div>
          <!--end::Input wrapper-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Input group=-->
      <!--begin::Input group=-->
      <div class="fv-row mb-10 fv-plugins-icon-container">
        <label class="form-label fw-bolder text-dark fs-6">New Password</label>
        <div class="position-relative mb-3">
          <b-form-input
            class="form-control form-control-lg form-control-solid"
            :type="!showPasswordNew ? 'password' : 'text'"
            placeholder=""
            v-model="form.password_new"
            :state="validateState('password_new')"
            autocomplete="off"
          />
          <span
            class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-20 me-n2"
            @click="togglePasswordNew"
          >
            <i
              class="la la-eye-slash fs-2"
              :class="{
                'd-none': showPasswordNew
              }"
            ></i>
            <i
              class="la la-eye fs-2"
              :class="{
                'd-none': !showPasswordNew
              }"
            ></i>
          </span>
        </div>
        <!--end::Input wrapper-->
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
      <!--end::Input group=-->
      <!--begin::Input group=-->
      <div class="fv-row mb-10 fv-plugins-icon-container">
        <label class="form-label fw-bolder text-dark fs-6"
          >Confirm New Password</label
        >
        <b-form-input
          class="form-control form-control-lg form-control-solid"
          type="password"
          placeholder=""
          v-model="form.confirmPassword"
          :state="validateState('confirmPassword')"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
      <!--end::Input group=-->
      <!--begin::Action-->
      <div class="text-center">
        <button
          type="submit"
          :disabled="loading"
          id="kt_new_password_submit"
          class="btn btn-lg btn-primary fw-bolder btn-block"
        >
          <span class="indicator-label" v-if="!loading">Confirm changes</span>
          <span class="indicator-progress" v-if="loading"
            >Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span
          ></span>
        </button>
      </div>
      <!--end::Action-->
      <div></div>
    </b-form>
    <!--end::Form-->
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CHANGE_PASSWORD } from "@/graphql/profile";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "เปลี่ยนรหัสผ่าน" }]);
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      showPasswordNew: false,
      form: {
        password_current: "",
        password_new: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    form: {
      password_current: {
        required,
        minLength: minLength(3)
      },
      password_new: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: { required, sameAsPassword: sameAs("password_new") }
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordNew() {
      this.showPasswordNew = !this.showPasswordNew;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันการบันทึกข้อมูล ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const password_new = this.$v.form.password_new.$model;
      const password_current = this.$v.form.password_current.$model;

      const input = {
        password_new,
        password_current
      };

      this.loading = true;

      const {
        data: { changePassword }
      } = await this.$apollo.mutate({
        mutation: CHANGE_PASSWORD,
        variables: { input }
      });
      this.loading = false;
      if (!changePassword.isSuccess) {
        this.$message.error(changePassword.message);
      } else {
        this.$message.success(changePassword.message);
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "login" }));
      }
    }
  }
};
</script>
