<template>
  <b-modal
    id="print-address-modal"
    centered
    size="lg"
    title="เลือกใบส่งพัสดุ"
    :ok-title="`Print (${selected.length})`"
    @ok="handleOk"
  >
    <b-overlay :show="$apollo.loading" rounded="sm">
      <div class="table-responsive" v-if="systemPayshippingList">
        <table
          class="table table-head-custom table-head-bg table-borderless table-vertical-center"
        >
          <thead>
            <tr>
              <th></th>
              <th>วันที่สร้าง</th>
              <th class="text-center text-nowrap">รหัสใบส่ง</th>
              <th class="text-center">รหัสผู้ใช้</th>
              <th class="text-center">สถานะ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in systemPayshippingList.records"
              v-bind:key="item.payshipping_doc"
            >
              <td>
                <span style="width: 20px">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      :value="item.payshipping_id"
                      :checked="checkedItem(item)"
                      v-on:change="selectItem($event, item)"
                    />&nbsp;
                    <span> </span>
                  </label>
                </span>
              </td>
              <td class="pl-2">
                {{ format_datetime(item.created_at) }}
              </td>
              <td class="text-center">
                <div class="mb-2 font-weight-bold">
                  <router-link
                    :to="`/admin/shippay/bill/${item.payshipping_id}`"
                  >
                    {{ item.payshipping_doc }}
                  </router-link>
                </div>
              </td>
              <td class="text-center">
                <router-link
                  v-if="item.user"
                  :to="`/admin/users/${item.user.userId}`"
                >
                  {{ item.user.username }}
                </router-link>
                <span v-if="!item.user"> - </span>
              </td>
              <td class="text-center text-nowrap">
                <span
                  class="label label-lg label-inline"
                  v-bind:class="`label-light-${getStatusColor(item.status)}`"
                >
                  {{ item.statusShow }}
                </span>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="
                systemPayshippingList &&
                  systemPayshippingList.records &&
                  !systemPayshippingList.records.length
              "
            >
              <td colspan="8" class="pt-8">ไม่พบรายการใบส่งพัสดุ</td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-pagination
        v-model="page"
        class="mt-0 mb-0"
        size="md"
        v-if="systemPayshippingList && systemPayshippingList.recordsData"
        :total-rows="systemPayshippingList.recordsData.recordsFiltered"
        :per-page="pageSize"
      ></b-pagination>
    </b-overlay>
  </b-modal>
</template>
<script>
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import { GET_PAYMENTS } from "@/graphql/admin/payshipping";
import { PRINT_BILL_ADDRESS } from "@/core/services/store/payshipping.module";

export default {
  props: ["title", "_id", "refetch", "is_refund"],
  mixins: [validationMixin],
  data() {
    return {
      selected: [],
      page: 1,
      pageSize: 10,
      status: "",
      search: ""
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    checkedItem(item) {
      return this.selected.find(x => x.payshipping_id === item.payshipping_id);
    },
    selectItem(event, item) {
      const checked = event.target.checked;
      const found = this.selected.find(
        x => x.payshipping_id === item.payshipping_id
      );
      if (!found && checked) {
        this.selected.push(item);
      } else if (found) {
        const index = this.selected.indexOf(found);
        this.selected.splice(index, 1);
      }
    },
    async handleSubmit() {
      if (!this.selected.length) {
        return;
      }

      this.$store
        .dispatch(PRINT_BILL_ADDRESS, {
          ids: this.selected.map(item => item.payshipping_id)
        })
        .then(data => {
          const myWindow = window.open("");
          myWindow.document.write(data);
          myWindow.document.close();
        })
        .catch(() => {
          this.$message.error("ไม่สามารถปริ้นได้ กรุณาลองใหม่อีกครั้ง");
        });
    },
    getStatusColor(status) {
      switch (status) {
        case -1:
        case -10:
          return "default";
        case 0:
          return "warning";
        case 22:
          return "primary";
        case 21:
        case 11:
        case 12:
          return "warning";
        case 101:
        case 102:
          return "success";
        default:
          return "primary";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  },
  apollo: {
    systemPayshippingList: {
      query: GET_PAYMENTS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    }
  }
};
</script>
