import gql from "graphql-tag";

export const GET_CATEGORY_LIST = gql`
  query {
    category_list {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        categoryId
        sequenceNumber
        originalName
      }
    }
  }
`;
