// action types
export const UPDATE_CHECKOUT = "updateCheckout";

// mutation types
export const SET_CHECKOUT = "setCheckout";

const state = {
  checkout: {},
  vendor: {},
  rateOrder: 0,
  serviceCube: 0,
  serviceKg: 0
};

const getters = {
  checkout(state) {
    return state.checkout;
  },

  vendor(state) {
    return state.vendor;
  },

  rateOrder(state) {
    return state.rateOrder;
  },

  serviceCube(state) {
    return state.serviceCube;
  },

  serviceKg(state) {
    return state.serviceKg;
  }
};

const actions = {
  [UPDATE_CHECKOUT](context, payload) {
    context.commit(SET_CHECKOUT, payload);
  }
};

const mutations = {
  [SET_CHECKOUT](
    state,
    { selected, vendor, rateOrder, serviceCube, serviceKg }
  ) {
    state.checkout = selected;
    state.vendor = vendor;
    state.rateOrder = rateOrder;
    state.serviceCube = serviceCube;
    state.serviceKg = serviceKg;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
