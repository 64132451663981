<template>
  <div>
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <!--begin::Stats Widget 29-->
        <div
          class="card card-custom bgi-no-repeat card-stretch gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(/media/svg/shapes/abstract-1.svg);
          "
        >
          <!--begin::Body-->
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <span class="svg-icon svg-icon-2x">
                <inline-svg src="/media/svg/icons/Shopping/Wallet.svg" />
              </span>
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
              v-if="cash"
            >
              ฿
              {{
                cash.baht.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }}
            </span>
            <span class="font-weight-bold text-muted font-size-lg">
              เงินในกระเป๋า
            </span>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 29-->
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="card card-custom gutter-b card-stretch card-shadowless">
          <div class="card-body p-0 d-flex">
            <router-link
              to="/wallet/topup/create"
              class="d-flex align-items-start justify-content-start flex-grow-1 bg-light-warning p-8 card-rounded flex-grow-1 position-relative"
            >
              <div
                class="d-flex flex-column align-items-start flex-grow-1 h-100"
              >
                <div class="p-1 flex-grow-1">
                  <h4 class="text-warning font-size-h2 font-weight-bolder">
                    เติมเงินเข้าระบบ
                  </h4>
                  <p class="text-dark-50 fs-3 font-weight-bold mt-3">
                    เพื่อชำระค่าสินค้า / ค่าบริการ
                  </p>
                </div>
              </div>
              <div class="position-absolute right-0 bottom-0 overflow-hidden">
                <img
                  src="/media/svg/humans/topup-1.webp"
                  alt="เติมเงินเข้าระบบ"
                  class="max-w-150px max-w-xl-200px mb-n6 mr-n6"
                />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            ธุรกรรมการเงิน
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            รายการธุรกรรมการเงินที่เกิดขึ้นในระบบ
          </span>
        </h3>

        <div class="card-toolbar">
          <div class="input-group input-group-solid" style="width: 280px">
            <input
              type="text"
              class="form-control"
              placeholder="ค้นหา"
              @input="debounceInput"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </div>
          </div>
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer"
                data-toggle="tab"
                :class="{ active: this.cat === 1 }"
                @click="pushCat(1)"
                >ธุรกรรมการเงิน</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer"
                data-toggle="tab"
                :class="{ active: this.cat === 2 }"
                @click="pushCat(2)"
                >เติมเงิน</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer"
                data-toggle="tab"
                :class="{ active: this.cat === 3 }"
                @click="pushCat(3)"
                >ถอนเงิน</a
              >
            </li>
          </ul>
          <div>
            <router-link
              to="/wallet/topup/create"
              class="btn btn-success font-weight-bolder font-size-sm"
            >
              <span class="svg-icon svg-icon-md svg-icon-white">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Communication/Add-user.svg-->
                <inline-svg src="/media/svg/icons/Shopping/Bitcoin.svg" />
                <!--end::Svg Icon-->
              </span>
              เติมเงินเข้าระบบ
            </router-link>
            <router-link
              to="/wallet/withdraw/create"
              class="btn btn-warning font-weight-bolder font-size-sm ml-4"
            >
              <span class="svg-icon svg-icon-md svg-icon-white">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Communication/Add-user.svg-->
                <inline-svg src="/media/svg/icons/Shopping/ATM.svg" />
                <!--end::Svg Icon-->
              </span>
              ถอนเงิน
            </router-link>
          </div>
        </div>
      </div>
      <!--end::Header-->
      <TransactionList v-if="cat === 1" :search="search" />
      <TopupList v-if="cat === 2" :search="search" />
      <WithdrawList v-if="cat === 3" :search="search" />
    </div>
  </div>
</template>
<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import TopupList from "./components/TopupList";
import TransactionList from "./components/TransactionList";
import WithdrawList from "./components/WithdrawList";
import { GET_CASH } from "@/graphql/profile";
import { imageUrlApi, WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "WalletList",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "กระเป๋าเงิน" }]);
  },
  created() {
    document.title = `กระเป๋าเงิน | ${WEBSITE}`;
  },
  components: {
    TopupList,
    TransactionList,
    WithdrawList
  },
  data() {
    const { cat = 1 } = this.$route.query;
    return {
      page: 1,
      pageSize: 10,
      loading: false,
      status: "",
      search: "",
      cat: Number(cat)
    };
  },
  methods: {
    imageUrlApi,
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    pushCat(val) {
      const newpath = `${this.$route.path}?cat=${val}`;
      if (this.$route.fullPath !== newpath) {
        this.$router.push({ name: "wallet", query: { cat: val } });
      }
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    }
  },
  watch: {
    "$route.query.cat": function(id) {
      this.cat = Number(id) || 1;
    }
  },
  apollo: {
    cash: {
      query: GET_CASH
    }
  }
};
</script>
