<template>
  <div v-frag>
    <b-dropdown
      :text="getText()"
      variant="white"
      block
      size="lg"
      class="border mb-4 d-lg-none"
      style="width: 100%"
    >
      <b-dropdown-item to="/seller/info">
        <i class="menu-icon la la-user-ninja icon-xl mr-1"></i>
        <span class="menu-text">ข้อมูลผู้ขาย</span>
      </b-dropdown-item>
      <b-dropdown-item to="/seller/product">
        <i class="menu-icon la la-shopping-bag icon-xl mr-1"></i>
        <span class="menu-text">สินค้าของฉัน</span>
      </b-dropdown-item>
      <b-dropdown-item to="/seller/product">
        <i class="menu-icon la la-clipboard-list icon-xl mr-1"></i>
        <span class="menu-text">คำสั่งซื้อของฉัน</span>
      </b-dropdown-item>
      <b-dropdown-item to="/seller/inventory">
        <i class="menu-icon la la-boxes icon-xl mr-1"></i>
        <span class="menu-text">ประวัติสต็อกสินค้า</span>
      </b-dropdown-item>
      <b-dropdown-item to="/chat/seller">
        <i class="menu-icon la la-sms icon-xl mr-1"></i>
        <span class="menu-text">สนทนา</span>
      </b-dropdown-item>
    </b-dropdown>
    <div class="d-flex">
      <div
        class="aside aside-left aside-fixed d-flex flex-column flex-row-auto seller"
      >
        <!-- begin:: Aside Menu -->
        <div class="aside-menu-wrapper flex-column-fluid">
          <div
            class="aside-menu min-h-lg-800px"
            data-menu-vertical="1"
            data-menu-dropdown-timeout="500"
          >
            <!-- example static menu here -->
            <perfect-scrollbar
              class="aside-menu scroll"
              style="max-height: 90vh; position: relative"
            >
              <ul class="menu-nav">
                <router-link
                  to="/seller/info"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-user-ninja icon-xl"></i>
                      <span class="menu-text">ข้อมูลผู้ขาย</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/seller/product"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-shopping-bag icon-xl"></i>
                      <span class="menu-text">สินค้าของฉัน</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/seller/sale/order"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-clipboard-list icon-xl"></i>
                      <span class="menu-text">
                        <div class="noti position-relative">
                          คำสั่งซื้อของฉัน
                          <span
                            v-if="getCurrentNoti.seller_order"
                            class="badge badge-pill badge-danger"
                          >
                            {{ getCurrentNoti.seller_order }}
                          </span>
                        </div>
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/seller/inventory"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-boxes icon-xl"></i>
                      <span class="menu-text">ประวัติสต็อกสินค้า</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/chat/seller"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-sms icon-xl"></i>
                      <span class="menu-text">
                        <div class="noti position-relative">
                          สนทนา
                          <span
                            v-if="getCurrentNoti.seller_message"
                            class="badge badge-pill badge-danger"
                          >
                            {{ getCurrentNoti.seller_message }}
                          </span>
                        </div>
                      </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>

      <div class="content-wrapper flex-row-fluid ml-lg-4">
        <b-carousel
          id="banner-seller"
          v-model="slide"
          :interval="4000"
          style="text-shadow: 1px 1px 2px #333"
          class="mb-4 rounded overflow-hidden"
        >
          <b-carousel-slide
            img-src="/assets/banner_seller-01.png"
          ></b-carousel-slide>
        </b-carousel>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "LayoutSeller",
  computed: {
    ...mapGetters(["currentPermission", "getCurrentNoti"])
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `มาเป็นผู้ขาย` }]);
  },
  methods: {
    getText() {
      const path = this.$route.path;
      switch (path) {
        case "/seller/product":
          return "สินค้าของฉัน";
        case "/seller/order":
          return "คำสั่งซื้อของฉัน";
        default:
          return "กรุณาเลือก";
      }
    }
  }
};
</script>
