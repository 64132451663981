<template>
  <div class="card card-custom card-stretch" v-if="systemWithdraw">
    <div class="card-body">
      <div class="row" v-if="systemWithdraw">
        <div class="col-lg-8">
          <div class="col-md-6 offset-sm-3">
            <label class="option">
              <span class="option-label">
                <span class="option-head justify-content-start">
                  <div class="symbol symbol-40 flex-shrink-0 mr-4">
                    <div
                      class="symbol-label"
                      :style="
                        `background-image: url('${BANK_URL}${systemWithdraw.bank_img}')`
                      "
                    ></div>
                  </div>
                  <span class="option-title">{{
                    systemWithdraw.bank_name
                  }}</span>
                </span>
                <span class="option-body">
                  <div class="mb-1" style="font-size: 1.1rem">
                    เลขบัญชี {{ systemWithdraw.account_no }}
                  </div>
                  <div style="font-size: 1.1rem">
                    ชื่อบัญชี {{ systemWithdraw.account_name }}
                  </div>
                </span>
              </span>
            </label>
            <div class="fv-plugins-message-container"></div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-8">
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-3 col-sm-4 col-4"
              >ผู้ใช้</label
            >
            <div class="col-form-label col-lg-4 col-md-6 col-sm-6 col-8">
              <router-link :to="`/admin/users/${systemWithdraw.userId}`">
                {{ systemWithdraw.user ? systemWithdraw.user.username : "-" }}
              </router-link>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-3 col-sm-4 col-4">
              เลขที่เอกสาร
            </label>
            <div class="col-form-label col-lg-4 col-md-6 col-sm-6 col-8">
              {{ systemWithdraw.withdraw_no }}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-3 col-sm-4 col-4">
              จำนวนเงิน
            </label>
            <div class="col-form-label col-lg-4 col-md-6 col-sm-6 col-8">
              {{ systemWithdraw.amount }}
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-3 col-sm-4 col-4">
              สถานะ
            </label>
            <div class="col-form-label col-lg-4 col-md-6 col-sm-6 col-8">
              <span
                class="label label-lg label-inline"
                v-bind:class="
                  `label-light-${getStatusColor(systemWithdraw.status)}`
                "
              >
                {{ systemWithdraw.statusShow }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-sm-4 col-4 col-form-label text-right">
              สลิปโอนเงิน
            </label>
            <div class="col-lg-5 col-md-6 col-sm-6 col-8">
              <div class="uppy" id="kt_uppy_5">
                <div class="uppy-wrapper">
                  <div class="uppy-Root uppy-FileInput-container">
                    <input
                      class="uppy-FileInput-input uppy-input-control"
                      ref="fileInput"
                      type="file"
                      accept="image/*"
                      name="files[]"
                      multiple=""
                      @input="pickFile"
                      id="file"
                      style=""
                    />
                    <label
                      class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                      for="file"
                    >
                      เลือก สลิปโอนเงิน
                    </label>
                  </div>
                </div>
                <div class="uppy-list">
                  <div
                    class="uppy-list-item"
                    v-if="systemWithdraw.file_attachment || previewImage"
                  >
                    <div
                      v-viewer
                      class="border symbol symbol-60 flex-shrink-0 mr-4"
                    >
                      <img
                        style="object-fit: contain"
                        :src="
                          previewImage ||
                            imageUrlApi(systemWithdraw.file_attachment)
                        "
                        height="200"
                        width="200"
                      />
                    </div>
                    <div class="uppy-list-label">
                      {{ file && file.name }}
                    </div>
                    <span class="uppy-list-remove" @click="removeFile()">
                      <i class="flaticon2-cancel-music"></i>
                    </span>
                  </div>
                </div>
              </div>
              <span class="form-text text-muted">
                อัพโหลดไฟล์ไม่เกิน 10MB.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-10 ml-auto">
          <button
            class="btn btn-success mr-2"
            v-on:click="approve($event, systemWithdraw)"
            style="width: 140px"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
            Approve
          </button>
          <button
            v-if="systemWithdraw.status === 0"
            v-on:click="reject($event, systemWithdraw)"
            class="btn btn-secondary"
            style="width: 140px"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import {
  APPROVE_WITHDRAW,
  REJECT_WITHDRAW,
  GET_WITHDRAW
} from "@/graphql/admin/withdraw";
import { getBankName } from "@/config";
import { imageUrlApi } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BANK_URL } from "@/config";

export default {
  name: "WithdrawDetail",
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `กระเป๋าเงิน`, route: "/admin/wallet?cat=3" },
      { title: `ถอนเงิน` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      BANK_URL,
      valid: true,
      id: Number(id),
      previewImage: null,
      file: null,
      fileValid: true,
      rules: {
        required: value => !!value || "กรุณากรอกข้อมูล"
      }
    };
  },
  methods: {
    getBankName,
    imageUrlApi,
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    async approve(e, value) {
      if (!this.file) {
        this.$message.error("กรุณาแนบไฟล์ หลักฐานการโอนเงิน");
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันอนุมัติโอนเงิน ?",
        icon: "question",
        html: `อนุมัติถอนเงิน ${value.withdraw_no}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { approveWithdraw }
      } = await this.$apollo.mutate({
        mutation: APPROVE_WITHDRAW,
        variables: {
          approve: {
            id: value.withdraw_id,
            file: this.file
          }
        }
      });
      if (approveWithdraw.is_error) {
        this.$message.error(approveWithdraw.message);
      } else {
        this.$message.success(approveWithdraw.message);
        this.$apollo.queries.systemWithdraw.refetch();
      }
    },
    async reject(e, value) {
      const confirm = await Swal.fire({
        title: "ยืนยันยกเลิกถอนเงิน ?",
        icon: "question",
        html: `ยกเลิกถอนเงิน ${value.withdraw_no}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { cancelWithdraw }
      } = await this.$apollo.mutate({
        mutation: REJECT_WITHDRAW,
        variables: {
          id: value.withdraw_id
        }
      });
      if (cancelWithdraw.is_error) {
        this.$message.error(cancelWithdraw.message);
      } else {
        this.$message.success(cancelWithdraw.message);
        this.$apollo.queries.systemWithdraw.refetch();
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
    },
    getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    systemWithdraw: {
      query: GET_WITHDRAW,
      variables() {
        return {
          id: this.id
        };
      }
    }
  }
};
</script>
