<template>
  <b-form
    class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
    novalidate="novalidate"
    @submit.stop.prevent="onSubmit"
  >
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-dark mb-3">Forgot Password ?</h1>
      <!--end::Title-->
      <!--begin::Link-->
      <div class="text-gray-400 fw-bold fs-4">
        Enter your email to reset your password.
      </div>
      <!--end::Link-->
    </div>
    <!--begin::Heading-->
    <!--begin::Input group-->
    <div class="fv-row mb-10 fv-plugins-icon-container">
      <label class="form-label fw-bolder text-gray-900 fs-6">Email</label>
      <b-form-input
        class="form-control form-control-solid"
        type="email"
        placeholder=""
        name="email"
        v-model="form.email"
        :state="validateState('email')"
        autocomplete="off"
      />
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group-->
    <!--begin::Actions-->
    <div class="d-flex flex-wrap justify-content-center pb-lg-0">
      <button
        type="submit"
        :disabled="loading"
        id="kt_password_reset_submit"
        class="btn btn-lg btn-primary fw-bolder mr-4 flex-fill"
      >
        <span class="indicator-label" v-if="!loading">Submit</span>
        <span class="indicator-progress" v-if="loading"
          >Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span
        ></span>
      </button>
      <router-link to="/login" class="flex-fill">
        <button
          type="button"
          class="btn btn-lg btn-secondary fw-bolder btn-block"
        >
          <span class="indicator-label" v-if="!loading">Cancel</span>
        </button>
      </router-link>
    </div>
    <!--end::Actions-->
  </b-form>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { RESET_PASSWORD } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "resetPassword",
  data() {
    return {
      loading: false,
      form: {
        email: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;

      this.loading = true;
      // send login request
      this.$store
        .dispatch(RESET_PASSWORD, { email })
        // go to which page after successfully login
        .then(({ isSuccess, message }) => {
          this.loading = false;
          if (isSuccess) {
            this.$router.push("/login");
            Swal.fire({
              title: "",
              text: message,
              icon: "success"
            });
          } else {
            Swal.fire({
              title: "",
              text: message,
              icon: "error"
            });
          }
        })
        .catch(({ message }) => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: message,
            icon: "error"
          });
        });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
