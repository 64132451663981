<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div
      class="card card-custom card-stretch"
      v-if="product_list && product_list.isSuccess"
    >
      <div class="card-header border-0 pt-5">
        <h3 class="card-title flex-column">
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              สินค้า {{ product_list.recordsData.recordsFiltered }} รายการ
            </span>
          </div>
        </h3>
      </div>
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <div class="input-group input-group-solid mr-4" style="width: 280px">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหา"
            @input="debounceInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </div>
        </div>
        <router-link
          :to="`/seller/product/new`"
          class="btn btn-success font-weight-bolder font-size-sm mr-4"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
          </span>
          เพิ่มสินค้าใหม่
        </router-link>
        <button
          @click="$apollo.queries.product_list.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
      </div>
      <div class="card-body pt-2 pb-0">
        <div class="table-responsive">
          <table class="table table-head-bg table-vertical-center">
            <thead>
              <tr>
                <th class="pl-4 text-nowrap">รหัสสินค้า</th>
                <th class="pl-4 text-nowrap">ชื่อสินค้า</th>
                <th class="pl-4 text-nowrap text-center">ประเภทสินค้า</th>
                <th class="pl-4 text-nowrap text-center">ราคา</th>
                <th class="pl-4 text-nowrap text-center">คลัง</th>
                <th class="pl-4 text-nowrap text-center">แสดง</th>
                <th width="80"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in product_list.records" v-bind:key="i">
                <td class="pl-4">
                  <router-link
                    :to="`/seller/product/${item.productId}`"
                    class="d-flex align-items-center"
                  >
                    {{ item.productId }}
                  </router-link>
                </td>
                <td class="pl-4">
                  <router-link
                    :to="`/seller/product/${item.productId}`"
                    class="d-flex align-items-center"
                  >
                    <div class="symbol symbol-50 symbol-light mr-2">
                      <span class="symbol-label">
                        <img
                          :src="`${imageUrlApi(item.mainImage)}`"
                          class="h-75 align-self-center"
                          :alt="item.productName"
                        />
                      </span>
                    </div>
                    <div class="text-truncate" style="max-width: 200px">
                      {{ item.productName }}
                    </div>
                  </router-link>
                </td>
                <td class="px-2 text-center">
                  {{ item.category ? item.category.originalName : "-" }}
                </td>
                <td class="px-2 text-center">
                  {{ item.priceShow }}
                </td>
                <td class="px-2 text-center">
                  {{ item.stock }}
                </td>
                <td class="px-2 text-center">
                  <span class="switch switch-sm mt-1 justify-content-center">
                    <label class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        :checked="item.is_published"
                        v-model="item.is_published"
                        @change="toggleDefault(item._id, item.is_published)"
                      />
                      <span></span>
                    </label>
                  </span>
                </td>
                <td class="text-right pr-4 text-nowrap">
                  <button
                    class="btn btn-icon btn-danger btn-sm mr-2"
                    @click="deleteItem(item._id, item.productName)"
                  >
                    <span class="la la-trash icon-lg" />
                  </button>
                  <router-link
                    :to="`/seller/product/${item.productId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                    </span>
                  </router-link>
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="
                  product_list &&
                    product_list.records &&
                    !product_list.records.length
                "
              >
                <td colspan="6" class="pt-8">ไม่พบข้อมูลสินค้า</td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="product_list && product_list.recordsData"
          :total-rows="product_list.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
    </div>
    <div
      class="card card-custom card-stretch"
      v-if="product_list && !product_list.isSuccess"
    >
      <div class="text-center py-8">
        <div class="mb-4 font-size-lg">
          {{ product_list.message }}
        </div>
        <router-link to="/seller/info">
          <button class="btn btn-primary">ตั้งค่า ข้อมูลผู้ขาย</button>
        </router-link>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import {
  GET_PRODUCT_LIST,
  DELETE_PRODUCT,
  UPDATE_PRODUCT_PUBLISHED
} from "@/graphql/seller_product";
import Swal from "sweetalert2";
import debounce from "debounce";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi } from "@/config";

export default {
  created() {
    document.title = `สินค้าของฉัน | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `มาเป็นผู้ขาย`, route: "/seller" },
      { title: `สินค้าของฉัน` }
    ]);
  },
  data() {
    return {
      pageSize: 10,
      page: 1,
      search: ""
    };
  },
  methods: {
    imageUrlApi,
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    async toggleDefault(_id, is_published) {
      const {
        data: { updateSellerProductPublished }
      } = await this.$apollo.mutate({
        mutation: UPDATE_PRODUCT_PUBLISHED,
        variables: {
          _id,
          is_published
        }
      });
      if (!updateSellerProductPublished.isSuccess) {
        this.$message.error(updateSellerProductPublished.message);
      } else {
        this.$apollo.queries.product_list.refetch();
      }
    },
    async deleteItem(_id, name) {
      const confirm = await Swal.fire({
        title: "ลบข้อมูลสินค้า ?",
        icon: "question",
        html: `ยืนยันการลบ ${name} ใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { deleteSellerProduct }
      } = await this.$apollo.mutate({
        mutation: DELETE_PRODUCT,
        variables: { _id }
      });
      if (!deleteSellerProduct.isSuccess) {
        this.$message.error(deleteSellerProduct.message);
      } else {
        this.$message.success(deleteSellerProduct.message);
        this.$apollo.queries.product_list.refetch();
      }
    }
  },
  apollo: {
    product_list: {
      query: GET_PRODUCT_LIST,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
