<template>
  <table class="table">
    <thead>
      <tr>
        <th class="pl-0 font-weight-bold text-muted text-nowrap">
          ชื่อ - นามสกุล
        </th>
        <th class="text-center font-weight-bold text-muted">
          รายละเอียดที่อยู่
        </th>
        <th class="text-center font-weight-bold text-muted">เลือก</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in list" v-bind:key="item._id">
        <td class="align-middle text-nowrap">
          {{ item.name }}
        </td>
        <td class="align-middle">
          {{ renderAddress(item) }}
        </td>
        <td class="text-center align-middle">
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm"
            @click="choose(item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
          </button>
        </td>
      </tr>
      <tr v-if="!list || !list.length">
        <td colspan="3" class="text-center p-4">ไม่มีข้อมูล</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: ["list", "onSelect"],
  methods: {
    choose(item) {
      if (this.onSelect) {
        this.onSelect(item);
      }
      this.$nextTick(() => {
        this.$bvModal.hide("address-modal");
      });
    },
    renderAddress(address) {
      const isBankok = address.province === "กรุงเทพมหานคร";
      const district =
        address.district && `${isBankok ? "แขวง " : "ตำบล"}${address.district}`;
      const amphoe =
        address.amphoe && `${isBankok ? "เขต " : "อำเภอ"}${address.amphoe}`;
      const province = address.province;
      const postcode = address.postcode && `${address.postcode}`;
      const address1 = address.address;
      const phone = address.phone && `เบอร์โทร ${address.phone}`;
      const arr = [];
      this.checkPush(arr, address1);
      this.checkPush(arr, district);
      this.checkPush(arr, amphoe);
      this.checkPush(arr, province);
      this.checkPush(arr, postcode);
      this.checkPush(arr, phone);
      return arr.join(", ");
    },
    checkPush(arr, val) {
      if (val) {
        arr.push(val);
      }
    }
  }
};
</script>
