<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card>
      <error-5 v-if="messageError" />
      <div v-if="product">
        <b-row>
          <b-col lg="4" class="pb-2">
            <div class="product-details-large">
              <img
                class="product-zoom"
                :alt="product.productName"
                :src="product.mainImage"
              />
            </div>
          </b-col>
          <b-col lg="8">
            <div class="mb-6">
              <p class="h2 font-weight-bold">{{ product.productName }}</p>
            </div>
            <div>
              <a class="btn btn-secondary" :href="product.url" target="_blank">
                ดูสินค้า
              </a>
            </div>
            <div class="detail_price">
              <div class="detail_price_title">ราคา</div>
              <div v-if="!product.priceRange.length" class="d-flex flex-column">
                <div class="detail_price_value">
                  {{ drawPriceCurrency(product, price) }}
                </div>
                <p class="detail_price_sub" v-if="!product.currency">
                  (ประมาณ ฿{{ format_number(price * rate, 2) }})
                </p>
              </div>
              <template v-if="product.priceRange.length">
                <div class="d-flex flex-fill">
                  <div
                    v-for="(range, index) in product.priceRange"
                    :key="range[1]"
                    class="flex-fill"
                    :class="{ 'font-weight-bolder': rangeActive === index }"
                  >
                    <div class="detail_price_value">
                      {{ drawPriceCurrency(product, range[1]) }}
                    </div>
                    <p class="detail_price_sub fs-3" v-if="!product.currency">
                      ฿{{ format_number(range[1] * rate, 2) }}
                    </p>
                  </div>
                </div>
                <div class="detail_price_title">จำนวนสินค้า</div>
                <div class="d-flex flex-fill">
                  <div
                    v-for="(range, index) in product.priceRange"
                    :key="range[0]"
                    class="flex-fill fs-3"
                    :class="{ 'font-weight-bolder': rangeActive === index }"
                  >
                    {{ drawRangeAmount(range[0], index) }}
                  </div>
                </div>
              </template>
            </div>

            <div class="sku_group">
              <div
                class="sku_container"
                v-if="product.properties[0] && product.properties[1]"
              >
                <div v-if="product.properties[0].title" class="sku_title">
                  {{ product.properties[0].title }}
                </div>
                <div class="sku_text">
                  <div
                    v-for="sku in product.properties[0].list"
                    v-bind:key="sku.id"
                    :class="[
                      selectedSku === sku.id ? 'selected' : '',
                      hasAmount[sku.id] ? ' completed' : ''
                    ]"
                    @click="clickSku(sku.id)"
                    class="sku_one"
                  >
                    <img v-if="sku.image" :src="sku.image" :alt="sku.title" />
                    <button v-if="!sku.image" class="btn-price">
                      {{ sku.title }}
                    </button>
                    <div
                      v-if="selectedSku !== sku.id && hasAmount[sku.id]"
                      class="cor"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="sku_container"
                v-if="product.properties[1] || product.properties[0]"
              >
                <div
                  v-if="(product.properties[1] || product.properties[0]).title"
                  class="sku_title"
                >
                  {{ (product.properties[1] || product.properties[0]).title }}
                </div>
                <div class="d-flex flex-column">
                  <div
                    v-for="sku in (
                      product.properties[1] || product.properties[0]
                    ).list"
                    v-bind:key="`${sku.id}${selectedSku}`"
                    class="sku_choose_list"
                  >
                    <img v-if="sku.image" :src="sku.image" :alt="sku.title" />
                    <b class="sku_list_title">
                      {{ sku.title }}
                    </b>
                    <span class="sku_price">{{
                      drawPriceCurrency(product, getPriceSku(sku.id))
                    }}</span>
                    <span class="stepper">
                      <span
                        class="mr-4"
                        style="font-size: 12px; color: rgba(0, 0, 0, 0.65)"
                        >คลัง:{{ getQtySku(sku.id) }}</span
                      >
                      <InputNumber
                        min="0"
                        :defaultValue="getAmount(selectedSku, sku.id)"
                        @onChange="changeAmount(selectedSku, sku.id, $event)"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="summary">
                <div class="summaryNav">
                  <div class="numberSku">จำนวน : {{ amountTotal }}</div>
                  <div class="RMB">
                    {{ drawPriceCurrency(product, priceTotal) }}
                  </div>
                  <div class="thb" v-if="!product.currency">
                    ฿{{ format_number(priceTotal * rate, 2) }}
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-addcart mt-12 ml-8" @click="addCart()">
              หยิบสินค้าใส่ตะกร้า
            </button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card v-if="product" class="mt-4">
      <div class="description-title">รายละเอียดสินค้า</div>
      <div class="description-wrapper">
        <div class="description-container">
          <span v-html="product.description" />
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
import bigDecimal from "js-big-decimal";
import InputNumber from "@/components/InputNumber";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_URL, WEBSITE } from "@/config";
import { ADD_CART } from "@/graphql/cart";
import Error5 from "../error/Error-5.vue";

export default {
  name: "ProductDetail",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "ตะกร้าสินค้า", route: "/cart" },
      { title: `ฝากสั่งสินค้าจากจีน` }
    ]);
  },
  created() {
    document.title = `ฝากสั่งสินค้าจากจีน | ${WEBSITE}`;
    this.loadData();
  },
  components: {
    InputNumber,
    Error5
  },
  data() {
    const { cache = "true" } = this.$route.query;
    return {
      cache: cache == "true",
      selectedSku: "",
      amountList: {},
      choose: [],
      price: 0,
      stock: 0,
      rate: 4.9,
      amountTotal: 0,
      priceTotal: 0,
      hasAmount: {},
      rangeActive: 0,
      product: false,
      loading: false,
      messageError: ""
    };
  },
  methods: {
    drawRangeAmount(amount, index) {
      if (index >= this.product.priceRange.length - 1) {
        return `≥ ${amount}`;
      }

      return `${amount} - ${this.product.priceRange[index + 1][0] - 1}`;
    },
    drawPriceCurrency(product, price) {
      if (product.currencySign) {
        return `${product.currencySign} ${this.format_number(price, 2)}`;
      }
      return `¥ ${this.format_number(price, 2)}`;
    },
    clickSku(id) {
      this.selectedSku = id;
    },
    getAmount(id1, id2) {
      const keyList = this.getKey(id1, id2);
      const key = keyList.join(";");
      if (this.amountList[key]) {
        return this.amountList[key].amount;
      } else {
        const key = keyList.reverse().join(";");
        return this.amountList[key] ? this.amountList[key].amount : 0;
      }
    },
    getAmountTotal() {
      let total = 0;
      for (const key in this.amountList) {
        if (Object.hasOwnProperty.call(this.amountList, key)) {
          const item = this.amountList[key];
          total += item.amount;
        }
      }
      return total;
    },
    getPriceTotal() {
      let total = 0;
      for (const key in this.amountList) {
        if (Object.hasOwnProperty.call(this.amountList, key)) {
          const item = this.amountList[key];
          total += item.amount * item.price;
        }
      }
      return total;
    },
    changeAmount(id1, id2, amount) {
      const { skus, priceRange } = this.product;
      const keyList = this.getKey(id1, id2);
      let key = keyList.join(";");
      if (!skus[key]) {
        key = keyList.reverse().join(";");
      }

      if (!this.amountList[key]) {
        this.amountList[key] = {
          id1: id1 || id2,
          id2: id1 ? id2 : "",
          key,
          price: skus[key].price,
          amount: 0
        };
      }
      this.amountList[key].amount = Number(amount);
      this.amountTotal = this.getAmountTotal();

      if (priceRange.length) {
        const sum = this.amountTotal;
        let price = priceRange[0][1];
        priceRange.forEach(val => {
          const [amount, p] = val;
          if (sum < amount) {
            return;
          }
          price = p;
        });

        for (const key in this.amountList) {
          if (Object.hasOwnProperty.call(this.amountList, key)) {
            this.amountList[key].price = price;
          }
        }
      }

      if (!this.amountList[key].amount) {
        delete this.amountList[key];
      }

      this.priceTotal = this.getPriceTotal();
      this.hasAmount[id1] = Number(amount);
    },
    async addCart() {
      const keys = Object.keys(this.amountList);
      if (!keys.length) {
        this.$message.warning("กรุณาเลือกสินค้า");
        return;
      }
      const cart = [];
      for (const key in this.amountList) {
        if (Object.hasOwnProperty.call(this.amountList, key)) {
          const item = this.amountList[key];
          let prop1 = null;
          let prop2 = null;
          prop1 = {
            ...this.product.properties[0].list.find(x => x.id === item.id1)
          };
          if (this.product.properties[1]) {
            prop2 = {
              ...this.product.properties[1].list.find(x => x.id === item.id2)
            };
          }
          prop1.name = this.product.properties[0].title;
          if (prop2) {
            prop2.name = this.product.properties[1].title;
          }
          const properties = [];
          if (prop1) {
            properties.push(prop1);
          }
          if (prop2) {
            properties.push(prop2);
          }
          const input = {
            productName: this.product.productName,
            url: this.product.url,
            mainImage: this.product.mainImage,
            vendor: this.product.vendor,
            currency: this.product.currency,
            currencySign: this.product.currencySign,
            price: Number(item.price),
            properties,
            amount: Number(item.amount)
          };
          cart.push(input);
        }
      }
      const {
        data: { addCart }
      } = await this.$apollo.mutate({
        mutation: ADD_CART,
        variables: {
          cart: cart
        }
      });
      if (addCart.is_error) {
        this.$message.error(addCart.message);
      } else {
        this.$message.success(addCart.message);
      }
    },
    getPrice() {
      const { skus } = this.product;
      const key = this.choose.map(x => x.id).join(";");
      const skuMap = this.choose.map(x => x.title).join(";");
      if (skus[key] && skus[key].sku === skuMap) {
        this.price = Number(skus[key].price);
        this.stock = Number(skus[key].qty_product);
      } else {
        const key = this.choose
          .map(x => x.id)
          .reverse()
          .join(";");
        const skuMap = this.choose
          .map(x => x.title)
          .reverse()
          .join(";");
        if (skus[key] && skus[key].sku === skuMap) {
          this.price = Number(skus[key].price);
          this.stock = Number(skus[key].qty_product);
        }
      }
    },
    getKey(id1, id2) {
      const keys = [];
      if (id1) {
        keys.push(id1);
      }
      if (id2) {
        keys.push(id2);
      }
      return keys;
    },
    getPriceSku(sku_id) {
      const { skus, priceRange } = this.product;
      const keyList = this.getKey(this.selectedSku, sku_id);
      const key = keyList.join(";");

      if (priceRange.length) {
        const sum = this.getAmountTotal();
        let price = priceRange[0][1];
        let rangeActive = 0;
        priceRange.forEach((val, index) => {
          const [amount, p] = val;
          if (sum < amount) {
            return;
          }
          rangeActive = index;
          price = p;
        });

        this.rangeActive = rangeActive;
        return price;
      }

      if (skus[key]) {
        return skus[key].price;
      } else {
        const key = keyList.reverse().join(";");
        return skus[key] ? skus[key].price : 0;
      }
    },
    getQtySku(sku_id) {
      const { skus } = this.product;
      const keyList = this.getKey(this.selectedSku, sku_id);
      const key = keyList.join(";");
      if (skus[key]) {
        return skus[key].qty_product;
      } else {
        const key = keyList.reverse().join(";");
        return skus[key] ? skus[key].qty_product : 0;
      }
    },
    async loadData() {
      const { url } = this.$route.params;
      this.loading = true;
      this.messageError = "";
      const data = await fetch(`${API_URL}/get_detail`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          cache: this.cache,
          url
        })
      });
      const product = await data.json();
      if (!product.productName || product.error_list) {
        this.loading = false;
        this.messageError = product.message || "ไม่พบรายการสินค้า";
        return;
      }
      this.loading = false;
      this.product = product;
      this.price = Number(product.maxPrice);
      this.rate = product.rate;
      if (product.properties.length > 1) {
        this.selectedSku = product.properties[0].list[0].id;
      }
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    },
    choose: function() {
      this.getPrice();
    }
  }
};
</script>
