<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card card-custom card-stretch" v-if="systemNewsList">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title flex-column">
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              ข่าวสาร
            </span>
          </div>
        </h3>
      </div>
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <router-link
          :to="`${$route.path}/create`"
          class="btn btn-success font-weight-bolder font-size-sm mr-4"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
          </span>
          เพิ่มข่าวสาร
        </router-link>
        <button
          @click="$apollo.queries.systemNewsList.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
      </div>
      <div class="card-body pt-2 pb-0">
        <div class="table-responsive">
          <table
            class="table table-sm table-head-bg table-borderless table-vertical-center"
          >
            <thead>
              <tr>
                <th class="pl-4 text-nowrap">หัวข้อ</th>
                <th class="px-2 text-center" width="200">รายละอียด</th>
                <th width="80"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in systemNewsList.records">
                <tr v-bind:key="i">
                  <td class="pl-4 text-nowrap">
                    <router-link :to="`${$route.path}/${item.userLevelId}`">
                      {{ item.userLevelName }}
                    </router-link>
                  </td>
                  <td class="px-2 text-center">
                    <span class="switch switch-sm mt-1 justify-content-center">
                      <label class="d-flex align-items-center">
                        <input
                          type="checkbox"
                          :checked="item.is_default"
                          v-model="item.is_default"
                          @change="toggleDefault(item._id, item.is_default)"
                        />
                        <span></span>
                      </label>
                    </span>
                  </td>
                  <td class="text-right pr-4 text-nowrap">
                    <button
                      class="btn btn-icon btn-danger btn-sm mr-2"
                      @click="deleteItem(item._id, item.userLevelName)"
                    >
                      <span class="la la-trash icon-lg" />
                    </button>
                    <router-link
                      :to="`${$route.path}/${item.userLevelId}`"
                      class="btn btn-icon btn-light btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-success">
                        <inline-svg
                          src="/media/svg/icons/Navigation/Arrow-right.svg"
                        ></inline-svg>
                      </span>
                    </router-link>
                  </td>
                </tr>
              </template>
              <tr
                class="text-center"
                v-if="
                  systemNewsList &&
                    systemNewsList.records &&
                    !systemNewsList.records.length
                "
              >
                <td colspan="6" class="pt-8">ไม่พบข่าวสาร</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import { GET_NEWS_LIST, DELETE_NEWS, UPDATE_NEWS } from "@/graphql/admin/news";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  created() {
    document.title = `ข่าวสาร | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ข่าวสาร` }
    ]);
  },
  data() {
    return {
      pageSize: 10,
      page: 1,
      search: ""
    };
  },
  methods: {
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    async toggleDefault(_id, is_show) {
      const {
        data: { updateNews }
      } = await this.$apollo.mutate({
        mutation: UPDATE_NEWS,
        variables: {
          input: {
            _id,
            is_show
          }
        }
      });
      if (!updateNews.isSuccess) {
        this.$message.error(updateNews.message);
      } else {
        this.$apollo.queries.systemNewsList.refetch();
      }
    },
    async deleteItem(_id, name) {
      const confirm = await Swal.fire({
        title: "ลบขนส่งจากจีนมาไทย ?",
        icon: "question",
        html: `ยืนยันการลบ ${name} ใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { deleteNews }
      } = await this.$apollo.mutate({
        mutation: DELETE_NEWS,
        variables: { _id }
      });
      if (!deleteNews.isSuccess) {
        this.$message.error(deleteNews.message);
      } else {
        this.$message.success(deleteNews.message);
        this.$apollo.queries.systemNewsList.refetch();
      }
    }
  },
  apollo: {
    systemNewsList: {
      query: GET_NEWS_LIST,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
