import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const VERIFY_EMAIL_AUTH = "auth/verify";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const TERM_AND_CONDITION = "termAndCondition";
export const UPDATE_PASSWORD = "updateUser";
export const RESET_PASSWORD = "resetPassword";
export const RESEND_MAIL = "resendMail";
export const ACCOUNT = "account";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_ERROR_REGISTER = "setErrorRegister";

const state = {
  errors: null,
  errorRegister: [],
  user:
    localStorage.getItem("user") && localStorage.getItem("user") != "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : {},
  permissions:
    localStorage.getItem("permissions") &&
    localStorage.getItem("permissions") != "undefined"
      ? JSON.parse(localStorage.getItem("permissions"))
      : {},
  is_admin:
    localStorage.getItem("user") && localStorage.getItem("user") != "undefined"
      ? JSON.parse(localStorage.getItem("user")).is_admin
      : false,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentPermission(state) {
    return state.permissions;
  },
  getIsAdmin(state) {
    return state.is_admin;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("do_login", credentials)
        .then(({ data }) => {
          const { isSuccess, message } = data;
          if (!isSuccess) {
            context.commit(SET_ERROR, [message]);
            return;
          }
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response.data);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      ApiService.get("", "do_logout")
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("signup", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR_REGISTER, response.data.error_list);
          reject(response.data);
        });
    });
  },
  [TERM_AND_CONDITION]() {
    return new Promise((resolve, reject) => {
      ApiService.get("", "term_and_condition")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [VERIFY_EMAIL_AUTH](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/verifyRegister", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.get("", "verify")
        .then(({ data }) => {
          const { isSuccess, message } = data;
          console.log("isSuccess", isSuccess);
          if (!isSuccess) {
            context.commit(SET_ERROR, [message]);
            return;
          }
          context.commit(SET_AUTH, data.data);
        })
        .catch(() => {
          context.dispatch(LOGOUT);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [RESET_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("do_reset_password", payload)
        .then(({ data }) => {
          context.commit(SET_PASSWORD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [RESEND_MAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("resend", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [ACCOUNT](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("", "account")
        .then(({ data }) => {
          console.log("data", data);
          const { isSuccess, message } = data;
          if (!isSuccess) {
            context.commit(SET_ERROR, [message]);
            return;
          }
          context.commit(SET_USER, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log("response", response);
          if (response) {
            context.commit(SET_ERROR, response.data.errors);
          }
          context.dispatch(LOGOUT);
          reject(response?.data);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ERROR_REGISTER](state, error) {
    state.errorRegister = error;
  },
  [SET_AUTH](state, user) {
    const { role, ...newprops } = user;
    localStorage.setItem("user", JSON.stringify(newprops));
    localStorage.setItem("permissions", JSON.stringify(role));
    state.isAuthenticated = true;
    state.user = newprops;
    state.permissions = role;
    state.is_admin = newprops.is_admin;
    state.errors = {};
    JwtService.saveToken(state.user.token);
    JwtService.saveRefreshToken(state.user.refreshToken);
  },
  [SET_USER](state, user) {
    const { role, ...newprops } = user;
    localStorage.setItem("user", JSON.stringify(newprops));
    localStorage.setItem("permissions", JSON.stringify(role));
    state.isAuthenticated = true;
    state.user = newprops;
    state.permissions = role;
    state.is_admin = newprops.is_admin;
    state.errors = {};
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.permissions = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
