import jwt from "jsonwebtoken";

const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_TOKEN_KEY = "id_token_refresh";

export const tokenIsExpired = () => {
  const decoded = jwt.decode(getRefreshToken());

  return decoded.exp * 1000 < new Date().valueOf();
};

export const getToken = () => {
  if (window.localStorage.getItem(ID_TOKEN_KEY) === "undefined") {
    destroyToken();
    return "";
  }
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRefreshToken = () => {
  if (window.localStorage.getItem(ID_REFRESH_TOKEN_KEY) === "undefined") {
    destroyToken();
    return "";
  }
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = token => {
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

export default {
  tokenIsExpired,
  getToken,
  getRefreshToken,
  saveToken,
  saveRefreshToken,
  destroyToken
};
