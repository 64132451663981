import gql from "graphql-tag";

export const GET_TRANSACTIONS = gql`
  query($page: Int!, $pageSize: Int!, $type: String, $search: String) {
    transactions(
      page: $page
      pageSize: $pageSize
      type: $type
      search: $search
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        transaction_id
        transaction_no
        amount
        userId
        user {
          userId
          username
        }
        remain
        reason
        collection_id
        transaction_type_id
        doc_id
        prefix {
          link_admin
        }
        transaction_type {
          desc
          url
        }
        created_id_info {
          userId
          username
        }
        created_at
      }
    }
  }
`;
