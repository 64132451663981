import gql from "graphql-tag";

export const GET_PAYMENT = gql`
  query($id: Int) {
    payshipping(id: $id) {
      _id
      payshipping_id
      payshipping_doc
      logisticsPrice
      minimumPrice
      minimum
      method
      remark
      transportNumberThai
      remarkSystem
      file_provider
      user {
        userId
        username
        full_name
        email
      }
      transports {
        _id
        transportId
        transportNumber
        amount
        ship_by
        lotId
        timeline {
          send_to_warehouse_china
          send_out_china
          send_to_warehouse_th
          payshipping
          sent_out_to_customer
        }
        remarkForCustomer
        remark
        qc
        guarantee
        price_wooden_box
        trackingPrice {
          priceName
          priceValue
          is_minimum
        }
        in_order {
          orderId
          orderCode
        }
        userLevelPrice {
          userLevelDetailName
          userLevelDetailPriceKg
          userLevelDetailPriceCube
          userLevelDetailShipping
        }
        size {
          weight
          long
          height
          width
        }
        logisticsServicePrice {
          _id
          priceName
          priceValue
        }
        size {
          weight
          width
          long
          height
        }
      }
      logistics {
        logisticsName
        desc
        logisticsServicePrice
        price
        is_address
      }
      address {
        name
        phone
        address
        province
        amphoe
        district
        postcode
      }
      sender {
        name
        phone
        address
        province
        amphoe
        district
        postcode
        charge
        show_price
      }
      transactions {
        _id
        transaction_id
        amount
        reason
        doc_id
        created_at
      }
      amount
      file_attachment
      status
      statusShow
      statusColor
      customer_received
      created_at
    }
  }
`;

export const GET_PAYMENTS = gql`
  query($page: Int!, $pageSize: Int!, $search: String, $status: String) {
    payshippings(
      page: $page
      pageSize: $pageSize
      search: $search
      status: $status
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        payshipping_id
        payshipping_doc
        logisticsPrice
        minimumPrice
        logistics {
          logisticsName
          logisticsServicePrice
          price
          is_address
        }
        address {
          name
        }
        user {
          userId
          username
        }
        transportCount
        amount
        file_attachment
        status
        statusShow
        statusColor
        created_at
        customer_received
      }
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation($input: PaymentInput) {
    transportPay(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_PAYMENT_TRACKINGS = gql`
  query {
    transportsToPay {
      recordsData {
        recordsFiltered
      }
      user {
        userId
        username
        address {
          _id
          name
          phone
          address
          province
          amphoe
          district
          postcode
        }
      }
      baht
      records {
        _id
        transportId
        transportNumber
        amount
        remarkForCustomer
        ship_by
        lotId
        no
        user {
          userId
          username
        }
        in_order {
          orderId
          orderCode
        }
        userLevelPrice {
          userLevelDetailName
          userLevelDetailPriceKg
          userLevelDetailPriceCube
          userLevelDetailShipping
        }
        size {
          weight
          long
          height
          width
        }
        logisticsServicePrice {
          _id
          priceName
          priceValue
        }
        trackingPrice {
          priceName
          priceValue
          is_minimum
        }
        timeline {
          send_to_warehouse_china
          send_out_china
          send_to_warehouse_th
          payshipping
          sent_out_to_customer
        }
        is_mall
        remark
        qc
        guarantee
        price_wooden_box
        status
        statusShow
        created_at
      }
    }
  }
`;

export const CANCEL_PAYMENT = gql`
  mutation($id: Int, $remark: String) {
    cancelPayment(id: $id, remark: $remark) {
      isSuccess
      message
    }
  }
`;

export const GET_TRANSACTIONS_WAIT = gql`
  query($_id: ID) {
    getPayshippingTransactionWait(_id: $_id) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        transaction_id
        amount
        reason
        collection_id
        transaction_type_id
        doc_id
        created_at
      }
    }
  }
`;
