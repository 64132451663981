<template>
  <b-modal
    id="track-choose-modal"
    centered
    title="เลือกเลขพัสดุ"
    size="lg"
    ok-title="ปิด"
    ok-only
    ok-variant=""
  >
    <b-overlay :show="$apollo.loading" rounded="sm">
      <div class="table-responsive table-sm">
        <b-input @input="debounceInput" placeholder="ค้นหารพัสดุ" />
        <table class="table">
          <thead>
            <tr>
              <th class="pl-0 font-weight-bold text-muted">เลขพัสดุ</th>
              <th class="text-center font-weight-bold text-muted">ตีลัง</th>
              <th class="text-center font-weight-bold text-muted">ขนส่งโดย</th>
              <th class="text-center font-weight-bold text-muted">
                เข้าโกดังจีน
              </th>
              <th class="text-center font-weight-bold text-muted">
                ออกโกดังจีน
              </th>
              <th class="text-center font-weight-bold text-muted">ถึงไทย</th>
              <th class="text-center font-weight-bold text-muted">เลือก</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="font-weight-boldest"
              v-for="item in (systemTransportList || { records: [] }).records"
              v-bind:key="item._id"
            >
              <td class="align-middle">
                {{ item.transportNumber }}
              </td>
              <td class="text-center align-middle">
                <span class="text-success" v-if="item.guarantee">เปิด</span>
                <span class="text-danger" v-if="!item.guarantee">ปิด</span>
              </td>
              <td class="text-center align-items-center align-middle">
                <span v-if="item.ship_by === 'ek'">รถ</span>
                <span v-if="item.ship_by !== 'ek'">เรือ</span>
              </td>
              <td class="text-center pl-0 align-middle">
                {{ format_datetime(item.timeline.send_to_warehouse_china) }}
              </td>
              <td class="text-center pl-0 align-middle">
                {{ format_datetime(item.timeline.send_out_china) }}
              </td>
              <td class="text-center pl-0 align-middle">
                {{ format_datetime(item.timeline.send_to_warehouse_th) }}
              </td>
              <td class="text-center align-middle">
                <button
                  class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  @click="chooseTrack(item.transportId)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                  </span>
                </button>
              </td>
            </tr>
            <tr
              v-if="!systemTransportList || !systemTransportList.records.length"
            >
              <td colspan="7" class="text-center p-4">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </b-modal>
</template>
<script>
import debounce from "debounce";
import { GET_FIND_TRACKINGS } from "@/graphql/admin/transport";
import { ORDER_CHOOSE_TRACKING } from "@/graphql/admin/order";
import dayjs from "dayjs";
export default {
  props: ["orderId", "refetch"],
  data() {
    return {
      trackingSearch: "",
      skipQuery: true
    };
  },
  methods: {
    debounceInput: debounce(function(val) {
      this.skipQuery = false;
      this.trackingSearch = val;
      this.$apollo.queries.systemTransportList.refetch();
    }, 400),
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    async chooseTrack(transportId) {
      const {
        data: { orderChooseTracking }
      } = await this.$apollo.mutate({
        mutation: ORDER_CHOOSE_TRACKING,
        variables: { orderId: Number(this.orderId), transportId }
      });
      if (!orderChooseTracking.isSuccess) {
        this.$message.error(orderChooseTracking.message);
      } else {
        this.$message.success(orderChooseTracking.message);
        if (this.refetch) {
          this.refetch();
        }
        this.$nextTick(() => {
          this.$bvModal.hide("track-choose-modal");
        });
      }
    }
  },
  apollo: {
    systemTransportList: {
      query: GET_FIND_TRACKINGS,
      variables() {
        return {
          page: 1,
          pageSize: 10,
          search: this.trackingSearch
        };
      },
      skip() {
        return this.skipQuery;
      }
    }
  }
};
</script>
