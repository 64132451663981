import ApiService from "@/core/services/api.service";
// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const OVERVIEW = "overview";
export const NOTI = "noti";
export const NOTI_ADMIN = "notiAdmin";

// mutation types
export const SET_OVERVIEW = "setOverview";
export const SET_NOTI = "setNoti";
export const SET_NOTI_ADMIN = "setNotiAdmin";

const state = {
  overview: {},
  noti: {},
  noti_admin: {},
  notification: {}
};

const getters = {
  getCurrentNoti(state) {
    return state.noti;
  },

  getCurrentNotiAdmin(state) {
    return state.noti_admin;
  },

  getNotification(state) {
    return state.notification;
  },

  getOverview(state) {
    return state.overview;
  }
};

const actions = {
  [OVERVIEW](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("overview")
        .then(({ data }) => {
          context.commit(SET_OVERVIEW, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [NOTI](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("", "noti")
        .then(({ data }) => {
          context.commit(SET_NOTI, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_NOTI, {});
          reject(response.data);
        });
    });
  },
  [NOTI_ADMIN](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("", "noti_admin")
        .then(({ data }) => {
          context.commit(SET_NOTI_ADMIN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_NOTI_ADMIN, {});
          reject(response.data);
        });
    });
  }
};

const mutations = {
  [SET_OVERVIEW](state, overview) {
    state.overview = overview && overview.data;
  },
  [SET_NOTI](state, noti) {
    if (noti) {
      state.noti = noti.data;
      state.notification = {
        setting_notification_bar: noti.setting_notification_bar
      };
    }
  },
  [SET_NOTI_ADMIN](state, noti) {
    if (noti) {
      state.noti_admin = noti.data;
      state.notification = {
        setting_notification_bar: noti.setting_notification_bar
      };
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
