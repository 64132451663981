<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="payshippings">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            รายการใบส่งพัสดุ
          </span>
          <span
            class="text-muted font-weight-bold font-size-sm"
            v-if="payshippings.recordsData"
          >
            Total {{ payshippings.recordsData.recordsFiltered }}+ document
          </span>
        </div>
      </h3>
    </div>
    <div
      class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
    >
      <div class="input-group input-group-solid mr-4" style="width: 280px">
        <input
          type="text"
          class="form-control"
          placeholder="ค้นหา"
          @input="debounceInput"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Search.svg" />
            </span>
          </span>
        </div>
      </div>
      <ul class="nav nav-pills nav-pills-sm nav-dark-75">
        <li class="nav-item">
          <a
            class="nav-link py-2 px-4 cursor-pointer"
            data-toggle="tab"
            :class="{ active: this.status === '' }"
            @click="
              status = '';
              page = 1;
            "
            >ทั้งหมด</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link py-2 px-4 cursor-pointer"
            data-toggle="tab"
            :class="{ active: this.status === '21' }"
            @click="
              status = '21';
              page = 1;
            "
            >เตรียมส่ง</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link py-2 px-4 cursor-pointer"
            data-toggle="tab"
            :class="{ active: this.status === '22' }"
            @click="
              status = '22';
              page = 1;
            "
            >มารับที่โกดัง</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link py-2 px-4 cursor-pointer"
            data-toggle="tab"
            :class="{ active: this.status === '100' }"
            @click="
              status = '100';
              page = 1;
            "
            >ส่งแล้ว</a
          >
        </li>
      </ul>
      <button
        @click="$apollo.queries.payshippings.refetch()"
        class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      >
        <i class="ki ki-refresh"></i>
      </button>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0">
      <!--begin::Table-->
      <div class="table-responsive" v-if="payshippings">
        <table
          class="table table-head-custom table-head-bg table-borderless table-vertical-center"
        >
          <thead>
            <tr>
              <th>วันที่สร้าง</th>
              <th class="text-center">รหัสใบส่ง</th>
              <th class="text-center">ขนส่งไทย</th>
              <th class="text-center">ค่าขนส่งจีน</th>
              <th class="text-center">ค่าขนส่งไทย</th>
              <th class="text-center">จำนวนพัสดุ</th>
              <th class="text-center">สถานะ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in payshippings.records"
              v-bind:key="item.payshipping_doc"
            >
              <td class="pl-2">
                {{ format_datetime(item.created_at) }}
              </td>
              <td class="text-center">
                <div class="mb-2 font-weight-bold">
                  <router-link :to="`/shippay/bill/${item.payshipping_id}`">
                    {{ item.payshipping_doc }}
                  </router-link>
                </div>
              </td>
              <td class="text-center">
                {{ item.logistics.logisticsName }}
              </td>
              <td class="text-center">
                {{ format_comma(getImportPrice(item)) }}
              </td>
              <td class="text-center">
                {{ format_comma(item.logistics.price) }}
              </td>
              <td class="text-center">
                {{ item.transportCount }}
              </td>
              <td class="text-center">
                <span
                  class="label label-lg label-inline"
                  v-bind:class="`label-light-${getStatusColor(item.status)}`"
                >
                  {{ item.statusShow }}
                </span>
              </td>
              <td class="text-right pr-0">
                <router-link
                  :to="`/shippay/bill/${item.payshipping_id}`"
                  class="btn btn-icon btn-light btn-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                    <inline-svg
                      src="/media/svg/icons/Navigation/Arrow-right.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </router-link>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="
                payshippings &&
                  payshippings.records &&
                  !payshippings.records.length
              "
            >
              <td colspan="7" class="pt-8">ไม่พบรายการพัสดุ</td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="payshippings && payshippings.recordsData"
          :total-rows="payshippings.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import { GET_PAYMENTS } from "@/graphql/payshipping";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  name: "shippay_bill",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "รายการใบส่งพัสดุ" }]);
  },
  created() {
    document.title = `รายการใบส่งพัสดุ | ${WEBSITE}`;
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      status: "",
      search: ""
    };
  },
  methods: {
    getImportPrice(item) {
      return (
        item.amount -
        (item.logistics.logisticsServicePrice +
          item.logistics.price +
          item.minimumPrice)
      );
    },
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor(status) {
      switch (status) {
        case -1:
        case -10:
          return "default";
        case 0:
          return "warning";
        case 22:
          return "primary";
        case 21:
        case 11:
        case 12:
          return "warning";
        case 101:
        case 102:
          return "success";
        default:
          return "primary";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_comma(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  },
  apollo: {
    payshippings: {
      query: GET_PAYMENTS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    }
  }
};
</script>
