<template>
  <div v-if="bankAccounts">
    <table
      class="table table-sm table-head-bg table-borderless table-vertical-center"
    >
      <thead>
        <tr>
          <th class="pl-4">ธนาคาร</th>
          <th>ชื่อบัญชี</th>
          <th>เลขบัญชี</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in bankAccounts.records" v-bind:key="item._id">
          <td>
            <button
              class="btn btn-link btn-sm"
              v-b-modal.bank-edit-modal
              @click="setEdit(item)"
            >
              {{ getBankName(item.bank_name) }}
            </button>
          </td>
          <td>
            {{ item.account_name }}
          </td>
          <td>
            {{ item.account_no }}
          </td>
          <td class="text-center">
            <button
              type="button"
              class="btn btn-icon btn-light btn-hover-danger btn-sm"
              @click="deleteBank(item)"
              v-if="!item.default"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </button>
          </td>
        </tr>
        <tr
          class="text-center"
          v-if="bankAccounts && !bankAccounts.records.length"
        >
          <td colspan="4" class="pt-8">ไม่มีข้อมูล กรุณาเพิ่ม</td>
        </tr>
      </tbody>
    </table>
    <button
      type="button"
      v-b-modal.bank-add-modal
      class="btn btn-block btn-dashed"
    >
      <i class="menu-icon la la-plus icon-xl"></i> เพิ่มบัญชีธนาคาร
    </button>
    <b-modal
      id="bank-edit-modal"
      centered
      size="lg"
      title="แก้ไขที่อยู่"
      @ok="handleOk"
    >
      <b-form ref="edit" @submit.prevent="updateBank">
        <div class="row">
          <div class="col-lg-10">
            <div class="form-group row">
              <label
                for="input-transportNumber"
                class="col-form-label text-right col-md-3 col-sm-12"
                >ธนาคาร</label
              >
              <div class="col-lg-5 col-md-9 col-sm-12">
                <v-select
                  searchable
                  labelTitle="กรุณาเลือกธนาคาร"
                  data-style="btn-primary"
                  required
                  :options="banks"
                  valueProp="value"
                  v-model="edit.bank_name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                for="input-account_name"
                class="col-form-label text-lg-right col-md-3 col-sm-12"
              >
                ชื่อบัญชี</label
              >
              <div class="col-lg-5 col-md-9 col-sm-12">
                <b-form-input
                  id="input-account_name"
                  v-model="edit.account_name"
                  required
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="input-account_no"
                class="col-form-label text-lg-right col-md-3 col-sm-12"
              >
                เลขบัญชี</label
              >
              <div class="col-lg-5 col-md-9 col-sm-12">
                <b-form-input
                  id="input-account_no"
                  v-model="edit.account_no"
                  required
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="bank-add-modal"
      centered
      size="lg"
      title="เพิ่มที่อยู่"
      @ok="handleOkAdd"
    >
      <b-form ref="add" @submit.prevent="addBank">
        <div class="row">
          <div class="col-lg-10">
            <div class="form-group row">
              <label
                for="input-transportNumber"
                class="col-form-label text-right col-md-3 col-sm-12"
                >ธนาคาร</label
              >
              <div class="col-lg-5 col-md-9 col-sm-12">
                <v-select
                  searchable
                  labelTitle="กรุณาเลือกธนาคาร"
                  data-style="btn-primary"
                  required
                  :options="banks"
                  valueProp="value"
                  v-model="add.bank_name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                for="input-account_name"
                class="col-form-label text-lg-right col-md-3 col-sm-12"
              >
                ชื่อบัญชี</label
              >
              <div class="col-lg-5 col-md-9 col-sm-12">
                <b-form-input
                  id="input-account_name"
                  v-model="add.account_name"
                  required
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="input-account_no"
                class="col-form-label text-lg-right col-md-3 col-sm-12"
              >
                เลขบัญชี</label
              >
              <div class="col-lg-5 col-md-9 col-sm-12">
                <b-form-input
                  id="input-account_no"
                  v-model="add.account_no"
                  required
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import VSelect from "@alfsnd/vue-bootstrap-select";
import { getBankName } from "@/config";
import {
  GET_PROFILE_BANK,
  CREATE_PROFILE_BANK,
  UPDATE_PROFILE_BANK,
  DELETE_PROFILE_BANK
} from "@/graphql/profile";

export default {
  mixins: [validationMixin],
  components: {
    VSelect
  },
  data() {
    return {
      add: {
        bank_name: "",
        account_no: "",
        account_name: "",
        branch_name: ""
      },
      edit: {
        bank_name: "",
        account_no: "",
        account_name: "",
        branch_name: ""
      },
      banks: [
        { text: "ธนาคารกสิกรไทย - Kasikornbank", value: "kbank" },
        { text: "ธนาคารกรุงเทพ - Bangkok Bank", value: "bbl" },
        { text: "ธนาคารไทยพาณิชย์ - Siam Commercial Bank", value: "scb" },
        { text: "ธนาคารกรุงไทย - Krungthai Bank", value: "ktb" },
        {
          text: "ธนาคารกรุงศรีอยุธยา - Bank of Ayudhya (Krungsri)",
          value: "bay"
        },
        { text: "ธนาคารซิตี้แบงค์ - Citibank", value: "citi" },
        { text: "ธนาคารออมสิน - Government Savings Bank", value: "gsb" },
        { text: "ธนาคารทหารไทย - TMB Bank", value: "tmb" },
        { text: "ธนาคารซีไอเอ็มบีไทย - CIMB Thai Bank", value: "cimb" },
        {
          text: "ธนาคารอาคารสงเคราะห์ - Government Housing Bank",
          value: "ghb"
        }
      ]
    };
  },
  validations: {
    add: {
      bank_name: {
        required
      },
      branch_name: {},
      account_no: {
        required
      },
      account_name: {
        required
      }
    },
    edit: {
      bank_name: {
        required
      },
      branch_name: {},
      account_no: {
        required
      },
      account_name: {
        required
      }
    }
  },
  methods: {
    getBankName,
    setEdit(value) {
      this.edit = JSON.parse(JSON.stringify(value));
    },
    handleOkAdd(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.addBank();
    },
    async addBank() {
      const valid = this.$refs.add.checkValidity();
      if (!valid) {
        return;
      }
      const {
        data: { bank_create }
      } = await this.$apollo.mutate({
        mutation: CREATE_PROFILE_BANK,
        variables: {
          ...this.add,
          bank_name:
            typeof this.$v.add.bank_name.$model === "string"
              ? this.$v.add.bank_name.$model
              : this.$v.add.bank_name.$model.value
        }
      });
      if (!bank_create.isSuccess) {
        this.$message.error(bank_create.message);
      } else {
        this.add = {
          bank_name: "",
          account_no: "",
          account_name: "",
          branch_name: ""
        };
        this.$bvModal.hide("bank-add-modal");
        this.$message.success(bank_create.message);
        this.$apollo.queries.bankAccounts.refetch();
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.updateBank();
    },
    async updateBank() {
      const valid = this.$refs.edit.checkValidity();
      if (!valid) {
        return;
      }
      const {
        data: { bank_update }
      } = await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_BANK,
        variables: {
          ...this.edit,
          bank_name:
            typeof this.$v.edit.bank_name.$model === "string"
              ? this.$v.edit.bank_name.$model
              : this.$v.edit.bank_name.$model.value
        }
      });
      if (!bank_update.isSuccess) {
        this.$message.error(bank_update.message);
      } else {
        this.$message.success(bank_update.message);
        this.$bvModal.hide("bank-edit-modal");
        this.$apollo.queries.bankAccounts.refetch();
      }
    },
    async deleteBank(value) {
      const confirm = await Swal.fire({
        title: "ยืนยันการลบข้อมูล ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { bank_delete }
      } = await this.$apollo.mutate({
        mutation: DELETE_PROFILE_BANK,
        variables: {
          _id: value._id
        }
      });
      if (!bank_delete.isSuccess) {
        this.$message.error(bank_delete.message);
      } else {
        this.$message.success(bank_delete.message);
        this.$apollo.queries.bankAccounts.refetch();
      }
    }
  },
  apollo: {
    bankAccounts: {
      query: GET_PROFILE_BANK
    }
  }
};
</script>
