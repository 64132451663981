<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card card-custom card-stretch">
      <div class="card-body p-0" v-if="systemOrder">
        <div class="row justify-content-center py-8 px-8 py-md-27 px-md-0">
          <div class="col-md-10">
            <div class="d-flex justify-content-end btn-space">
              <button
                v-if="systemOrder.record.status !== -10"
                class="btn btn-default font-weight-bold"
                @click="cancelOrder(systemOrder.record.orderId)"
                style="min-width: 144px"
              >
                ยกเลิก
              </button>
              <button
                v-if="systemOrder.record.status === 0"
                @click="approveOrder(systemOrder.record.orderId)"
                class="btn btn-primary font-weight-bold"
                style="min-width: 144px"
              >
                ให้ลูกค้าชำระเงิน
              </button>
              <button
                v-if="systemOrder.record.status >= 20"
                v-b-modal.transaction-modal
                @click="refund()"
                class="btn btn-danger font-weight-bold"
                style="min-width: 144px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/Shopping/Money.svg" />
                </span>
                คืนเงิน
              </button>
              <button
                v-if="systemOrder.record.status >= 20"
                v-b-modal.transaction-modal
                @click="addition()"
                class="btn btn-primary font-weight-bold"
                style="min-width: 144px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/Shopping/Dollar.svg" />
                </span>
                เก็บเงิน
              </button>
              <button
                v-if="systemOrder.record.status === 10"
                class="btn btn-primary font-weight-bold"
                style="min-width: 144px"
                @click="systemOrderDoCheck(systemOrder.record.orderId)"
              >
                ตรวจสอบอีกครั้ง
              </button>
              <button
                v-if="systemOrder.record.status === 20"
                class="btn btn-success font-weight-bold"
                style="min-width: 144px"
                @click="buyComplete(systemOrder.record.orderId)"
              >
                สั่งซื้อเรียบร้อย
              </button>
              <b-dropdown
                v-if="systemOrder.record.status >= 30"
                :text="systemOrder.record.statusShow"
                class="ml-4"
                style="width: 144px"
              >
                <b-dropdown-item
                  @click="waitingTransport(systemOrder.record.orderId)"
                >
                  ขอเลขขนส่ง
                </b-dropdown-item>
                <b-dropdown-item
                  @click="buyComplete(systemOrder.record.orderId)"
                >
                  สั่งซื้อเรียบร้อย
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="col-md-10">
            <div
              class="d-flex justify-content-between align-items-center py-5 py-md-10 flex-column flex-md-row"
            >
              <h1 class="d-flex font-weight-boldest">
                ข้อมูลการสั่งซื้อ {{ systemOrder.record.orderCode }}
              </h1>
              <div
                class="d-flex flex-wrap justify-content-center"
                style="column-gap: 8px"
              >
                <span
                  class="d-flex flex-column opacity-70"
                  v-if="systemOrder.record.cancel_by_info"
                >
                  <div class="h3">
                    <span class="font-weight-bolder mb-2 mr-2">ยกเลิกโดย</span>
                    <span>{{
                      systemOrder.record.cancel_by_info.username
                    }}</span>
                  </div>
                </span>
                <span
                  class="d-flex flex-column text-warning"
                  v-if="systemOrder.record.approve_by_info"
                >
                  <div class="h3">
                    <span class="font-weight-bolder mb-2 mr-2">อนุมัติโดย</span>
                    <span>{{
                      systemOrder.record.approve_by_info.username
                    }}</span>
                  </div>
                </span>
                <span
                  class="d-flex flex-column opacity-70 text-success"
                  v-if="systemOrder.record.buy_by_info"
                >
                  <div class="h3">
                    <span class="font-weight-bolder mb-2 mr-2"
                      >สั่งซื้อโดย</span
                    >
                    <span>{{ systemOrder.record.buy_by_info.username }}</span>
                  </div>
                </span>
                <span
                  class="d-flex flex-column opacity-70"
                  v-if="systemOrder.record.user"
                >
                  <div class="h3">
                    <span class="font-weight-bolder mb-2 mr-2">ลูกค้า</span>
                    <span>{{ systemOrder.record.user.username }}</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="border-bottom w-100"></div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">วันที่สั่งซื้อ</span>
                <span class="opacity-70">
                  {{ format_datetime(systemOrder.record.created_at) }}
                </span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">อัตราแลกเปลี่ยน</span>
                <span class="opacity-70">{{ systemOrder.record.rate }}</span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">
                  ค่าส่งในจีน({{ systemOrder.record.currencySign || "¥" }})
                  <span v-if="systemOrder.record.status === 0">
                    <button
                      v-if="!systemOrder.record.currencySign"
                      class="btn btn-warning btn-sm"
                      type="button"
                      @click="
                        updateOrderCurrency(systemOrder.record._id, 'THB', '฿')
                      "
                    >
                      เปลี่ยนสกุลเงินเป็น ฿
                    </button>
                    <button
                      v-if="systemOrder.record.currencySign"
                      class="btn btn-warning btn-sm"
                      type="button"
                      @click="
                        updateOrderCurrency(systemOrder.record._id, '', '')
                      "
                    >
                      เปลี่ยนสกุลเงินเป็น ¥
                    </button>
                  </span>
                </span>
                <span class="opacity-70">
                  <InputNumber
                    v-if="systemOrder.record.status === 0"
                    step="1"
                    :defaultValue="systemOrder.record.shipping_price || 0"
                    decimal="2"
                    :disabledAppend="true"
                    @onChange="
                      updateShippingPrice(systemOrder.record._id, $event)
                    "
                  />
                  <span
                    v-if="systemOrder.record.status > 0"
                    style="margin-right: 8px"
                  >
                    {{ systemOrder.record.shipping_price }}
                  </span>
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">ประเภทการขนส่ง</span>
                <span class="opacity-70">
                  {{ systemOrder.record.ship_by === "ek" ? "รถ" : "เรือ" }}
                </span>
              </div>
              <div class="flex-column flex-root">
                <div class="font-weight-bolder mb-2">สถานะ</div>
                <div
                  class="label label-lg label-inline"
                  v-bind:class="
                    `label-light-${getStatusColor(systemOrder.record.status)}`
                  "
                >
                  {{ systemOrder.record.statusShow }}
                </div>
              </div>
              <div class="d-flex flex-column flex-root text-danger">
                <span class="font-weight-bolder mb-2">หมายเหตุจากลูกค้า</span>
                <span class="opacity-70">
                  {{ systemOrder.record.remark || "-" }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">ค่าบริการฝากสั่ง</span>
                <div class="opacity-70">
                  <div>คิวละ {{ systemOrder.record.serviceCube || 0 }}</div>
                  <div>กิโลละ {{ systemOrder.record.serviceKg || 0 }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row justify-content-center pb-8 px-8 pb-md-10 px-md-0"
          v-if="systemOrder.record"
        >
          <div class="col-md-10">
            <b-button variant="primary" v-b-modal.track-modal>
              <i class="ki ki-plus icon-sm"></i>เพิ่มรหัสพัสดุ
            </b-button>
            <b-button
              variant="success"
              class="ml-4"
              v-b-modal.track-choose-modal
            >
              เลือก
            </b-button>
            <div class="table-responsive table-sm">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted">เลขพัสดุ</th>
                    <th class="text-center font-weight-bold text-muted">
                      ตีลัง
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      ขนส่งโดย
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      เข้าโกดังจีน
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      ออกโกดังจีน
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      ถึงไทย
                    </th>
                    <th class="text-center font-weight-bold text-muted"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="font-weight-boldest"
                    v-for="item in systemOrder.record.transports"
                    v-bind:key="item._id"
                  >
                    <td class="align-middle">
                      {{ item.transportNumber }}
                    </td>
                    <td class="text-center align-middle">
                      <span class="text-success" v-if="item.guarantee"
                        >เปิด</span
                      >
                      <span class="text-danger" v-if="!item.guarantee"
                        >ปิด</span
                      >
                    </td>
                    <td class="text-center align-items-center align-middle">
                      <span v-if="item.ship_by === 'ek'">รถ</span>
                      <span v-if="item.ship_by !== 'ek'">เรือ</span>
                    </td>
                    <td class="text-center pl-0 align-middle">
                      {{ format_date(item.timeline.send_to_warehouse_china) }}
                    </td>
                    <td class="text-center pl-0 align-middle">
                      {{ format_date(item.timeline.send_out_china) }}
                    </td>
                    <td class="text-center pl-0 align-middle">
                      {{ format_date(item.timeline.send_to_warehouse_th) }}
                    </td>
                    <td class="text-center align-middle">
                      <button
                        class="btn btn-icon btn-light btn-hover-danger btn-sm"
                        @click="removeTrack(item.transportId)"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-danger">
                          <inline-svg
                            src="/media/svg/icons/General/Trash.svg"
                          />
                        </span>
                      </button>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      systemOrder.record.transports &&
                        !systemOrder.record.transports.length
                    "
                  >
                    <td colspan="7" class="text-center p-4">ไม่มีข้อมูล</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
          <div class="col-md-10">
            <div class="table-responsive">
              <table class="table mb-8">
                <div
                  v-for="vendor in systemOrder.record.vendors"
                  v-bind:key="vendor._id"
                  v-frag
                >
                  <thead>
                    <tr>
                      <th class="pl-0 font-weight-bold text-muted">
                        <div
                          class="text-overflow"
                          style="width: 90px; display: inline-block"
                        >
                          {{ vendor.vendor }}
                        </div>
                        <span class="ml-4 text-success" v-if="vendor.guarantee">
                          ตีลังไม้
                        </span>
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        จำนวน
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        ราคา({{ vendor.currencySign || "¥" }})
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        รวม({{ vendor.currencySign || "¥" }})
                      </th>
                      <th class="text-right pr-0 font-weight-bold text-muted">
                        รวม
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="font-weight-boldest"
                      v-for="item in vendor.items"
                      v-bind:key="item._id"
                    >
                      <td
                        class="pl-0 pt-6 pb-6 align-items-center align-middle"
                      >
                        <div class="d-flex">
                          <!--begin::Symbol-->
                          <a :href="item.url" target="_blank">
                            <div
                              class="symbol symbol-40 flex-shrink-0 mr-4 bg-light"
                            >
                              <div
                                class="symbol-label"
                                :style="
                                  `background-image: url('${item.mainImage}')`
                                "
                              ></div>
                            </div>
                            <!--end::Symbol-->
                          </a>
                          <div>
                            <a :href="item.url" target="_blank">
                              {{ item.productName }}
                            </a>
                            <div
                              class="opacity-80 font-weight-bold d-block"
                              v-bind:key="c.id"
                              v-for="c in item.properties"
                            >
                              <span v-if="c.name">{{ c.name }} : </span>
                              <img
                                v-viewer
                                v-if="c.image"
                                :src="c.image"
                                style="height: 20px"
                                class="align-self-center pointer mr-2"
                                :alt="item.productName"
                              />
                              <span>{{ c.title }}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-right pt-0 align-middle text-nowrap">
                        <span v-if="systemOrder.record.status > 0">
                          {{ format_number_pretty(item.amount_buy) }}
                        </span>
                        <InputNumber
                          step="1"
                          v-if="systemOrder.record.status === 0"
                          :defaultValue="item.amount_buy"
                          decimal="2"
                          @onChange="
                            updateAmount(
                              systemOrder.record.orderId,
                              vendor._id,
                              item._id,
                              $event
                            )
                          "
                        />
                      </td>
                      <td class="text-right pt-0 align-middle text-nowrap">
                        <span v-if="systemOrder.record.status > 0">
                          {{ format_number_pretty(item.price, 2)
                          }}{{ item.currencySign || "¥" }}
                        </span>
                        <InputNumber
                          step="0.1"
                          v-if="systemOrder.record.status === 0"
                          :defaultValue="item.price"
                          decimal="2"
                          @onChange="
                            updatePrice(
                              systemOrder.record.orderId,
                              vendor._id,
                              item._id,
                              $event
                            )
                          "
                        />
                      </td>
                      <td class="text-right pt-0 align-middle">
                        <span v-if="item.currencySign">
                          {{ format_number_pretty(item.price, 2)
                          }}{{ item.currencySign }}
                        </span>
                        <span v-if="!item.currencySign">
                          {{
                            format_number_pretty(
                              item.price * item.amount_buy,
                              2
                            )
                          }}¥
                        </span>
                      </td>
                      <td
                        class="text-primary pr-0 pt-0 text-right align-middle"
                      >
                        <span v-if="item.currencySign">
                          {{
                            format_number_pretty(
                              item.price * item.amount_buy,
                              2
                            )
                          }}{{ item.currencySign }}
                        </span>
                        <span v-if="!item.currencySign">
                          {{
                            format_number_pretty(
                              item.price *
                                systemOrder.record.rate *
                                item.amount_buy,
                              2
                            )
                          }}฿
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom card-stretch mt-8">
      <div class="card-body p-0" v-if="systemOrder">
        <div
          class="row justify-content-center pt-8 pb-8 px-8 pb-md-8 px-md-0"
          v-if="systemOrder && systemOrder.record.transactions.length"
        >
          <div class="col-md-10">
            <span
              class="font-weight-bolder text-dark mr-4"
              style="font-size: 16px"
            >
              จ่ายเงิน
            </span>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted">วันที่</th>
                    <th class="font-weight-bold text-muted">หมายเหตุ</th>
                    <th class="text-right font-weight-bold text-muted">
                      จำนวน
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="font-weight-boldest"
                    v-for="item in systemOrder.record.transactions"
                    v-bind:key="item._id"
                  >
                    <td class="pl-0 align-items-center align-middle">
                      {{ format_datetime(item.created_at) }}
                    </td>
                    <td class="align-middle">
                      {{ item.reason }}
                    </td>
                    <td class="text-right align-middle">
                      <span
                        class="font-weight-bolder d-block font-size-lg"
                        v-bind:class="{
                          'text-danger': item.amount < 0,
                          'text-success': item.amount >= 0
                        }"
                      >
                        {{ format_number_pretty(item.amount, 2) }}฿
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pt-8 pb-8 px-8 pb-md-8 px-md-0">
          <div class="col-md-10">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted">
                      ประเภทการจ่ายเงิน
                    </th>
                    <th class="font-weight-bold text-muted">สถานะจ่ายเงิน</th>
                    <th class="font-weight-bold text-muted text-right">
                      ยอดทั้งหมด
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="font-weight-bolder">
                    <td class="pl-0">เงินในกระเป๋า</td>
                    <td>
                      {{
                        systemOrder.record.pay_date
                          ? "จ่ายเรียบร้อย"
                          : "ยังไม่ได้ชำระ"
                      }}
                    </td>
                    <td
                      class="text-primary font-size-h3 font-weight-boldest text-right"
                    >
                      {{
                        format_number_pretty(systemOrder.record.total_baht, 2)
                      }}฿
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ModalTrack
        v-if="systemOrder"
        :orderId="systemOrder.record.orderId"
        :refetch="onCompleteTransport"
      />
      <ModalSelectTrack
        v-if="systemOrder"
        :orderId="systemOrder.record.orderId"
        :refetch="onCompleteTransport"
      />
      <ModalTransaction
        v-if="systemOrder"
        :orderId="systemOrder.record.orderId"
        :refetch="onCompleteTransport"
        :is_refund="is_refund"
        :title="is_refund ? 'คืนเงิน' : 'เก็บเงิน'"
      />
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import bigDecimal from "js-big-decimal";
import InputNumber from "@/components/InputNumber";
import ModalTrack from "./components/ModalTrack";
import ModalSelectTrack from "./components/ModalSelectTrack";
import ModalTransaction from "./components/ModalTransaction";
import {
  GET_ORDER,
  CANCEL_ORDER,
  UPDATE_ORDER_AMOUNT,
  APPROVE_ORDER,
  WATING_APPROVE,
  BUY_COMPLETE,
  WAITING_TRANSPORT,
  UPDATE_ORDER_SHIPPING_PRICE,
  ORDER_ADD_TRACKING,
  ORDER_REMOVE_TRACKING,
  CHANGE_ORDER_SHIPPING,
  UPDATE_ORDER_CURRENCY
} from "@/graphql/admin/order";

export default {
  name: "order_detail",
  components: {
    InputNumber,
    ModalTrack,
    ModalSelectTrack,
    ModalTransaction
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "รายการสั่งซื้อ", route: "/admin/shop/order" },
      { title: `สั่งซื้อ FF${this.id}` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      guarantee: false,
      transportNumber: "",
      trackingSearch: "",
      valid: false,
      is_refund: false,
      rules: {
        required: value => !!value || "กรุณากรอกข้อมูล"
      }
    };
  },
  methods: {
    refund() {
      this.is_refund = true;
    },
    addition() {
      this.is_refund = false;
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 40:
        case 90:
          return "success";
        default:
          return "primary";
      }
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val, digits = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    },
    onCompleteTransport() {
      this.$apollo.queries.systemOrder.refetch();
    },
    async cancelOrder(orderId) {
      const confirm = await Swal.fire({
        title: "ยกเลิกรายการสั่งซื้อ ?",
        icon: "question",
        html: `ยืนยันการยกเลิกรายการ ${this.systemOrder.record.orderCode}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { systemOrderReject }
      } = await this.$apollo.mutate({
        mutation: CANCEL_ORDER,
        variables: {
          id: orderId
        }
      });
      if (!systemOrderReject.isSuccess) {
        this.$message.error(systemOrderReject.message);
      } else {
        this.$message.success(systemOrderReject.message);
        this.$apollo.queries.systemOrder.refetch();
      }
    },
    async removeTrack(transportId) {
      const confirm = await Swal.fire({
        title: "นำเลขพัสดุออกจากรายการสั่งซื้อ ?",
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { orderRemoveTracking }
      } = await this.$apollo.mutate({
        mutation: ORDER_REMOVE_TRACKING,
        variables: { orderId: this.systemOrder.record.orderId, transportId }
      });
      if (!orderRemoveTracking.isSuccess) {
        this.$message.error(orderRemoveTracking.message);
      } else {
        this.$message.success(orderRemoveTracking.message);
        this.$apollo.queries.systemOrder.refetch();
      }
    },
    async orderAddTracking() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      const input = {
        orderId: this.systemOrder.record.orderId,
        transportNumber: this.transportNumber,
        guarantee: this.guarantee
      };
      const {
        data: { orderAddTracking }
      } = await this.$apollo.mutate({
        mutation: ORDER_ADD_TRACKING,
        variables: { ...input }
      });
      if (!orderAddTracking.isSuccess) {
        this.$message.error(orderAddTracking.message);
      } else {
        this.$message.success(orderAddTracking.message);
        this.dialog = false;
        this.$apollo.queries.systemOrder.refetch();
      }
    },
    async updateShippingPrice(_id, price) {
      await this.$apollo.mutate({
        mutation: UPDATE_ORDER_SHIPPING_PRICE,
        variables: {
          input: {
            _id,
            shipping_price: Number(price)
          }
        }
      });
      this.$apollo.queries.systemOrder.refetch();
    },
    async updateOrderCurrency(_id, currency, currencySign) {
      await this.$apollo.mutate({
        mutation: UPDATE_ORDER_CURRENCY,
        variables: {
          input: {
            _id,
            currency,
            currencySign
          }
        }
      });
      this.$apollo.queries.systemOrder.refetch();
    },
    async updatePrice(orderId, vendor_id, item_id, price) {
      await this.$apollo.mutate({
        mutation: UPDATE_ORDER_AMOUNT,
        variables: {
          input: {
            orderId,
            vendor_id,
            item_id,
            price: Number(price)
          }
        }
      });
      this.$apollo.queries.systemOrder.refetch();
    },
    async updateAmount(orderId, vendor_id, item_id, amount) {
      await this.$apollo.mutate({
        mutation: UPDATE_ORDER_AMOUNT,
        variables: {
          input: {
            orderId,
            vendor_id,
            item_id,
            amount_buy: Number(amount)
          }
        }
      });
      this.$apollo.queries.systemOrder.refetch();
    },
    async approveOrder(orderId) {
      await this.$apollo.mutate({
        mutation: APPROVE_ORDER,
        variables: {
          id: orderId
        }
      });
      this.$apollo.queries.systemOrder.refetch();
    },
    async buyComplete(orderId) {
      await this.$apollo.mutate({
        mutation: BUY_COMPLETE,
        variables: {
          id: orderId
        }
      });
      this.$apollo.queries.systemOrder.refetch();
    },
    async waitingTransport(orderId) {
      await this.$apollo.mutate({
        mutation: WAITING_TRANSPORT,
        variables: {
          id: orderId
        }
      });
      this.$apollo.queries.systemOrder.refetch();
    },
    async systemOrderDoCheck(orderId) {
      const {
        data: { systemOrderDoCheck }
      } = await this.$apollo.mutate({
        mutation: WATING_APPROVE,
        variables: {
          id: orderId
        }
      });
      if (!systemOrderDoCheck.isSuccess) {
        this.$message.error(systemOrderDoCheck.message);
      } else {
        this.$message.success(systemOrderDoCheck.message);
        this.$apollo.queries.systemOrder.refetch();
      }
    },
    async systemChangeOrderShipping(_id, ship_by) {
      const confirm = await Swal.fire({
        title: "ต้องการเปลี่ยนขนส่ง",
        icon: "question",
        html: `ยืนยันเปลี่ยนขนส่งเป็น ${ship_by === "ek" ? "เรือ" : "รถ"}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { systemChangeOrderShipping }
      } = await this.$apollo.mutate({
        mutation: CHANGE_ORDER_SHIPPING,
        variables: {
          _id,
          ship_by
        }
      });
      if (systemChangeOrderShipping.is_error) {
        this.$message.error(systemChangeOrderShipping.message);
      } else {
        this.$message.success(systemChangeOrderShipping.message);
        this.$apollo.queries.systemOrder.refetch();
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    }
  },
  apollo: {
    systemOrder: {
      query: GET_ORDER,
      variables() {
        return {
          id: this.id
        };
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
