import gql from "graphql-tag";

export const ONE_DRIVE_API = gql`
  mutation($_id: ID) {
    oneDriveApi(_id: $_id) {
      isSuccess
      message
      data
    }
  }
`;

export const DELETE_LOT = gql`
  mutation($_id: ID) {
    delete_lot(_id: $_id) {
      isSuccess
      message
    }
  }
`;

export const CREATE_LOT = gql`
  mutation($input: LotInput) {
    newLot(input: $input) {
      isSuccess
      is_error
      message
    }
  }
`;

export const UPDATE_LOT = gql`
  mutation($input: LotInput) {
    update_lot(input: $input) {
      isSuccess
      is_error
      message
    }
  }
`;

export const GET_LOT_LIST = gql`
  query($pageSize: Int!, $page: Int!, $search: String) {
    systemLotList(pageSize: $pageSize, page: $page, search: $search) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        lotId
        lotName
        ship_by
        lotUrl
        type_name
        send_to_warehouse_china
        send_out_china
        send_to_warehouse_th
        is_processing
        updated_at
        numOfTransport
      }
    }
  }
`;

export const GET_LOT = gql`
  query($id: Int) {
    systemLot(id: $id) {
      isSuccess
      message
      record {
        _id
        lotId
        lotName
        ship_by
        lotUrl
        send_to_warehouse_china
        send_out_china
        send_to_warehouse_th
        is_processing
        type
        transportList {
          _id
          transportId
          transportNumber
          amount
          userId
          user {
            userId
            username
          }
          in_order {
            orderId
            orderCode
          }
          file_tracking
          userLevelPrice {
            userLevelDetailName
            userLevelDetailPriceKg
            userLevelDetailPriceCube
            userLevelDetailShipping
          }
          trackingPrice {
            priceName
            priceValue
            is_minimum
          }
          size {
            weight
            long
            height
            width
          }
          lotId
          no
          guarantee
          price_wooden_box
          timeline {
            send_to_warehouse_china
            send_out_china
            send_to_warehouse_th
            payshipping
            sent_out_to_customer
          }
        }
      }
    }
  }
`;

export const ADD_LOT_TRACKING = gql`
  mutation($input: LotTransportInput) {
    add_transport_to_lot(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const REMOVE_LOT_TRACKING = gql`
  mutation($lotId: Int, $transportId: Int) {
    remove_transport_lot(lotId: $lotId, transportId: $transportId) {
      isSuccess
      message
    }
  }
`;

export const GET_FIND_TRACKINGS = gql`
  query($transportId: Int, $transportNumber: String) {
    findTransport(
      transportId: $transportId
      transportNumber: $transportNumber
    ) {
      isSuccess
      data {
        _id
        transportId
        transportNumber
        amount
        ship_by
        user {
          userId
          username
        }
        in_order {
          orderId
          orderCode
        }
        file_tracking
        userLevelPrice {
          userLevelDetailName
          userLevelDetailPriceCube
          userLevelDetailPriceKg
          userLevelDetailShipping
        }
        size {
          weight
          long
          height
          width
        }
        logisticsServicePrice {
          _id
          priceName
          priceValue
        }
        trackingPrice {
          priceName
          priceValue
          is_minimum
        }
        lotId
        no
        timeline {
          send_to_warehouse_china
          send_out_china
          send_to_warehouse_th
          payshipping
          sent_out_to_customer
        }
        remark
        remarkForCustomer
        guarantee
        price_wooden_box
        status
        statusShow
        created_at
      }
    }
  }
`;
