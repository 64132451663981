<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="systemLogisticsList">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            รายการขนส่งภายในประเทศ
          </span>
        </div>
      </h3>
    </div>
    <div
      class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
    >
      <div class="input-group input-group-solid mr-4" style="width: 280px">
        <input
          type="text"
          class="form-control"
          placeholder="ค้นหา"
          @input="debounceInput"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Search.svg" />
            </span>
          </span>
        </div>
      </div>
      <router-link
        :to="`${$route.path}/create`"
        class="btn btn-success font-weight-bolder font-size-sm mr-4"
      >
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
        </span>
        เพิ่มขนส่งในประเทศ
      </router-link>
      <button
        @click="$apollo.queries.systemLogisticsList.refetch()"
        class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      >
        <i class="ki ki-refresh"></i>
      </button>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0" v-if="systemLogisticsList">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-sm table-head-bg table-borderless table-vertical-center"
        >
          <thead>
            <tr>
              <th class="text-center">ลำดับ</th>
              <th>ชื่อขนส่ง</th>
              <th>ประเทศ</th>
              <th class="text-center">ค่าแพ็คสินค้า</th>
              <th style="min-width: 60px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in systemLogisticsList.records" v-bind:key="i">
              <td class="text-center">
                {{ item.no }}
              </td>
              <td>
                <router-link
                  :to="`/admin/setting/thai-shipping/${item.logisticsId}`"
                >
                  <div class="d-flex">
                    <div class="symbol symbol-50 symbol-light mr-2">
                      <span class="symbol-label">
                        <img
                          :src="imageUrlApi(item.image)"
                          v-if="item.image"
                          class="h-100 align-self-center object-fit-contain"
                          :alt="item.logisticsName"
                        />
                      </span>
                    </div>
                    <div class="d-flex">
                      <span class="my-auto">
                        {{ item.logisticsName }}
                      </span>
                    </div>
                  </div>
                </router-link>
              </td>
              <td>{{ countryList[item.country] }}</td>
              <td class="text-center">
                {{ item.logisticsServicePrice }}
              </td>
              <td class="text-right pr-4">
                <router-link
                  :to="`/admin/setting/thai-shipping/${item.logisticsId}`"
                  class="btn btn-icon btn-light btn-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                    <inline-svg
                      src="/media/svg/icons/Navigation/Arrow-right.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </router-link>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="
                systemLogisticsList &&
                  systemLogisticsList.records &&
                  !systemLogisticsList.records.length
              "
            >
              <td colspan="4" class="pt-8">ไม่พบขนส่งภายในประเทศ</td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="systemLogisticsList && systemLogisticsList.recordsData"
          :total-rows="systemLogisticsList.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import dayjs from "dayjs";
import debounce from "debounce";
import { GET_LOGISTICS_LIST } from "@/graphql/admin/delivery";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi } from "@/config";

export default {
  created() {
    document.title = `ขนส่งภายในประเทศ | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ขนส่งภายในประเทศ` }
    ]);
  },
  data() {
    return {
      countryList: { thai: "ไทย", lao: "ลาว" },
      page: 1,
      pageSize: 20,
      search: ""
    };
  },
  methods: {
    imageUrlApi,
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  },
  apollo: {
    systemLogisticsList: {
      query: GET_LOGISTICS_LIST,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
