<template>
  <ul class="menu-nav">
    <li class="menu-item">
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> แดชบอร์ด </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item">
      <router-link
        to="/wallet"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> กระเป๋าเงิน </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item">
      <router-link
        to="/shop/order"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              ใบสั่งซื้อ
              <span
                v-if="getCurrentNoti && getCurrentNoti.trade"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNoti.trade }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item">
      <router-link
        to="/forwarder"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              รหัสพัสดุ
              <span
                v-if="getCurrentNoti && getCurrentNoti.track"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNoti.track }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item">
      <router-link
        to="/shippay/bill"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> ใบส่งพัสดุ </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item">
      <router-link
        to="/payments"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              โอนเงินไปจีน
              <span
                v-if="getCurrentNoti && getCurrentNoti.payment"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNoti.payment }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <!-- <li class="menu-item">
      <router-link
        to="/taobao"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              ฝากจ่ายเถาเป่า
              <span
                v-if="getCurrentNoti && getCurrentNoti.taobao"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNoti.taobao }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li> -->
    <li class="menu-item" v-if="IS_SELLER">
      <router-link
        to="/seller/product"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              มาเป็นผู้ขาย
              <span
                v-if="
                  getCurrentNoti &&
                    (getCurrentNoti.seller_message ||
                      getCurrentNoti.seller_order)
                "
                class="badge badge-pill badge-danger"
              >
                {{
                  getCurrentNoti.seller_message + getCurrentNoti.seller_order
                }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="getIsAdmin">
      <router-link
        to="/admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> หน้าแอดมิน </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { IS_SELLER } from "@/config";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["getIsAdmin", "getCurrentNoti"])
  },
  data() {
    return {
      IS_SELLER
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
