<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" class="form" @submit.stop.prevent="create">
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <h3 class="text-dark-75 font-weight-bold mb-8">สร้างรายการขนส่ง</h3>
          <div class="row mt-4">
            <div class="col-lg-10">
              <div class="form-group row mb-2" v-if="searchUserList">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ผู้ใช้งาน</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <v-select
                    searchable
                    :filterable="false"
                    placeholder="กรุณารหัสผู้ใช้"
                    required
                    :options="searchUserList.records"
                    label="username"
                    @search="searchUser"
                    v-model="form.user"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.user"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-transportNumber"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >เลข Tracking</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-input
                    id="input-transportNumber"
                    v-model="form.transportNumber"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ประเภทสินค้า</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-select
                    v-model="form.userLevelPrice"
                    :state="validateState('userLevelPrice')"
                    required
                    :options="
                      systemUserLevel &&
                        (form.ship_by === 'ek'
                          ? systemUserLevel.shippingEk
                          : systemUserLevel.shippingShip)
                    "
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-remarkPurchaseOrder"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >เลข PO</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-input type="text" v-model="form.remarkPurchaseOrder" />
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >โกดังพัสดุ</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-select
                    v-model="form.trackingWarehouse"
                    :state="validateState('trackingWarehouse')"
                    required
                    :options="trackingWarehouses"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="amount"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >จำนวน</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-input
                    id="amount"
                    type="number"
                    :state="nameState"
                    min="1"
                    required
                    v-model="form.amount"
                    class="mb-0"
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="amount"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ตีลังไม้</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <span class="switch switch-sm ml-4 mt-2">
                    <label class="d-flex align-items-center">
                      <input type="checkbox" v-model="form.guarantee" />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ค่าตีลัง
                </label>
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-input
                    v-model="form.price_wooden_box"
                    type="number"
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label
                  for="amount"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ค่าบริการฝากสั่ง</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <span class="switch switch-sm ml-4 mt-2">
                    <label class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        v-model="form.check_service"
                        @change="setServicePrice()"
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
              <div
                class="row"
                v-if="form.check_service && systemUserLevel?.serviceCube"
              >
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ฝากสั่งคิดเพิ่ม คิวละ</label
                >
                <div class="col-form-label col-lg-5 col-sm-4 col-12">
                  {{ systemUserLevel.serviceCube }}
                </div>
              </div>
              <div
                class="row"
                v-if="form.check_service && systemUserLevel?.serviceKg"
              >
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ฝากสั่งคิดเพิ่ม กิโลละ</label
                >
                <div class="col-form-label col-lg-5 col-sm-4 col-12">
                  {{ systemUserLevel.serviceKg }}
                </div>
              </div>
              <div class="form-group row mb-2 mt-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >เลือกขนส่งระหว่างประเทศ</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-dropdown
                    v-model="form.ship_by"
                    :state="nameState"
                    :text="
                      form.ship_by === 'ek'
                        ? 'ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)'
                        : 'ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน）'
                    "
                  >
                    <b-dropdown-item value="ek" @click="form.ship_by = 'ek'">
                      ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)
                    </b-dropdown-item>
                    <b-dropdown-item
                      value="ship"
                      @click="form.ship_by = 'ship'"
                    >
                      ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >น้ำหนัก</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-input
                    v-model="form.size.weight"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >ขนาดพัสดุ (กxยxส)</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <div class="d-flex" style="gap: 8px">
                    <b-form-input
                      v-model="form.size.width"
                      placeholder="กว้าง"
                    ></b-form-input>
                    <b-form-input
                      v-model="form.size.long"
                      placeholder="ยาว"
                    ></b-form-input>
                    <b-form-input
                      v-model="form.size.height"
                      placeholder="สูง"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="remark"
                  class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                  >บันทึกช่วยจำ</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <b-form-input
                    id="remark"
                    now-button
                    v-model="form.remark"
                    class="mb-2"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-4"
                style="width: 140px"
              >
                เพิ่มรายการขนส่ง
              </button>
              <router-link
                to="/forwarder"
                class="btn btn-secondary"
                style="width: 140px"
              >
                ยกเลิก
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import debounce from "debounce";
import Swal from "sweetalert2";
import VSelect from "vue-select";
import { SEARCH_USERS } from "@/graphql/admin/user";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_TRACKING } from "@/graphql/admin/transport.js";
import { GET_USER_LEVEL } from "@/graphql/admin/user_level";
import { GET_TRACKING_WAREHOUSE_LIST } from "@/graphql/tracking_warehouse";

export default {
  mixins: [validationMixin],
  name: "forwarder_create",
  components: {
    VSelect
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการพัสดุ`, route: "/forwarder" },
      { title: `สร้างรายการพัสดุ` }
    ]);
  },
  data() {
    return {
      nameState: null,
      search: "",
      trackingPrice: {},
      trackingWarehouse: null,
      form: {
        transportNumber: "",
        user: null,
        amount: 0,
        ship_by: "ek",
        remark: "",
        remarkPurchaseOrder: "",
        guarantee: false,
        size: {
          weight: 0,
          width: 0,
          long: 0,
          height: 0
        },
        timeline: {},
        trackingPrice: {}
      }
    };
  },
  validations: {
    form: {
      user: {
        userId: {
          required
        }
      },
      transportNumber: {
        required
      },
      amount: {
        required
      },
      ship_by: {
        required
      },
      userLevelPrice: {
        required
      },
      serviceCube: {},
      serviceKg: {},
      remarkPurchaseOrder: {},
      remark: {},
      guarantee: {},
      price_wooden_box: {},
      size: {},
      trackingWarehouse: {},
      timeline: {
        send_to_warehouse_china: {},
        send_out_china: {},
        send_to_warehouse_th: {}
      }
    }
  },
  methods: {
    setServicePrice() {
      if (this.form.check_service) {
        this.form.serviceCube = this.systemUserLevel.serviceCube;
        this.form.serviceKg = this.systemUserLevel.serviceKg;
      } else {
        this.form.serviceCube = 0;
        this.form.serviceKg = 0;
      }
    },
    searchUser(search, loading) {
      if (search.length) {
        this.search = search;
        loading(true);
        this.searchDelay(loading, search, this);
      }
    },
    searchDelay: debounce((loading, search, vm) => {
      vm.$apollo.queries.searchUserList.refetch({ search }).then(() => {
        loading(false);
      });
    }, 350),
    validateStateUser(name) {
      const { $dirty, $error } = this.$v.form["user"][name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async create() {
      this.$v.form.$touch();
      this.setServicePrice();

      if (this.$v.form.$anyError) {
        return;
      }

      const userId = this.$v.form.user.userId.$model;
      const transportNumber = this.$v.form.transportNumber.$model;
      const ship_by = this.$v.form.ship_by.$model;
      const amount = this.$v.form.amount.$model;
      const remark = this.$v.form.remark.$model;
      const guarantee = this.$v.form.guarantee.$model;
      const price_wooden_box = this.$v.form.price_wooden_box.$model;
      const serviceCube = this.$v.form.serviceCube.$model;
      const serviceKg = this.$v.form.serviceKg.$model;
      const userLevelPrice = this.$v.form.userLevelPrice.$model;
      const size = this.$v.form.size.$model;
      const trackingWarehouse = this.$v.form.trackingWarehouse.$model;
      const remarkPurchaseOrder = this.$v.form.remarkPurchaseOrder.$model;

      size.weight = Number(size.weight);
      size.width = Number(size.width);
      size.long = Number(size.long);
      size.height = Number(size.height);

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        transportNumber,
        ship_by,
        userId,
        amount: Number(amount),
        remark,
        guarantee,
        price_wooden_box,
        serviceCube,
        serviceKg,
        size: size,
        trackingWarehouse,
        remarkPurchaseOrder,
        userLevelPrice: {
          userLevelDetailName: userLevelPrice.userLevelDetailName,
          userLevelDetailNameShort: userLevelPrice.userLevelDetailNameShort,
          userLevelDetailPriceCube: userLevelPrice.userLevelDetailPriceCube,
          userLevelDetailPriceKg: userLevelPrice.userLevelDetailPriceKg,
          userLevelDetailShipping: userLevelPrice.userLevelDetailShipping
        }
      };
      const {
        data: { createTransport }
      } = await this.$apollo.mutate({
        mutation: CREATE_TRACKING,
        variables: {
          input: input
        }
      });
      if (!createTransport.isSuccess) {
        this.$message.error(createTransport.message);
      } else {
        this.$message.success(createTransport.message);
        this.$router.push({ name: "admin_forwarder" });
      }
    }
  },
  apollo: {
    searchUserList: {
      query: SEARCH_USERS,
      variables() {
        return {
          search: this.search
        };
      }
    },
    trackingWarehouses: {
      query: GET_TRACKING_WAREHOUSE_LIST,
      update: ({ trackingWarehouses }) => {
        return (
          trackingWarehouses?.records.map(item => ({
            text: `${item.name} (kg: ${item.warehouseKg ||
              0}, cbm: ${item.warehouseCube || 0})`,
            value: item._id
          })) || []
        );
      }
    },
    systemUserLevel: {
      query: GET_USER_LEVEL,
      skip() {
        return !this || !this.form || !this.form.user || !this.form.user.userId;
      },
      variables() {
        return {
          userId: this.form && this.form.user && this.form.user.userId
        };
      },
      update: ({ systemUserLevel }) => {
        if (!systemUserLevel) {
          return {
            shippingShip: [],
            shippingEk: []
          };
        }
        systemUserLevel.shippingEk = (systemUserLevel.shippingEk || []).map(
          x => ({
            text: `${x.userLevelDetailName} (kg: ${x.userLevelDetailPriceKg}, cbm: ${x.userLevelDetailPriceCube})`,
            value: {
              userLevelDetailName: x.userLevelDetailName,
              userLevelDetailNameShort: x.userLevelDetailNameShort,
              userLevelDetailPriceCube: x.userLevelDetailPriceCube,
              userLevelDetailPriceKg: x.userLevelDetailPriceKg,
              userLevelDetailShipping: x.userLevelDetailShipping
            }
          })
        );
        systemUserLevel.shippingShip = (systemUserLevel.shippingShip || []).map(
          x => ({
            text: `${x.userLevelDetailName} (kg: ${x.userLevelDetailPriceKg}, cbm: ${x.userLevelDetailPriceCube})`,
            value: {
              userLevelDetailName: x.userLevelDetailName,
              userLevelDetailNameShort: x.userLevelDetailNameShort,
              userLevelDetailPriceCube: x.userLevelDetailPriceCube,
              userLevelDetailPriceKg: x.userLevelDetailPriceKg,
              userLevelDetailShipping: x.userLevelDetailShipping
            }
          })
        );
        return systemUserLevel;
      }
    }
  }
};
</script>
