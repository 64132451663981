import gql from "graphql-tag";

export const GET_ORDER = gql`
  query($id: Int!) {
    orderDetail(id: $id) {
      isSuccess
      message
      record {
        _id
        orderId
        orderCode
        ship_by
        shipping_price
        serviceCube
        serviceKg
        cancel_remark
        remark
        status
        statusShow
        currency
        currencySign
        user {
          username
        }
        total
        rate
        total_baht
        created_at
        pay_date
        transactions {
          amount
          reason
          doc_id
          created_at
        }
        transports {
          _id
          transportId
          transportNumber
          remark
          created_at
          payshipping_id
          payshipping_doc
          ship_by
          guarantee
          no
          timeline {
            send_to_warehouse_china
            send_out_china
            send_to_warehouse_th
            payshipping
            sent_out_to_customer
          }
        }
        vendors {
          _id
          vendor
          provider
          guarantee
          qc
          shipping_price
          currency
          currencySign
          remark
          items {
            _id
            mainImage
            productName
            url
            price
            amount_buy
            amount
            remark
            remarkForCustomer
            file_attachment
            currency
            currencySign
            properties {
              id
              name
              title
              image
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query(
    $pageSize: Int!
    $page: Int!
    $status: String
    $ship_by: String
    $search: String
  ) {
    orderList(
      pageSize: $pageSize
      page: $page
      status: $status
      ship_by: $ship_by
      search: $search
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        orderId
        orderCode
        ship_by
        remark
        status
        statusShow
        item_count
        total
        rate
        total_baht
        created_at
        itemsLess {
          _id
          mainImage
          productName
          url
          price
          amount_buy
          amount
          properties {
            id
            name
            title
            image
          }
        }
      }
    }
  }
`;

export const CHECKOUT_ORDER = gql`
  mutation($input: ChecoutOderInput!) {
    checkoutOrder(input: $input) {
      isSuccess
      message
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation($id: Int, $remark: String) {
    orderDetailReject(id: $id, remark: $remark) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_ORDER_REMARK = gql`
  mutation($id: ID, $remark: String) {
    updateOrder(input: { _id: $id, remark: $remark }) {
      isSuccess
      message
    }
  }
`;

export const PAY_ORDER = gql`
  mutation($id: Int) {
    payOrder(id: $id) {
      isSuccess
      message
    }
  }
`;
