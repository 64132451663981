<template>
  <b-modal
    id="dept-modal"
    @hidden="resetModal"
    centered
    title="เพิ่มห้องสนทนา"
    :hide-footer="true"
  >
    <b-overlay :show="$apollo.loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="handleSubmit">
        <div class="form-group row mb-2">
          <label for="input-dept_name" class="col-form-label text-right col-3">
            ชื่อ
          </label>
          <div class="col-lg-8 col-9">
            <b-form-input
              id="input-dept_name"
              v-model="systemDepartment.dept_name"
              required
              :state="validateState('dept_name')"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <div class="form-group row mb-2">
          <label for="input-dept_desc" class="col-form-label text-right col-3">
            รายละเอียด
          </label>
          <div class="col-lg-8 col-9">
            <b-form-input
              id="input-dept_desc"
              v-model="systemDepartment.dept_desc"
              :state="validateState('dept_desc')"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <div class="form-group row mb-2">
          <label for="input-no" class="col-form-label text-right col-3">
            ลำดับ
          </label>
          <div class="col-lg-8 col-9">
            <b-form-input
              id="input-no"
              v-model.number="systemDepartment.no"
              required
              :state="validateState('no')"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="text-center pt-15">
          <button @click="close" type="button" class="btn btn-white mr-3">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary">
            <span class="indicator-label" v-if="!$apollo.loading">Submit</span>
            <span class="indicator-progress" v-if="$apollo.loading"
              >Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ml-2"
              ></span
            ></span>
          </button>
        </div>
        <!--end::Actions-->
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  GET_ADMIN_DEPARTMENT
} from "@/graphql/admin/department.js";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  props: ["refetch", "_id", "onClose"],
  data() {
    return {
      systemDepartment: {
        dept_name: "",
        dept_desc: "",
        no: 0
      }
    };
  },
  validations: {
    systemDepartment: {
      dept_name: {
        required
      },
      dept_desc: {},
      no: {
        required
      }
    }
  },
  methods: {
    resetModal() {
      if (this.onClose) {
        this.onClose();
      }
      this.systemDepartment = {
        dept_name: "",
        dept_desc: "",
        no: 0
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.systemDepartment[name];
      return $dirty ? !$error : null;
    },
    close() {
      this.$nextTick(() => {
        this.$bvModal.hide("dept-modal");
      });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      this.$v.systemDepartment.$touch();
      if (this.$v.systemDepartment.$anyError) {
        return;
      }
      const dept_name = this.$v.systemDepartment.dept_name.$model;
      const dept_desc = this.$v.systemDepartment.dept_desc.$model;
      const no = this.$v.systemDepartment.no.$model;
      const input = {
        dept_name,
        dept_desc,
        no
      };
      if (this._id) {
        input.dept_id = this.systemDepartment.dept_id;
        const {
          data: { updateDepartment }
        } = await this.$apollo.mutate({
          mutation: UPDATE_DEPARTMENT,
          variables: { input }
        });
        if (!updateDepartment.isSuccess) {
          this.$message.error(updateDepartment.message);
        } else {
          this.$message.success(updateDepartment.message);
          if (this.refetch) {
            this.refetch();
          }
          this.$nextTick(() => {
            this.$bvModal.hide("dept-modal");
          });
        }
        return;
      }
      const {
        data: { createDepartment }
      } = await this.$apollo.mutate({
        mutation: CREATE_DEPARTMENT,
        variables: { input }
      });
      if (!createDepartment.isSuccess) {
        this.$message.error(createDepartment.message);
      } else {
        this.$message.success(createDepartment.message);
        if (this.refetch) {
          this.refetch();
        }
        this.$nextTick(() => {
          this.$bvModal.hide("dept-modal");
        });
      }
    }
  },
  apollo: {
    systemDepartment: {
      query: GET_ADMIN_DEPARTMENT,
      skip() {
        return !this._id;
      },
      variables() {
        return {
          _id: this._id
        };
      },
      update: ({ systemDepartment }) => {
        const data = { ...systemDepartment.data };
        return data;
      }
    }
  }
};
</script>
