<template>
  <!--begin::Body-->
  <div class="card-body pt-0 pb-3">
    <!--begin::Table-->
    <div class="table-responsive">
      <table
        class="table table-head-custom table-head-bg table-borderless table-vertical-center"
      >
        <thead>
          <tr class="text-uppercase">
            <th style="min-width: 100px" class="pl-7">
              <span class="text-dark-75">รหัสธุรกรรม</span>
            </th>
            <th style="min-width: 100px">ผู้ใช้</th>
            <th style="min-width: 100px">วันที่</th>
            <th style="min-width: 150px">ธนาคาร</th>
            <th style="min-width: 120px">ยอดเงิน</th>
            <th style="min-width: 100px">รูปหลักฐาน</th>
            <th style="min-width: 100px">สถานะ</th>
            <th style="min-width: 60px"></th>
          </tr>
        </thead>
        <tbody v-if="systemTopupList">
          <tr v-for="item in systemTopupList.records" v-bind:key="item._id">
            <td class="pl-7 py-8 font-weight-bolder font-size-lg">
              {{ item.topup_no }}
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">
                <router-link :to="`/admin/users/${item.userId}`">
                  {{ item.user ? item.user.username : "-" }}
                </router-link>
              </span>
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">
                {{ format_datetime(item.created_at) }}
              </span>
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.bank ? item.bank.bank_name : "-" }}
              </span>
            </td>
            <td>
              <span
                class="text-success font-weight-bolder d-block font-size-lg"
              >
                ฿{{ format_number_pretty(item.amount, 2) }}
              </span>
            </td>
            <td>
              <div class="symbol symbol-40 flex-shrink-0 mr-4">
                <img
                  :src="imageUrlApi(item.file_attachment)"
                  style="object-fit: cover"
                  class="symbol-label"
                  v-viewer
                />
              </div>
            </td>
            <td>
              <span
                class="label label-lg label-inline"
                v-bind:class="`label-light-${getStatusColor(item.status)}`"
              >
                {{ item.statusShow }}
              </span>
            </td>
            <td class="text-right pr-0 text-nowrap">
              <button
                v-if="item.status === 0"
                v-on:click="approve($event, item)"
                class="btn btn-icon btn-light btn-hover-primary btn-sm py-2 mr-2"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                </span>
              </button>
              <router-link
                :to="`/admin/wallet/topup/${item.topup_id}`"
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg
                    src="/media/svg/icons/Navigation/Arrow-right.svg"
                  />
                </span>
              </router-link>
            </td>
          </tr>
          <tr
            class="text-center"
            v-if="
              systemTopupList &&
                systemTopupList.records &&
                !systemTopupList.records.length
            "
          >
            <td colspan="8" class="pt-8">ไม่พบรายการ</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--end::Table-->
    <b-pagination
      v-model="page"
      class="mt-4"
      size="md"
      v-if="systemTopupList && systemTopupList.recordsData"
      :total-rows="
        systemTopupList && systemTopupList.recordsData.recordsFiltered
      "
      :per-page="pageSize"
    ></b-pagination>
  </div>
  <!--end::Body-->
</template>
<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { imageUrlApi, WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_TOPUPS, APPROVE_TOPUP } from "@/graphql/admin/topup";

export default {
  name: "TopupList",
  props: ["search"],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "กระเป๋าเงิน" }]);
  },
  created() {
    document.title = `กระเป๋าเงิน | ${WEBSITE}`;
  },
  data() {
    const { page = 1 } = this.$route.query;
    return {
      page: Number(page) || 1,
      pageSize: 10,
      loading: false,
      status: "",
      cat: 2
    };
  },
  methods: {
    imageUrlApi,
    async approve(e, value) {
      const confirm = await Swal.fire({
        title: "ต้องการอนุมัติเติมเงิน ?",
        icon: "question",
        html: `อนุมัติเติมเงิน ${value.topup_no}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { approveTopup }
      } = await this.$apollo.mutate({
        mutation: APPROVE_TOPUP,
        variables: {
          id: value.topup_id
        }
      });
      if (approveTopup.error) {
        this.$message.error(approveTopup.message);
      } else {
        this.$message.success(approveTopup.message);
        this.$apollo.queries.systemTopupList.refetch();
      }
    },
    getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val, digits = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    }
  },
  watch: {
    page: function(val) {
      const newpath = `${this.$route.path}?cat=${this.cat}&page=${val}`;
      if (newpath !== this.$route.fullPath) {
        this.$router.push(newpath);
      }
    },
    "$route.query.page": function(val) {
      this.page = Number(val) || 1;
    }
  },
  apollo: {
    systemTopupList: {
      query: GET_TOPUPS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          type: "all"
        };
      }
    }
  }
};
</script>
