<template>
  <div
    class="main"
    v-if="data"
    :style="`top: ${top}px;height: calc(100% - ${top}px);`"
    :class="{
      'main-visible': visible && data
    }"
  >
    <!-- Chat -->
    <div class="chat dropzone-form-js" v-if="data">
      <!-- Chat: body -->
      <div class="chat-body">
        <!-- Chat: Header -->
        <div class="chat-header border-bottom py-4 py-lg-6 px-lg-8">
          <div class="container-xxl">
            <div class="row align-items-center">
              <!-- Close chat(mobile) -->
              <div class="col-2 d-xl-none">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <div class="text-muted px-0" @click="toggleVisible">
                      <i class="icon-2x la la-angle-left"></i>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Chat photo -->
              <div class="col-8 col-xl-8">
                <div class="media text-center text-xl-left">
                  <div class="media-body align-self-center">
                    <h6 class="mb-n1">
                      {{ data.dept.dept_name }}
                    </h6>
                    <small class="text-muted">{{ currentUser.username }}</small>
                  </div>
                </div>
              </div>
            </div>
            <!-- .row -->
          </div>
        </div>
        <!-- Chat: Header -->

        <!-- Chat: Content-->
        <div
          class="chat-content px-lg-8 scroll-y"
          v-if="data"
          id="message_wrapper"
          v-chat-scroll
          @v-chat-scroll-top-reached="more"
        >
          <div class="container-xxl py-6 py-lg-10">
            <!--begin::Messages-->

            <!--begin::Message(in)-->
            <div
              class="d-flex mb-10"
              v-for="item in list"
              :key="item._id"
              :class="{
                'justify-content-start': item.receiver_id,
                'justify-content-end': !item.receiver_id
              }"
            >
              <!--begin::Wrapper-->
              <div
                class="d-flex flex-column"
                :class="{
                  'align-items-start': item.receiver_id,
                  'align-items-end': !item.receiver_id
                }"
              >
                <!--begin::User-->
                <div class="d-flex align-items-center">
                  <div
                    class="mr-3 text-right"
                    v-if="item.sender_id && !item.receiver_id"
                  >
                    <div
                      class="fs-5 fw-bolder text-gray-900 text-hover-primary ml-2"
                    >
                      {{ item.sender.full_name }}
                    </div>
                    <div class="text-muted fs-7 mb-1">
                      {{ format_datetime(item.created_at) }}
                    </div>
                  </div>
                  <!--begin::Avatar-->
                  <div class="symbol symbol-30px symbol-circle">
                    <span
                      class="symbol-label bg-light-danger text-danger fs-6 fw-bolder"
                      >{{
                        item.sender ? item.sender.full_name.substr(0, 2) : ""
                      }}</span
                    >
                  </div>
                  <!--end::Avatar-->
                  <div class="ml-3" v-if="item.sender_id && item.receiver_id">
                    <div
                      class="fs-5 fw-bolder text-gray-900 text-hover-primary mr-2"
                    >
                      {{ item.sender ? item.sender.full_name : "" }}
                    </div>
                    <div class="text-muted fs-7 mb-1">
                      {{ format_datetime(item.created_at) }}
                    </div>
                  </div>
                </div>
                <!--end::User-->
                <!--begin::Text-->
                <div
                  class="p-4 rounded text-dark fw-bold mw-lg-400px text-start text-message mt-2"
                  v-if="item.body"
                  :class="{
                    'bg-light-info': item.receiver_id,
                    'bg-light-primary': !item.receiver_id
                  }"
                >
                  {{ item.body }}
                </div>
                <div
                  v-if="item.file_attachment"
                  class="p-4 rounded text-dark fw-bold mw-lg-400px text-start text-message mt-2"
                  :class="{
                    'bg-light-info': !item.receiver_id,
                    'bg-light-primary': item.receiver_id
                  }"
                >
                  <img
                    v-viewer
                    :src="item.file_attachment"
                    class="img-message"
                  />
                </div>
                <!--end::Text-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::Message(in)-->
            <!--end::Messages-->
          </div>

          <!-- Scroll to end -->
          <div class="end-of-chat"></div>
        </div>
        <!-- Chat: Content -->

        <!-- Chat: DropzoneJS container -->
        <div class="chat-files hide-scrollbar px-lg-8">
          <div class="container-xxl">
            <div class="dropzone-previews-js form-row py-4">
              <div
                v-if="previewImage"
                class="col-lg-4 my-3 dz-processing dz-image-preview dz-error dz-complete"
              >
                <div class="card bg-light uppy">
                  <div class="card-body p-3 uppy-list">
                    <div
                      class="media align-items-center uppy-list-item p-0 my-0"
                    >
                      <div class="dropzone-image-preview">
                        <div class="avatar avatar mr-5">
                          <img
                            :src="previewImage"
                            class="avatar-img rounded"
                            :alt="file.name"
                          />
                        </div>
                      </div>

                      <div class="media-body overflow-hidden">
                        <h6 class="text-truncate small mb-0 fs-5">
                          {{ file.name }}
                        </h6>
                        <p class="extra-small">
                          <strong>{{ file.size / 1000 }}</strong> KB
                        </p>
                      </div>
                      <div class="ml-5">
                        <span
                          @click="removeFile()"
                          class="uppy-list-remove text-muted"
                        >
                          <i class="flaticon2-cancel-music"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Chat: DropzoneJS container -->

        <!-- Chat: Footer -->
        <b-overlay :show="loading" rounded="sm">
          <div class="chat-footer border-top py-2 py-lg-6 px-lg-8">
            <div class="container-xxl">
              <div class="form-row align-items-center">
                <div class="col">
                  <div class="input-group">
                    <!-- Textarea -->
                    <b-form-textarea
                      class="form-control form-control-flush"
                      rows="1"
                      max-rows="6"
                      v-model="message"
                      placeholder="เขียนข้อความ"
                    />

                    <!-- Upload button -->
                    <div class="input-group-append">
                      <input
                        class="d-none"
                        ref="fileInput"
                        type="file"
                        name="files[]"
                        accept="image/*"
                        multiple=""
                        @input="pickFile"
                        id="file"
                        style=""
                      />
                      <label
                        for="file"
                        class="btn btn-icon btn-secondary btn-minimal bg-transparent border-0 dropzone-button-js dz-clickable"
                      >
                        <span class="la la-paperclip icon-2x" />
                      </label>
                    </div>
                  </div>
                </div>

                <!-- Submit button -->
                <div class="col-auto">
                  <button
                    class="btn btn-icon btn-primary rounded-circle"
                    type="button"
                    @click="save"
                  >
                    <span class="fe-send"></span>
                    <span class="la la-send icon-2x" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
        <!-- Chat: Footer -->
      </div>
      <!-- Chat: body -->
    </div>
    <!-- Chat -->
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/_private_chat.scss";
</style>
<script>
// https://themes.2the.me/Messenger-1.1/chat-1.html
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { CREATE_MESSAGE } from "@/graphql/message.js";
import { mapGetters } from "vuex";

export default {
  props: ["list", "data", "refetch", "fetchMore", "enabled", "visible"],
  data() {
    return {
      file: null,
      previewImage: null,
      loading: false,
      message: "",
      top: 0
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    toggleVisible() {
      const path = `/messages?d=${this.data.dept.dept_id}`;
      if (this.$route.fullPath !== path) {
        this.$router.push(path);
      }
      this.$emit("change-visible", !this.visible);
    },
    more() {
      if (this.fetchMore) {
        this.fetchMore();
      }
    },
    async save() {
      if (!this.message && !this.file) {
        return;
      }
      this.loading = true;
      const {
        data: { createMessage }
      } = await this.$apollo.mutate({
        mutation: CREATE_MESSAGE,
        variables: {
          input: {
            dept_id: this.data.dept.dept_id,
            file: this.file,
            body: this.message
          }
        }
      });
      this.loading = false;
      const { message, is_error } = createMessage;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.message = "";
        this.removeFile();
        if (this.refetch) {
          this.refetch();
        }
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
    }
  },
  watch: {
    visible: function(newVal) {
      if (newVal) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    }
  }
};
</script>
