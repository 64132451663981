import gql from "graphql-tag";

export const SELLER_ORDER_LIST = gql`
  query($pageSize: Int!, $page: Int!, $status: String, $search: String) {
    seller_order_list(
      pageSize: $pageSize
      page: $page
      status: $status
      search: $search
    ) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        sellerOrderId
        ship_by
        statusShow
        status
        total
        user {
          username
          full_name
        }
        itemsLess {
          _id
          mainImage
          productName
          url
          amount_buy
          amount
          properties {
            id
            name
            title
            image
          }
        }
        created_at
      }
    }
  }
`;

export const SELLER_ORDER = gql`
  query($id: Int) {
    seller_order(id: $id) {
      isSuccess
      message
      canCancel
      canConfirmCancel
      data {
        _id
        sellerOrderId
        ship_by
        total
        status
        statusShow
        user {
          username
          full_name
        }
        logistics {
          logisticsName
        }
        address {
          name
          phone
          address
          province
          amphoe
          district
          postcode
        }
        vendors {
          status
          statusShow
          total_product
          shipping_price
          forwarder_price
          file_shipping
          fee
          total
          total_pay
          remark
          cancel_remark
          cancel_request
          items {
            _id
            mainImage
            productName
            url
            amount_buy
            amount
            price
            properties {
              id
              name
              title
              image
            }
          }
          transports {
            _id
            transportId
            transportNumber
            status
            statusShow
            timeline {
              send_to_warehouse_china
              send_out_china
              send_to_warehouse_th
              payshipping
              sent_out_to_customer
            }
            timeline_list {
              name
              date
            }
          }
        }
        created_at
      }
    }
  }
`;

export const SELLER_ORDER_TRACKING_CREATE = gql`
  mutation($input: TrackingInput, $orderId: ID) {
    seller_order_tracking_create(input: $input, orderId: $orderId) {
      isSuccess
      message
    }
  }
`;

export const SELLER_ORDER_TRACKING_UPDATE = gql`
  mutation($input: TrackingInput) {
    seller_order_tracking_update(input: $input) {
      isSuccess
      message
    }
  }
`;

export const SELLER_ORDER_TRACKING_DELETE = gql`
  mutation($_id: ID, $orderId: ID) {
    seller_order_tracking_delete(_id: $_id, orderId: $orderId) {
      isSuccess
      message
    }
  }
`;

export const SELLER_ORDER_STATUS = gql`
  query {
    seller_order_status {
      isSuccess
      message
      records {
        status
        value
      }
    }
  }
`;

export const SELLER_ORDER_CANCEL = gql`
  mutation($_id: ID, $remark: String) {
    seller_order_cencel(_id: $_id, remark: $remark) {
      isSuccess
      message
    }
  }
`;

export const CONFIRM_SELLER_ORDER_CANCEL = gql`
  mutation($_id: ID) {
    confirm_seller_order_cencel(_id: $_id) {
      isSuccess
      message
    }
  }
`;

export const UPLOAD_SELLER_ORDER = gql`
  mutation($_id: ID, $file: String) {
    upload_seller_order(_id: $_id, file: $file) {
      isSuccess
      message
    }
  }
`;
