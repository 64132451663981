import gql from "graphql-tag";

export const GET_ORDERS = gql`
  query(
    $pageSize: Int!
    $page: Int!
    $status: String
    $ship_by: String
    $search: String
  ) {
    systemOrderList(
      pageSize: $pageSize
      page: $page
      status: $status
      ship_by: $ship_by
      search: $search
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        orderId
        orderCode
        ship_by
        remark
        status
        statusShow
        item_count
        total
        rate
        total_baht
        created_at
        userId
        user {
          username
        }
        itemsLess {
          _id
          mainImage
          productName
          url
          price
          amount_buy
          amount
          properties {
            id
            name
            title
            image
          }
        }
      }
    }
  }
`;

export const GET_ORDER = gql`
  query($id: Int!) {
    systemOrder(id: $id) {
      isSuccess
      message
      record {
        _id
        orderId
        orderCode
        run_id
        ship_by
        shipping_price
        serviceCube
        serviceKg
        cancel_remark
        remark
        status
        statusShow
        currency
        currencySign
        lock
        user {
          username
        }
        total
        rate
        total_baht
        created_at
        pay_date
        updated_id_info {
          userId
          username
        }
        cancel_by_info {
          userId
          username
        }
        approve_by_info {
          userId
          username
        }
        buy_by_info {
          userId
          username
        }
        transactions {
          amount
          reason
          doc_id
          created_at
        }
        transports {
          _id
          transportId
          transportNumber
          remark
          created_at
          payshipping_id
          payshipping_doc
          ship_by
          guarantee
          no
          timeline {
            send_to_warehouse_china
            send_out_china
            send_to_warehouse_th
            payshipping
            sent_out_to_customer
          }
        }
        vendors {
          _id
          vendor
          provider
          guarantee
          qc
          shipping_price
          remark
          currency
          currencySign
          items {
            _id
            mainImage
            productName
            url
            price
            amount_buy
            amount
            remark
            currency
            currencySign
            remarkForCustomer
            file_attachment
            properties {
              id
              name
              title
              image
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ORDER_AMOUNT = gql`
  mutation($input: OrderItemInput) {
    updateOrderAmount(input: $input) {
      isSuccess
      message
    }
  }
`;

export const APPROVE_ORDER = gql`
  mutation($id: Int) {
    approveOrder(id: $id) {
      isSuccess
      message
    }
  }
`;

export const WATING_APPROVE = gql`
  mutation($id: Int) {
    systemOrderDoCheck(id: $id) {
      isSuccess
      message
    }
  }
`;

export const BUY_COMPLETE = gql`
  mutation($id: Int) {
    buyComplete(id: $id) {
      isSuccess
      message
    }
  }
`;

export const WAITING_TRANSPORT = gql`
  mutation($id: Int) {
    waitingTransport(id: $id) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_ORDER_SHIPPING_PRICE = gql`
  mutation($input: OderInput) {
    updateOrderShippingPrice(input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_ORDER_CURRENCY = gql`
  mutation($input: OderInput) {
    updateOrderCurrency(input: $input) {
      isSuccess
      message
    }
  }
`;

export const ORDER_ADD_TRACKING = gql`
  mutation(
    $orderId: Int
    $transportNumber: String
    $qc: Boolean
    $guarantee: Boolean
  ) {
    orderAddTracking(
      orderId: $orderId
      transportNumber: $transportNumber
      qc: $qc
      guarantee: $guarantee
    ) {
      isSuccess
      message
    }
  }
`;

export const ORDER_CHOOSE_TRACKING = gql`
  mutation($orderId: Int, $transportId: Int) {
    orderChooseTracking(orderId: $orderId, transportId: $transportId) {
      isSuccess
      message
    }
  }
`;

export const ORDER_REMOVE_TRACKING = gql`
  mutation($orderId: Int, $transportId: Int) {
    orderRemoveTracking(orderId: $orderId, transportId: $transportId) {
      isSuccess
      message
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation($id: Int) {
    systemOrderReject(id: $id) {
      isSuccess
      message
    }
  }
`;

export const CHANGE_ORDER_SHIPPING = gql`
  mutation($_id: ID, $ship_by: String) {
    systemChangeOrderShipping(_id: $_id, ship_by: $ship_by) {
      is_error
      message
    }
  }
`;
