import gql from "graphql-tag";

export const CREATE_USER_LEVEL = gql`
  mutation($input: UserLevelInput) {
    createUserLevel(input: $input) {
      isSuccess
      is_error
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_USER_LEVEL = gql`
  mutation($input: UserLevelInput) {
    updateUserLevel(input: $input) {
      isSuccess
      is_error
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_USER_LEVEL = gql`
  mutation($_id: ID!) {
    deleteUserLevel(_id: $_id) {
      isSuccess
      is_error
      message
    }
  }
`;

export const GET_USER_LEVEL_LIST = gql`
  query {
    systemUserLevelList {
      records {
        _id
        userLevelId
        userLevelNo
        userLevelName
        serviceCube
        serviceKg
        is_show
        is_default
        total_role_used
        created_at
      }
    }
  }
`;

export const GET_USER_LEVEL = gql`
  query($id: Int, $userId: Int) {
    systemUserLevel(id: $id, userId: $userId) {
      _id
      userLevelId
      userLevelNo
      userLevelName
      serviceCube
      serviceKg
      shippingEk {
        _id
        userLevelDetailName
        userLevelDetailNameShort
        userLevelDetailPriceKg
        userLevelDetailPriceCube
        userLevelDetailShipping
      }
      shippingShip {
        _id
        userLevelDetailName
        userLevelDetailNameShort
        userLevelDetailPriceKg
        userLevelDetailPriceCube
        userLevelDetailShipping
      }
      is_default
      is_show
    }
  }
`;
