<template>
  <ul class="menu-nav">
    <li class="menu-item" v-if="permission['admin_user']">
      <router-link
        to="/admin/users"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> ผู้ใช้งาน </span>
        </a>
      </router-link>
    </li>
    <li
      class="menu-item"
      v-if="permission['admin_balance'] || permission['admin_topup']"
    >
      <router-link
        to="/admin/wallet"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              กระเป๋าเงิน
              <span
                v-if="
                  getCurrentNotiAdmin &&
                    (getCurrentNotiAdmin.withdraw || getCurrentNotiAdmin.topup)
                "
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNotiAdmin.withdraw + getCurrentNotiAdmin.topup }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="permission['admin_order']">
      <router-link
        to="/admin/shop/order"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              ใบสั่งซื้อ
              <span
                v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.trade"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNotiAdmin.trade }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="permission['admin_payshipping']">
      <router-link
        to="/admin/forwarder"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> รหัสพัสดุ </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="permission['admin_payshipping']">
      <router-link
        to="/admin/shippay/bill"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              ใบส่งพัสดุ
              <span
                v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.bill"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNotiAdmin.bill }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="permission['admin_lot']">
      <router-link
        to="/admin/lot"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m"> ล็อต </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="permission['admin_payment']">
      <router-link
        to="/admin/payments"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              โอนเงินไปจีน
              <span
                v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.payment"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNotiAdmin.payment }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="permission['admin_chat']">
      <router-link
        to="/admin/chat"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2 noti">
            <span class="menu-m">
              แชท
              <span
                v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.msg"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNotiAdmin.msg }}
              </span>
            </span>
          </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item" v-if="permission['setting']">
      <router-link
        to="/admin/setting"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> ตั้งค่า </span>
        </a>
      </router-link>
    </li>
    <li class="menu-item">
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          class="menu-link"
          data-tab="1"
          data-toggle="tab"
          :href="href"
          role="tab"
          aria-selected="true"
          :class="[isActive && 'active', isExactActive && 'active']"
          @click="navigate"
        >
          <span class="menu-text fs-2"> หน้าผู้ใช้ </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      permission: {}
    };
  },
  computed: {
    ...mapGetters(["getIsAdmin", "getCurrentNotiAdmin", "currentPermission"])
  },
  mounted() {
    // setting permission
    const permission = {};
    const settingPermission =
      this.checkPermission("admin_role", 1) ||
      this.checkPermission("admin_bank", 1) ||
      this.checkPermission("admin_china_thai", 1) ||
      this.checkPermission("admin_exchange_rate", 1) ||
      this.checkPermission("admin_delivery", 1);
    permission["admin_user"] = this.checkPermission("admin_user", 0);
    permission["admin_order"] = this.checkPermission("admin_order", 0);
    permission["admin_topup"] = this.checkPermission("admin_topup", 0);
    permission["admin_balance"] = this.checkPermission("admin_balance", 0);
    permission["admin_payshipping"] = this.checkPermission(
      "admin_payshipping",
      0
    );
    permission["admin_chat"] = this.checkPermission("admin_chat", 0);
    permission["admin_payment"] = this.checkPermission("admin_payment", 0);
    permission["admin_lot"] = this.checkPermission("admin_lot", 0);
    permission["admin_lot"] = this.checkPermission("admin_lot", 0);
    permission["admin_taobao"] = this.checkPermission("admin_taobao", 0);
    permission["setting"] = settingPermission;
    this.permission = permission;
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }
      const { permissionRole } = this.currentPermission;

      const find = permissionRole.find(
        x => x.permissionInfo && x.permissionInfo.permissionName === name
      );
      if (!find) {
        return false;
      }
      if (type === 0 && find.readableRole) {
        return true;
      }
      if (type === 1 && find.writeableRole) {
        return true;
      }
      return false;
    }
  }
};
</script>
