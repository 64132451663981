import gql from "graphql-tag";

export const ADD_CART = gql`
  mutation($cart: [CartInput]) {
    addCart(input: { cart: $cart }) {
      isSuccess
      message
    }
  }
`;

export const GET_CARTS = gql`
  query {
    profile_cart {
      isSuccess
      message
      record {
        rate
        serviceCube
        serviceKg
        profile_cart {
          _id
          vendor
          provider
          qc
          guarantee
          is_mall
          currency
          currencySign
          items {
            _id
            productName
            mainImage
            vendor
            amount
            price
            url
            provider
            remark
            file_attachment
            currency
            currencySign
            properties {
              id
              name
              title
              image
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PROFILE_CART = gql`
  mutation($input: [CartShopInput]!) {
    cart_delete(input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_AMOUNT_CART = gql`
  mutation($input: TwoIdInt!) {
    cart_update(input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_CRAFTQC_CART = gql`
  mutation($input: IdInt!) {
    enableWoodCart(input: $input) {
      isSuccess
      message
    }
  }
`;
