<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            ธุรกรรมการเงิน
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            รายการธุรกรรมการเงินที่เกิดขึ้นในระบบ
          </span>
        </h3>

        <div class="card-toolbar">
          <div class="input-group input-group-solid mr-4" style="width: 280px">
            <input
              type="text"
              class="form-control"
              placeholder="ค้นหา"
              @input="debounceInput"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </div>
          </div>
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item" v-if="permissionBalance">
              <a
                class="nav-link py-2 px-4 cursor-pointer"
                data-toggle="tab"
                :class="{ active: this.cat === 1 }"
                @click="pushCat(1)"
                >ธุรกรรมการเงิน</a
              >
            </li>
            <li class="nav-item" v-if="permissionTopup">
              <a
                class="nav-link py-2 px-4 cursor-pointer noti"
                data-toggle="tab"
                :class="{ active: this.cat === 2 }"
                @click="pushCat(2)"
                >เติมเงิน
                <span
                  v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.topup"
                  style="top: -8px"
                  class="badge badge-pill badge-danger"
                >
                  {{ getCurrentNotiAdmin.topup }}
                </span>
              </a>
            </li>
            <li class="nav-item" v-if="permissionBalance">
              <a
                class="nav-link py-2 px-4 cursor-pointer noti"
                data-toggle="tab"
                :class="{ active: this.cat === 3 }"
                @click="pushCat(3)"
                >ถอนเงิน
                <span
                  v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.withdraw"
                  style="top: -8px"
                  class="badge badge-pill badge-danger"
                >
                  {{ getCurrentNotiAdmin.withdraw }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--end::Header-->
      <TransactionList v-if="permissionBalance && cat === 1" :search="search" />
      <TopupList v-if="permissionTopup && cat === 2" :search="search" />
      <WithdrawList v-if="permissionBalance && cat === 3" :search="search" />
    </div>
  </div>
</template>
<script>
import debounce from "debounce";
import TopupList from "./components/TopupList";
import TransactionList from "./components/TransactionList";
import WithdrawList from "./components/WithdrawList";
import { imageUrlApi, WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "WalletList",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "กระเป๋าเงิน" }]);
    this.permissionTopup = this.checkPermission("admin_topup", 1);
    this.permissionBalance = this.checkPermission("admin_balance", 1);

    if (!this.permissionBalance) {
      this.cat = 2;
    }
  },
  created() {
    document.title = `กระเป๋าเงิน | ${WEBSITE}`;
  },
  computed: {
    ...mapGetters(["currentPermission", "getCurrentNotiAdmin"])
  },
  components: {
    TopupList,
    TransactionList,
    WithdrawList
  },
  data() {
    const { cat = 1 } = this.$route.query;
    return {
      page: 1,
      pageSize: 10,
      loading: false,
      status: "",
      search: "",
      cat: Number(cat),
      permissionTopup: false,
      permissionBalance: false
    };
  },
  methods: {
    imageUrlApi,
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    pushCat(val) {
      const newpath = `${this.$route.path}?cat=${val}`;
      if (this.$route.fullPath !== newpath) {
        this.$router.push({ name: "admin_wallet", query: { cat: val } });
      }
    },
    checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }
      const { permissionRole } = this.currentPermission;

      const find = permissionRole.find(
        x => x.permissionInfo && x.permissionInfo.permissionName === name
      );
      if (!find) {
        return false;
      }
      if (type === 0 && find.readableRole) {
        return true;
      }
      if (type === 1 && find.writeableRole) {
        return true;
      }
      return false;
    }
  },
  watch: {
    "$route.query.cat": function(id) {
      this.cat = Number(id) || 1;
    }
  }
};
</script>
