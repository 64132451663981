<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            คำสั่งซื้อของฉัน
          </span>
          <span
            class="text-muted font-weight-bold font-size-sm"
            v-if="seller_order_list && seller_order_list.recordsData"
          >
            Total {{ seller_order_list.recordsData.recordsFiltered }}+ orders
          </span>
        </div>
      </h3>
    </div>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <div
        class="card-toolbar d-flex flex-md-row flex-column justify-content-end px-8"
      >
        <div class="input-group input-group-solid mr-4" style="width: 280px">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหา"
            @input="debounceInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </div>
        </div>
        <div>
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer"
                data-toggle="tab"
                :class="{ active: this.status === '' }"
                @click="
                  status = '';
                  page = 1;
                "
                >ทั้งหมด</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer noti"
                data-toggle="tab"
                :class="{ active: this.status === '0' }"
                @click="
                  status = '0';
                  page = 1;
                "
                >ที่ต้องจัดส่ง
                <span
                  v-if="statusValue['0']"
                  style="top: -8px"
                  class="badge badge-pill badge-danger"
                >
                  {{ statusValue["0"] }}
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer noti"
                data-toggle="tab"
                :class="{ active: this.status === '10' }"
                @click="
                  status = '10';
                  page = 1;
                "
                >การจัดส่ง
                <span
                  v-if="statusValue['10']"
                  style="top: -8px"
                  class="badge badge-pill badge-danger"
                >
                  {{ statusValue["10"] }}
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer noti"
                data-toggle="tab"
                :class="{ active: this.status === '20' }"
                @click="
                  status = '20';
                  page = 1;
                "
              >
                รอจัดส่งในลาว
                <span
                  v-if="statusValue['20']"
                  style="top: -8px"
                  class="badge badge-pill badge-danger"
                >
                  {{ statusValue["20"] }}
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 cursor-pointer"
                data-toggle="tab"
                :class="{ active: this.status === '90' }"
                @click="
                  status = '90';
                  page = 1;
                "
                >สำเร็จแล้ว</a
              >
            </li>
            <button
              @click="refresh"
              class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            >
              <i class="ki ki-refresh"></i>
            </button>
          </ul>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-2 pb-0">
        <!--begin::Table-->
        <div class="table-responsive" v-if="seller_order_list">
          <div class="order-list-pannel mt-4">
            <div
              v-for="order in seller_order_list.records"
              v-bind:key="order._id"
            >
              <div class="order-item">
                <router-link
                  :to="`/seller/sale/order/${order.sellerOrderId}`"
                  class="order-title"
                >
                  <div class="title-prefix">
                    {{ order.user.full_name }}
                  </div>
                  <div class="id-btn">
                    <span class="text-muted mr-2">
                      {{ format_datetime(order.created_at) }} |
                    </span>
                    หมายเลขคำสั่งซื้อ #{{ order.sellerOrderId }}
                  </div>
                </router-link>
                <div class="package-body">
                  <div class="item-product">
                    <div class="order-view-item order-view-item--small">
                      <div class="order-product-list">
                        <div
                          class="order-product-wrapper"
                          v-for="item in order.itemsLess"
                          :key="item._id"
                        >
                          <img :src="item.mainImage" class="item-images" />
                          <div class="ct-item-product">
                            <div class="ct-item-product-inner">
                              <div class="ct-item-product-info">
                                <div class="ct-item-product-name link">
                                  {{ item.productName }}
                                </div>
                              </div>
                              <div class="ct-item-price-qty">
                                <div class="ct-item-product-qty">
                                  <div class="ct-item-product-num">
                                    x{{ item.amount_buy }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div lass="item-total">
                    <div class="price">฿{{ order.total }}</div>
                  </div>
                  <div class="item-status">
                    <div class="status">
                      <div class="container">
                        <span
                          class="label label-lg label-inline"
                          v-bind:class="
                            `label-light-${getStatusColor(order.status)}`
                          "
                        >
                          {{ order.statusShow }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="item-channel">
                    <router-link
                      :to="`/seller/sale/order/${order.sellerOrderId}`"
                    >
                      <button class="btn btn-secondary">ดูรายละเอียด</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="no-data"
              v-if="
                seller_order_list &&
                  seller_order_list.records &&
                  !seller_order_list.records.length
              "
            >
              <i class="la la-file-o mb-2"></i>
              ไม่พบคำสั่งซื้อ
            </div>
          </div>
        </div>
        <!--end::Table-->
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="seller_order_list && seller_order_list.recordsData"
          :total-rows="seller_order_list.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Body-->
    </b-overlay>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";
import { SELLER_ORDER_LIST, SELLER_ORDER_STATUS } from "@/graphql/seller_order";
import dayjs from "dayjs";
import debounce from "debounce";
import bigDecimal from "js-big-decimal";

export default {
  created() {
    document.title = `คำสั่งซื้อของฉัน | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `มาเป็นผู้ขาย`, route: "/seller" },
      { title: `คำสั่งซื้อของฉัน` }
    ]);
  },
  data() {
    return {
      pageSize: 10,
      page: 1,
      search: "",
      status: "",
      statusValue: {}
    };
  },
  methods: {
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 40:
        case 90:
          return "success";
        default:
          return "primary";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    refresh() {
      this.$apollo.queries.seller_order_list.refetch();
      this.$apollo.queries.seller_order_status.refetch();
    }
  },
  watch: {
    page: function() {
      this.$apollo.queries.seller_order_status.refetch();
    },
    status: function() {
      this.$apollo.queries.seller_order_status.refetch();
    }
  },
  apollo: {
    seller_order_list: {
      query: SELLER_ORDER_LIST,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    },
    seller_order_status: {
      query: SELLER_ORDER_STATUS,
      result({ loading, data }) {
        if (!loading && data) {
          const statusValue = {};
          data.seller_order_status.records.map(x => {
            statusValue[x.status] = x.value;
          });
          this.statusValue = statusValue;
        }
      }
    }
  }
};
</script>
