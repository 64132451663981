<template>
  <table class="table table-borderless">
    <tbody>
      <tr v-for="item in list" v-bind:key="item._id">
        <td class="align-middle text-nowrap h3 font-weight-bolder">
          {{ item.logisticsName }}
        </td>
        <td class="align-middle h5">
          <div class="d-flex">
            <div style="width: 120px">ค่าจัดส่ง</div>
            <div>{{ priceLogistics(item) }}</div>
          </div>
          <div class="d-flex mt-2">
            <div style="width: 120px">ค่าแพ็คสินค้า</div>
            <div>฿{{ item.logisticsServicePrice }}</div>
          </div>
        </td>
        <td class="text-center align-middle">
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm"
            @click="choose(item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
          </button>
        </td>
      </tr>
      <tr v-if="!list || !list.length">
        <td colspan="3" class="text-center p-4">ไม่มีข้อมูล</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { getPriceLogistics } from "@/config";
export default {
  props: ["list", "onSelect", "sumWeight"],
  methods: {
    priceLogistics(logistics) {
      const price = getPriceLogistics(logistics, { kg: this.sumWeight });
      return `฿${price}`;
    },
    choose(item) {
      if (this.onSelect) {
        const price = getPriceLogistics(item, { kg: this.sumWeight });
        this.onSelect(item, price);
      }
      this.$nextTick(() => {
        this.$bvModal.hide("logistics-modal");
      });
    },
    checkPush(arr, val) {
      if (val) {
        arr.push(val);
      }
    }
  }
};
</script>
