<template>
  <div>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <div
        class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-9 mt-n9"
        v-if="systemRoleList"
      >
        <!--begin::Col-->
        <div
          class="col-xl-4 col-sm-6 col-12"
          v-for="item in systemRoleList.records"
          :key="item._id"
        >
          <!--begin::Card-->
          <div class="card card-flush h-md-100">
            <!--begin::Card header-->
            <div class="card-header bs5">
              <!--begin::Card title-->
              <div class="card-title">
                <h2>{{ item.role_name }}</h2>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-1">
              <!--begin::Users-->
              <div class="fw-bolder text-gray-600 mb-5">
                Total users with this role: {{ item.total_role_used }}
              </div>
              <!--end::Users-->
              <!--begin::Permissions-->
              <div class="d-flex flex-column text-gray-600">
                <div
                  class="d-flex align-items-center py-2"
                  v-for="row in item.permissionRole"
                  :key="row._id"
                >
                  <span class="bullet bg-primary mr-3"></span>
                  {{ drawAccess(row) }}
                </div>
                <div
                  v-if="item.moreNumber"
                  class="d-flex align-items-center py-2"
                >
                  <span class="bullet bg-primary mr-3"></span>
                  <em>and {{ item.moreNumber }} more...</em>
                </div>
              </div>
              <!--end::Permissions-->
            </div>
            <!--end::Card body-->
            <!--begin::Card footer-->
            <div class="card-footer flex-wrap pt-0">
              <button class="btn btn-light btn-active-primary my-1 mr-2">
                View Role
              </button>
              <button
                type="button"
                class="btn btn-white btn-active-light-primary my-1"
                v-b-modal.role-modal
                @click="setEdit(item._id)"
              >
                Edit Role
              </button>
            </div>
            <!--end::Card footer-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Col-->
        <!--begin::Add new card-->
        <div class="col-xl-4 col-sm-6 col-12">
          <!--begin::Card-->
          <div class="card h-md-100">
            <!--begin::Card body-->
            <div class="card-body d-flex flex-center">
              <!--begin::Button-->
              <button
                type="button"
                class="btn btn-clear d-flex flex-column flex-center"
                v-b-modal.role-modal
              >
                <!--begin::Illustration-->
                <img
                  src="media/illustrations/user-role.png"
                  alt=""
                  class="mw-100 mh-150px mb-7"
                  @click="editId = null"
                />
                <!--end::Illustration-->
                <!--begin::Label-->
                <div class="fw-bolder fs-3 text-gray-600 text-hover-primary">
                  Add New Role
                </div>
                <!--end::Label-->
              </button>
              <!--begin::Button-->
            </div>
            <!--begin::Card body-->
          </div>
          <!--begin::Card-->
        </div>
        <!--begin::Add new card-->
      </div>
    </b-overlay>
    <role-form :refetch="onRefetch" :_id="editId" :onClose="onClose" />
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ROLES } from "@/graphql/admin/role.js";
import { WEBSITE } from "@/config";
import RoleForm from "./components/RoleForm.vue";

export default {
  components: { RoleForm },
  created() {
    document.title = `สิทธิ์การใช้งาน | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `สิทธิ์การใช้งาน` }
    ]);
  },
  data() {
    return {
      editId: null,
      page: 1,
      pageSize: 10,
      status: "",
      search: ""
    };
  },
  methods: {
    onClose() {
      this.editId = null;
    },
    setEdit(id) {
      this.editId = id;
    },
    drawAccess(permission) {
      if (permission.readableRole && permission.writeableRole) {
        return `ดูและแก้ไข ${permission.permissionInfo.permissionTitle}`;
      }
      if (permission.readableRole) {
        return `ดูอย่างเดียว ${permission.permissionInfo.permissionTitle}`;
      }
      if (permission.writeableRole) {
        return `ดูและแก้ไข ${permission.permissionInfo.permissionTitle}`;
      }
      return permission.permissionInfo.permissionTitle;
    },
    onRefetch() {
      this.$apollo.queries.systemRoleList.refetch();
    }
  },
  apollo: {
    systemRoleList: {
      query: GET_ROLES,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    }
  }
};
</script>
