<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card card-custom card-stretch" v-if="inventory_list">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title flex-column">
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              ประวัติสต็อกสินค้า
              {{ inventory_list.recordsData.recordsFiltered }} รายการ
            </span>
          </div>
        </h3>
      </div>
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <div class="input-group input-group-solid mr-4" style="width: 280px">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหา"
            @input="debounceInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </div>
        </div>
        <button
          @click="$apollo.queries.inventory_list.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
      </div>
      <div class="card-body pt-2 pb-0">
        <div class="table-responsive">
          <table class="table table-head-bg table-vertical-center">
            <thead>
              <tr>
                <th class="pl-4 text-nowrap">รหัสสินค้า</th>
                <th class="pl-4 text-nowrap">ชื่อสินค้า</th>
                <th class="text-nowrap text-center">จำนวนที่เพิ่ม</th>
                <th class="text-nowrap text-center">วันที่ของเข้า</th>
                <th class="pl-4 text-nowrap">หมายเหตุ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in inventory_list.records" v-bind:key="i">
                <td class="pl-4">
                  <router-link
                    :to="`/seller/product/${item.productId}`"
                    class="d-flex align-items-center"
                  >
                    {{ item.productId }}
                  </router-link>
                </td>
                <td class="pl-4">
                  <router-link
                    :to="`/seller/product/${item.productId}`"
                    style="max-width: 200px"
                    class="text-truncate d-block"
                  >
                    {{ item.product.productName }}
                  </router-link>
                </td>
                <td class="px-2 text-center">
                  {{ item.amount }}
                </td>
                <td class="px-2 text-center">
                  {{ format_date(item.created_at) }}
                </td>
                <td class="px-2">
                  {{ item.description }}
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="
                  inventory_list &&
                    inventory_list.records &&
                    !inventory_list.records.length
                "
              >
                <td colspan="6" class="pt-8">ไม่พบข้อมูลสินค้า</td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="inventory_list && inventory_list.recordsData"
          :total-rows="inventory_list.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import { GET_INVENTORY_LIST } from "@/graphql/seller_inventory";
import debounce from "debounce";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi } from "@/config";

export default {
  created() {
    document.title = `ประวัติสล็อกสินค้า | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `มาเป็นผู้ขาย`, route: "/seller" },
      { title: `ประวัติสล็อกสินค้า` }
    ]);
  },
  data() {
    return {
      pageSize: 10,
      page: 1,
      search: ""
    };
  },
  methods: {
    imageUrlApi,
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  },
  apollo: {
    inventory_list: {
      query: GET_INVENTORY_LIST,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
