import gql from "graphql-tag";

export const DELETE_USER = gql`
  mutation($id: ID!) {
    deleteUser(_id: $id) {
      isSuccess
      message
    }
  }
`;

export const APPROVE_USER = gql`
  mutation($id: Int!, $input: UserInput) {
    approveUser(id: $id, input: $input) {
      isSuccess
      message
    }
  }
`;

export const REJECT_USER = gql`
  mutation($id: Int!) {
    rejectUser(id: $id) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($input: UserInput) {
    updateUser(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation($input: UserInput) {
    createUser(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_USER = gql`
  query($id: Int!) {
    user(id: $id) {
      userId
      userNo
      full_name
      photo_url
      username
      password
      line
      email
      phone
      is_admin
      userLevelId
      roleId
      balance
      address {
        _id
        name
        phone
        province
        amphoe
        district
        postcode
        address
        default
      }
      verify_file
      status
      statusShow
      created_at
    }
  }
`;

export const GET_USERS = gql`
  query($pageSize: Int, $page: Int, $search: String, $status: Int) {
    users(pageSize: $pageSize, page: $page, search: $search, status: $status) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        userId
        userNo
        full_name
        photo_url
        username
        email
        phone
        is_admin
        created_at
        status
        statusShow
        updated_id_info {
          userId
          username
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query($pageSize: Int, $page: Int, $search: String) {
    searchUserList(pageSize: $pageSize, page: $page, search: $search) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        userId
        username
      }
    }
  }
`;

export const GET_USER_ADDRESS = gql`
  query {
    user {
      address {
        _id
        name
        phone
        province
        amphoe
        district
        postcode
        address
        default
        default_sender
      }
    }
  }
`;

export const CREATE_USER_ADDRESS = gql`
  mutation($input: AddressInput) {
    createUserAddress(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_USER_ADDRESS = gql`
  mutation($input: AddressInput) {
    updateUserAddress(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_USER_ADDRESS = gql`
  mutation($userId: Int, $_id: ID) {
    deleteUserAddress(userId: $userId, _id: $_id) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;
