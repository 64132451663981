import gql from "graphql-tag";

export const CREATE_BANK = gql`
  mutation($input: BankInput) {
    createSystemBank(input: $input) {
      isSuccess
      is_error
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_BANK = gql`
  mutation($input: BankInput) {
    updateSystemBank(input: $input) {
      isSuccess
      is_error
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_BANK = gql`
  mutation($_id: ID) {
    deleteSystemBank(_id: $_id) {
      isSuccess
      is_error
      message
    }
  }
`;

export const GET_BANKS = gql`
  query {
    systemBankList {
      records {
        _id
        bank_id
        account_no
        bank_name
        account_name
        branch_name
        bank_img
        bank_qr
        is_show
        created_at
      }
    }
  }
`;

export const GET_BANK = gql`
  query($id: ID) {
    systemBank(_id: $id) {
      _id
      bank_id
      account_no
      bank_name
      account_name
      branch_name
      bank_img
      bank_qr
      bank_type
    }
  }
`;
