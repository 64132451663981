import gql from "graphql-tag";

export const CREATE_PRODUCT = gql`
  mutation($input: ProductInput) {
    createSellerProduct(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation($input: ProductInput) {
    updateSellerProduct(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_PRODUCT_PUBLISHED = gql`
  mutation($_id: ID, $is_published: Boolean) {
    updateSellerProductPublished(_id: $_id, is_published: $is_published) {
      isSuccess
      message
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation($_id: ID) {
    deleteSellerProduct(_id: $_id) {
      isSuccess
      message
    }
  }
`;

export const GET_PRODUCT_LIST = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    product_list(page: $page, pageSize: $pageSize, search: $search) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        productId
        productName
        category {
          originalName
        }
        priceShow
        mainImage
        stock
        is_published
        created_at
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query($id: String) {
    product(id: $id) {
      isSuccess
      message
      data {
        _id
        productId
        productName
        stock
        price
        shipping_price
        properties {
          id
          title
          list {
            id
            title
          }
        }
        type
        desc
        skus {
          skuId
          skuName
          price
          stock
        }
        categoryId
        category {
          categoryId
          originalName
        }
        priceShow
        mainImage
        imageList {
          path
          default
          highlight
        }
        size {
          weight
          width
          height
          long
        }
        is_published
        created_at
      }
    }
  }
`;
