<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="paymentList">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            โอนเงินไปจีน
          </span>
          <span
            class="text-muted font-weight-bold font-size-sm"
            v-if="paymentList.recordsData"
          >
            Total {{ paymentList.recordsData.recordsFiltered }}+ payments
          </span>
        </div>
      </h3>
    </div>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <div class="input-group input-group-solid mr-4" style="width: 280px">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหา"
            @input="debounceInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </div>
        </div>
        <b-dropdown :text="getStatusText()">
          <b-dropdown-item
            value=""
            @click="
              status = '';
              page = 1;
            "
          >
            ทั้งหมด
          </b-dropdown-item>
          <b-dropdown-item
            value="-1"
            @click="
              status = '-1';
              page = 1;
            "
          >
            ยกเลิก
          </b-dropdown-item>
          <b-dropdown-item
            value="0"
            @click="
              status = '0';
              page = 1;
            "
          >
            รอผู้ดูแลตรวจสอบ
          </b-dropdown-item>
          <b-dropdown-item
            value="1"
            @click="
              status = '1';
              page = 1;
            "
          >
            สำเร็จ
          </b-dropdown-item>
        </b-dropdown>
        <button
          @click="$apollo.queries.paymentList.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon ml-4"
        >
          <i class="ki ki-refresh"></i>
        </button>
        <router-link
          to="/payments/create"
          class="btn btn-success font-weight-bolder font-size-sm ml-4"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
          </span>
          สร้างรายการโอนเงินไปจีน
        </router-link>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-8 pb-0">
        <!--begin::Table-->
        <div class="table-responsive" v-if="paymentList">
          <table
            class="table table-head-custom table-head-bg table-borderless table-vertical-center"
          >
            <thead>
              <tr>
                <th>
                  <span class="text-dark-75 text-nowrap">เลขที่</span>
                </th>
                <th class="text-nowrap">
                  <span class="text-dark-75">วันที่สร้าง</span>
                </th>
                <th>
                  <span class="text-dark-75">รูป</span>
                </th>
                <th class="text-nowrap">
                  <span class="text-dark-75">รายละเอียดธนาคาร</span>
                </th>
                <th class="text-right">
                  <span class="text-dark-75">เรท</span>
                </th>
                <th class="text-right text-nowrap">
                  <span class="text-dark-75">ยอดโอน</span>
                </th>
                <th class="text-right text-nowrap">
                  <span class="text-dark-75">ยอดเงิน</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">สถานะ</span>
                </th>
                <th style="width: 50px">
                  <span class="text-dark-75">จัดการ</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in paymentList.records" v-bind:key="item._id">
                <td>
                  <router-link :to="`/payments/${item.paymentId}`">
                    {{ item.paymentDoc }}
                  </router-link>
                </td>
                <td>
                  {{ format_datetime(item.created_at) }}
                </td>
                <td>
                  <div class="symbol symbol-40 flex-shrink-0 mr-4">
                    <img
                      v-if="item.file_attachment"
                      :src="imageUrlApi(item.file_attachment)"
                      style="object-fit: cover"
                      class="symbol-label"
                      v-viewer
                    />
                  </div>
                </td>
                <td>
                  {{ item.bank_name }}
                </td>
                <td class="text-right">
                  {{ item.rate }}
                </td>
                <td class="text-right text-nowrap">
                  <span class="text-dark-75 font-weight-bolder font-size-lg">
                    {{ format_number(item.amount, 2, true, true) }}
                  </span>
                </td>
                <td class="text-right text-nowrap">
                  <span class="text-dark-75 font-weight-bolder font-size-lg">
                    {{ format_number(item.baht, 2, true) }}
                  </span>
                </td>
                <td class="text-center text-nowrap">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-${getStatusColor(item.status)}`"
                  >
                    {{ item.statusShow }}
                  </span>
                </td>
                <td class="text-right pr-0">
                  <router-link
                    :to="`/payments/${item.paymentId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="
                  paymentList &&
                    paymentList.records &&
                    !paymentList.records.length
                "
              >
                <td colspan="9" class="pt-8">ไม่พบรายการ</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="paymentList && paymentList.recordsData"
          :total-rows="paymentList.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Body-->
    </b-overlay>
  </div>
</template>

<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { imageUrlApi, WEBSITE } from "@/config";
import { GET_TRANSFERS } from "@/graphql/payment";
import Swal from "sweetalert2";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "โอนเงินไปจีน" }]);
  },
  created() {
    document.title = `โอนเงินไปจีน | ${WEBSITE}`;
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      orderList: {},
      loading: false,
      status: "",
      search: ""
    };
  },
  methods: {
    imageUrlApi,
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        default:
          return "primary";
      }
    },
    getStatusText() {
      switch (this.status) {
        case "":
          return "ทั้งหมด";
        case "0":
          return "รอผู้ดูแลตรวจสอบ";
        case "1":
          return "สำเร็จ";
        case "-1":
          return "ยกเลิกแล้ว";
        default:
          return "";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_number(val, decimal = 0, format = false, yuan = false) {
      const currency = format ? (yuan ? "¥ " : "฿ ") : "";
      if (format) {
        if (val >= 0)
          return `${currency}${Number(
            bigDecimal.round(val, decimal)
          ).toLocaleString(undefined, {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal
          })}`;
        return `-${currency}${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    paymentList: {
      query: GET_TRANSFERS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      },
      result: ({ data, loading }) => {
        if (loading || !data) {
          return;
        }
        const { paymentList } = data;
        const { isSuccess, message } = paymentList;
        if (!isSuccess) {
          Swal.fire({
            title: "",
            text: message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        }
      }
    }
  }
};
</script>
