<template>
  <div class="order-seller">
    <div class="card-style web-card" v-if="data">
      <div
        class="col-12 manage-header"
        v-if="canCancel || canConfirmCancel || data.vendors.cancel_remark"
      >
        <div class="c-body text-right">
          <button
            v-if="canCancel"
            class="btn btn-danger btn-lg"
            @click="cancelOrder(data)"
          >
            ยกเลิกใบสั่งซื้อ
          </button>
          <button
            v-if="canConfirmCancel"
            class="btn btn-danger btn-lg"
            @click="confirmCancelOrder(data)"
          >
            ยืนยันยกเลิกใบสั่งซื้อ
          </button>
          <div
            class="text-danger mt-2"
            v-if="data.vendors.cancel_request && data.vendors.cancel_remark"
          >
            ลูกค้าขอยกเลิกเนื่องจาก {{ data.vendors.cancel_remark }}
          </div>
          <div
            class="text-danger mt-2"
            v-if="!data.vendors.cancel_request && data.vendors.cancel_remark"
          >
            ยกเลิกเนื่องจาก {{ data.vendors.cancel_remark }}
          </div>
        </div>
      </div>
      <div style="margin: 0" class="col-8">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-hashtag" /></div>
          <div class="name">หมายเลขคำสั่งซื้อ</div>
        </div>
        <div class="c-body">
          <div>#{{ data.sellerOrderId }}</div>
        </div>
      </div>
      <div class="col-8 mt-6">
        <div class="c-header">
          <div class="icon text-primary">
            <span class="la" :class="getStatusIcon(data.status)" />
          </div>
          <div class="name">สถานะ</div>
        </div>
        <div class="c-body">
          <span
            class="label label-lg label-inline"
            v-bind:class="`label-light-${getStatusColor(data.status)}`"
          >
            {{ data.statusShow }}
          </span>
        </div>
      </div>
      <div class="col-8 mt-6">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-map-marker" /></div>
          <div class="name">ที่อยู่ในการจัดส่ง</div>
        </div>
        <div class="c-body">
          <div>
            <div v-if="data.address">
              {{ data.address.name }}, {{ data.address.phone }}
            </div>
            <div v-if="data.address">
              {{ renderAddress(data.address) }}
            </div>
          </div>
        </div>
      </div>
      <div class="c-header mt-6">
        <div class="icon text-primary">
          <span class="la la-shipping-fast" />
        </div>
        <div class="name">ขนส่งโดย</div>
      </div>
      <div class="c-body">
        <div v-if="data.logistics">
          {{ data.logistics.logisticsName }}
        </div>
        <div v-if="!data.logistics">-</div>
      </div>
      <div class="col-8 mt-6">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-truck" /></div>
          <div class="name">ข้อมูลการขนส่ง</div>
        </div>
        <div class="c-body">
          <div>
            <div
              class="row-transport d-flex justify-content-between align-items-center"
              v-for="item in data.vendors.transports"
              :key="item._id"
            >
              <div class="flex-fill">
                <div class="w-50">
                  <span class="font-weight-bolder">รหัสพัสดุ</span>
                  {{ item.transportNumber }}
                </div>
                <div
                  class="mt-2 history"
                  v-if="item.timeline_list && item.timeline_list.length"
                >
                  <div
                    class="lg-log"
                    :class="i === 0 ? 'first' : ''"
                    v-for="(line, i) in item.timeline_list"
                    :key="line.name"
                  >
                    <div class="legend">
                      <div class="icon"></div>
                      <div class="line"></div>
                    </div>
                    <div class="status-log">
                      <div class="status">
                        {{ line.name }}
                      </div>
                      <div class="time">
                        <span>{{ format_datetime(line.date) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-icon btn-link btn-sm"
                type="button"
                v-if="data.status != 90"
                @click="removeTracking(item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-danger">
                  <inline-svg src="/media/svg/icons/General/Trash.svg" />
                </span>
              </button>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-block btn-dashed mt-4"
            v-b-modal.seller-tracking-modal
            v-if="data.status != 90"
          >
            <i class="menu-icon la la-plus icon-xl"></i> เพิ่มรหัสพัสดุ
          </button>
          <div class="col-lg-6 col-md-6 col-sm-6 col-8 mt-4">
            <b-overlay :show="loadingImage">
              <div class="uppy" id="kt_uppy_5">
                <div class="uppy-wrapper" v-if="data.status != 90">
                  <div class="uppy-Root uppy-FileInput-container">
                    <input
                      class="uppy-FileInput-input uppy-input-control"
                      ref="fileInput"
                      type="file"
                      accept="image/*"
                      name="files[]"
                      multiple=""
                      @input="pickFile"
                      id="file"
                      style=""
                    />
                    <label
                      class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                      for="file"
                    >
                      แนบรูปฝากส่งสินค้า Dropship
                    </label>
                  </div>
                </div>
                <div class="uppy-list">
                  <div class="uppy-list-item" v-if="filenaame || previewImage">
                    <div
                      v-viewer
                      class="border symbol symbol-60 flex-shrink-0 mr-4"
                    >
                      <img
                        style="object-fit: contain"
                        :src="previewImage || imageUrlApi(filenaame)"
                        height="200"
                        width="200"
                      />
                    </div>
                    <div class="uppy-list-label">
                      {{ file && file.name }}
                    </div>
                    <span
                      class="uppy-list-remove"
                      v-if="filenaame"
                      @click="removeFile()"
                    >
                      <i class="flaticon2-cancel-music"></i>
                    </span>
                  </div>
                </div>
              </div>
              <span class="form-text text-muted" v-if="data.status != 90">
                อัพโหลดไฟล์ไม่เกิน 10MB.
              </span>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
    <div class="card-style web-card mt-6" v-if="data">
      <div style="margin: 0" class="col-12">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-money-bill" /></div>
          <div class="name">รายละเอียดการชำระเงิน</div>
        </div>
        <div class="product-list mt-4">
          <table>
            <thead>
              <tr class="product-list-head">
                <th class="no">No.</th>
                <th>สินค้าทั้งหมด</th>
                <th class="price">ราคาต่อชิ้น</th>
                <th class="qty">จำนวน</th>
                <th class="price">ราคาขายสุทธิ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.vendors.items" :key="item._id">
                <td class="no">{{ index + 1 }}</td>
                <td>
                  <div class="product-item product">
                    <div
                      class="product-image"
                      :style="`background-image: url('${item.mainImage}');`"
                    ></div>
                    <div class="product-detail">
                      <div
                        title="หนังสือมังงะภาษาจีน อยู่ๆ ฉันก็กลายเป็นเจ้าหญิง"
                        class="product-name"
                      >
                        {{ item.productName }}
                      </div>
                      <div class="product-meta">
                        <div v-if="item.properties.length">
                          ตัวเลือกสินค้า :
                          <div
                            v-for="(prop, index) in item.properties"
                            :key="prop.id"
                            v-frag
                          >
                            <span v-if="!prop.image">
                              {{ prop.title }}
                              <div
                                v-frag
                                v-if="index < item.properties.length - 1"
                              >
                                ,
                              </div>
                            </span>
                            <img :src="prop.image" v-if="prop.image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="price">{{ format_number(item.price, 2) }}</td>
                <td class="qty">{{ item.amount_buy }}</td>
                <td class="price">
                  {{ format_number(item.amount_buy * item.price, 2) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-4" v-if="data.vendors.remark">
            หมายเหตุ : {{ data.vendors.remark }}
          </div>
        </div>
        <div class="income-container">
          <div class="income-label-column">
            <div class="income-item">
              <div class="income-label">รวมค่าสินค้า</div>
            </div>
            <div class="income-item">
              <div class="income-label">ค่าจัดส่งทั้งหมด</div>
            </div>
            <div class="income-item">
              <div class="income-label">ค่าธรรมเนียมและค่าบริการ</div>
            </div>
            <div class="income-item total">
              <div class="income-label">ยอดชำระเงิน</div>
            </div>
          </div>
          <div class="income-value-column">
            <div class="income-item">
              <div class="income-value">
                {{ format_number(data.vendors.total_product, 2, true) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                {{ format_number(data.vendors.shipping_price, 2, true) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                {{ format_number(data.vendors.fee, 2, true) }}
              </div>
            </div>
            <div class="income-item total">
              <div class="income-value big-total">
                ฿{{ format_number(data.vendors.total, 2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-style web-card mt-6" v-if="data">
      <div style="margin: 0" class="col-12">
        <div class="c-header">
          <div class="icon text-primary"><span class="la la-receipt" /></div>
          <div class="name">การชำระเงินของผู้ซื้อ</div>
        </div>
        <div class="income-container">
          <div class="income-label-column">
            <div class="income-item">
              <div class="income-label">รวมค่าสินค้า</div>
            </div>
            <div class="income-item">
              <div class="income-label">ค่าจัดส่ง</div>
            </div>
            <div class="income-item">
              <div class="income-label">ค่าขนส่งระหว่างประเทศ</div>
            </div>
            <div class="income-item total">
              <div class="income-label">การชำระเงินทั้งหมดของผู้ซื้อ</div>
            </div>
          </div>
          <div class="income-value-column">
            <div class="income-item">
              <div class="income-value">
                ฿{{ format_number(data.vendors.total_product, 2) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                ฿{{ format_number(data.vendors.shipping_price, 2) }}
              </div>
            </div>
            <div class="income-item">
              <div class="income-value">
                ฿{{ format_number(data.vendors.forwarder_price, 2) }}
              </div>
            </div>
            <div class="income-item total">
              <div class="income-value big-total">
                ฿{{ format_number(data.vendors.total_pay, 2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-seller-tracking
      v-if="data"
      :orderId="data._id"
      :refetch="refetchTracking"
    />
  </div>
</template>
<script>
import bigDecimal from "js-big-decimal";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import axios from "axios";
import ModalSellerTracking from "@/components/ModalSellerTracking.vue";
import { API_URL, IMAGE_URL, imageUrlApi } from "@/config";
import { WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  SELLER_ORDER,
  SELLER_ORDER_CANCEL,
  CONFIRM_SELLER_ORDER_CANCEL,
  SELLER_ORDER_TRACKING_DELETE,
  UPLOAD_SELLER_ORDER
} from "@/graphql/seller_order";

export default {
  components: { ModalSellerTracking },
  created() {
    document.title = `หมายเลขคำสั่งซื้อ | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `คำสั่งซื้อของฉัน`, route: "/seller/sale/order" },
      { title: `หมายเลขคำสั่งซื้อ` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      data: null,
      canCancel: false,
      canConfirmCancel: false,
      file: null,
      filenaame: "",
      previewImage: null,
      loadingImage: false
    };
  },
  methods: {
    imageUrlApi,
    refetchTracking() {
      this.$apollo.queries.seller_order.refetch();
      this.$nextTick(() => {
        this.$bvModal.hide("seller-tracking-modal");
      });
    },
    renderAddress(address) {
      const isBankok = address.province === "กรุงเทพมหานคร";
      const district =
        address.district && `${isBankok ? "แขวง" : "ตำบล"}${address.district}`;
      const amphoe =
        address.amphoe && `${isBankok ? "เขต" : "อำเภอ"}${address.amphoe}`;
      const province = address.province;
      const postcode = address.postcode && `${address.postcode}`;
      const address1 = address.address;
      const arr = [];
      this.checkPush(arr, address1);
      this.checkPush(arr, district);
      this.checkPush(arr, amphoe);
      this.checkPush(arr, province);
      this.checkPush(arr, postcode);
      return arr.join(" ");
    },
    checkPush(arr, val) {
      if (val) {
        arr.push(val);
      }
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    },
    async removeTracking(item) {
      const confirm = await Swal.fire({
        title: "นำพัสดุออกจากคำสั่งซื้อ ?",
        icon: "question",
        html: `นำพัสดุ ${item.transportNumber} ออกจากคำสั่งซื้อ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { seller_order_tracking_delete }
      } = await this.$apollo.mutate({
        mutation: SELLER_ORDER_TRACKING_DELETE,
        variables: { _id: item._id, orderId: this.data._id }
      });
      const { isSuccess, message } = seller_order_tracking_delete;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.seller_order.refetch();
      }
    },
    async cancelOrder(item) {
      const confirm = await Swal.fire({
        title: "ต้องการยกเลิกคำสั่งซื้อ ?",
        icon: "question",
        html: `ยกเลิกคำสั่งซื้อ #${item.sellerOrderId} หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        input: "textarea",
        inputLabel: "Message",
        inputPlaceholder: "เหตุผลในการยกเลิก",
        inputAttributes: {
          "aria-label": "Type your message here"
        }
      });
      if (!confirm.isConfirmed) {
        return;
      }
      const remark = confirm.value;
      const {
        data: { seller_order_cencel }
      } = await this.$apollo.mutate({
        mutation: SELLER_ORDER_CANCEL,
        variables: { _id: this.data._id, remark: remark }
      });
      const { isSuccess, message } = seller_order_cencel;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.seller_order.refetch();
      }
    },
    async confirmCancelOrder(item) {
      const confirm = await Swal.fire({
        title: "ยืนยันยกเลิกคำสั่งซื้อ ?",
        icon: "question",
        html: `ยืนยันยกเลิกคำสั่งซื้อ #${item.sellerOrderId} หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.isConfirmed) {
        return;
      }
      const {
        data: { confirm_seller_order_cencel }
      } = await this.$apollo.mutate({
        mutation: CONFIRM_SELLER_ORDER_CANCEL,
        variables: { _id: this.data._id }
      });
      const { isSuccess, message } = confirm_seller_order_cencel;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.seller_order.refetch();
      }
    },
    upload(file, onUploadProgress) {
      let formData = new FormData();

      formData.append("file", file);

      return axios.post(`${API_URL}/upload/temp2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        this.loadingImage = true;
        this.upload(file[0], event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(async res => {
          const { data } = res;
          if (data) {
            this.previewImage = `${IMAGE_URL}${data.data}`;
            this.filenaame = data.data;
            const {
              data: { upload_seller_order }
            } = await this.$apollo.mutate({
              mutation: UPLOAD_SELLER_ORDER,
              variables: { _id: this.data._id, file: this.filenaame }
            });
            const { isSuccess, message } = upload_seller_order;
            this.loadingImage = false;
            if (!isSuccess) {
              this.$message.error(message);
            } else {
              this.$message.success(message);
              this.$apollo.queries.seller_order.refetch();
            }
          }
        });
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    async removeFile() {
      const confirm = await Swal.fire({
        title: "ต้องการลบรูป ?",
        icon: "question",
        html: `ลบรูปฝากส่งสินค้า หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { upload_seller_order }
      } = await this.$apollo.mutate({
        mutation: UPLOAD_SELLER_ORDER,
        variables: { _id: this.data._id, file: "" }
      });
      const { isSuccess, message } = upload_seller_order;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.seller_order.refetch();
      }
      this.filenaame = null;
      this.file = null;
      this.previewImage = null;
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    getStatusIcon(status) {
      switch (status) {
        case -10:
          return "la-close";
        case -2:
          return "la-clock-o";
        case 0:
          return "la-box";
        case 10:
          return "la-truck";
        case 20:
          return "la-truck";
        case 90:
          return "la-check-circle-o";
        default:
          return "primary";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 40:
        case 90:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    seller_order: {
      query: SELLER_ORDER,
      variables() {
        return {
          id: this.id
        };
      },
      result({ loading, data }) {
        if (!loading && data) {
          const { isSuccess, message } = data.seller_order;
          if (!isSuccess) {
            this.$message.error(message);
          } else {
            this.data = data.seller_order.data;
            this.canCancel = data.seller_order.canCancel;
            this.canConfirmCancel = data.seller_order.canConfirmCancel;
            this.filenaame = this.data.vendors.file_shipping;
          }
        }
      }
    }
  }
};
</script>
