<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="systemForwarderList">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            ค่านำเข้า
          </span>
        </div>
      </h3>
    </div>
    <div
      class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
    >
      <div class="input-group input-group-solid mr-4" style="width: 280px">
        <input
          type="text"
          class="form-control"
          placeholder="ค้นหา"
          @input="debounceInput"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Search.svg" />
            </span>
          </span>
        </div>
      </div>
      <router-link
        :to="`${$route.path}/new`"
        class="btn btn-success font-weight-bolder font-size-sm mr-4"
      >
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
        </span>
        เพิ่มค่านำเข้า
      </router-link>
      <button
        @click="$apollo.queries.systemForwarderList.refetch()"
        class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      >
        <i class="ki ki-refresh"></i>
      </button>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0" v-if="systemForwarderList">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-sm table-head-bg table-vertical-center">
          <thead>
            <tr>
              <th class="pl-4">ชื่อประเภทสินค้า</th>
              <th>ขนส่ง</th>
              <th class="px-2 text-center" width="100">ค่าเริ่มต้น</th>
              <th style="min-width: 60px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in systemForwarderList.records">
              <tr v-bind:key="item._id">
                <td class="pl-4">
                  <router-link
                    :to="`/admin/seller/forwarder/${item.forwarderId}`"
                  >
                    <div class="d-flex">
                      <div class="d-flex">
                        <span class="my-auto">
                          {{ item.forwarderName }}
                        </span>
                      </div>
                    </div>
                  </router-link>
                </td>
                <td>
                  {{ drawShipBy(item.ship_by_list) }}
                </td>
                <td class="px-2 text-center">
                  <span class="switch switch-sm mt-1 justify-content-center">
                    <label class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        :checked="item.is_default"
                        v-model="item.is_default"
                        @change="toggleDefault(item._id, item.is_default)"
                      />
                      <span></span>
                    </label>
                  </span>
                </td>
                <td class="text-right pr-4">
                  <router-link
                    :to="`/admin/seller/forwarder/${item.forwarderId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                </td>
              </tr>
            </template>
            <tr
              class="text-center"
              v-if="
                systemForwarderList &&
                  systemForwarderList.records &&
                  !systemForwarderList.records.length
              "
            >
              <td colspan="4" class="pt-8">ไม่พบข้อมูล</td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="systemForwarderList && systemForwarderList.recordsData"
          :total-rows="systemForwarderList.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import dayjs from "dayjs";
import debounce from "debounce";
import {
  GET_FORWARDER_LIST,
  UPDATE_FORWARDER
} from "@/graphql/admin/seller_forwarder";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi } from "@/config";

export default {
  created() {
    document.title = `ค่านำเข้า | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ขายสินค้า`, route: "/admin/seller" },
      { title: `ค่านำเข้า` }
    ]);
  },
  data() {
    return {
      page: 1,
      mapShip: { ek: "รถ", ship: "เรือ" },
      pageSize: 20,
      search: ""
    };
  },
  methods: {
    imageUrlApi,
    drawShipBy(ship_by) {
      const text = ship_by.map(x => this.mapShip[x]);
      return text.join(", ");
    },
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    async toggleDefault(_id, is_default) {
      const {
        data: { updateForwarder }
      } = await this.$apollo.mutate({
        mutation: UPDATE_FORWARDER,
        variables: {
          input: {
            _id,
            is_default
          }
        }
      });
      if (!updateForwarder.isSuccess) {
        this.$message.error(updateForwarder.message);
      } else {
        this.$apollo.queries.systemForwarderList.refetch();
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  },
  apollo: {
    systemForwarderList: {
      query: GET_FORWARDER_LIST,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
