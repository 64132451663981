<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" @submit.prevent="save">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">เพิ่มประเภทสินค้า</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group row mb-2">
                <label
                  for="input-originalName"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ชื่อประเภทสินค้า</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-originalName"
                    v-model="category.originalName"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-sequenceNumber"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ลำดับ</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-sequenceNumber"
                    v-model="category.sequenceNumber"
                    :state="nameState"
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-forwarder_price_kg"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ราคากิโล</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-forwarder_price_kg"
                    v-model="category.forwarder_price_kg"
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-forwarder_price_cube"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ราคาคิว.</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-forwarder_price_cube"
                    v-model="category.forwarder_price_cube"
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-fee_value"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ค่าธรรมเนียม</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-fee_value"
                    v-model="category.fee_value"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-originalName"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ประเภทค่าธรรมเนียม</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-select
                    v-model="category.fee_type"
                    :state="validateState('fee_type')"
                    required
                    :options="feeTypeList"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-sequenceNumber"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >รายละเอียด
                </label>
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-textarea
                    id="input-sequenceNumber"
                    v-model="category.description"
                    :state="nameState"
                    placeholder=""
                  ></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body">
        <div class="row">
          <div class="col-lg-10 ml-lg-auto">
            <button
              type="submit"
              class="btn btn-success mr-4"
              style="min-width: 140px"
            >
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/General/Save.svg" />
              </span>
              บันทึกประเภทสินค้า
            </button>
            <router-link
              to="/admin/seller/category"
              class="btn btn-secondary"
              style="width: 140px"
            >
              ยกเลิก
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_CATEGORY } from "@/graphql/admin/seller_category";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `เพิ่มขนส่งภายในประเทศ | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ประเภทสินค้า`, route: "/admin/seller/category" },
      { title: `เพิ่มประเภทสินค้า` }
    ]);
  },
  data() {
    return {
      nameState: null,
      messageError: "",
      feeTypeList: [
        { text: "%", value: 0 },
        { text: "฿", value: 1 }
      ],
      category: {
        originalName: "",
        description: "",
        sequenceNumber: 0,
        forwarder_price_kg: 0,
        forwarder_price_cube: 0,
        fee_value: 0,
        fee_type: 0
      }
    };
  },
  validations: {
    category: {
      originalName: {
        required
      },
      description: {},
      fee_value: { required },
      fee_type: { required },
      forwarder_price_kg: { required },
      forwarder_price_cube: { required },
      sequenceNumber: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.category[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.category.$touch();
      if (this.$v.category.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const originalName = this.$v.category.originalName.$model;
      const description = this.$v.category.description.$model;
      const sequenceNumber = this.$v.category.sequenceNumber.$model;
      const fee_type = this.$v.category.fee_type.$model;
      const fee_value = this.$v.category.fee_value.$model;
      const forwarder_price_kg = this.$v.category.forwarder_price_kg.$model;
      const forwarder_price_cube = this.$v.category.forwarder_price_cube.$model;

      const input = {
        originalName,
        description,
        sequenceNumber,
        fee_type,
        fee_value: Number(fee_value),
        forwarder_price_kg: Number(forwarder_price_kg),
        forwarder_price_cube: Number(forwarder_price_cube)
      };

      input.sequenceNumber = Number(input.sequenceNumber);
      const {
        data: { createCategory }
      } = await this.$apollo.mutate({
        mutation: CREATE_CATEGORY,
        variables: {
          input: input
        }
      });
      const { message, is_error } = createCategory;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$router.push("/admin/seller/category");
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  }
};
</script>
