<template>
  <div id="kt_header" ref="kt_header" class="header flex-column header-fixed">
    <HeaderNoti />
    <div class="header-top header-menu">
      <div class="container">
        <div
          class="d-none d-lg-flex align-items-center mr-3"
          id="kt_header_menu"
          ref="kt_header_menu"
        >
          <!--begin::Logo-->
          <div class="mr-10">
            <router-link to="/">
              <img
                alt="Logo"
                :src="layoutConfig('self.logo.default')"
                class="max-w-50px"
              />
            </router-link>
          </div>
          <!--end::Logo-->
          <ul
            class="header-tabs nav menu-nav align-self-end font-size-lg"
            role="tablist"
          >
            <li class="nav-item">
              <router-link
                @click="setActiveTab"
                to="/dashboard"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  แดชบอร์ด
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                @click="setActiveTab"
                to="/wallet"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  กระเป๋าเงิน
                </a>
              </router-link>
            </li>
            <template v-for="(menu, i) in menus">
              <li
                class="nav-item noti menu-item menu-item-submenu menu-item-open-dropdown mr-0"
                aria-haspopup="true"
                data-menu-toggle="hover"
                v-if="menu.condition && menu.childs.length > 0"
                v-bind:key="i"
              >
                <a
                  class="nav-link menu-link menu-toggle py-4 px-5 radius-bottom-0 align-items-center"
                  :class="{
                    active: menu.childs.some(child =>
                      hasActiveChildren(child.link)
                    )
                  }"
                >
                  {{ menu.title }}
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="/media/svg/icons/Navigation/Angle-down.svg"
                    />
                  </span>
                  <span
                    v-if="
                      getCurrentNoti &&
                        menu.notiKey.some(key => getCurrentNoti[key])
                    "
                    class=" badge badge-pill badge-danger"
                  >
                    {{
                      menu.notiKey.reduce(
                        (acc, key) => acc + getCurrentNoti[key],
                        0
                      )
                    }}
                  </span>
                </a>
                <div
                  class="menu-submenu menu-submenu-classic"
                  v-if="menu.childs && menu.childs.length"
                >
                  <ul class="menu-subnav">
                    <template v-for="(child, j) in menu.childs">
                      <router-link
                        :to="child.link"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        v-bind:key="j"
                      >
                        <li
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                          class="menu-item"
                          v-if="child.condition"
                          :class="[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ]"
                        >
                          <a
                            :href="href"
                            class="menu-link noti"
                            @click="navigate"
                          >
                            <i class="menu-icon" :class="child.icon"></i>
                            <span
                              :class="{ 'text-dark': !isActive }"
                              class="font-size-lg position-relative pr-4"
                            >
                              {{ child.title }}
                              <span
                                v-if="
                                  getCurrentNoti &&
                                    child.notiKey &&
                                    getCurrentNoti[child.notiKey]
                                "
                                class="badge badge-pill badge-danger"
                              >
                                {{ getCurrentNoti[child.notiKey] }}
                              </span>
                            </span>
                          </a>
                        </li>
                      </router-link>
                    </template>
                  </ul>
                </div>
              </li>
              <li
                class="nav-item noti"
                v-bind:key="i"
                v-if="menu.condition && menu.childs.length === 0"
              >
                <router-link
                  @click="setActiveTab"
                  :to="menu.link"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <a
                    class="nav-link py-4 px-5"
                    data-tab="1"
                    data-toggle="tab"
                    :href="href"
                    role="tab"
                    aria-selected="true"
                    :class="[isActive && 'active', isExactActive && 'active']"
                    @click="navigate"
                  >
                    {{ menu.title }}
                    <span
                      v-if="
                        getCurrentNoti &&
                          menu.notiKey.some(key => getCurrentNoti[key])
                      "
                      class="badge badge-pill badge-danger"
                    >
                      {{
                        menu.notiKey.reduce(
                          (acc, key) => acc + getCurrentNoti[key],
                          0
                        )
                      }}
                    </span>
                  </a>
                </router-link>
              </li>
            </template>
            <li class="nav-item" v-if="getIsAdmin">
              <router-link
                @click="setActiveTab"
                to="/admin"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  หน้าแอดมิน
                </a>
              </router-link>
            </li>
          </ul>
        </div>
        <KTTopbar></KTTopbar>
      </div>
    </div>
    <div class="header-bottom subheader py-4 subheader-solid">
      <div
        class="container d-flex align-items-center justify-content-end flex-wrap flex-sm-nowrap"
      >
        <div class="ml-5" style="width: 100%">
          <div
            class="input-group input-group-solid input-group-lg"
            style="width: 100%"
          >
            <input
              type="text"
              class="form-control"
              placeholder="วางลิงก์สินค้าจากเว็บไซต์จีน taobao/1688/alibaba/shopee"
              v-model="searchItem"
              @keyup.enter="enterSearch"
            />
            <span
              v-if="searchItem"
              class="icon-xl la la-times-circle opacity-40 pointer"
              @click="searchItem = ''"
            />
            <div class="input-group-append pointer" @click="enterSearch">
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none header-bottom">
      <div class="container">
        <div
          class="header-navs header-navs-right"
          id="kt_header_navs"
          ref="kt_header_navs"
        >
          <div class="tab-pane py-5 show active">
            <!--begin::Menu-->
            <div
              class="header-menu header-menu-mobile header-menu-layout-default"
            >
              <KTMenu></KTMenu>
            </div>
            <!--end::Menu-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import { IS_SELLER } from "@/config";
import HeaderNoti from "@/view/layout/header/HeaderNoti.vue";

export default {
  name: "KTHeader",
  data() {
    return {
      menus: [
        {
          title: "ใบสั่งซื้อ",
          link: null,
          notiKey: ["trade", "user_seller_order"],
          condition: true,
          childs: [
            {
              title: "ฝากสั่งสินค้า",
              link: "/shop/order",
              icon: "la la-shopping-basket",
              notiKey: "trade",
              condition: true
            },
            {
              title: "การสั่งซื้อของฉัน",
              link: "/shop/sale",
              icon: "la la-shopping-cart",
              condition: IS_SELLER,
              notiKey: "user_seller_order"
            }
          ]
        },
        {
          title: "ฝากส่งพัสดุ",
          link: null,
          notiKey: ["track"],
          condition: true,
          childs: [
            {
              title: "รหัสพัสดุ",
              link: "/forwarder",
              icon: "flaticon2-list-2",
              condition: true,
              notiKey: "track"
            },
            {
              title: "ใบส่งพัสดุ",
              link: "/shippay/bill",
              condition: true,
              icon: "flaticon2-mail-1"
            }
          ]
        },
        {
          title: "โอนเงินไปจีน",
          link: null,
          condition: true,
          notiKey: ["payment"],
          childs: [
            {
              title: "โอนเงินไปจีน",
              link: "/payments",
              icon: "flaticon2-world",
              condition: true,
              notiKey: "payment"
            }
            // {
            //   title: "ฝากจ่ายเถาเป่า",
            //   link: "/taobao",
            //   condition: true,
            //   icon: "flaticon2-list"
            // }
          ]
        },
        {
          title: "มาเป็นผู้ขาย",
          link: "/seller",
          condition: IS_SELLER,
          notiKey: ["seller_order", "seller_message"],
          childs: []
        },
        {
          title: "หน้าแอดมิน",
          link: "/admin",
          condition: this.getIsAdmin,
          childs: []
        }
      ],
      tabIndex: 0,
      searchItem: "",
      IS_SELLER
    };
  },
  components: {
    KTTopbar,
    KTMenu,
    HeaderNoti
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init(
      this.$refs["kt_header"],
      this.$refs["kt_header_mobile"]
    );

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_navs"]
    );

    const headerRef = this.$refs["kt_header_navs"];

    headerRef.querySelectorAll("a.menu-link").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "getIsAdmin",
      "getCurrentNoti"
    ]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    enterSearch() {
      const url = this.searchItem;
      this.$router.push(
        "/shop/search/" + encodeURIComponent(url.replace(".html", ""))
      );
      this.searchItem = "";
    }
  }
};
</script>
