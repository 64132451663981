<template>
  <b-form
    class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
    novalidate="novalidate"
    id="kt_sign_up_form"
    @submit.stop.prevent="onSubmit"
  >
    <!--begin::Heading-->
    <div class="mb-10 text-center">
      <!--begin::Title-->
      <h1 class="text-dark mb-3">สมัครสมาชิก</h1>
      <!--end::Title-->
      <!--begin::Link-->
      <div class="text-gray-400 fw-bold fs-4">
        มีบัญชีใช้งานแล้ว?
        <router-link to="/login" class="link-primary fw-bolder"
          >เข้าสู่ระบบที่นี่</router-link
        >
      </div>
      <!--end::Link-->
    </div>
    <!--end::Heading-->
    <b-alert
      class="mb-6"
      v-for="error in errors"
      v-bind:key="error"
      show
      variant="danger"
    >
      {{ error }}
    </b-alert>
    <!--begin::Input group-->
    <div class="row fv-row mb-7 fv-plugins-icon-container">
      <!--begin::Col-->
      <div class="col-xl-12">
        <label class="form-label fw-bolder text-dark fs-6"
          >ชื่อ - นามสกุล</label
        >
        <b-form-input
          class="form-control form-control-lg form-control-solid"
          type="text"
          placeholder=""
          name="full_name"
          :state="validateState('full_name')"
          v-model="form.full_name"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
      <!--end::Col-->
    </div>
    <!--begin::Input group-->
    <div class="row fv-row mb-7 fv-plugins-icon-container">
      <!--begin::Col-->
      <div class="col-xl-12">
        <label class="form-label fw-bolder text-dark fs-6">เบอร์โทรศัพท์</label>
        <b-form-input
          class="form-control form-control-lg form-control-solid"
          type="text"
          placeholder=""
          name="phone"
          :state="validateState('phone')"
          v-model="form.phone"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->
    <!--begin::Input group-->
    <div class="fv-row mb-7 fv-plugins-icon-container">
      <label class="form-label fw-bolder text-dark fs-6">อีเมล</label>
      <b-form-input
        class="form-control form-control-lg form-control-solid"
        type="email"
        placeholder=""
        name="email"
        :state="validateState('email')"
        v-model="form.email"
        autocomplete="off"
      />
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group-->
    <!--begin::Input group-->
    <div
      class="mb-10 fv-row fv-plugins-icon-container"
      data-kt-password-meter="true"
    >
      <!--begin::Wrapper-->
      <div class="mb-1">
        <!--begin::Label-->
        <label class="form-label fw-bolder text-dark fs-6">รหัสผ่าน</label>
        <!--end::Label-->
        <!--begin::Input wrapper-->
        <div class="position-relative mb-3">
          <b-form-input
            class="form-control form-control-lg form-control-solid"
            :type="!showPassword ? 'password' : 'text'"
            placeholder=""
            name="password"
            :state="validateState('password')"
            v-model="form.password"
            autocomplete="off"
          />
          <span
            class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-20 me-n2"
            @click="togglePassword"
          >
            <i
              class="la la-eye-slash fs-2"
              :class="{
                'd-none': showPassword
              }"
            ></i>
            <i
              class="la la-eye fs-2"
              :class="{
                'd-none': !showPassword
              }"
            ></i>
          </span>
        </div>
        <!--end::Input wrapper-->
      </div>
      <!--end::Wrapper-->
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group=-->
    <!--begin::Input group-->
    <div class="fv-row mb-5 fv-plugins-icon-container">
      <label class="form-label fw-bolder text-dark fs-6">ยืนยันรหัสผ่าน</label>
      <b-form-input
        class="form-control form-control-lg form-control-solid"
        type="password"
        placeholder=""
        name="confirm-password"
        :state="validateState('confirmPassword')"
        v-model="form.confirmPassword"
        autocomplete="off"
      />
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group-->
    <!--begin::Input group-->
    <div class="fv-row mb-5 fv-plugins-icon-container">
      <label class="form-label fw-bolder text-dark fs-6">LINE ID</label>
      <b-form-input
        class="form-control form-control-lg form-control-solid"
        placeholder=""
        name="line"
        :state="validateState('line')"
        v-model="form.line"
      />
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <div
      class="p-4 border border-width-1 rounded overflow-auto mb-2"
      style="max-height: 400px;"
    >
      เงื่อนไขและข้อตกลงนี้ถือว่าเป็นการเข้าใจกันดีแล้วทั้งสองฝ่าย
      ซึ่งต่อไปนี้ขอแทน {{ TITLE_T_AND_C }} ว่า "บริษัท" และลูกค้าว่า "ท่าน"
      โดยถือว่าท่านยอมรับเงื่อนไข ขอบเขตการรับผิดชอบของบริษัท
      ก่อนการใช้บริการเรียบร้อยแล้ว ดังต่อไปนี้<br /><br />
      ในกรณีที่ท่านใช้บริการฝากสั่งกับทางบริษัท(ให้บริษัทสั่งซื้อให้)
      ทางบริษัทเป็นเพียงตัวกลางใน การติดต่อซื้อขายจากร้านค้าที่ท่านเลือกมา
      ไม่มีส่วนในการรับผิดชอบ การันตีความผิดพลาด ของร้านค้าจีน อาทิเช่น
      สินค้าไม่เหมือนแบบ แตกหัก ส่งผิดสี ผิดไซส์ ส่งไม่ครบ ไม่คืนเงิน เป็นต้น
      ทางบริษัทยินดีเป็นสื่อกลางในการติดตามทวงถามเท่านั้น<br /><br />
      ในกรณีที่ท่านสั่งสินค้าเอง (ใช้เฉพาะบริการขนส่ง) กรณีมีปัญหากับร้านค้าจีน
      ทางบริษัทมีค่าใช้จ่ายในการติดตามทวงถามแต่ไม่รับประกันผลการรับผิดชอบจากร้านค้าจีนได้
      เนื่องจากท่านเป็นผู้เลือกสั่งซื้อสินค้ากับทางร้านค้าจีนด้วยตนเองทางบริษัทเป็นเพียงสื่อกลางในการติดต่อให้เท่านั้น<br />
      กรณีที่สินค้าสูญหายจากการขนส่งแต่ท่านชำระค่าขนส่งแล้ว
      ทางบริษัทรับผิดชอบตามมูลค่าสินค้าจริงไม่เกิน 3 เท่าของค่าขนส่ง
      สินค้าที่สูญหาย หากท่านยังไม่ได้ชำระค่าขนส่ง
      ทางบริษัทรับผิดชอบตามมูลค่าสินค้าจริงไม่เกิน 2
      เท่าของค่าขนส่งสินค้าที่สูญหาย ท่านสามารถทำประกันสินค้าโดยชำระ 5%
      ของราคาสินค้า เมื่อสินค้าสูญหายบริษัทรับผิดชอบเต็ม 100%
      ของมูลค่าสินค้านั้น ๆ โดย การทำประกันสินค้า สามารถติดต่อได้กับทางบริษัท<br />
      ทางบริษัทขอสงวนสิทธิ์ไม่รับผิดชอบในกรณีสินค้า เสียหาย แตกหัก
      จากบรรจุภัณฑ์ที่ไม่แข็งแรง เหมาะสม กรณีสินค้าแตกหักเสียหายง่าย
      ท่านควรแจ้งบริษัทหรือร้านค้าตีลังไม้เพื่อป้องกันสินค้าเสียหาย<br /><br />
      บริษัทไม่รับขนส่งสินค้าผิดกฎหมายทุกชนิด
      หากท่านส่งสินค้าเข้ามาแล้วถูกตรวจยึด อายัด ทางบริษัทไม่รับผิดชอบทุกกรณี
      หรือหากมีความเสียหายเกิดขึ้น ท่านต้องรับผิดชอบทั้งทางแพ่งและทางอาญา
      แต่เพียงฝ่ายเดียว<br /><br />
      ในกรณีที่ท่านได้รับสินค้า แล้วพบว่าบรรจุภัณฑ์เสียหาย สินค้าหลุดตกหล่น
      สินค้ามีรอยกรีด เกิดความเสียหายถึงตัวสินค้า
      อันเนื่องมาจากการขนส่งหรือการสุ่มตรวจของเจ้าหน้าที่
      ทางบริษัทไม่สามารถรับผิดชอบต่อปัจจัยดังกล่าวได้<br /><br />
      ในกรณีที่สินค้าถึงไทยแล้ว เพื่อการติดตามกับทางร้านค้า
      ว่าสินค้าที่สั่งมาถูกต้องตามแบบครบถ้วนหรือไม่เมื่อท่านได้รับสินค้าแล้วจะต้องตรวจสอบหากพบว่าสินค้าใดมีปัญหา
      ต้องแจ้งกับทางบริษัทโดยเร็วที่สุด ภายใน 3 วัน
      หลังจากรับสินค้าและต้องเก็บหีบห่อ/กล่องบรรจุสินค้านั้นไว้
      เพื่อติดตามทวงถามจากร้านค้าจีนหากท่านแจ้งล่าช้ากว่ากำหนด
      สินค้าอาจติดตามยาก หรือติดตามไม่ได้ และท่านอาจไม่ได้รับการชดเชยใด ๆ
      จากร้านค้าจีนทางบริษัทไม่สามารถรับผิดชอบใด ๆ ระหว่างท่านกับร้านค้าจีน
      บริษัทเป็นเพียงสื่อกลางในการติดตามทวงถามเท่านั้น<br /><br />
      ทางบริษัทขอสงวนสิทธิ์ในการแจ้งปรับเปลี่ยนราคาค่าขนส่งโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
      ราคาที่เสนออาจมีการปรับเปลี่ยนได้ภายหลัง<br /><br />
      บริษัทขอสงวนสิทธิ์ไม่จัดส่งสินค้า หากยังไม่ได้รับการชำระเงินจากท่าน
      บริษัทขอสงวนสิทธิ์ในการตรวจเช็คสินค้า
      เพื่อระบุและบันทึกรายละเอียดสินค้า<br /><br />
      ระยะเวลาการขนส่งเป็นระยะเวลาโดยเฉลี่ย อาจเร็วกว่าหรือช้ากว่าที่ประมาณการณ์
      ทางบริษัทไม่รับประกันถึงความล่าช้า
      ไม่สามารถรับผิดชอบในกรณีที่สินค้าติดตรวจ ติดภัยธรรมชาติ
      หรือปัจจัยที่คาร์โก้ไม่สามารถควบคุมได้ ทำให้สินค้ามาไม่ทันตามกำหนด<br /><br />
      สินค้าที่สถานะถึงโกดังไทย
      หากไม่มีการชำระค่าขนส่งหรือชำระค่าขนส่งแล้วแต่ไม่เข้ามารับสินค้าเกิน 30
      วัน โดยที่ทางบริษัท ไม่ได้เก็บค่าฝากสินค้า ทางบริษัทขอสงวนสิทธิ์
      ในการนำสินค้าออกจำหน่าย เพื่อชดเชยต้นทุนการนำเข้า
      โดยไม่ต้องแจ้งให้ทราบล่วงหน้า<br /><br />
      สำหรับลูกค้า สั่งซื้อและนำเข้าสินค้า หากสินค้าของท่านเข้าโกดังไทยแล้ว
      ให้ลูกค้าชำระค่าขนส่งและแจ้งจัดส่งสินค้าภายใน 3 วัน
      นับจากวันที่บริษัทแจ้งสินค้าเข้าโกดังไทย หากลูกค้าแจ้งจัดส่งสินค้าล่าช้า
      ทางบริษัทขอปฏิเสธการรับผิดชอบ กรณีที่ท่านได้รับสินค้าไม่ครบ
      บริษัทขอสงวนสิทธิ์ไม่ติดตามให้ทุกกรณี<br /><br />
      หากสินค้าของท่านเข้าโกดังไทยแล้ว
      ให้ลูกค้าชำระค่าขนส่งและแจ้งจัดส่งสินค้าภายใน 3 วัน
      นับจากวันที่บริษัทแจ้งสินค้าเข้าโกดังไทย หากท่านได้รับสินค้าแล้ว
      ท่านต้องตรวจสอบ ว่าได้รับถูกต้องครบถ้วนตามใบส่งสินค้าหรือไม่ หากไม่ถูกต้อง
      ให้ท่านแจ้งบริษัททราบภายใน 3 วัน นับจากวันที่ท่านได้รับสินค้า หากเกินกำหนด
      ทางบริษัทขอสงวนสิทธิ์ไม่รับผิดชอบทุกกรณี<br /><br />
      <div v-html="term" />
    </div>
    <div class="fv-row mb-5 fv-plugins-icon-container">
      <div class="form-group row">
        <div class="col-12">
          <label class="checkbox checkbox-single">
            <input
              type="checkbox"
              name="accept"
              :state="validateState('accept')"
              v-model="form.accept"
            />
            <span class="mr-3"></span>
            ข้าพเจ้ายอมรับเงื่อนไข ข้อยกเว้น และข้อกำหนดต่างๆ
            <br />
            ข้าพเจ้าเข้าใจและรับทราบข้อปฏิบัตินี้แล้วโดยครบถ้วนสมบูรณ์
          </label>
        </div>
      </div>
    </div>
    <!--end::Input group-->
    <!--begin::Actions-->
    <div class="text-center mt-8">
      <button
        type="submit"
        id="kt_sign_up_submit"
        :disabled="loading"
        class="btn btn-lg btn-primary btn-block"
      >
        <span class="indicator-label" v-if="!loading">สมัครสมาชิก</span>
        <span class="indicator-progress" v-if="loading"
          >Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span
        ></span>
      </button>
    </div>
    <!--end::Actions-->
    <div></div>
  </b-form>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import {
  REGISTER,
  TERM_AND_CONDITION
} from "@/core/services/store/auth.module";
import { API_URL, IMAGE_URL, TITLE_T_AND_C } from "@/config";

export default {
  mixins: [validationMixin],
  name: "RegisterUser",
  data() {
    return {
      TITLE_T_AND_C,
      loading: false,
      showPassword: false,
      previewImage: null,
      file: null,
      term: "",
      form: {
        email: "",
        password: "",
        line: "",
        file: null
      }
    };
  },
  validations: {
    form: {
      full_name: {
        required
      },
      phone: {
        required
      },
      email: {
        required,
        email
      },
      accept: {
        required
      },
      line: { required },
      password: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      file: {}
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getTermAndCondition() {
      this.loading = true;

      this.$store
        .dispatch(TERM_AND_CONDITION)
        .then(data => {
          this.loading = false;
          console.log("data", data);
          this.term = data.message;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (!this.$v.form.accept.$model) {
        Swal.fire({
          title: "",
          text: "กรุณายอมรับเงื่อนไขข้อตกลง",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
        return;
      }

      const full_name = this.$v.form.full_name.$model;
      const phone = this.$v.form.phone.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const file = this.$v.form.file.$model;
      const line = this.$v.form.line.$model;

      this.loading = true;

      this.$store
        .dispatch(REGISTER, {
          full_name: full_name,
          phone: phone,
          email: email,
          password: password,
          line,
          file: file
        })
        .then(data => {
          this.loading = false;
          if (data.isSuccess) {
            // this.$router.push("/wait?email=" + email);
            this.$router.push("/login");
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success"
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    upload(file, onUploadProgress) {
      let formData = new FormData();

      formData.append("file", file);

      return axios.post(`${API_URL}/upload/temp2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        this.upload(file[0], event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(res => {
          const { data } = res;
          if (data) {
            this.previewImage = `${IMAGE_URL}${data.data}`;
            this.form.file = data.data;
          }
        });
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.form.file = null;
      this.file = null;
      this.previewImage = null;
    }
  },
  mounted() {
    this.getTermAndCondition();
  },
  computed: {
    ...mapState({
      errors: state => {
        return state.auth.errorRegister.map(x => x.message);
      }
    })
  }
};
</script>
