<template>
  <div id="kt_header" ref="kt_header" class="header flex-column header-fixed">
    <HeaderNoti />
    <div class="header-top admin header-menu">
      <div class="container p-0">
        <div
          class="d-none d-lg-flex align-items-center mr-3"
          id="kt_header_menu"
          ref="kt_header_menu"
        >
          <!--begin::Logo-->
          <div class="mr-10">
            <router-link to="/admin">
              <img
                alt="Logo"
                :src="layoutConfig('self.logo.default')"
                class="max-w-50px"
              />
            </router-link>
          </div>
          <!--end::Logo-->
          <ul
            class="header-tabs nav menu-nav align-self-end font-size-lg"
            role="tablist"
          >
            <li class="nav-item" v-if="permission['admin_user']">
              <router-link
                @click="setActiveTab"
                to="/admin/users"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  ผู้ใช้งาน
                </a>
              </router-link>
            </li>
            <li
              class="nav-item noti"
              v-if="permission['admin_balance'] || permission['admin_topup']"
            >
              <router-link
                @click="setActiveTab"
                to="/admin/wallet"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  กระเป๋าเงิน
                  <span
                    v-if="
                      getCurrentNotiAdmin &&
                        (getCurrentNotiAdmin.withdraw ||
                          getCurrentNotiAdmin.topup)
                    "
                    class="badge badge-pill badge-danger"
                  >
                    {{
                      getCurrentNotiAdmin.withdraw + getCurrentNotiAdmin.topup
                    }}
                  </span>
                </a>
              </router-link>
            </li>
            <li class="nav-item noti" v-if="permission['admin_order']">
              <router-link
                @click="setActiveTab"
                to="/admin/shop/order"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  ใบสั่งซื้อ
                  <span
                    v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.trade"
                    class="badge badge-pill badge-danger"
                  >
                    {{ getCurrentNotiAdmin.trade }}
                  </span>
                </a>
              </router-link>
            </li>
            <li
              class="nav-item noti menu-item menu-item-submenu menu-item-open-dropdown mr-0"
              v-if="permission['admin_lot'] || permission['admin_payshipping']"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="nav-link menu-link menu-toggle py-4 px-5 radius-bottom-0 align-items-center"
                :class="{
                  active:
                    hasActiveChildren('/admin/forwarder') ||
                    hasActiveChildren('/admin/shippay/bill') ||
                    hasActiveChildren('/admin/lot')
                }"
              >
                ฝากส่งพัสดุ
                <span
                  v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.bill"
                  class="badge badge-pill badge-danger"
                >
                  {{ getCurrentNotiAdmin.bill }}
                </span>
                <span class="svg-icon svg-icon-md">
                  <inline-svg
                    src="/media/svg/icons/Navigation/Angle-down.svg"
                  />
                </span>
                <span
                  v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.track"
                  class="badge badge-pill badge-danger"
                >
                  {{ getCurrentNotiAdmin.track }}
                </span>
              </a>
              <div class="menu-submenu menu-submenu-classic">
                <ul class="menu-subnav">
                  <router-link
                    to="/admin/forwarder"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="permission['admin_payshipping']"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link noti" @click="navigate">
                        <i class="menu-icon flaticon2-list-2"></i>
                        <span
                          :class="{ 'text-dark': !isActive }"
                          class="font-size-lg position-relative pr-4"
                        >
                          รหัสพัสดุ
                          <span
                            v-if="
                              getCurrentNotiAdmin && getCurrentNotiAdmin.track
                            "
                            class="badge badge-pill badge-danger"
                          >
                            {{ getCurrentNotiAdmin.track }}
                          </span>
                        </span>
                      </a>
                    </li>
                  </router-link>

                  <router-link
                    to="/admin/shippay/bill"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="permission['admin_payshipping']"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon flaticon2-mail-1"></i>
                        <span
                          :class="{ 'text-dark': !isActive }"
                          class="font-size-lg position-relative pr-4"
                        >
                          ใบส่งพัสดุ
                          <span
                            v-if="
                              getCurrentNotiAdmin && getCurrentNotiAdmin.bill
                            "
                            class="badge badge-pill badge-danger"
                          >
                            {{ getCurrentNotiAdmin.bill }}
                          </span>
                        </span>
                      </a>
                    </li>
                  </router-link>
                  <router-link
                    to="/admin/lot"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="permission['admin_lot']"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon flaticon2-layers-1"></i>
                        <span
                          :class="{ 'text-dark': !isActive }"
                          class="font-size-lg"
                        >
                          ล็อต
                        </span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
            <li class="nav-item noti" v-if="permission['admin_payment']">
              <router-link
                @click="setActiveTab"
                to="/admin/payments"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  โอนเงินไปจีน
                  <span
                    v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.payment"
                    class="badge badge-pill badge-danger"
                  >
                    {{ getCurrentNotiAdmin.payment }}
                  </span>
                </a>
              </router-link>
            </li>
            <li class="nav-item noti" v-if="permission['admin_chat']">
              <router-link
                @click="setActiveTab"
                to="/admin/chat"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  แชท
                  <span
                    v-if="getCurrentNotiAdmin && getCurrentNotiAdmin.msg"
                    class="badge badge-pill badge-danger"
                  >
                    {{ getCurrentNotiAdmin.msg }}
                  </span>
                </a>
              </router-link>
            </li>
            <li class="nav-item" v-if="permission['setting']">
              <router-link
                @click="setActiveTab"
                to="/admin/setting"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  ตั้งค่า
                </a>
              </router-link>
            </li>
            <li class="nav-item" v-if="permission['admin_seller']">
              <router-link
                @click="setActiveTab"
                to="/admin/seller"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  ขายสินค้า
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                @click="setActiveTab"
                to="/dashboard"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  class="nav-link py-4 px-5"
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  :class="[isActive && 'active', isExactActive && 'active']"
                  @click="navigate"
                >
                  หน้าผู้ใช้
                </a>
              </router-link>
            </li>
          </ul>
        </div>
        <KTTopbar></KTTopbar>
      </div>
    </div>
    <div class="header-bottom subheader py-4 subheader-solid">
      <div
        class="container d-flex align-items-center justify-content-end flex-wrap flex-sm-nowrap"
      >
        <div class="ml-5" style="width: 100%">
          <div
            class="input-group input-group-solid input-group-lg"
            style="width: 100%"
          >
            <input
              type="text"
              class="form-control pr-2"
              placeholder="วางลิงก์สินค้าจากเว็บไซต์จีน taobao/1688/alibaba/shopee"
              v-model="searchItem"
              @keyup.enter="enterSearch"
            />
            <span
              v-if="searchItem"
              class="icon-xl la la-times-circle opacity-40 pointer"
              @click="searchItem = ''"
            />
            <div class="input-group-append pointer" @click="enterSearch">
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none header-bottom">
      <div class="container">
        <div
          class="header-navs header-navs-right"
          id="kt_header_navs"
          ref="kt_header_navs"
        >
          <div class="tab-pane py-5 show active">
            <!--begin::Menu-->
            <div
              class="header-menu header-menu-mobile header-menu-layout-default"
            >
              <KTMenu></KTMenu>
            </div>
            <!--end::Menu-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/admin/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/admin/Menu.vue";
import { NOTI_ADMIN } from "@/core/services/store/profile.module.js";
import HeaderNoti from "@/view/layout/header/HeaderNoti.vue";
import { ACCOUNT } from "@/core/services/store/auth.module";

export default {
  name: "KTHeader",
  data() {
    return {
      tabIndex: 0,
      permission: {},
      searchItem: "",
      polling: null
    };
  },
  components: {
    KTTopbar,
    KTMenu,
    HeaderNoti
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init(
      this.$refs["kt_header"],
      this.$refs["kt_header_mobile"]
    );

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_navs"]
    );

    // setting permission
    const permission = {};
    const settingPermission =
      this.checkPermission("admin_role", 1) ||
      this.checkPermission("admin_bank", 1) ||
      this.checkPermission("admin_china_thai", 1) ||
      this.checkPermission("admin_exchange_rate", 1) ||
      this.checkPermission("admin_delivery", 1);
    permission["admin_user"] = this.checkPermission("admin_user", 0);
    permission["admin_order"] = this.checkPermission("admin_order", 0);
    permission["admin_topup"] = this.checkPermission("admin_topup", 0);
    permission["admin_balance"] = this.checkPermission("admin_balance", 0);
    permission["admin_payshipping"] = this.checkPermission(
      "admin_payshipping",
      0
    );
    permission["admin_chat"] = this.checkPermission("admin_chat", 0);
    permission["admin_payment"] = this.checkPermission("admin_payment", 0);
    permission["admin_lot"] = this.checkPermission("admin_lot", 0);
    permission["admin_seller"] = this.checkPermission("admin_seller", 0);
    permission["setting"] = settingPermission;
    this.permission = permission;

    const headerRef = this.$refs["kt_header_navs"];

    setTimeout(() => {
      headerRef.querySelectorAll("a.menu-link").forEach(item => {
        item.addEventListener("click", () => {
          KTLayoutHeaderMenu.getOffcanvas().hide();
        });
      });
    }, 400);
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentPermission",
      "getCurrentNotiAdmin"
    ]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    loadData() {
      this.$store.dispatch(NOTI_ADMIN);
    },
    loadAccount() {
      this.$store.dispatch(ACCOUNT);
    },
    poll() {
      this.polling = setInterval(() => {
        this.loadData();
      }, 9000);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    enterSearch() {
      const url = this.searchItem;
      this.$router.push(
        "/shop/search/" + encodeURIComponent(url.replace(".html", ""))
      );
    },
    checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }
      const { permissionRole } = this.currentPermission;

      const find = permissionRole.find(
        x => x.permissionInfo && x.permissionInfo.permissionName === name
      );
      if (!find) {
        return false;
      }
      if (type === 0 && find.readableRole) {
        return true;
      }
      if (type === 1 && find.writeableRole) {
        return true;
      }
      return false;
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    this.loadData();
    this.poll();
    this.loadAccount();
  }
};
</script>
