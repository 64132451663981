import gql from "graphql-tag";

export const GET_DEPARTMENTS = gql`
  query($id: ID) {
    sellerDepartments(id: $id) {
      isSuccess
      message
      records {
        _id
        seller {
          _id
          userId
          full_name
          seller {
            _id
            seller_name
          }
        }
        no
        is_show
        noti
      }
    }
  }
`;
