<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div
      class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style="background-image: url(/assets/progress-hd.png)"
    >
      <!--begin::Content-->
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-6 p-sm-10 pb-lg-20"
      >
        <!--begin::Logo-->
        <a :href="LANDING_URL" class="mb-6">
          <img alt="Logo" :src="loaderLogo" class="h-120px" />
        </a>
        <!--end::Logo-->
        <div
          class="w-lg-500px w-sm-400px w-100 bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
        >
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { LANDING_URL } from "@/config";

export default {
  name: "login-1",
  data() {
    return {
      LANDING_URL: LANDING_URL,
      state: "signin",
      form: {
        email: "",
        password: ""
      }
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "dashboard" });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "isAuthenticated", "layoutConfig"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
    loaderLogo() {
      return this.layoutConfig("loader.logo");
    }
  }
};
</script>
import { LANDING_URL } from "@/config/index"; import { LANDING_URL } from
"@/config/index";
