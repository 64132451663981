<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card>
      <error-5 v-if="messageError" />
      <div v-if="product">
        <b-row>
          <b-col lg="4" class="pb-2">
            <div class="product-details-large">
              <img
                class="product-zoom"
                :alt="product.productName"
                :src="product.mainImage"
              />
            </div>
          </b-col>
          <b-col lg="8">
            <div class="mb-6">
              <p class="h2 font-weight-bold">{{ product.productName }}</p>
            </div>
            <div class="detail_price">
              <div class="detail_price_title">ราคา</div>
              <div class="d-flex flex-column">
                <div class="detail_price_value">
                  {{ product.priceShow }}
                </div>
              </div>
            </div>
            <div class="sku_group">
              <div
                class="sku_container"
                v-if="product.properties[0] && product.properties[1]"
              >
                <div v-if="product.properties[0].title" class="sku_title">
                  {{ product.properties[0].title }}
                </div>
                <div class="sku_text">
                  <div
                    v-for="sku in product.properties[0].list"
                    v-bind:key="sku.id"
                    :class="[
                      selectedSku === sku.id ? 'selected' : '',
                      hasAmount[sku.id] ? ' completed' : ''
                    ]"
                    @click="clickSku(sku.id)"
                    class="sku_one"
                  >
                    <img v-if="sku.image" :src="sku.image" :alt="sku.title" />
                    <button v-if="!sku.image" class="btn-price">
                      {{ sku.title }}
                    </button>
                    <div
                      v-if="selectedSku !== sku.id && hasAmount[sku.id]"
                      class="cor"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="sku_container"
                v-if="product.properties[1] || product.properties[0]"
              >
                <div
                  v-if="(product.properties[1] || product.properties[0]).title"
                  class="sku_title"
                >
                  {{ (product.properties[1] || product.properties[0]).title }}
                </div>
                <div class="d-flex flex-column">
                  <div
                    v-for="sku in (
                      product.properties[1] || product.properties[0]
                    ).list"
                    v-bind:key="`${sku.id}${selectedSku}`"
                    class="sku_choose_list"
                  >
                    <img v-if="sku.image" :src="sku.image" :alt="sku.title" />
                    <b class="sku_list_title">
                      {{ sku.title }}
                    </b>
                    <span class="sku_price">{{
                      drawPriceCurrency(product, getPriceSku(sku.id))
                    }}</span>
                    <span class="stepper">
                      <span
                        class="mr-4"
                        style="font-size: 12px; color: rgba(0, 0, 0, 0.65)"
                        >คลัง:{{ getQtySku(sku.id) }}</span
                      >
                      <InputNumber
                        min="0"
                        :defaultValue="getAmount(selectedSku, sku.id)"
                        @onChange="changeAmount(selectedSku, sku.id, $event)"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="summary">
                <div class="summaryNav">
                  <div class="numberSku">จำนวน : {{ amountTotal }}</div>
                  <div class="RMB">
                    {{ drawPriceCurrency(product, priceTotal) }}
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-addcart mt-12 ml-8" @click="addCart()">
              หยิบสินค้าใส่ตะกร้า
            </button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card v-if="seller" class="mt-4">
      <div class="d-flex">
        <b-avatar :src="imageUrlApi(seller.seller_logo)" size="7rem"></b-avatar>
        <div class="d-flex flex-column justify-content-between ml-4">
          <div>
            <div class="font-size-sm text-muted">จัดจำหน่ายโดย</div>
            {{ seller.seller_name }}
          </div>
          <router-link :to="`/chat/seller?id=${seller._id}`">
            <button class="btn btn-outline-success btn-sm">
              <span class="svg-icon svg-icon-lg">
                <inline-svg src="/media/svg/icons/Communication/Chat4.svg" />
              </span>
              แชทเลย
            </button>
          </router-link>
        </div>
      </div>
    </b-card>
    <b-card v-if="product" class="mt-4">
      <div class="description-title">รายละเอียดสินค้า</div>
      <div class="description-wrapper">
        <div class="description-container">
          <span v-html="product.desc" />
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
import bigDecimal from "js-big-decimal";
import InputNumber from "@/components/InputNumber";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_URL, WEBSITE, imageUrlApi } from "@/config";
import { ADD_CART } from "@/graphql/seller_cart";
import { NOTI } from "@/core/services/store/profile.module.js";
import Error5 from "../error/Error-5.vue";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "สินค้าทั้งหมด", route: "/product/all" },
      { title: `สินค้า` }
    ]);
  },
  created() {
    document.title = `สินค้า | ${WEBSITE}`;
    this.loadData();
  },
  components: {
    InputNumber,
    Error5
  },
  data() {
    const { id } = this.$route.params;
    const { cache = "true" } = this.$route.query;
    return {
      cache: cache == "true",
      id,
      seller: null,
      selectedSku: "",
      amountList: {},
      choose: [],
      price: 0,
      stock: 0,
      rate: 4.9,
      amountTotal: 0,
      priceTotal: 0,
      hasAmount: {},
      product: false,
      loading: false,
      messageError: ""
    };
  },
  methods: {
    imageUrlApi,
    drawPriceCurrency(product, price) {
      return `฿${this.format_number(price, 2)}`;
    },
    clickSku(id) {
      this.selectedSku = id;
    },
    getAmount(id1, id2) {
      const keyList = this.getKey(id1, id2);
      const key = keyList.join(";");
      if (this.amountList[key]) {
        return this.amountList[key].amount;
      } else {
        const key = keyList.reverse().join(";");
        return this.amountList[key] ? this.amountList[key].amount : 0;
      }
    },
    getAmountTotal() {
      let total = 0;
      for (const key in this.amountList) {
        if (Object.hasOwnProperty.call(this.amountList, key)) {
          const item = this.amountList[key];
          total += item.amount;
        }
      }
      return total;
    },
    getPriceTotal() {
      let total = 0;
      for (const key in this.amountList) {
        if (Object.hasOwnProperty.call(this.amountList, key)) {
          const item = this.amountList[key];
          total += item.amount * item.price;
        }
      }
      return total;
    },
    changeAmount(id1, id2, amount) {
      const { skus } = this.product;
      const keyList = this.getKey(id1, id2);
      let key = keyList.join(";");
      if (!skus[key]) {
        key = keyList.reverse().join(";");
      }
      if (!skus[key]) {
        return;
      }
      if (!this.amountList[key]) {
        this.amountList[key] = {
          id1: id1 || id2,
          id2: id1 ? id2 : "",
          key,
          price: skus[key].price,
          amount: 0
        };
      }
      this.amountList[key].amount = Number(amount);
      if (!this.amountList[key].amount) {
        delete this.amountList[key];
      }
      this.amountTotal = this.getAmountTotal();
      this.priceTotal = this.getPriceTotal();
      this.hasAmount[id1] = Number(amount);
    },
    async addCart() {
      const keys = Object.keys(this.amountList);
      if (!keys.length) {
        this.$message.warning("กรุณาเลือกสินค้า");
        return;
      }
      const cart = [];
      for (const key in this.amountList) {
        if (Object.hasOwnProperty.call(this.amountList, key)) {
          const item = this.amountList[key];
          let prop1 = null;
          let prop2 = null;
          prop1 = {
            ...this.product.properties[0].list.find(x => x.id === item.id1)
          };
          if (this.product.properties[1]) {
            prop2 = {
              ...this.product.properties[1].list.find(x => x.id === item.id2)
            };
          }
          prop1.name = this.product.properties[0].title;
          if (prop2) {
            prop2.name = this.product.properties[1].title;
          }
          const properties = [];
          if (prop1) {
            properties.push(prop1);
          }
          if (prop2) {
            properties.push(prop2);
          }
          const input = {
            productId: this.product.productId,
            price: Number(item.price),
            properties,
            skuId: key,
            amount: Number(item.amount)
          };
          cart.push(input);
        }
      }
      const {
        data: { addCartSeller }
      } = await this.$apollo.mutate({
        mutation: ADD_CART,
        variables: {
          input: cart
        }
      });
      if (!addCartSeller.isSuccess) {
        this.$message.error(addCartSeller.message);
      } else {
        this.$message.success(addCartSeller.message);
        this.$store.dispatch(NOTI);
      }
    },
    getKey(id1, id2) {
      const keys = [];
      if (id1) {
        keys.push(id1);
      }
      if (id2) {
        keys.push(id2);
      }
      return keys;
    },
    getPriceSku(sku_id) {
      const { skus } = this.product;
      const keyList = this.getKey(this.selectedSku, sku_id);
      const key = keyList.join(";");
      if (skus[key]) {
        return skus[key].price;
      } else {
        const key = keyList.reverse().join(";");
        return skus[key] ? skus[key].price : 0;
      }
    },
    getQtySku(sku_id) {
      const { skus } = this.product;
      const keyList = this.getKey(this.selectedSku, sku_id);
      const key = keyList.join(";");
      if (skus[key]) {
        return skus[key].stock;
      } else {
        const key = keyList.reverse().join(";");
        return skus[key] ? skus[key].stock : 0;
      }
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    },
    async loadData() {
      this.loading = true;
      const res = await fetch(`${API_URL}/seller/product/${this.id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      this.loading = false;
      if (res.status === 200) {
        const data = await res.json();
        if (data.isSuccess) {
          this.product = data.data;
          this.seller = data.seller;
        } else {
          this.$message.error(data.message);
        }
      } else {
        this.$message.error("ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  }
};
</script>
