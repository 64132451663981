<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" @submit.prevent="save">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">แก้ไขค่านำเข้า</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group row mb-2">
                <label
                  for="input-forwarderName"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ชื่อค่านำเข้า</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-forwarderName"
                    v-model="forwarder.forwarderName"
                    :state="validateState('forwarderName')"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="input-transportNumber"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ขนส่ง</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <label
                    class="mt-2 checkbox checkbox-single d-flex align-items-center"
                  >
                    <input
                      type="checkbox"
                      value="ek"
                      v-model="forwarder.ship_by_list"
                    />
                    <span></span>
                    <div class="ml-2">รถ</div>
                  </label>
                  <label
                    class="mt-2 checkbox checkbox-single d-flex align-items-center"
                  >
                    <input
                      type="checkbox"
                      value="ship"
                      v-model="forwarder.ship_by_list"
                    />
                    <span></span>
                    <div class="ml-2">เรือ</div>
                  </label>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="input-transportNumber"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ค่าเริ่มต้น</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <span class="switch switch-sm mt-1">
                    <label class="d-flex align-items-center">
                      <input type="checkbox" v-model="forwarder.is_default" />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card card-body mt-4 mb-4"
        v-for="(price, i) in forwarderPrice"
        :key="`shipping${i}`"
      >
        <h3>
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              ราคาทาง{{ mapShip[price.ship_by] }}
            </span>
          </div>
        </h3>
        <table class="table table-sm table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="pl-4">ประเภท</th>
              <th>ราคากิโลกรัม <span class="opacity-50">(บาท)</span></th>
              <th>ราคาคิว. <span class="opacity-50">(บาท)</span></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in price.list"
              :key="`price-${price.ship_by}-${index}`"
            >
              <td class="pl-4">
                {{ item.category.originalName }}
              </td>
              <td>
                <input
                  v-model="item.priceKg"
                  placeholder="ราคากิโลกรัม"
                  required
                  class="form-control"
                />
              </td>
              <td>
                <input
                  v-model="item.priceCube"
                  placeholder="ราคาคิว."
                  required
                  class="form-control"
                />
              </td>
            </tr>
            <tr class="text-center" v-if="price.list && !price.list.length">
              <td colspan="4" class="pt-8">
                <div>ไม่มีข้อมูล กรุณาเพิ่มประเภทสินค้า</div>
                <router-link to="/admin/seller/category/new">
                  <button type="button" class="btn btn-dashed mt-4">
                    <i class="menu-icon la la-plus icon-xl"></i>
                    เพิ่มประเภทสินค้า
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card card-body">
        <div class="d-flex justify-content-center">
          <button
            type="submit"
            class="btn btn-success mr-4"
            style="min-width: 140px"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/General/Save.svg" />
            </span>
            บันทึกค่านำเข้า
          </button>
          <button
            type="button"
            class="btn btn-danger mr-4"
            style="min-width: 140px"
            @click="deleteItem"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
            ลบ
          </button>
          <router-link
            to="/admin/seller/forwarder"
            class="btn btn-secondary"
            style="width: 140px"
          >
            ยกเลิก
          </router-link>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  UPDATE_FORWARDER,
  GET_FORWARDER_CATEGORY_LIST,
  GET_FORWARDER,
  DELETE_FORWARDER
} from "@/graphql/admin/seller_forwarder";

export default {
  name: "ForwarderDetail",
  mixins: [validationMixin],
  created() {
    document.title = `แก้ไขค่านำเข้า | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ค่านำเข้า`, route: "/admin/seller/forwarder" },
      { title: `แก้ไขค่านำเข้า` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      nameState: null,
      messageError: "",
      mapShip: { ek: "รถ", ship: "เรือ" },
      category_list: [],
      forwarderPrice: [],
      forwarder: {
        forwarderName: "",
        is_default: false,
        ship_by_list: ["ek", "ship"],
        forwarderPrice: []
      }
    };
  },
  validations: {
    forwarder: {
      forwarderName: {
        required
      },
      ship_by_list: {},
      is_default: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.forwarder[name];
      return $dirty ? !$error : null;
    },
    async deleteItem() {
      const confirm = await Swal.fire({
        title: "ต้องการค่านำเข้า ?",
        icon: "question",
        html: `ยืนยันลบ ${this.forwarder.forwarderName} หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { deleteForwarder }
      } = await this.$apollo.mutate({
        mutation: DELETE_FORWARDER,
        variables: {
          _id: this.forwarder._id
        }
      });
      const { message, isSuccess } = deleteForwarder;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$router.push("/admin/seller/forwarder");
      }
    },
    drawShipping() {
      const forwarderPrice = this.forwarderPrice.filter(x =>
        this.forwarder.ship_by_list.includes(x.ship_by)
      );
      this.forwarder.ship_by_list.map(ship_by => {
        const shipping = [];
        const find = forwarderPrice.find(x => x.ship_by === ship_by);
        if (find) {
          return;
        }
        const list = this.forwarder.forwarderPrice.filter(
          x => x.ship_by === ship_by
        );
        for (let i = 0; i < list.length; i++) {
          const record = list[i];
          shipping.push({
            categoryId: record.categoryId,
            category: record.category,
            priceKg: record.priceKg,
            priceCube: record.priceCube,
            ship_by
          });
        }
        if (!list.length) {
          for (let i = 0; i < this.category_list.length; i++) {
            const record = this.category_list[i];
            shipping.push({
              categoryId: record.categoryId,
              category: {
                originalName: record.originalName
              },
              priceKg: record.forwarder_price_kg,
              priceCube: record.forwarder_price_cube,
              ship_by
            });
          }
        }
        forwarderPrice.push({
          ship_by: ship_by,
          list: shipping
        });
      });
      this.forwarderPrice = forwarderPrice.sort(this.compare);
    },
    compare(a, b) {
      if (a.ship_by < b.ship_by) return -1;
      if (a.ship_by > b.ship_by) return 1;
      return 0;
    },
    async save() {
      this.$v.forwarder.$touch();
      if (this.$v.forwarder.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const forwarderName = this.$v.forwarder.forwarderName.$model;
      const ship_by_list = this.$v.forwarder.ship_by_list.$model;

      let forwarderPrice = [];
      this.forwarderPrice.map(ship => {
        const shipping = ship.list.map(x => {
          x.priceKg = Number(x.priceKg);
          x.priceCube = Number(x.priceCube);
          return {
            categoryId: x.categoryId,
            ship_by: x.ship_by,
            priceKg: x.priceKg,
            priceCube: x.priceCube
          };
        });
        forwarderPrice = [...forwarderPrice, ...shipping];
      });

      const input = {
        _id: this.forwarder._id,
        forwarderName,
        forwarderPrice,
        ship_by_list
      };

      const {
        data: { updateForwarder }
      } = await this.$apollo.mutate({
        mutation: UPDATE_FORWARDER,
        variables: {
          input: input
        }
      });
      const { message, isSuccess } = updateForwarder;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$router.push("/admin/seller/forwarder");
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  },
  watch: {
    "forwarder.ship_by_list": function() {
      this.drawShipping();
    }
  },
  apollo: {
    systemForwarder: {
      query: GET_FORWARDER,
      variables() {
        return {
          id: this.id
        };
      },
      result({ loading, data }) {
        if (!loading && data) {
          this.forwarder = data.systemForwarder.data;
          const forwarderPrice = this.forwarder.forwarderPrice;
          const newForwarderPrice = {};
          this.forwarderPrice = [];
          forwarderPrice.map(price => {
            if (!newForwarderPrice[price.ship_by]) {
              newForwarderPrice[price.ship_by] = [];
            }
            newForwarderPrice[price.ship_by].push(price);
          });
          for (const key in newForwarderPrice) {
            this.forwarderPrice.push({
              ship_by: key,
              list: newForwarderPrice[key]
            });
          }
        }
      }
    },
    system_category_list: {
      query: GET_FORWARDER_CATEGORY_LIST,
      variables() {
        return {
          pageSize: 99,
          page: 1,
          search: ""
        };
      },
      result({ loading, data }) {
        if (!loading && data) {
          const { system_category_list } = data;
          const { isSuccess, message, records } = system_category_list;
          if (!isSuccess) {
            this.$message.error(message);
          } else {
            this.category_list = records;
          }
        }
      }
    }
  }
};
</script>
