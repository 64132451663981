import gql from "graphql-tag";

export const GET_FORWARDER_LIST = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    systemForwarderList(page: $page, pageSize: $pageSize, search: $search) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        forwarderId
        forwarderName
        ship_by_list
        is_default
      }
    }
  }
`;

export const GET_FORWARDER = gql`
  query($id: Int) {
    systemForwarder(id: $id) {
      isSuccess
      message
      data {
        _id
        forwarderId
        forwarderName
        ship_by_list
        forwarderPrice {
          categoryId
          ship_by
          priceKg
          priceCube
          category {
            originalName
          }
        }
        is_default
      }
    }
  }
`;

export const CREATE_FORWARDER = gql`
  mutation($input: ForwarderInput) {
    createForwarder(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_FORWARDER = gql`
  mutation($input: ForwarderInput) {
    updateForwarder(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_FORWARDER = gql`
  mutation($_id: ID) {
    deleteForwarder(_id: $_id) {
      isSuccess
      message
    }
  }
`;

export const GET_FORWARDER_CATEGORY_LIST = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    system_category_list(page: $page, pageSize: $pageSize, search: $search) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        categoryId
        originalName
        forwarder_price_kg
        forwarder_price_cube
      }
    }
  }
`;
