import gql from "graphql-tag";

export const GET_SELECT_BANK = gql`
  query {
    selectBank {
      value
      label
      img
    }
  }
`;

export const GET_BANKS = gql`
  query {
    cash {
      baht
      yuan
    }
    bankList {
      records {
        bank_id
        account_no
        bank_name
        account_name
        branch_name
        bank_qr
        bank_img
      }
    }
  }
`;
