import gql from "graphql-tag";

export const CREATE_MESSAGE = gql`
  mutation($input: SellerMessageInput) {
    createSellerMessage(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query($page: Int, $pageSize: Int, $sellerId: Int, $id: ID) {
    seller_messages(
      page: $page
      pageSize: $pageSize
      sellerId: $sellerId
      id: $id
    ) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      dept {
        userId
        seller {
          seller_name
        }
      }
      records {
        _id
        sender_id
        sender {
          full_name
          seller {
            seller_logo
            seller_name
          }
        }
        receiver_id
        receiver {
          full_name
          seller {
            seller_logo
            seller_name
          }
        }
        body
        file_attachment
        created_at
      }
    }
  }
`;
