<template>
  <div>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body p-lg-12">
        <!--begin::Layout-->
        <div class="d-flex flex-column flex-xl-row" v-if="payshipping">
          <!--begin::Content-->
          <div class="col-lg-8 col-md-12 col-12 pl-0">
            <!--begin::Invoice 2 content-->
            <div class="mt-n1">
              <!--begin::Wrapper-->
              <div class="m-0">
                <!--begin::Label-->
                <div class="fw-bolder fs-3 text-gray-800 mb-0">
                  เลขที่ใบส่งพัสดุ {{ payshipping.payshipping_doc }}
                </div>
                <!--end::Label-->
                <!--begin::Row-->
                <div class="row g-5 mb-6">
                  <!--end::Col-->
                  <div class="col-6">
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      วันที่สร้าง:
                    </div>
                    <!--end::Label-->
                    <!--end::Col-->
                    <div class="fw-bolder fs-6 text-gray-800">
                      {{ format_datetime(payshipping.created_at) }}
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Col-->
                  <!--end::Col-->
                  <div class="col-6 text-right">
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      วันที่ส่ง:
                    </div>
                    <!--end::Label-->
                    <!--end::Info-->
                    <div class="fw-bolder fs-6 text-gray-800">
                      <span>{{
                        format_datetime(payshipping.customer_received)
                      }}</span>
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="row g-5 mb-8">
                  <!--end::Col-->
                  <div class="col-sm-6">
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      รายละเอียด:
                    </div>
                    <!--end::Label-->
                    <!--end::Text-->
                    <div class="fw-bolder fs-5 text-gray-800">
                      รหัสลูกค้า:
                      {{ payshipping.user.username }}
                    </div>
                    <!--end::Text-->
                    <!--end::Description-->
                    <div class="fs-5 text-gray-800">
                      ชื่อ-สกุล: {{ payshipping.user.full_name }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      อีเมล: {{ payshipping.user.email }}
                    </div>
                    <div class="fs-5 text-danger">
                      หมายเหตุ: {{ payshipping.remark }}
                    </div>
                    <!--end::Description-->
                  </div>
                  <!--end::Col-->
                  <!--end::Col-->
                  <div class="col-sm-6 text-right" v-if="payshipping.address">
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      ที่อยู่จัดส่ง:
                    </div>
                    <!--end::Label-->
                    <!--end::Text-->
                    <div class="fw-bolder fs-6 text-gray-800">
                      ผู้รับ {{ payshipping.address.name }}
                    </div>
                    <!--end::Text-->
                    <!--end::Description-->
                    <div class="fs-5 text-gray-800">
                      โทร. {{ payshipping.address.phone }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      {{ payshipping.address.address }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      ตำบล {{ payshipping.address.district }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      อำเภอ {{ payshipping.address.amphoe }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      {{ payshipping.address.province }}
                      {{ payshipping.address.postcode }}
                    </div>
                    <!--end::Description-->
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Content-->
                <div class="flex-grow-1">
                  <!--begin::Table-->
                  <div class="table-responsive border-bottom mb-9">
                    <table class="table mb-3">
                      <thead>
                        <tr class="border-bottom fs-6 fw-bolder text-gray-400">
                          <th class="pb-2">เลขพัสดุ (จีน)</th>
                          <th class="text-center pb-2">จำนวน</th>
                          <th class="text-center pb-2 text-nowrap">ขนส่งโดย</th>
                          <th class="text-center pb-2">ขนาด(cm)</th>
                          <th class="text-center pb-2 text-nowrap">
                            น้ำหนัก(kg)
                          </th>
                          <th class="text-center pb-2 text-nowrap">
                            ปริมาตร(cbm)
                          </th>
                          <th class="text-center pb-2 text-nowrap">
                            เรทค่าส่ง
                          </th>
                          <th class="text-center pb-2 text-nowrap">คิดตาม</th>
                          <th class="text-center pb-2 text-nowrap">
                            ค่าส่งจีน
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="fw-bolder text-gray-700 fs-6"
                          v-for="item in payshipping.transports"
                          :key="item._id"
                        >
                          <td class="pt-6">
                            <router-link :to="`/forwarder/${item.transportId}`">
                              {{ item.transportNumber }}
                            </router-link>
                          </td>
                          <td class="pt-6 text-center">{{ item.amount }}</td>
                          <td class="pt-6 text-center">
                            {{ item.ship_by === "ek" ? "รถ" : "เรือ" }}
                          </td>
                          <td class="pt-6 text-center text-nowrap">
                            {{
                              `${item.size.width || 0} x ${item.size.long ||
                                0} x ${item.size.height || 0}`
                            }}
                          </td>
                          <td class="pt-6 text-center">
                            {{ format_comma(item.size.weight * item.amount) }}
                          </td>
                          <td class="pt-6 text-center">
                            {{ calculateCube(item.size) }}
                          </td>
                          <td class="pt-6 text-center">
                            {{
                              item.trackingPrice.priceName === "kg"
                                ? item.userLevelPrice.userLevelDetailPriceKg
                                : item.userLevelPrice.userLevelDetailPriceCube
                            }}
                          </td>
                          <td class="pt-6 text-center text-nowrap">
                            {{
                              item.trackingPrice.priceName === "kg"
                                ? "น้ำหนัก"
                                : "ปริมาตร"
                            }}
                          </td>
                          <td class="pt-6 text-right text-dark fw-boldest pr-0">
                            {{ format_comma(item.trackingPrice.priceValue) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--end::Table-->
                  <!--begin::Container-->
                  <div class="d-flex justify-content-end">
                    <!--begin::Section-->
                    <div class="mw-300px">
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountname-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          ค่าขนส่งไทย
                        </div>
                        <!--end::Accountname-->
                        <!--begin::Label-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(payshipping.logistics.price) }} บาท
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountname-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          ค่าบริการแพ็คขนส่ง
                        </div>
                        <!--end::Accountname-->
                        <!--begin::Label-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{
                            format_comma(
                              payshipping.logistics.logisticsServicePrice
                            )
                          }}
                          บาท
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountnumber-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          รวมค่าขนส่งจีน
                        </div>
                        <!--end::Accountnumber-->
                        <!--begin::Number-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(importPrice) }} บาท
                        </div>
                        <!--end::Number-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountnumber-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          รวมค่าตีลังไม้
                        </div>
                        <!--end::Accountnumber-->
                        <!--begin::Number-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(price_wooden_box) }} บาท
                        </div>
                        <!--end::Number-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div
                        v-if="payshipping.minimumPrice"
                        class="d-flex flex-stack mb-3"
                      >
                        <!--begin::Accountnumber-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          ขั้นต่ำ {{ payshipping.minimum }} บาท/บิล :
                        </div>
                        <!--end::Accountnumber-->
                        <!--begin::Number-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(payshipping.minimumPrice) }} บาท
                        </div>
                        <!--end::Number-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack">
                        <!--begin::Code-->
                        <div class="fw-bolder pr-10 text-gray-800 fs-5">
                          รวมเป็นเงิน
                        </div>
                        <!--end::Code-->
                        <!--begin::Label-->
                        <div class="text-right fw-bolder fs-5 text-gray-800">
                          {{ format_comma(payshipping.amount) }} บาท
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Item-->
                    </div>
                    <!--end::Section-->
                  </div>
                  <!--end::Container-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::Invoice 2 content-->
          </div>
          <!--end::Content-->
          <!--begin::Sidebar-->
          <div class="col-lg-4 col-md-12 col-12 pr-0">
            <!--begin::Invoice 2 sidebar-->
            <div
              class="border-width-1 border-dashed border-gray-300 mt-md-8 card-rounded min-w-md-350px p-6 bg-lighten"
            >
              <!--begin::Labels-->
              <div class="mb-8">
                <span class="mr-2 fw-boldest text-gray-800"> สถานะ </span>
                <span
                  class="label label-lg label-inline"
                  v-bind:class="
                    `label-light-${getStatusColor(payshipping.status)}`
                  "
                >
                  {{ payshipping.statusShow }}
                </span>
              </div>
              <!--end::Labels-->
              <!--begin::Title-->
              <h6 class="mb-4 fw-boldest text-gray-600 text-hover-primary">
                ข้อมูลขนส่งไทย
              </h6>
              <!--end::Title-->
              <!--begin::Item-->
              <div class="mb-6">
                <div class="fw-bold text-gray-600 fs-6">บริษัทขนส่ง:</div>
                <div class="fw-bolder text-gray-800 fs-5">
                  {{ payshipping.logistics.logisticsName }}
                </div>
                <div v-if="payshipping.file_provider">
                  <div class="fw-bold text-gray-600 fs-6 mt-6">
                    QR Code Shopee, Lazada:
                  </div>
                  <div class="border symbol symbol-60 flex-shrink-0 mt-2">
                    <img
                      style="object-fit: cover"
                      v-viewer
                      :src="imageUrlApi(payshipping.file_provider)"
                      :key="payshipping.file_provider"
                      height="250"
                      width="250"
                    />
                  </div>
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="mb-2">
                <div class="fw-bold text-gray-600 fs-6">เลขพัสดุไทย:</div>
                <div class="fw-bolder text-gray-800 fs-5">
                  <div
                    v-for="item in payshipping.transportNumberThai"
                    :key="item"
                  >
                    {{ item }}
                  </div>
                  <div v-if="!payshipping.transportNumberThai.length">-</div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="fw-bold text-gray-600 fs-6">ไฟล์แนบ:</div>
                <div class="col-12 mt-1">
                  <div class="uppy">
                    <div class="uppy-list">
                      <div
                        class="uppy-list-item my-1"
                        v-if="payshipping.file_attachment"
                      >
                        <div class="d-flex">
                          <div
                            v-viewer
                            class="border symbol symbol-60 flex-shrink-0 mr-4"
                          >
                            <img
                              style="object-fit: contain"
                              :src="imageUrlApi(payshipping.file_attachment)"
                              height="200"
                              width="200"
                            />
                          </div>
                          <div
                            class="uppy-list-label align-self-center"
                            v-if="payshipping.file_attachment"
                          >
                            {{ getFileName(payshipping.file_attachment) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Title-->
              <h6 class="mb-4 mt-8 fw-boldest text-gray-600 text-hover-primary">
                ข้อมูลการจัดส่งจีน
              </h6>
              <!--end::Title-->
              <!--begin::Item-->
              <div class="mb-6">
                <div class="fw-bold text-gray-600 fs-6">รวมน้ำหนัก:</div>
                <div class="fw-bolder fs-5 text-gray-800">
                  {{ format_comma(all_kg) }} Kg
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="mb-6">
                <div class="fw-bold text-gray-600 fs-6">รวมปริมาตร:</div>
                <div class="fw-bolder text-gray-800 fs-5">
                  {{ format_number_4(all_cube) }} cbm
                </div>
              </div>
              <!--end::Item-->
            </div>
            <div
              class="border-width-1 border-dashed border-gray-300 mt-md-8 card-rounded min-w-md-350px p-6 bg-lighten"
              v-if="payshipping.transactions.length > 0"
            >
              <div class="table-responsive mb-0">
                <table class="table">
                  <thead>
                    <tr>
                      <th colspan="3" class="fs-4 py-2 px-0">ธุรกรรมการเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in payshipping.transactions"
                      :key="item._id"
                    >
                      <td class="px-1 text-nowrap">
                        {{ format_date(item.created_at) }}
                      </td>
                      <td class="px-1 text-nowrap">{{ item.reason }}</td>
                      <td class="text-right px-1 text-nowrap">
                        <span
                          class="font-weight-bolder d-block font-size-lg"
                          v-bind:class="{
                            'text-danger': item.amount < 0,
                            'text-success': item.amount >= 0
                          }"
                        >
                          {{ format_number(item.amount, 2, true) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--end::Invoice 2 sidebar-->
          </div>
          <!--end::Sidebar-->
        </div>
        <!--end::Layout-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import bigDecimal from "js-big-decimal";
import { GET_PAYMENT } from "@/graphql/payshipping";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { calculateCube, WEBSITE, imageUrlApi } from "@/config";

export default {
  name: "shippay_bill_detail",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "รายการใบส่งพัสดุ" }]);
  },
  created() {
    document.title = `ใบส่งพัสดุ | ${WEBSITE}`;
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      price_wooden_box: 0,
      importPrice: 0,
      all_kg: 0,
      all_cube: 0
    };
  },
  methods: {
    getFileName(file) {
      return file.split("/").pop();
    },
    calculateCube,
    imageUrlApi,
    onCompleteTransport() {
      this.$apollo.queries.payshipping.refetch();
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_comma(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    },
    format_number_4(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
      });
    },
    priceLogistics() {
      this.price_wooden_box = this.payshipping.transports.reduce(
        (total, x) => total + x.price_wooden_box ?? 0,
        0
      );
      this.importPrice = this.payshipping.transports.reduce(
        (total, x) => total + x.trackingPrice?.priceValue ?? 0,
        0
      );
      const kg = this.payshipping.transports.reduce(
        (total, x) => total + (x.size?.weight ?? 0 * x.amount),
        0
      );
      const cube = this.payshipping.transports.reduce(
        (total, x) => total + Number(calculateCube(x.size)),
        0
      );
      this.all_kg = kg;
      this.all_cube = cube;
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
        case -1:
          return "default";
        case 0:
          return "warning";
        case 22:
          return "primary";
        case 21:
        case 11:
        case 12:
          return "warning";
        case 101:
        case 102:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    payshipping: {
      query: GET_PAYMENT,
      variables() {
        return {
          id: this.id
        };
      },
      update: ({ payshipping }) => payshipping,
      result({ data, loading }) {
        if (!loading) {
          const { payshipping } = data;
          if (!payshipping) {
            return;
          }
          const { payshipping_doc } = payshipping;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: `รายการใบส่งพัสดุ`, route: "/shippay/bill" },
            { title: `ใบส่งพัสดุ ${payshipping_doc}` }
          ]);
          this.priceLogistics();
          document.title = `ใบส่งพัสดุ ${payshipping_doc} | ${WEBSITE}`;
        }
      }
    }
  }
};
</script>
