<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" @submit.prevent="save" v-if="systemUserLevel">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">สร้างราคาขนส่งจากจีนมาไทย</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group row mb-2">
                <label
                  for="input-userLevelName"
                  class="col-form-label text-right col-md-4 col-5"
                >
                  ชื่อ
                </label>
                <div class="col-lg-5 col-md-9 col-7">
                  <b-form-input
                    id="input-userLevelName"
                    v-model="systemUserLevel.userLevelName"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-is_default"
                  class="col-form-label text-right col-md-4 col-5"
                >
                  ค่าเริ่มต้นตอนสมัคร
                </label>
                <div class="col-lg-5 col-md-9 col-7">
                  <span class="switch switch-sm mt-1">
                    <label class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        id="input-is_default"
                        v-model="systemUserLevel.is_default"
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body mt-4 mb-4">
        <h3>
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              ราคาทางรถ
            </span>
          </div>
        </h3>
        <table class="table table-sm table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="pl-4">ประเภท</th>
              <th>ตัวอักษร</th>
              <th>ราคากิโลกรัม <span class="opacity-50">(บาท)</span></th>
              <th>ราคาคิว. <span class="opacity-50">(บาท)</span></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in systemUserLevel.shippingEk"
              v-bind:key="item._id"
            >
              <td>
                <input
                  v-model="item.userLevelDetailName"
                  :state="nameState"
                  placeholder="ประเภท"
                  required
                  class="form-control"
                />
              </td>
              <td>
                <input
                  v-model="item.userLevelDetailNameShort"
                  :state="nameState"
                  placeholder="ตัวอักษร"
                  required
                  class="form-control"
                />
              </td>
              <td>
                <input
                  v-model="item.userLevelDetailPriceKg"
                  :state="nameState"
                  placeholder="ราคากิโลกรัม"
                  required
                  class="form-control"
                />
              </td>
              <td>
                <input
                  v-model="item.userLevelDetailPriceCube"
                  :state="nameState"
                  placeholder="ราคาคิว."
                  required
                  class="form-control"
                />
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-icon btn-light btn-hover-danger btn-sm"
                  @click="deleteRow(index)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg src="/media/svg/icons/General/Trash.svg" />
                  </span>
                </button>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="
                systemUserLevel.shippingEk && !systemUserLevel.shippingEk.length
              "
            >
              <td colspan="4" class="pt-8">ไม่มีข้อมูล กรุณาเพิ่ม</td>
            </tr>
          </tbody>
        </table>
        <button
          type="button"
          @click="addRow()"
          class="btn btn-block btn-dashed"
        >
          <i class="menu-icon la la-plus icon-xl"></i> เพิ่มราคา
        </button>
      </div>
      <div class="card card-body mt-4 mb-4">
        <h3>
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              ราคาทางเรือ
            </span>
          </div>
        </h3>
        <table class="table table-sm table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="pl-4">ประเภท</th>
              <th>ตัวอักษร</th>
              <th>ราคากิโลกรัม <span class="opacity-50">(บาท)</span></th>
              <th>ราคาคิว. <span class="opacity-50">(บาท)</span></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in systemUserLevel.shippingShip"
              v-bind:key="item._id"
            >
              <td>
                <input
                  v-model="item.userLevelDetailName"
                  :state="nameState"
                  placeholder="ประเภท"
                  required
                  class="form-control"
                />
              </td>
              <td>
                <input
                  v-model="item.userLevelDetailNameShort"
                  :state="nameState"
                  placeholder="ตัวอักษร"
                  required
                  class="form-control"
                />
              </td>
              <td>
                <input
                  v-model="item.userLevelDetailPriceKg"
                  :state="nameState"
                  placeholder="ราคากิโลกรัม"
                  required
                  class="form-control"
                />
              </td>
              <td>
                <input
                  v-model="item.userLevelDetailPriceCube"
                  :state="nameState"
                  placeholder="ราคาคิว."
                  required
                  class="form-control"
                />
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-icon btn-light btn-hover-danger btn-sm"
                  @click="deleteRowShip(index)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg src="/media/svg/icons/General/Trash.svg" />
                  </span>
                </button>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="
                systemUserLevel.shippingShip &&
                  !systemUserLevel.shippingShip.length
              "
            >
              <td colspan="4" class="pt-8">ไม่มีข้อมูล กรุณาเพิ่ม</td>
            </tr>
          </tbody>
        </table>
        <button
          type="button"
          @click="addRowShip()"
          class="btn btn-block btn-dashed"
        >
          <i class="menu-icon la la-plus icon-xl"></i> เพิ่มราคา
        </button>
      </div>
      <div class="card card-body">
        <div class="row">
          <div class="col-lg-10 ml-lg-auto">
            <button
              type="submit"
              class="btn btn-success mr-4"
              style="min-width: 140px"
            >
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/General/Save.svg" />
              </span>
              บันทึกขนส่งจากจีนมาไทย
            </button>
            <router-link
              to="/admin/setting/china-to-thai"
              class="btn btn-secondary"
              style="width: 140px"
            >
              ยกเลิก
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import uuidv4 from "uuid/v4";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { CREATE_USER_LEVEL } from "@/graphql/admin/user_level";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `เพิ่มขนส่งจากจีนมาไทย | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ขนส่งจากจีนมาไทย`, route: "/admin/setting/china-to-thai" },
      {
        title: `เพิ่มขนส่งจากจีนมาไทย`
      }
    ]);
  },
  validations: {
    systemUserLevel: {
      userLevelName: {
        required
      },
      is_default: {
        required
      },
      shippingEk: {
        required
      },
      shippingShip: {
        required
      }
    }
  },
  data() {
    return {
      nameState: null,
      systemUserLevel: {
        userLevelName: "",
        is_default: false,
        shippingEk: [],
        shippingShip: []
      }
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    async save() {
      if (!this.checkFormValidity()) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const _id = this.systemUserLevel._id;
      const userLevelId = this.systemUserLevel.userLevelId;
      const userLevelName = this.$v.systemUserLevel.userLevelName.$model;
      const is_default = this.systemUserLevel.is_default;
      const shippingEk = this.$v.systemUserLevel.shippingEk.$model;
      const shippingShip = this.$v.systemUserLevel.shippingShip.$model;

      const input = {
        _id,
        userLevelId,
        userLevelName,
        shippingEk,
        shippingShip,
        is_default
      };

      input.shippingEk = input.shippingEk.map(x => ({
        userLevelDetailName: x.userLevelDetailName,
        userLevelDetailNameShort: x.userLevelDetailNameShort,
        userLevelDetailShipping: x.userLevelDetailShipping,
        userLevelDetailPriceKg: Number(x.userLevelDetailPriceKg),
        userLevelDetailPriceCube: Number(x.userLevelDetailPriceCube)
      }));
      input.shippingShip = input.shippingShip.map(x => ({
        userLevelDetailName: x.userLevelDetailName,
        userLevelDetailNameShort: x.userLevelDetailNameShort,
        userLevelDetailShipping: x.userLevelDetailShipping,
        userLevelDetailPriceKg: Number(x.userLevelDetailPriceKg),
        userLevelDetailPriceCube: Number(x.userLevelDetailPriceCube)
      }));

      const {
        data: { createUserLevel }
      } = await this.$apollo.mutate({
        mutation: CREATE_USER_LEVEL,
        variables: {
          input
        }
      });
      if (createUserLevel.is_error) {
        this.$message.error(
          createUserLevel.message ||
            (createUserLevel.error_list &&
              createUserLevel.error_list.map(x => x.message).join())
        );
      } else {
        this.$message.success(
          createUserLevel.message ||
            (createUserLevel.error_list &&
              createUserLevel.error_list.map(x => x.message).join())
        );
        this.$router.push("/admin/setting/china-to-thai");
      }
    },
    addRow() {
      if (!this.systemUserLevel.shippingEk) {
        this.systemUserLevel.shippingEk = [];
      }
      this.systemUserLevel.shippingEk.push({
        _id: uuidv4(),
        userLevelDetailName: "",
        userLevelDetailNameShort: "",
        userLevelDetailPriceKg: 0,
        userLevelDetailPriceCube: 0,
        userLevelDetailShipping: "ek"
      });
    },
    deleteRow(index) {
      this.systemUserLevel.shippingEk.splice(index, 1);
    },
    addRowShip() {
      if (!this.systemUserLevel.shippingShip) {
        this.systemUserLevel.shippingShip = [];
      }
      this.systemUserLevel.shippingShip.push({
        _id: uuidv4(),
        userLevelDetailName: "",
        userLevelDetailNameShort: "",
        userLevelDetailPriceKg: 0,
        userLevelDetailPriceCube: 0,
        userLevelDetailShipping: "ship"
      });
    },
    deleteRowShip(index) {
      this.systemUserLevel.shippingShip.splice(index, 1);
    }
  }
};
</script>
