<template>
  <div>
    <div class="row">
      <div class="col-xl-3">
        <!--begin::Stats Widget 29-->
        <div
          class="card card-custom bgi-no-repeat card-stretch gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(/media/svg/shapes/abstract-1.svg);
          "
        >
          <!--begin::Body-->
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <span class="svg-icon svg-icon-2x">
                <inline-svg src="/media/svg/icons/Shopping/Wallet.svg" />
              </span>
            </span>
            <span
              v-if="cash"
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
            >
              ฿
              {{
                cash.baht.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }}
            </span>
            <span class="font-weight-bold text-muted font-size-sm">
              เงินในกระเป๋า
            </span>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 29-->
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <b-form class="form" ref="form" @submit.stop.prevent="do_save">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <h3 class="text-dark-75 font-weight-bold mb-8">ฝากจ่ายเถาเป่า</h3>
            <div class="form-group row">
              <label
                class="col-form-label text-sm-right col-md-2 col-sm-3 col-12"
                >ประเภท</label
              >
              <div
                class="col-lg-8 col-9 d-flex align-items-center"
                :class="{
                  'radio-invalid': validateState('typeTaobao') === false
                }"
              >
                <div
                  class="radio-list"
                  v-if="taobao_accounts && taobao_accounts.records"
                >
                  <template v-for="item in taobao_accounts.records">
                    <label class="radio" v-bind:key="item._id">
                      <input
                        type="radio"
                        name="type"
                        :value="item.taobaoAccountId"
                        v-model="form.typeTaobao"
                      />
                      <span></span>
                      {{ item.account_desc }}
                    </label>
                  </template>
                  <label class="radio">
                    <input
                      type="radio"
                      name="type"
                      value="login"
                      ref="typeTaobao"
                      v-model="form.typeTaobao"
                    />
                    <span></span>
                    ล็อคอินเข้าไปจ่ายให้
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group row mb-3" v-if="form.typeTaobao === 'login'">
              <label
                for="input-taobao_username"
                class="col-form-label text-sm-right col-md-2 col-sm-3 col-12"
              >
                ไอดีเถาเปา
              </label>
              <div class="col-md-4 col-sm-8 col-12">
                <b-form-input
                  id="input-taobao_username"
                  ref="taobao_username"
                  v-model="form.taobao_username"
                  :state="validateState('taobao_username')"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row mb-3" v-if="form.typeTaobao === 'login'">
              <label
                for="input-taobao_password"
                class="col-form-label text-right col-md-2 col-sm-3 col-12"
              >
                รหัสผ่านเถาเปา
              </label>
              <div class="col-md-4 col-sm-8 col-12">
                <b-form-input
                  id="input-taobao_password"
                  ref="taobao_password"
                  v-model="form.taobao_password"
                  :state="validateState('taobao_password')"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row mb-3">
              <label
                for="input-remark"
                class="col-form-label text-sm-right col-md-2 col-sm-3 col-12"
              >
                บันทึกช่วยจำ
              </label>
              <div class="col-md-4 col-sm-8 col-12">
                <b-form-textarea
                  id="input-remark"
                  v-model="form.remark"
                  :state="validateState('remark')"
                  rows="2"
                  placeholder=""
                ></b-form-textarea>
              </div>
            </div>
            <b-form-group
              label-cols="12"
              label-cols-sm="3"
              label-cols-md="2"
              label-align-sm="right"
              content-cols="8"
              content-cols-sm="7"
              content-cols-lg="9"
              content-cols-xl="9"
              label="รายการฝากจ่าย"
            >
              <table class="table table-bordered table-sku table-sm">
                <thead>
                  <tr>
                    <th class="text-center py-2">ลำดับ</th>
                    <th class="text-center py-2">รายการสั่งซื้อ</th>
                    <th class="text-center py-2">ราคา ¥</th>
                    <th class="text-center py-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in form.detail"
                    :key="item.id"
                    class="text-center"
                  >
                    <td class="align-middle" style="width: 10%;">
                      {{ index + 1 }}
                    </td>
                    <td class="p-0" style="width: 60%;">
                      <b-form-input
                        class="input-sku"
                        :ref="`detail.${index}.order_number`"
                        :state="validateStateDetail(index, 'order_number')"
                        v-model="form.detail[index].order_number"
                      />
                    </td>
                    <td class="p-0" style="width: 20%;">
                      <b-form-input
                        class="input-sku text-right"
                        :ref="`detail.${index}.price`"
                        v-model="form.detail[index].price"
                        :state="validateStateDetail(index, 'price')"
                        type="number"
                      />
                    </td>
                    <td class="align-middle" style="width: 10%;">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm"
                        style="max-height: 28px;"
                        @click="deleteRowDetail(item)"
                        v-if="index > 0"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-danger">
                          <i class="la la-trash icon-lg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                type="button"
                @click="addRowDetail()"
                class="btn btn-block btn-dashed btn-sm"
              >
                <i class="menu-icon la la-plus icon-xl"></i> เพิ่มแถว
              </button>
              <div class="mt-4">
                <div class="row py-2">
                  <div class="col-md-9 col-sm-6 col-6 text-right">
                    ยอดเงินหยวน {{ display_yuan }}&nbsp;:
                  </div>
                  <div class="col-md-3 col-sm-6 col-6 text-right">
                    {{ format_comma(total_yuan) }}
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-md-9 col-sm-6 col-6 text-right">
                    ค่าบริการ ({{ form.detail.length }} x
                    {{ service_taobao }})&nbsp;:
                  </div>
                  <div class="col-md-3 col-sm-6 col-6 text-right">
                    {{ format_comma(total_service_taobao) }}
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-md-9 col-sm-6 col-6 text-right">
                    รวมยอดที่ต้องชำระ&nbsp;:
                  </div>
                  <div class="col-md-3 col-sm-6 col-6 text-right">
                    {{ format_comma(total) }}
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 ml-lg-auto">
                <button
                  class="btn btn-success mr-2"
                  type="submit"
                  style="width: 144px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/General/Save.svg" />
                  </span>
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { minValue } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import uuidv4 from "uuid/v4";
import { GET_CASH } from "@/graphql/profile";
import {
  CREATE_TAOBAO,
  GET_TAOBAO_ACCOUNTS,
  GET_SETTING_SERVICE_TAOBAO
} from "@/graphql/taobao";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { multipy } from "@/config";
import { WEBSITE } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `เพิ่มฝากจ่ายเถาเป่า | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ฝากจ่ายเถาเป่า`, route: "/taobao" },
      { title: `สร้างฝากจ่ายเถาเป่า` }
    ]);
  },
  data() {
    return {
      previewImage: null,
      valid: false,
      loading: false,
      service_taobao: 0,
      form: {
        typeTaobao: "",
        taobao_username: "",
        taobao_password: "",
        remark: "",
        detail: [{ id: uuidv4(), price: 0, order_number: "" }]
      }
    };
  },
  validations: {
    form: {
      typeTaobao: { required },
      taobao_username: {
        isValid: (value, form) => {
          return form.typeTaobao === "login" ? required(value) : true;
        }
      },
      taobao_password: {
        isValid: (value, form) => {
          return form.typeTaobao === "login" ? required(value) : true;
        }
      },
      detail: {
        $each: {
          order_number: { required },
          price: { required, minValue: minValue(0.01) }
        }
      },
      remark: {}
    }
  },
  computed: {
    display_yuan() {
      let total = 0;
      this.form.detail.forEach(item => {
        total += item.price;
      });
      return `(${this.format_comma(total)} x ${this.cash?.rateTaobao ?? 0})`;
    },
    total_yuan() {
      let total = 0;
      this.form.detail.forEach(item => {
        total += multipy(this.cash?.rateTaobao ?? 0, item.price);
      });
      return total;
    },
    total_service_taobao() {
      return this.service_taobao * this.form.detail.length;
    },
    total() {
      return this.total_yuan + this.total_service_taobao;
    }
  },
  methods: {
    format_comma(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];

      return $dirty ? !$error : null;
    },
    validateStateDetail(index, name) {
      const { $dirty, $error } = this.$v.form.detail.$each[index][name];
      return $dirty ? !$error : null;
    },
    deleteRowDetail(item) {
      this.form.detail = this.form.detail.filter(x => x.id !== item.id);
    },
    scrollToFirstError() {
      const keys = Object.keys(this.$v.form);

      for (let key in keys) {
        const input = keys[key];
        if (input.includes("$") || input === "detail") continue;
        if (this.$v.form[input].$error) {
          this.$refs[input]?.focus();
          return false;
        }
      }

      for (let key in this.$v.form.detail.$each) {
        const item = this.$v.form.detail.$each[key];
        if (item.order_number?.$error) {
          this.$refs[`detail.${key}.order_number`]?.[0]?.focus();
          return false;
        }
        if (item.price?.$error) {
          this.$refs[`detail.${key}.price`]?.[0]?.focus();
          return false;
        }
      }
    },
    addRowDetail() {
      this.form.detail.push({ id: uuidv4(), price: 0, order_number: "" });
    },
    async do_save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$nextTick(() => this.scrollToFirstError());
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันการฝากจ่ายเถาเป่า?",
        icon: "question",
        html: `กรุณาตรวจสอบ รายการ ก่อนบันทึกทุกครั้ง`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        typeTaobao: this.form.typeTaobao === "login" ? "login" : "sent",
        detail: this.form.detail,
        remark: this.form.remark
      };
      if (this.form.typeTaobao === "login") {
        input.account_name = this.form.taobao_username;
        input.taobao_password = this.form.taobao_password;
      } else {
        this.taobao_accounts.records.forEach(item => {
          if (item.taobaoAccountId === this.form.typeTaobao) {
            input.account_name = item.account_name;
            input.account_desc = item.account_desc;
          }
        });
      }
      input.detail = input.detail.map(item => {
        return {
          order_number: item.order_number,
          price: Number(item.price)
        };
      });

      this.loading = true;

      try {
        const {
          data: { createTaobao }
        } = await this.$apollo.mutate({
          mutation: CREATE_TAOBAO,
          variables: {
            input
          }
        });
        this.loading = false;
        if (!createTaobao.isSuccess) {
          this.$message.error(
            createTaobao.message ||
              (createTaobao.error_list &&
                createTaobao.error_list.map(x => x.message).join())
          );
        } else {
          this.$message.success(createTaobao.message);
          this.$router.push({ name: "taobao_list" });
        }
      } catch (error) {
        this.loading = false;
        this.$message.error(error.message);
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
    }
  },
  apollo: {
    settingServiceTaobao: {
      query: GET_SETTING_SERVICE_TAOBAO,
      result({ data }) {
        if (data) {
          this.service_taobao = data.settingServiceTaobao.service_taobao;
        }
      }
    },
    taobao_accounts: {
      query: GET_TAOBAO_ACCOUNTS
    },
    cash: {
      query: GET_CASH
    }
  }
};
</script>
