<template>
  <div v-if="systemUserLevelList">
    <div class="form-group row">
      <label class="col-form-label col-3 col-lg-2 text-lg-right text-left"
        >เลือกราคาขนส่ง</label
      >
      <div class="col-5">
        <select
          type="text"
          class="form-control form-select"
          :state="nameUserLevelState"
          v-model="user.userLevelId"
          @change="onChange"
          required
        >
          <option
            v-for="item in systemUserLevelList.records"
            v-bind:key="item._id"
            :value="item.userLevelId"
          >
            {{ item.userLevelName }}
          </option>
        </select>
      </div>
    </div>
    <h3>
      <div class="d-flex align-items-center" style="height: 35px">
        <span class="card-label font-weight-bolder text-dark"> ราคาทางรถ </span>
      </div>
    </h3>
    <table
      v-if="systemUserLevel && systemUserLevel.shippingEk"
      class="table table-vertical-center mb-0"
    >
      <thead>
        <tr>
          <th>ประเภท</th>
          <th>ราคากิโลกรัม <span class="opacity-50">(บาท)</span></th>
          <th>ราคาคิว. <span class="opacity-50">(บาท)</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in systemUserLevel.shippingEk" v-bind:key="item._id">
          <td>
            {{ item.userLevelDetailName }}
          </td>
          <td>
            {{ item.userLevelDetailPriceKg }}
          </td>
          <td>
            {{ item.userLevelDetailPriceCube }}
          </td>
        </tr>
      </tbody>
    </table>
    <h3>
      <div class="d-flex align-items-center" style="height: 35px">
        <span class="card-label font-weight-bolder text-dark">
          ราคาทางเรือ
        </span>
      </div>
    </h3>
    <table
      v-if="systemUserLevel && systemUserLevel.shippingShip"
      class="table table-vertical-center mb-0"
    >
      <thead>
        <tr>
          <th>ประเภท</th>
          <th>ราคากิโลกรัม <span class="opacity-50">(บาท)</span></th>
          <th>ราคาคิว. <span class="opacity-50">(บาท)</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in systemUserLevel.shippingShip" v-bind:key="item._id">
          <td>
            {{ item.userLevelDetailName }}
          </td>
          <td>
            {{ item.userLevelDetailPriceKg }}
          </td>
          <td>
            {{ item.userLevelDetailPriceCube }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import {
  GET_USER_LEVEL,
  GET_USER_LEVEL_LIST
} from "@/graphql/admin/user_level";

export default {
  mixins: [validationMixin],
  props: ["data", "onChange"],
  data() {
    return {
      nameUserLevelState: null,
      user: {
        userId: this.data?.userId,
        userLevelId: this.data?.userLevelId
      }
    };
  },
  validations: {
    user: {
      userLevelId: {}
    }
  },
  watch: {
    data: function(newVal) {
      this.user.userLevelId = newVal.userLevelId;
      this.user.userId = newVal.userId;
    }
  },
  apollo: {
    systemUserLevelList: {
      query: GET_USER_LEVEL_LIST
    },
    systemUserLevel: {
      query: GET_USER_LEVEL,
      skip() {
        return !this || !this.user || !this.user.userLevelId;
      },
      variables() {
        return {
          id: this.user && this.user.userLevelId
        };
      }
    }
  }
};
</script>
