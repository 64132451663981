<template>
  <b-modal id="track-modal" centered title="เพิ่มเลขพัสดุ" @ok="handleOk">
    <b-overlay :show="$apollo.loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="orderAddTracking">
        <div class="form-group row">
          <label
            for="input-transportNumber"
            class="col-form-label text-right col-lg-3 col-sm-12"
            >เลขพัสดุ</label
          >
          <div class="col-lg-5 col-md-9 col-sm-12">
            <b-form-input
              id="input-transportNumber"
              v-model="form.transportNumber"
              required
              :state="nameState"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <div class="form-group row">
          <label
            for="input-amount"
            class="col-form-label text-right col-lg-3 col-sm-12"
            >ตีลังไม้</label
          >
          <div class="col-lg-5 col-md-9 col-sm-12">
            <span class="switch switch-sm mt-1">
              <label class="d-flex align-items-center">
                <input
                  type="checkbox"
                  v-model="form.guarantee"
                  :checked="form.guarantee"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { ORDER_ADD_TRACKING } from "@/graphql/admin/order";
export default {
  props: ["orderId", "refetch"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        transportNumber: "",
        guarantee: false
      },
      nameState: null
    };
  },
  validations: {
    form: {
      transportNumber: {
        required
      },
      guarantee: {}
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.orderAddTracking();
    },
    async orderAddTracking() {
      if (!this.checkFormValidity()) {
        return;
      }
      const transportNumber = this.$v.form.transportNumber.$model;
      const guarantee = this.$v.form.guarantee.$model;
      const input = {
        orderId: Number(this.orderId),
        transportNumber: transportNumber,
        guarantee: guarantee
      };
      const {
        data: { orderAddTracking }
      } = await this.$apollo.mutate({
        mutation: ORDER_ADD_TRACKING,
        variables: { ...input }
      });
      if (!orderAddTracking.isSuccess) {
        this.$message.error(orderAddTracking.message);
      } else {
        this.$message.success(orderAddTracking.message);
        if (this.refetch) {
          this.refetch();
        }
        this.$nextTick(() => {
          this.$bvModal.hide("track-modal");
        });
      }
    }
  }
};
</script>
