<template>
  <div class="card card-custom card-stretch">
    <div class="card-header border-0 pt-5" v-if="getPayshippingItems">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            พัสดุที่ต้องชำระเงิน
          </span>
          <span class="text-muted font-weight-bold font-size-sm">
            Total {{ getPayshippingItems.length }} transports
          </span>
        </div>
      </h3>
    </div>
    <div class="card-body pt-0 pb-6">
      <b-alert
        v-if="!getPayshippingItems || !getPayshippingItems.length"
        show
        variant="warning"
        >ไม่มีรายการ</b-alert
      >
      <b-overlay :show="loading" rounded="sm">
        <div class="table-responsive">
          <table
            class="table table-borderless table-vertical-center mb-8 table-sm"
            v-if="getPayshippingItems"
          >
            <thead>
              <tr>
                <th><span class="text-dark-75">เลขพัสดุ</span></th>
                <th class="text-center">
                  <span class="text-dark-75">ประเภทสินค้า</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ถึงโกดังปลายทาง</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">จำนวน</span>
                </th>
                <th class="text-center text-nowrap">
                  <span class="text-dark-75">น้ำหนัก</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ขนาด</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">CBM</span>
                </th>
                <th class="text-center text-nowrap">
                  <span class="text-dark-75">ตีลังไม้</span>
                </th>
                <th class="text-center text-nowrap">
                  <span class="text-dark-75">ค่าตีลังไม้</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ขนส่ง</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ล็อต</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ราคา</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getPayshippingItems" v-bind:key="item._id">
                <td>
                  <router-link
                    target="_blank"
                    :to="`/forwarder/${item.transportId}`"
                  >
                    {{ item.transportNumber }}
                  </router-link>
                </td>
                <td class="text-center">
                  {{
                    item.userLevelPrice
                      ? item.userLevelPrice.userLevelDetailName
                      : "-"
                  }}
                </td>
                <td class="text-center">
                  {{
                    format_date(
                      item.timeline ? item.timeline.send_to_warehouse_th : ""
                    )
                  }}
                </td>
                <td class="text-center">
                  {{ item.amount }}
                </td>
                <td class="text-center">
                  {{
                    item.size.weight
                      ? format_number_pretty(item.size.weight * item.amount, 2)
                      : "-"
                  }}
                </td>
                <td class="text-center text-nowrap">
                  {{
                    `${item.size.width || 0} x ${item.size.long || 0} x ${item
                      .size.height || 0}`
                  }}
                </td>
                <td class="text-center">
                  {{ calculateCube(item.size, item.amount) }}
                </td>
                <td class="text-center">
                  <span class="text-success" v-if="item.guarantee">เปิด</span>
                  <span class="text-danger" v-if="!item.guarantee">ปิด</span>
                </td>
                <td class="text-center">
                  {{ item.price_wooden_box }}
                </td>
                <td class="text-center">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="
                      `label-light-${
                        item.ship_by === 'ek' ? 'success' : 'primary'
                      }`
                    "
                  >
                    {{ item.ship_by === "ek" ? "รถ" : "เรือ" }}
                  </span>
                </td>
                <td class="text-center">
                  {{ item.lotId }}
                </td>
                <td
                  class="text-center text-nowrap"
                  :class="item?.trackingPrice?.is_minimum ? 'text-danger' : ''"
                >
                  <div v-if="item.trackingPrice">
                    {{
                      item.trackingPrice.priceName === "kg"
                        ? "กิโล : "
                        : "ขนาด : "
                    }}
                    {{ item.trackingPrice.priceValue }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-form class="form" @submit.stop.prevent="do_save">
          <div class="row mt-4">
            <div class="col-lg-12">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                  >บริษัทขนส่ง</label
                >
                <div class="col-md-6 col-sm-6 col-12">
                  <b-form-select
                    v-model="form.logistics"
                    v-if="logisticsList"
                    :disabled="file"
                    @change="priceLogistics"
                    ref="logistics"
                    :state="validateState('logistics')"
                    :options="[
                      {
                        value: '',
                        text: 'เลือกบริษัทขนส่ง',
                        disabled: true
                      },
                      ...logisticsList.records.map(x => ({
                        value: x,
                        text: x.logisticsName
                      }))
                    ]"
                  ></b-form-select>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                  >หมายเหตุ</label
                >
                <div class="col-md-6 col-sm-6 col-12">
                  <b-form-input v-model="form.remark" />
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="form-group row mb-0">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                >
                  แนบไฟล์ Dropship
                </label>
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="uppy">
                    <div class="uppy-wrapper">
                      <div class="uppy-Root uppy-FileInput-container">
                        <input
                          class="uppy-FileInput-input uppy-input-control"
                          ref="fileInput"
                          type="file"
                          name="files[]"
                          accept="image/*"
                          multiple=""
                          @input="pickFile"
                          id="file"
                          style=""
                        />
                        <label
                          class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                          for="file"
                        >
                          เลือกไฟล์
                        </label>
                      </div>
                    </div>
                    <div class="uppy-list">
                      <div class="uppy-list-item" v-if="previewImage">
                        <div
                          v-viewer
                          class="border symbol symbol-60 flex-shrink-0 mr-4"
                        >
                          <img
                            style="object-fit: contain"
                            :src="previewImage"
                            height="200"
                            width="200"
                          />
                        </div>
                        <div class="uppy-list-label">
                          {{ file.name }}
                        </div>
                        <span class="uppy-list-remove" @click="removeFile()">
                          <i class="flaticon2-cancel-music"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <span class="form-text text-muted">
                    Max file size is 20MB.
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                ></label>
                <div class="col-md-6 col-sm-6 col-12">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    v-b-modal.address-modal
                  >
                    เลือกที่อยู่จัดส่ง
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                  >ชื่อ - นามสกุล</label
                >
                <div class="col-md-6 col-sm-6 col-12">
                  <b-form-input
                    v-model="form.address.name"
                    :state="validateStateAddress('name')"
                    ref="address.name"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                  >รายละเอียดที่อยู่</label
                >
                <div class="col-md-6 col-sm-6 col-12">
                  <b-form-input
                    v-model="form.address.address"
                    :state="validateStateAddress('address')"
                    ref="address.address"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row mb-2 mt-2">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                ></label>
                <div class="col-md-6 col-sm-6 col-12 d-flex" style="gap: 6px">
                  <addressinput-subdistrict
                    v-model="form.address.district"
                    :class="validateStateAddressClassName('district')"
                    placeholder="ตำบล"
                    ref="address.district"
                  />
                  <addressinput-district
                    v-model="form.address.amphoe"
                    :class="validateStateAddressClassName('amphoe')"
                    placeholder="อำเภอ"
                    ref="address.amphoe"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                ></label>
                <div class="col-md-6 col-sm-6 col-12 d-flex" style="gap: 6px">
                  <addressinput-province
                    v-model="form.address.province"
                    :class="validateStateAddressClassName('province')"
                    placeholder="จังหวัด"
                    ref="address.province"
                  />
                  <addressinput-zipcode
                    placeholder="รหัสไปรษณีย์"
                    ref="address.postcode"
                    :class="validateStateAddressClassName('postcode')"
                    v-model="form.address.postcode"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row mb-4">
                <label
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                  >เบอร์โทรศัพท์</label
                >
                <div class="col-md-6 col-sm-6 col-12">
                  <b-form-input
                    v-model="form.address.phone"
                    ref="address.phone"
                    :state="validateStateAddress('phone')"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row mt-4">
                <label
                  for="input-ship_by"
                  class="col-form-label text-sm-right col-md-3 col-sm-4 col-12"
                ></label>
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="row">
                    <div class="col-md-6">
                      <table
                        class="table table-borderless"
                        aria-describedby="ข้อมูลการจัดส่งจีน"
                      >
                        <thead>
                          <tr>
                            <th
                              class="fs-5 pl-0"
                              colspan="2"
                              style="width: 50%"
                            >
                              ข้อมูลการจัดส่งจีน
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="pl-0">รวมน้ำหนัก :</td>
                            <td class="text-right px-0">
                              {{ format_number_pretty(all_kg, 2) }} Kg
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0">รวมปริมาตร :</td>
                            <td class="text-right px-0">
                              {{ format_number_pretty(all_cube, 2) }} cbm
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0">รวมค่าขนส่งจีน :</td>
                            <td class="text-right px-0">
                              {{ format_number_pretty(importPrice, 2) }} บาท
                            </td>
                          </tr>
                          <tr v-if="minimumPrice">
                            <td class="pl-0">
                              ขั้นต่ำ {{ minimumPayshipping.minimum }} บาท/บิล :
                            </td>
                            <td class="text-right px-0">
                              {{ format_number_pretty(minimumPrice, 2) }} บาท
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-6">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th
                              class="fs-5 pl-0"
                              colspan="2"
                              style="width: 50%"
                            >
                              ข้อมูลขนส่งไทย
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="pl-0 text-nowrap">บริษัทขนส่ง :</td>
                            <td class="text-right px-0">
                              {{
                                form.logistics
                                  ? form.logistics.logisticsName
                                  : "-"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0 text-nowrap">
                              ค่าบริการแพ็คขนส่ง :
                            </td>
                            <td class="text-right px-0">
                              {{ format_number_pretty(logisticsServicePrice) }}
                              บาท
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0 text-nowrap">ค่าขนส่งไทย :</td>
                            <td class="text-right px-0">
                              {{ format_number_pretty(deliveryPrice) }} บาท
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="dashed mb-8"></div>
                  <div class="bg-light p-2 mb-4 rounded text-right fs-2">
                    ยอดรวมที่ต้องชำระ :
                    {{ " " }}
                    {{
                      total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                    บาท
                  </div>
                  <div class="text-right">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      style="min-width: 140px"
                    >
                      <span class="svg-icon svg-icon-md">
                        <inline-svg
                          src="/media/svg/icons/Shopping/Dollar.svg"
                        />
                      </span>
                      ชำระค่าบริการนำเข้าสินค้า
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </b-overlay>
    </div>
    <b-modal
      id="address-modal"
      centered
      title="เลือกที่อยู่จัดส่ง"
      size="lg"
      ok-title="ปิด"
      ok-only
      ok-variant=""
    >
      <modal-address
        v-if="profile"
        :list="addressList"
        :onSelect="onSelectAddress"
      />
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { CREATE_PAYMENT } from "@/graphql/payshipping";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import bigDecimal from "js-big-decimal";
import { calculateCube, getPriceLogistics } from "@/config";
import { GET_LOGISTICS_LIST_SELECT } from "@/graphql/delivery";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_PROFILE_ADDRESS } from "@/graphql/profile";
import { UPDATE_PAYSHIPPING } from "@/core/services/store/payshipping.module.js";
import ModalAddress from "@/components/ModalAddress.vue";
import { MINIMUM_PAYSHIPPING } from "@/graphql/admin/setting";

export default {
  components: { ModalAddress },
  mixins: [validationMixin],
  created() {
    if (!this.getPayshippingItems || !this.getPayshippingItems.length) {
      this.$router.push("/forwarder/payshipping/create");
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการพัสดุ`, route: "/forwarder" },
      { title: `ที่ต้องชำระ`, route: "/forwarder/payshipping/create" },
      { title: `ชำระเงิน` }
    ]);
  },
  mounted() {
    this.priceLogistics();
  },
  data() {
    return {
      previewImage: null,
      file: null,
      importPrice: 0,
      logisticsServicePrice: 0,
      deliveryPrice: 0,
      total: 0,
      addressList: [],
      all_kg: 0,
      all_cube: 0,
      loading: false,
      minimumPrice: 0,
      form: {
        logistics: "",
        remark: "",
        address: {
          name: "",
          address: "",
          province: "",
          amphoe: "",
          district: "",
          postcode: "",
          phone: ""
        }
      }
    };
  },
  validations() {
    if (!this.file) {
      return {
        form: {
          address: {
            name: { required },
            address: { required },
            province: { required },
            amphoe: { required },
            district: { required },
            postcode: { required },
            phone: { required }
          },
          remark: {},
          logistics: { required }
        }
      };
    } else {
      return {
        form: {
          address: {
            name: { required },
            address: { required },
            province: { required },
            amphoe: { required },
            district: { required },
            postcode: { required },
            phone: { required }
          },
          remark: {},
          logistics: {}
        }
      };
    }
  },
  methods: {
    calculateCube,
    scrollToFirstError() {
      const keys = Object.keys(this.$v.form);

      for (let key in keys) {
        const input = keys[key];
        if (input.includes("$")) continue;
        if (this.$v.form[input].$error) {
          this.$refs[input]?.focus();

          const child_keys = Object.keys(this.$v.form[input]);
          for (let child in child_keys) {
            const child_input = child_keys[child];

            if (child_input.includes("$")) continue;
            if (this.$v.form[input][child_input].$error) {
              this.$refs[`${input}.${child_input}`]?.focus();
            }
          }
          break;
        }
      }
    },
    onSelectAddress(item) {
      this.form.address = item;
    },
    validateStateAddressClassName(name) {
      const { $dirty, $error } = this.$v.form["address"][name];
      return $dirty ? (!$error ? "is-valid" : "is-invalid") : "";
    },
    validateStateAddress(name) {
      const { $dirty, $error } = this.$v.form["address"][name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    priceLogistics() {
      this.logisticsServicePrice =
        this.form.logistics?.logisticsServicePrice ?? 0;

      if (this.file) {
        this.logisticsServicePrice += Number(
          this.logisticsList.servicePriceDropship
        );
      }
      this.importPrice = this.getPayshippingItems.reduce(
        (total, x) =>
          total +
          (x.trackingPrice?.priceValue ?? 0) +
          (x.price_wooden_box ?? 0),
        0
      );
      const kg = this.getPayshippingItems.reduce(
        (total, x) => total + (x.size?.weight ?? 0) * x.amount,
        0
      );
      const cube = this.getPayshippingItems.reduce(
        (total, x) => total + Number(calculateCube(x.size, x.amount)),
        0
      );
      const price = getPriceLogistics(this.form.logistics, { kg });

      this.total =
        price +
        this.logisticsServicePrice +
        this.importPrice +
        this.minimumPrice;
      this.deliveryPrice = price;
      this.all_kg = kg;
      this.all_cube = cube;
    },
    async do_save() {
      if (!this.getPayshippingItems.length) {
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$nextTick(() => this.scrollToFirstError());
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันชำระค่าบริการค่านำเข้า",
        icon: "question",
        html: `ชำระเงิน จำนวน ${this.format_number(this.total, 2)}฿`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      this.loading = true;

      const address = this.$v.form.address.$model;
      const logistics = this.$v.form.logistics.$model;
      const remark = this.$v.form.remark.$model;

      const input = {
        address,
        remark,
        transports: this.getPayshippingItems.map(x => x.transportId),
        file_provider_stream: this.file,
        logistics: {
          logisticsId: logistics.logisticsId
        }
      };
      const {
        data: { transportPay }
      } = await this.$apollo.mutate({
        mutation: CREATE_PAYMENT,
        variables: {
          input: input
        }
      });
      this.loading = false;
      this.$nextTick(() => {
        if (!transportPay.isSuccess) {
          this.$message.error(transportPay.message);
        } else {
          this.$message.success(transportPay.message);
          this.$store.dispatch(UPDATE_PAYSHIPPING, []);
          this.$router.push("/shippay/bill");
        }
      });
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val, digits = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.form.logistics = "";
        this.$emit("input", file[0]);
        this.priceLogistics();
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
      this.priceLogistics();
    }
  },
  computed: {
    ...mapGetters(["getPayshippingItems"])
  },
  apollo: {
    logisticsList: {
      query: GET_LOGISTICS_LIST_SELECT
    },
    minimumPayshipping: {
      query: MINIMUM_PAYSHIPPING,
      result({ data, loading }) {
        if (!loading) {
          const { minimumPayshipping } = data;
          const { minimum, price } = minimumPayshipping;
          if (this.importPrice < +minimum) {
            this.minimumPrice = +price - this.importPrice;
          }
          this.priceLogistics();
        }
      }
    },
    profile: {
      query: GET_PROFILE_ADDRESS,
      update: data => data,
      result({ data, loading }) {
        if (!loading) {
          const { profile } = data;
          this.addressList = profile.address;
          const find =
            profile.address.find(x => x.default) || profile.address[0] || {};
          this.form.address = find;
        }
      }
    }
  }
};
</script>
