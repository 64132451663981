import gql from "graphql-tag";

export const ADD_CART = gql`
  mutation($input: [SellerCartItemInput]) {
    addCartSeller(input: $input) {
      isSuccess
      message
    }
  }
`;

export const CHECKOUT_SELLER = gql`
  mutation($input: ChecoutOderInput!) {
    checkout_seller(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const CALCULATE_FORWARDER = gql`
  query($input: ChecoutOderInput!) {
    calculate_forwarder(input: $input) {
      isSuccess
      message
      data
      shipping_price {
        seller_name
        sellerId
        shipping_price
      }
    }
  }
`;

export const DELETE_CART = gql`
  mutation($sellerId: Int, $_id: ID) {
    deleteCartSeller(sellerId: $sellerId, _id: $_id) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_CART = gql`
  mutation($sellerId: Int, $_id: ID, $amount: Int) {
    updateCartSeller(sellerId: $sellerId, _id: $_id, amount: $amount) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_GARANTEE_CART = gql`
  mutation($seller_id: Int, $guarantee: Boolean) {
    updateCartGaranteeSeller(
      sellerId: $sellerId
      _id: $_id
      guarantee: $guarantee
    ) {
      isSuccess
      message
    }
  }
`;

export const GET_CARTS = gql`
  query {
    cart_list {
      isSuccess
      message
      records {
        _id
        vendor
        sellerId
        qc
        guarantee
        items {
          _id
          productName
          productId
          mainImage
          vendor
          amount
          price
          skuId
          url
          remark
          size {
            weight
          }
          properties {
            id
            name
            title
            image
          }
        }
      }
    }
  }
`;
