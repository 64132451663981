<template>
  <div v-if="data">
    <div class="float-right mb-4">
      <button class="btn btn-primary" v-b-modal.transaction-modal>
        เพิ่ม/ลดเงินในกระเป๋า
      </button>
    </div>
    <div class="table-responsive">
      <table
        class="table table-head-custom table-head-bg table-borderless table-vertical-center"
      >
        <thead>
          <tr class="text-uppercase">
            <th style="min-width: 100px" class="pl-7">
              <span class="text-dark-75">รหัสธุรกรรม</span>
            </th>
            <th style="min-width: 100px">
              <span class="text-dark-75">วันที่</span>
            </th>
            <th style="min-width: 100px">
              <span class="text-dark-75">ประเภท</span>
            </th>
            <th style="min-width: 150px">
              <span class="text-dark-75">หมายเหตุ</span>
            </th>
            <th style="min-width: 120px" class="text-right pr-7">
              <span class="text-dark-75">ยอดเงิน</span>
            </th>
            <th style="min-width: 100px" class="text-right pr-7">
              <span class="text-dark-75">คงเหลือ</span>
            </th>
          </tr>
        </thead>
        <tbody v-if="transactionsUser">
          <tr v-for="item in transactionsUser.records" v-bind:key="item._id">
            <td class="pl-7 py-8 font-weight-bolder font-size-lg">
              {{ item.transaction_no }}
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">
                {{ format_datetime(item.created_at) }}
              </span>
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.transaction_type ? item.transaction_type.desc : "" }}
              </span>
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">
                {{ item.reason }}
              </span>
            </td>

            <td class="text-right pr-7">
              <span
                class="font-weight-bolder d-block font-size-lg"
                v-bind:class="{
                  'text-danger': item.amount < 0,
                  'text-success': item.amount >= 0
                }"
              >
                {{ format_number(item.amount, 2, true) }}
              </span>
            </td>
            <td class="text-right pr-7">
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ format_number(item.remain, 2, true) }}
              </span>
            </td>
          </tr>
          <tr
            class="text-center"
            v-if="
              transactionsUser &&
                transactionsUser.records &&
                !transactionsUser.records.length
            "
          >
            <td colspan="7" class="pt-8">ไม่พบรายการ</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-model="page"
      class="mt-4"
      size="md"
      v-if="transactionsUser && transactionsUser.recordsData"
      :total-rows="transactionsUser.recordsData.recordsFiltered"
      :per-page="pageSize"
    ></b-pagination>
    <modal-transaction
      title="เพิ่ม/ลดเงินในกระเป๋า"
      :refetch="onComplete"
      :userId="data.userId"
    />
  </div>
</template>
<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import { GET_TRANSACTIONS_USER } from "@/graphql/admin/transaction";
import ModalTransaction from "./ModalTransaction.vue";

export default {
  components: { ModalTransaction },
  props: ["data"],
  data() {
    return {
      page: 1,
      pageSize: 10
    };
  },
  methods: {
    onComplete() {
      this.$apollo.queries.transactionsUser.refetch();
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    transactionsUser: {
      query: GET_TRANSACTIONS_USER,
      skip() {
        return !this.data || !this.data.userId;
      },
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          userId: this.data.userId
        };
      }
    }
  }
};
</script>
