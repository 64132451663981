import gql from "graphql-tag";

export const GET_CATEGORY_LIST = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    system_category_list(page: $page, pageSize: $pageSize, search: $search) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        categoryId
        sequenceNumber
        originalName
        description
        fee_type
        fee_value
        created_at
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query($id: Int) {
    system_category(id: $id) {
      isSuccess
      message
      data {
        _id
        categoryId
        sequenceNumber
        originalName
        description
        fee_type
        fee_value
        forwarder_price_kg
        forwarder_price_cube
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation($input: CategoryInput) {
    createCategory(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation($input: CategoryInput) {
    updateCategory(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation($input: CategoryInput) {
    deleteCategory(input: $input) {
      isSuccess
      message
    }
  }
`;
