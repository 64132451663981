<template>
  <div v-if="cart_list">
    <h1 class="mb-8 cart-h-section">รถเข็นสินค้า ({{ totalItem }} รายการ)</h1>
    <div class="cart-pad">
      <div class="my-cart">
        <div class="card card-custom">
          <!--begin::Body-->
          <div class="card-body pt-2 pb-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table class="table table-borderless mb-8">
                <div
                  v-for="vendor in cart_list.records"
                  v-bind:key="vendor._id"
                  v-frag
                >
                  <thead>
                    <tr>
                      <th>
                        <span style="width: 20px">
                          <label class="checkbox checkbox-single checkbox-all">
                            <input
                              type="checkbox"
                              :value="vendor._id"
                              :checked="
                                checkedAllItem(vendor._id, vendor.items.length)
                              "
                              v-on:change="selectShop($event, vendor)"
                            />&nbsp;
                            <span></span>
                          </label>
                        </span>
                      </th>
                      <th
                        class="pl-0 font-weight-bold text-muted d-flex align-items-center"
                      >
                        <div class="text-overflow" style="width: 90px">
                          {{ vendor.vendor }}
                        </div>
                      </th>
                      <th class="text-center font-weight-bold text-muted">
                        จำนวน
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        ราคา/หน่วย
                      </th>
                      <th class="text-right pr-0 font-weight-bold text-muted">
                        ราคารวม
                      </th>
                    </tr>
                  </thead>
                  <tbody class="pb-8">
                    <tr
                      class="font-weight-boldest"
                      v-for="item in vendor.items"
                      v-bind:key="item._id"
                    >
                      <td class="pt-8">
                        <span style="width: 20px">
                          <label class="checkbox checkbox-single">
                            <input
                              type="checkbox"
                              :value="item._id"
                              :checked="checkedItem(vendor._id, item._id)"
                              v-on:change="selectItem($event, vendor, item)"
                            />&nbsp;
                            <span> </span>
                          </label>
                        </span>
                      </td>
                      <td
                        class="pl-0 pt-6 pb-6 align-items-center align-middle"
                      >
                        <div class="d-flex">
                          <!--begin::Symbol-->
                          <a :href="item.url" target="_blank">
                            <div
                              class="symbol symbol-40 flex-shrink-0 mr-4 bg-light"
                            >
                              <div
                                class="symbol-label"
                                :style="
                                  `background-image: url('${item.mainImage}')`
                                "
                              ></div>
                            </div>
                            <!--end::Symbol-->
                          </a>
                          <div>
                            <a :href="item.url" target="_blank">
                              {{ item.productName }}
                            </a>
                            <div
                              class="opacity-80 font-weight-bold d-block"
                              v-bind:key="`${item._id}${c.title}${c.id}`"
                              v-for="c in item.properties"
                            >
                              <span v-if="c.name">{{ c.name }} : </span>
                              <img
                                v-viewer
                                v-if="c.image"
                                :src="c.image"
                                style="height: 20px"
                                class="align-self-center pointer mr-2"
                                :alt="item.productName"
                              />
                              <span>{{ c.title }}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-center pt-6 pb-6 text-nowrap">
                        <InputNumber
                          :defaultValue="item.amount"
                          @onChange="updateAmount(vendor, item._id, $event)"
                        />
                      </td>
                      <td class="text-right pt-6 pb-0">
                        {{ vendor.currencySign || "฿"
                        }}{{ format_number(item.price, 2) }}
                      </td>
                      <td class="text-right pt-6 pb-0">
                        <div>
                          {{ vendor.currencySign || "฿"
                          }}{{ format_number(item.price * item.amount, 2) }}
                        </div>
                        <button
                          class="btn btn-icon btn-link btn-sm"
                          @click="deleteCart(vendor.sellerId, item._id)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <inline-svg
                              src="/media/svg/icons/General/Trash.svg"
                            />
                          </span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </div>
              </table>
              <div
                v-if="cart_list && !cart_list.records.length"
                class="text-center h5 pb-4"
              >
                ไม่มีข้อมูลสินค้า
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div class="order-summary">
        <div class="card card-custom px-4 py-4 align-center">
          <div><h4>สรุปการสั่งซื้อ</h4></div>
          <div class="pt-3">
            <div class="total-items-price">
              <div>ราคารวมจากร้านค้า ({{ count }} ชิ้น)</div>
              <div>฿{{ format_number(totalBaht, 2) }}</div>
            </div>
            <div class="text-danger font-size-sm mt-2">
              * ยังไม่รวมค่าจัดส่งและค่าบริหารจัดการสินค้า
            </div>
            <div class="order-summary-total mt-4">
              <label itemkey="totalPrice">ยอดสุทธิโดยประมาณ</label>
              <label>
                <span class="number-font order-total">
                  ฿{{ format_number(totalBaht, 2) }}
                </span>
              </label>
            </div>
            <button
              class="btn btn-success btn-block btn-lg mt-4"
              @click="cartCheckout()"
            >
              ดำเนินการต่อ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles.scss";
</style>
<script>
import { mapGetters } from "vuex";
import frag from "vue-frag";
import { UPDATE_CHECKOUT } from "@/core/services/store/seller_checkout.module";
import InputNumber from "@/components/InputNumber";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import bigDecimal from "js-big-decimal";
import {
  GET_CARTS,
  UPDATE_GARANTEE_CART,
  UPDATE_CART,
  DELETE_CART
} from "@/graphql/seller_cart";
import { WEBSITE } from "@/config";
import Swal from "sweetalert2";

export default {
  name: "SellerCart",
  mounted() {
    document.title = `รถเข็นสินค้า Marketplace | ${WEBSITE}`;
    this.selected = this.seller_checkout;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "สินค้าทั้งหมด", route: "/product/all" },
      { title: `รถเข็นสินค้า Marketplace` }
    ]);
  },
  data() {
    return {
      count: 0,
      totalItem: 0,
      total: 0,
      totalBaht: 0,
      totalBahtShopee: 0,
      selected: {}
    };
  },
  computed: {
    ...mapGetters(["seller_checkout"])
  },
  directives: {
    frag
  },
  components: {
    InputNumber
  },
  methods: {
    selectShop(event, vendor) {
      const checked = event.target.checked;
      if (checked) {
        this.selected[vendor._id] = [...vendor.items];
      } else {
        this.selected[vendor._id] = [];
      }
      this.calculate();
    },
    selectItem(event, vendor, item) {
      const checked = event.target.checked;
      let found = false;
      if (!this.selected[vendor._id]) {
        this.selected[vendor._id] = [];
      }
      if (this.selected[vendor._id]) {
        found = this.selected[vendor._id].find(x => x._id === item._id);
        if (!found && checked) {
          this.selected[vendor._id].push(item);
        } else if (found) {
          const index = this.selected[vendor._id].indexOf(found);
          this.selected[vendor._id].splice(index, 1);
          if (!this.selected[vendor._id].length) {
            delete this.selected[vendor._id];
          }
        }
      }
      this.calculate();
    },
    checkedAllItem(vendor_id, length_all) {
      return (
        this.selected[vendor_id] &&
        length_all === this.selected[vendor_id].length
      );
    },
    checkedItem(vendor_id, item) {
      return (
        this.selected[vendor_id] &&
        this.selected[vendor_id].find(x => x._id === item)
      );
    },
    cartCheckout() {
      const vendor = {};
      Object.keys(this.selected).map(key => {
        if (this.selected[key].length) {
          const v = this.cart_list.records.find(x => x._id === key);
          if (v) {
            vendor[key] = {
              vendor: v.vendor,
              currency: v.currency,
              currencySign: v.currencySign,
              _id: v._id,
              guarantee: v.guarantee,
              sellerId: v.sellerId
            };
          } else {
            this.$message.error("ข้อมูลมีการเปลี่ยนแปลง กรุณารีเฟรช");
            return;
          }
        }
      });
      this.$store.dispatch(UPDATE_CHECKOUT, {
        selected: this.selected,
        vendor
      });
      const count = Object.values(this.selected)
        .map(x => x.length)
        .reduce((total, val) => total + val, 0);
      if (!count) {
        this.$message.error("กรุณาเลือกสินค้า");
        return;
      }
      this.$router.push({ name: "seller_checkout" });
    },
    async deleteCart(vendorId, itemId) {
      const confirm = await Swal.fire({
        title: "คุณแน่ใจหรือไม่",
        icon: "question",
        html: `ว่าต้องการลบสินค้านี้ออกจากตะกร้าสินค้า ?`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { deleteCartSeller }
      } = await this.$apollo.mutate({
        mutation: DELETE_CART,
        variables: {
          sellerId: vendorId,
          _id: itemId
        }
      });
      const { isSuccess, message } = deleteCartSeller;
      if (!isSuccess) {
        this.$message.error(message);
        return;
      }
      this.$apollo.queries.cart_list.refetch();
    },
    async enableWoodCart(vendorId, enable) {
      await this.$apollo.mutate({
        mutation: UPDATE_GARANTEE_CART,
        variables: {
          sellerId: vendorId,
          guarantee: enable
        }
      });
      this.$apollo.queries.cart_list.refetch();
    },
    async updateAmount(vendor, itemId, amount) {
      const { _id: vendorId, sellerId } = vendor;
      this.cart_list.records.map(shop => {
        if (shop._id === vendorId) {
          shop.items.map(item => {
            if (item._id === itemId) {
              item.amount = amount;
              return;
            }
          });
        }
      });
      if (this.selected[vendorId]) {
        this.selected[vendorId].map(item => {
          if (item._id === itemId) {
            item.amount = amount;
            return;
          }
        });
      }
      this.calculate();
      const {
        data: { updateCartSeller }
      } = await this.$apollo.mutate({
        mutation: UPDATE_CART,
        variables: {
          sellerId: sellerId,
          _id: itemId,
          amount
        }
      });
      const { isSuccess, message } = updateCartSeller;
      if (!isSuccess) {
        this.$message.error(message);
        return;
      }
    },
    format_number(val, decimal = 0) {
      return Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    },
    calculate() {
      let total = 0;
      let totalBahtShopee = 0;
      let count = 0;
      Object.values(this.selected).map(x => {
        count += x.reduce((total, val) => total + val.amount, 0);
        const sum = x.reduce(
          (total, val) => total + (!val.currency ? val.price : 0) * val.amount,
          0
        );
        totalBahtShopee += x.reduce(
          (total, val) => total + (val.currency ? val.price : 0) * val.amount,
          0
        );
        total += sum;
      });
      if (this.cart_list) {
        this.count = count;
        this.total = total;
        this.totalBaht = total;
        this.totalBahtShopee = totalBahtShopee;
      }
    }
  },
  watch: {
    cart_list: {
      handler: function() {
        this.calculate();
      }
    },
    selected: {
      handler: function() {
        this.calculate();
      },
      deep: true
    }
  },
  apollo: {
    cart_list: {
      query: GET_CARTS,
      result({ data, loading }) {
        if (!loading && data) {
          this.totalItem = data.cart_list.records.reduce(
            (total, cur) => total + cur.items.length,
            0
          );
        }
      }
    }
  }
};
</script>
