<template>
  <div class="d-flex flex-column flex-lg-row">
    <!--begin::Sidebar-->
    <div
      class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-0 mb-lg-0"
    >
      <!--begin::Contacts-->
      <div class="card card-flush">
        <!--begin::Card body-->
        <div class="card-body py-5 px-5">
          <!--begin::List-->
          <button
            class="btn btn-block btn-success btn-lg fs-5"
            @click="addDept"
            v-if="permissionDepartment"
          >
            เพิ่มห้องสนทนา
          </button>
          <div
            v-if="isMobile && systemDepartmentList"
            class="text-center scroll-x d-flex my-7 pt-2"
          >
            <div
              v-for="item in systemDepartmentList.records"
              :key="item._id"
              @click="clickDept(item)"
              class="d-block text-reset mr-7 mr-lg-6"
            >
              <!--begin::Avatar-->
              <div class="symbol symbol-40px symbol-circle mb-3 noti">
                <span
                  class="symbol-label bg-light-danger text-danger fs-6 fw-bolder"
                  :class="{
                    'border-3 border border-primary': d === item.dept_id
                  }"
                  >{{ item.dept_name.substr(0, 2)
                  }}<span
                    v-if="item.noti"
                    class="badge badge-pill badge-danger"
                  >
                    {{ item.noti }}
                  </span>
                </span>
              </div>
              <!--end::Avatar-->
              <div>{{ item.dept_name }}</div>
              <div class="small">{{ item.dept_desc }}</div>
            </div>
          </div>
          <div
            class="scroll-y mr-n5 pr-5 h-300px h-lg-auto mt-4"
            style="max-height: 687px"
            v-if="systemDepartmentList && !isMobile"
          >
            <div
              v-for="item in systemDepartmentList.records"
              :key="item._id"
              class="d-flex flex-stack py-4 btn-active-light-primary px-4 rounded pointer"
              :class="{ 'bg-light-primary': d === item.dept_id }"
              @click="clickDept(item)"
            >
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle noti">
                  <span
                    class="symbol-label bg-light-danger text-danger fs-6 fw-bolder"
                  >
                    {{ item.dept_name.substr(0, 2) }}
                    <span
                      v-if="item.noti"
                      class="badge badge-pill badge-danger"
                    >
                      {{ item.noti }}
                    </span>
                  </span>
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ml-5">
                  <div
                    class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-1"
                  >
                    {{ item.dept_name }}
                  </div>
                  <span class="text-muted font-weight-bold font-size-sm">
                    {{ item.dept_desc }}
                  </span>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
              <!--begin::Lat seen-->
              <div class="d-flex align-items-center ms-2" style="gap: 6px">
                <span
                  class="text-muted text-nowrap fs-7 mb-1"
                  v-if="permissionDepartment"
                >
                  <button
                    type="button"
                    class="btn btn-icon btn-light btn-hover-warning btn-sm mr-2"
                    @click="setEdit(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-warning">
                      <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon btn-light btn-hover-danger btn-sm"
                    @click="deleteDept(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg src="/media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </span>
              </div>
              <!--end::Lat seen-->
            </div>
          </div>
          <!--end::List-->
        </div>
        <!--end::Card body-->
      </div>
      <div class="card card-flush mt-4">
        <!--begin::Card header-->
        <div class="card-header pt-7 pb-2">
          <!--begin::Form-->
          <form class="w-100 position-relative" autocomplete="off">
            <!--begin::Icon-->
            <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
            <span
              class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ml-5 translate-middle-y"
            >
              <inline-svg src="/media/svg/icons/General/Search.svg" />
            </span>
            <!--end::Svg Icon-->
            <!--end::Icon-->
            <!--begin::Input-->
            <input
              type="text"
              class="form-control form-control-solid px-15"
              name="search"
              value=""
              @input="debounceInput"
              placeholder="Search by username or email..."
            />
            <!--end::Input-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Card header-->
        <div class="card-body py-5 px-5">
          <div
            class="text-center py-2 text-danger"
            v-if="
              !systemUserDeptList ||
                !systemUserDeptList.records ||
                systemUserDeptList.records.length === 0
            "
          >
            กรุณาเลือกห้องสนทนา
          </div>
          <div
            class="scroll-y mr-n5 pr-5 h-lg-auto"
            style="max-height: 687px; height: calc(100vh - 596px);"
            v-if="systemUserDeptList"
          >
            <div
              v-for="item in systemUserDeptList.records"
              :key="item._id"
              class="d-flex flex-stack py-4 btn-active-light-primary px-4 rounded pointer"
              :class="{ 'bg-light-primary': u === item.userId }"
              @click="clickUser(item)"
            >
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle noti">
                  <span
                    class="symbol-label bg-light-danger text-danger fs-6 fw-bolder"
                    >{{ item.username.substr(-3) }}
                    <span
                      v-if="item.noti"
                      class="badge badge-pill badge-danger"
                    >
                      {{ item.noti }}
                    </span>
                  </span>
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ml-5">
                  <span
                    class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                    >{{ item.username }}</span
                  >
                  <div class="fw-bold text-gray-400">{{ item.full_name }}</div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Contacts-->
    </div>
    <!--end::Sidebar-->
    <!--begin::Content-->
    <div
      class="flex-lg-row-fluid ml-lg-7 ms-xl-10"
      v-if="systemUserMessageList && !isMobile"
    >
      <message-list
        :list="listMessage"
        :data="systemUserMessageList"
        :refetch="onRefetchMessage"
        :fetchMore="fetchMore"
      />
    </div>
    <!--end::Content-->
    <chat-mobile
      v-if="isMobile"
      :visible="visible"
      @change-visible="visible = $event"
      :list="listMessage"
      :data="systemUserMessageList"
      :refetch="onRefetchMessage"
      :fetchMore="fetchMore"
    />
    <modal-department :refetch="onRefetch" :_id="editId" :onClose="onClose" />
  </div>
</template>
<style lang="scss">
@import "src/assets/sass/_private_chat";
</style>
<script>
import debounce from "debounce";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { isMobile } from "@/lib/detectDevice";
import {
  GET_ADMIN_DEPARTMENTS,
  DELETE_DEPARTMENT
} from "@/graphql/admin/department.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_USER_DEPT, GET_USER_MESSAGES } from "@/graphql/message.js";
import { WEBSITE } from "@/config";
import ModalDepartment from "./components/ModalDepartment.vue";

const MessageList = () => import("./components/MessageList.vue");
const ChatMobile = () => import("./components//ChatMobile.vue");

export default {
  name: "ChatList",
  components: { ModalDepartment, MessageList, ChatMobile },
  created() {
    document.title = `ติดต่อผู้ใช้งาน | ${WEBSITE}`;
  },
  computed: {
    ...mapGetters(["currentPermission"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `ติดต่อผู้ใช้งาน` }]);

    this.permissionDepartment = this.checkPermission("admin_department", 1);

    this.poll();
  },
  data() {
    const { d = 0, u = 0 } = this.$route.query;
    const d_ = Number(d);
    const u_ = Number(u);
    return {
      d: d_,
      u: u_,
      editId: null,
      isMobile: isMobile(),
      pageUser: 1,
      searchUser: "",
      pageMessage: 1,
      listMessage: [],
      visible: !!d_,
      enabledScroll: true,
      permissionDepartment: false
    };
  },
  methods: {
    fetchMore() {
      this.pageMessage = this.pageMessage + 1;
    },
    onClose() {
      this.editId = null;
    },
    debounceInput: debounce(function(e) {
      this.searchUser = e.target.value;
      this.pageUser = 1;
    }, 400),
    addDept() {
      this.editId = null;
      this.$nextTick(() => {
        this.$bvModal.show("dept-modal");
      });
    },
    setEdit(item) {
      this.editId = item._id;
      this.$nextTick(() => {
        this.$bvModal.show("dept-modal");
      });
    },
    async deleteDept(value) {
      const confirm = await Swal.fire({
        title: "ยืนยันการลบข้อมูล ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { deleteDepartment }
      } = await this.$apollo.mutate({
        mutation: DELETE_DEPARTMENT,
        variables: {
          id: value._id
        }
      });
      if (!deleteDepartment.isSuccess) {
        this.$message.error(deleteDepartment.message);
      } else {
        this.$message.success(deleteDepartment.message);
        this.$apollo.queries.systemDepartmentList.refetch();
      }
    },
    onRefetch() {
      this.$apollo.queries.systemDepartmentList.refetch();
    },
    onRefetchMessage() {
      this.enabledScroll = true;
      this.$apollo.queries.systemUserMessageList.refetch();
    },
    clickDept(item) {
      this.enabledScroll = true;
      this.d = item.dept_id;
      this.pageMessage = 1;
      this.pageUser = 1;
      const path = `/admin/chat?d=${this.d}&u=${this.u}`;
      if (this.$route.fullPath !== path) {
        this.$router.push(path);
      }
    },
    clickUser(item) {
      this.enabledScroll = true;
      this.u = item.userId;
      this.pageMessage = 1;
      this.visible = true;
      const path = `/admin/chat?d=${this.d}&u=${this.u}`;
      if (this.$route.fullPath !== path) {
        this.$router.push(path);
      }
    },
    poll() {
      setInterval(() => {
        this.enabledScroll = false;
        this.$apollo.queries.systemUserMessageList.refetch();
      }, 5000);
      setInterval(() => {
        this.$apollo.queries.systemDepartmentList.refetch();
      }, 5200);
      setInterval(() => {
        this.$apollo.queries.systemUserDeptList.refetch();
      }, 5500);
    },
    checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }
      const { permissionRole } = this.currentPermission;

      const find = permissionRole.find(
        x => x.permissionInfo && x.permissionInfo.permissionName === name
      );

      if (!find) {
        return false;
      }
      if (type === 0 && find.readableRole) {
        return true;
      }
      if (type === 1 && find.writeableRole) {
        return true;
      }
      return false;
    }
  },
  watch: {
    "$route.query.d": function(val) {
      this.d = Number(val) || 0;
    },
    "$route.query.u": function(val) {
      this.u = Number(val) || 0;
    }
  },
  apollo: {
    systemDepartmentList: {
      query: GET_ADMIN_DEPARTMENTS
    },
    systemUserDeptList: {
      query: GET_USER_DEPT,
      skip() {
        return !this || !this.d;
      },
      variables() {
        return {
          page: this.pageUser,
          pageSize: 10,
          dept_id: this.d,
          search: this.searchUser
        };
      }
    },
    systemUserMessageList: {
      query: GET_USER_MESSAGES,
      skip() {
        return !this || !this.d || !this.u;
      },
      variables() {
        return {
          page: this.pageMessage,
          dept_id: this.d,
          userId: this.u
        };
      },
      result({ loading, data }) {
        if (!loading && data) {
          const { systemUserMessageList } = data;
          if (this.pageMessage != 1) {
            this.listMessage = systemUserMessageList.records.concat(
              this.listMessage
            );
          } else {
            this.listMessage = systemUserMessageList.records;
            const container = this.$el.querySelector("#message_wrapper");
            if (container && this.enabledScroll) {
              this.$nextTick(() => {
                setTimeout(() => {
                  container.scrollTop = container.scrollHeight;
                }, 500);
              });
            }
          }
        }
      }
    }
  }
};
</script>
