<template>
  <div>
    <table class="table table-sm table-borderless table-vertical-center">
      <thead>
        <tr>
          <th>ชื่อ-นามสกุล</th>
          <th>เบอร์ติดต่อ</th>
          <th>ที่อยู่</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in user.address" v-bind:key="item._id">
          <td>
            <button
              class="btn btn-link btn-sm"
              v-b-modal.address-edit-modal
              @click="setEdit(item, index)"
            >
              {{ item.name }}
            </button>
          </td>
          <td>
            {{ item.phone }}
          </td>
          <td>
            {{ item.address }}
          </td>
          <td class="text-center">
            <button
              type="button"
              class="btn btn-icon btn-light btn-hover-danger btn-sm"
              @click="deleteAddress(index)"
              v-if="!item.default"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </button>
          </td>
        </tr>
        <tr class="text-center" v-if="user.address && !user.address.length">
          <td colspan="4" class="pt-8">ไม่มีข้อมูล กรุณาเพิ่ม</td>
        </tr>
      </tbody>
    </table>
    <button
      type="button"
      v-b-modal.address-add-modal
      class="btn btn-block btn-dashed"
    >
      <i class="menu-icon la la-plus icon-xl"></i> เพิ่มที่อยู่
    </button>
    <b-modal
      id="address-edit-modal"
      centered
      size="lg"
      title="แก้ไขที่อยู่"
      @ok="handleOk"
    >
      <b-form ref="formEdit" @submit.prevent="updateAdress">
        <div class="row">
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ชื่อ - นามสกุล</label>
              <div class="col-12">
                <b-form-input
                  v-model="formEdit.name"
                  :state="validateStateEdit('name')"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เบอร์โทรศัพท์</label>
              <div class="col-12">
                <b-form-input
                  v-model="formEdit.phone"
                  :state="validateStateEdit('phone')"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">บ้านเลขที่</label>
              <div class="col-12">
                <b-form-input
                  v-model="formEdit.address"
                  :state="validateStateEdit('address')"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">แขวง/ตำบล</label>
              <div class="col-12">
                <addressinput-subdistrict
                  v-model="formEdit.district"
                  placeholder="ตำบล"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เขต/อำเภอ</label>
              <div class="col-12">
                <addressinput-district
                  v-model="formEdit.amphoe"
                  placeholder="อำเภอ"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">จังหวัด</label>
              <div class="col-12">
                <addressinput-province
                  placeholder="จังหวัด"
                  v-model="formEdit.province"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">รหัสไปรษณีย์</label>
              <div class="col-12">
                <addressinput-zipcode
                  placeholder="รหัสไปรษณีย์"
                  v-model="formEdit.postcode"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ที่อยู่หลัก</label>
              <div class="col-12">
                <span class="switch switch-sm mt-1">
                  <label class="d-flex align-items-center">
                    <b-form-input
                      type="checkbox"
                      id="input-is_default"
                      v-model="formEdit.default"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="address-add-modal"
      centered
      size="lg"
      title="เพิ่มที่อยู่"
      @ok="handleOkAdd"
    >
      <b-form ref="formAdd" @submit.prevent="addAdress">
        <div class="row">
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ชื่อ - นามสกุล</label>
              <div class="col-12">
                <b-form-input
                  v-model="formAdd.name"
                  :state="validateStateAdd('name')"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เบอร์โทรศัพท์</label>
              <div class="col-12">
                <b-form-input
                  v-model="formAdd.phone"
                  :state="validateStateAdd('phone')"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">บ้านเลขที่</label>
              <div class="col-12">
                <b-form-input
                  v-model="formAdd.address"
                  :state="validateStateAdd('address')"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">แขวง/ตำบล</label>
              <div class="col-12">
                <addressinput-district
                  v-model="formAdd.district"
                  placeholder="ตำบล"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เขต/อำเภอ</label>
              <div class="col-12">
                <addressinput-district
                  v-model="formAdd.amphoe"
                  placeholder="อำเภอ"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">จังหวัด</label>
              <div class="col-12">
                <addressinput-province
                  placeholder="จังหวัด"
                  v-model="formAdd.province"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">รหัสไปรษณีย์</label>
              <div class="col-12">
                <addressinput-zipcode
                  placeholder="รหัสไปรษณีย์"
                  v-model="formAdd.postcode"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ที่อยู่หลัก</label>
              <div class="col-12">
                <span class="switch switch-sm mt-1">
                  <label class="d-flex align-items-center">
                    <b-form-input
                      type="checkbox"
                      id="input-is_default"
                      v-model="formAdd.default"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import uuidv4 from "uuid/v4";

export default {
  mixins: [validationMixin],
  props: ["data", "onChange"],
  data() {
    return {
      user: this.data,
      nameNewAddressState: null,
      nameAddressState: null,
      formAdd: {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      },
      editIndex: 0,
      formEdit: {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      }
    };
  },
  validations: {
    formAdd: {
      name: {
        required
      },
      phone: {
        required
      },
      province: {},
      amphoe: {},
      district: {},
      postcode: {},
      address: {
        required
      },
      default: {}
    },
    formEdit: {
      name: {
        required
      },
      phone: {
        required
      },
      province: {},
      amphoe: {},
      district: {},
      postcode: {},
      address: {
        required
      },
      default: {}
    }
  },
  methods: {
    setEdit(value, index) {
      this.editIndex = index;
      this.formEdit = JSON.parse(JSON.stringify(value));
    },
    validateStateEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    validateStateAdd(name) {
      const { $dirty, $error } = this.$v.formAdd[name];
      return $dirty ? !$error : null;
    },
    handleOkAdd(bvModalEvt) {
      this.addAdress(bvModalEvt);
    },
    async addAdress(bvModalEvt) {
      this.$v.formAdd.$touch();
      if (this.$v.formAdd.$anyError) {
        bvModalEvt.preventDefault();
        return;
      }
      // this.$bvModal.hide("address-add-modal");
      this.onChange([
        ...(this.user.address || []),
        { _id: uuidv4(), ...this.formAdd }
      ]);
      this.formAdd = {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      };
    },
    handleOk(bvModalEvt) {
      this.updateAdress(bvModalEvt);
    },
    updateAdress(bvModalEvt) {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        bvModalEvt.preventDefault();
        return;
      }
      if (!this.user.address) {
        this.user.address = [];
      }
      this.user.address[this.editIndex] = {
        ...this.formEdit
      };
      this.$bvModal.hide("address-edit-modal");
      this.onChange([...this.user.address]);
      this.formEdit = {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      };
      this.formAdd = {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      };
    },
    deleteAddress(index) {
      const address = [...this.user.address];
      address.splice(index, 1);
      this.user.address = address;
      this.onChange([...address]);
      this.formAdd = {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      };
    }
  },
  watch: {
    data: function(newVal) {
      this.user = newVal;
    }
  }
};
</script>
