<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar d-none d-lg-flex">
    <!--begin: My Cart Seller -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-if="IS_SELLER"
    >
      <template v-slot:button-content>
        <router-link
          to="/cart/seller"
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
            <span
              v-if="getCurrentNoti && getCurrentNoti.seller_cart"
              class="badge badge-pill badge-danger"
            >
              {{ getCurrentNoti.seller_cart }}
            </span>
          </span>
        </router-link>
      </template>
    </b-dropdown>
    <!--end: My Cart Seller -->
    <!--begin: My Cart -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <router-link
          to="/cart"
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="/media/svg/icons/Shopping/Cart3.svg" />
            <span
              v-if="getCurrentNoti && getCurrentNoti.cart"
              class="badge badge-pill badge-danger"
            >
              {{ getCurrentNoti.cart }}
            </span>
          </span>
        </router-link>
      </template>
    </b-dropdown>
    <!--end: My Cart -->
    <!--begin: My Message -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <router-link
          to="/messages"
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="/media/svg/icons/Communication/Group-chat.svg" />
            <span
              v-if="getCurrentNoti && getCurrentNoti.msg"
              class="badge badge-pill badge-danger"
            >
              {{ getCurrentNoti.msg }}
            </span>
          </span>
        </router-link>
      </template>
    </b-dropdown>
    <!--end: My Message -->

    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      ref="dropdown"
      @mouseover="onOver"
      @mouseleave="onLeave"
    >
      <template v-slot:button-content>
        <button
          class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
        >
          <div class="d-flex flex-column text-right pr-3">
            <span
              class="text-white opacity-50 font-weight-bold font-size-sm d-none d-md-inline"
              >{{ currentUser.full_name }}</span
            >
            <span
              class="text-white font-weight-bolder font-size-sm d-none d-md-inline"
              >{{ currentUser.username }}</span
            >
          </div>
          <span class="symbol symbol-35">
            <span
              class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"
              >{{
                currentUser.full_name ? currentUser.full_name[0] : "-"
              }}</span
            >
          </span>
        </button>
      </template>
      <b-dropdown-item to="/profile">
        <span class="la la-user icon-md mr-1" />โปรไฟล์
      </b-dropdown-item>
      <b-dropdown-item to="/password-management">
        <span class="la la-lock icon-md mr-1" />เปลี่ยนรหัสผ่าน
      </b-dropdown-item>
      <b-dropdown-item @click="onLogout">
        <span class="text-danger">
          <span class="la la-sign-out-alt icon-md mr-1" />ออกจากระบบ
        </span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { ACCOUNT, LOGOUT } from "@/core/services/store/auth.module";
import i18nService from "@/core/services/i18n.service.js";
import { NOTI } from "@/core/services/store/profile.module.js";
import { IS_SELLER } from "@/config";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      polling: null,
      IS_SELLER: IS_SELLER
    };
  },
  methods: {
    loadAccount() {
      this.$store.dispatch(ACCOUNT);
    },
    loadData() {
      this.$store.dispatch(NOTI);
    },
    poll() {
      this.polling = setInterval(() => {
        this.loadData();
      }, 9000);
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    this.loadData();
    this.poll();
    this.loadAccount();
  },
  computed: {
    ...mapGetters(["currentUser", "getCurrentNoti"]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
