<template>
  <div>
    <b-alert v-if="messageError" variant="danger" show>{{
      messageError
    }}</b-alert>
    <b-overlay :show="loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="save">
        <div class="card">
          <div class="card-header">
            <h3 class="mb-0">ข้อมูลผู้ขาย</h3>
          </div>
          <div class="card-body" v-if="seller && seller.seller_address">
            <div class="row mt-4">
              <div class="col-lg-10">
                <div class="form-group row mb-2">
                  <label class="col-form-label text-right col-sm-3 col-4"
                    >ชื่อร้านค้า</label
                  >
                  <div class="col-xl-6 col-lg-8 col-8">
                    <b-form-input
                      v-model="seller.seller_name"
                      :state="validateState('seller_name')"
                      required
                      placeholder=""
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label text-right col-sm-3 col-4">
                    โลโก้ร้านค้า
                  </label>
                  <div class="col-lg-5 col-md-6 col-sm-6 col-8">
                    <div class="uppy" id="kt_uppy_5">
                      <div class="uppy-wrapper">
                        <div class="uppy-Root uppy-FileInput-container">
                          <input
                            class="uppy-FileInput-input uppy-input-control"
                            ref="fileInput"
                            type="file"
                            name="files[]"
                            multiple=""
                            @input="pickFile"
                            id="file"
                            style=""
                          />
                          <label
                            class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                            for="file"
                          >
                            เลือก รูป
                          </label>
                        </div>
                      </div>
                      <div class="uppy-list">
                        <div
                          class="uppy-list-item"
                          v-if="seller.seller_logo || previewImage"
                        >
                          <div v-viewer class="box-courier-logo">
                            <img
                              :src="
                                previewImage || imageUrlApi(seller.seller_logo)
                              "
                            />
                          </div>
                          <span class="uppy-list-remove" @click="removeFile()">
                            <i class="flaticon2-cancel-music"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <span class="form-text text-muted">
                      อัพโหลดไฟล์ไม่เกิน 10MB.
                    </span>
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label class="col-form-label text-right col-sm-3 col-4"
                    >ที่อยู่</label
                  >
                  <div class="col-xl-6 col-lg-8 col-8">
                    <b-form-input
                      v-model="seller.seller_address.address"
                      :state="validateStateAddress('address')"
                      required
                      placeholder="บ้านเลขที่"
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <label
                    class="col-form-label text-right col-sm-3 col-4"
                  ></label>
                  <div class="col-xl-6 col-lg-8 col-8 d-flex" style="gap: 6px">
                    <addressinput-subdistrict
                      v-model="seller.seller_address.district"
                      :class="validateStateAddressClassName('district')"
                      placeholder="ตำบล"
                    />
                    <addressinput-district
                      v-model="seller.seller_address.amphoe"
                      :class="validateStateAddressClassName('amphoe')"
                      placeholder="อำเภอ"
                    />
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label
                    class="col-form-label text-right col-sm-3 col-4"
                  ></label>
                  <div class="col-xl-6 col-lg-8 col-8 d-flex" style="gap: 6px">
                    <addressinput-province
                      v-model="seller.seller_address.province"
                      :class="validateStateAddressClassName('province')"
                      placeholder="จังหวัด"
                    />
                    <addressinput-zipcode
                      v-model="seller.seller_address.postcode"
                      :class="validateStateAddressClassName('postcode')"
                      placeholder="รหัสไปรษณีย์"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 col-sm-9 col-12 ml-auto">
                <button
                  type="submit"
                  class="btn btn-success mr-4"
                  style="min-width: 130px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/General/Save.svg" />
                  </span>
                  บันทึก
                </button>
                <button
                  type="button"
                  @click="$apollo.queries.seller.refetch()"
                  class="btn btn-secondary"
                  style="min-width: 130px"
                >
                  รีเซ็ต
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { SELLER_INFO, UPDATE_SELLER } from "@/graphql/seller_info";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi, API_URL, IMAGE_URL } from "@/config";
import axios from "axios";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `ข้อมูลผู้ขาย | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `มาเป็นผู้ขาย`, route: "/seller" },
      { title: `ข้อมูลผู้ขาย` }
    ]);
  },
  data() {
    return {
      messageError: "",
      loading: false,
      previewImage: null,
      seller: {
        seller_name: "",
        seller_logo: "",
        seller_address: {
          address: "",
          province: "",
          amphoe: "",
          district: "",
          postcode: ""
        }
      }
    };
  },
  validations: {
    seller: {
      seller_name: {
        required
      },
      seller_logo: {},
      seller_address: {
        address: { required },
        province: { required },
        amphoe: { required },
        district: { required },
        postcode: { required }
      }
    }
  },
  methods: {
    imageUrlApi,
    validateStateAddressClassName(name) {
      const { $dirty, $error } = this.$v.seller.seller_address[name];
      return $dirty ? (!$error ? "is-valid" : "is-invalid") : "";
    },
    validateStateAddress(name) {
      const { $dirty, $error } = this.$v.seller.seller_address[name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.seller[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.seller.$touch();
      if (this.$v.seller.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      this.loading = true;
      const {
        data: { updateSeller }
      } = await this.$apollo.mutate({
        mutation: UPDATE_SELLER,
        variables: {
          input: {
            seller_name: this.$v.seller.seller_name.$model,
            seller_logo: this.$v.seller.seller_logo.$model,
            seller_address: this.$v.seller.seller_address.$model
          }
        }
      });
      this.loading = false;

      const { isSuccess, message } = updateSeller;
      this.messageError = "";
      if (!isSuccess) {
        this.messageError = message;
      } else {
        this.$message.success(message);
        this.$apollo.queries.seller.refetch();
      }
    },
    upload(file, onUploadProgress) {
      let formData = new FormData();

      formData.append("file", file);

      return axios.post(`${API_URL}/upload/temp2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        this.upload(file[0], event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(res => {
          const { data } = res;
          if (data) {
            this.previewImage = `${IMAGE_URL}${data.data}`;
            this.seller.seller_logo = data.data;
          }
        });
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.seller.seller_logo = null;
      this.file = null;
      this.previewImage = null;
    }
  },
  apollo: {
    seller: {
      query: SELLER_INFO,
      result({ loading, data }) {
        if (!loading && data) {
          const { isSuccess, message } = data.seller;
          if (!isSuccess) {
            this.$message.error(message);
            return;
          }
          this.seller = data.seller.data || {
            seller_name: "",
            seller_logo: "",
            seller_address: {
              address: "",
              province: "",
              amphoe: "",
              district: "",
              postcode: ""
            }
          };
        }
      }
    }
  }
};
</script>
