<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="orderList">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            รายการสั่งซื้อ
          </span>
          <span
            class="text-muted font-weight-bold font-size-sm"
            v-if="orderList.recordsData"
          >
            Total {{ orderList.recordsData.recordsFiltered }}+ orders
          </span>
        </div>
      </h3>
      <div
        class="card-toolbar d-flex flex-md-row flex-column justify-content-end"
      >
        <div class="input-group input-group-solid" style="width: 280px">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหา"
            @input="debounceInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </div>
        </div>
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer"
              data-toggle="tab"
              :class="{ active: this.status === '' }"
              @click="
                status = '';
                page = 1;
              "
              >ทั้งหมด</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer"
              data-toggle="tab"
              :class="{ active: this.status === '0' }"
              @click="
                status = '0';
                page = 1;
              "
              >กำลังตรวจสอบ</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer noti"
              data-toggle="tab"
              :class="{ active: this.status === '10' }"
              @click="
                status = '10';
                page = 1;
              "
              >รอชำระเงิน
              <span
                v-if="getCurrentNoti && getCurrentNoti.trade"
                style="top: -8px"
                class="badge badge-pill badge-danger"
              >
                {{ getCurrentNoti.trade }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer"
              data-toggle="tab"
              :class="{ active: this.status === '20' }"
              @click="
                status = '20';
                page = 1;
              "
              >ชำระเงินแล้ว</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer"
              data-toggle="tab"
              :class="{ active: this.status === '30' }"
              @click="
                status = '30';
                page = 1;
              "
              >รอเลขขนส่ง</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer"
              data-toggle="tab"
              :class="{ active: this.status === '40' }"
              @click="
                status = '40';
                page = 1;
              "
              >สั่งซื้อแล้ว</a
            >
          </li>
          <button
            @click="$apollo.queries.orderList.refetch()"
            class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
          >
            <i class="ki ki-refresh"></i>
          </button>
        </ul>
      </div>
    </div>
    <!--end::Header-->
    <b-overlay :show="$apollo.loading" rounded="sm">
      <!--begin::Body-->
      <div class="card-body pt-2 pb-0 px-4">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-borderless table-vertical-center"
            aria-describedby="รายการสั่งซื้อ"
          >
            <thead>
              <tr>
                <th class="p-0" style="width: 50px"></th>
                <th class="p-0" style="min-width: 150px"></th>
                <th class="py-0 text-right" style="min-width: 140px"></th>
                <th class="py-0 text-right" style="min-width: 140px"></th>
                <th class="p-0 text-center" style="min-width: 120px"></th>
                <th class="p-0" style="min-width: 40px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orderList.records" v-bind:key="order._id">
                <td class="pl-0 py-5">
                  <div
                    class="symbol symbol-50 symbol-light mr-2"
                    v-bind:key="'mainImage' + p._id"
                    v-for="p in order.itemsLess"
                  >
                    <span class="symbol-label">
                      <img
                        :src="`${p.mainImage}`"
                        class="h-75 align-self-center"
                        :alt="p.productName"
                      />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <div class="mb-2 font-weight-bold">
                    รหัสสั่งซื้อ {{ order.orderCode }}
                  </div>
                  <router-link
                    :to="`/shop/order/${order.orderId}`"
                    v-bind:key="'productName' + p._id"
                    v-for="p in order.itemsLess"
                    style="max-width: 350px"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg d-inline-block text-truncate"
                  >
                    {{ p.productName }}
                  </router-link>
                  <div v-bind:key="'desc' + p._id" v-for="p in order.itemsLess">
                    <span
                      class="text-muted font-weight-bold d-block"
                      v-bind:key="c.id"
                      v-for="c in p.properties"
                    >
                      <span v-if="c.name">{{ c.name }} : </span>
                      <img
                        v-viewer
                        v-if="c.image"
                        :src="c.image"
                        style="height: 20px"
                        class="align-self-center pointer mr-2"
                        :alt="p.productName"
                      />
                      <span>{{ c.title }}</span>
                    </span>
                  </div>
                </td>
                <td class="text-right">
                  <div class="font-weight-bold mb-2">
                    ประเภทการขนส่ง
                    {{ order.ship_by === "ek" ? "รถ" : "เรือ" }}
                  </div>
                  <div class="font-weight-bold mb-2">
                    อัตราแลกเปลี่ยน {{ order.rate }}
                  </div>
                  <div class="text-muted text-nowrap">
                    วันที่สร้าง {{ format_datetime(order.created_at) }}
                  </div>
                </td>
                <td class="text-right">
                  <div class="font-weight-bold mb-2" v-if="order.total">
                    ราคา(¥) {{ format_number_pretty(order.total, 2) }}
                  </div>
                  <div class="font-weight-bold" v-if="order.total_baht">
                    ราคา(฿) {{ format_number_pretty(order.total_baht, 2) }}
                  </div>
                </td>
                <td class="text-center">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-${getStatusColor(order.status)}`"
                  >
                    {{ order.statusShow }}
                  </span>
                </td>
                <td class="text-right pr-0">
                  <router-link
                    :to="`/shop/order/${order.orderId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="
                  orderList && orderList.records && !orderList.records.length
                "
              >
                <td colspan="6" class="pt-8">ไม่พบรายการสั่งซื้อ</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="orderList && orderList.recordsData"
          :total-rows="orderList.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Body-->
    </b-overlay>
  </div>
</template>

<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import { mapGetters } from "vuex";
import { GET_ORDERS } from "@/graphql/order";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  name: "order_list",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "รายการสั่งซื้อ" }]);
  },
  created() {
    document.title = `รายการสั่งซื้อ | ${WEBSITE}`;
  },
  computed: {
    ...mapGetters(["getCurrentNoti"])
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      orderList: {},
      loading: false,
      status: "",
      search: ""
    };
  },
  methods: {
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 40:
        case 90:
          return "success";
        default:
          return "primary";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val, digits = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    }
  },
  apollo: {
    orderList: {
      query: GET_ORDERS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    }
  }
};
</script>
