<template>
  <div
    class="bx-ribbon"
    v-if="getNotification && getNotification.setting_notification_bar"
  >
    <i class="la la-bullhorn text-white icon-lg mr-3" />
    {{ getNotification.setting_notification_bar }}
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getNotification"])
  }
};
</script>
