import gql from "graphql-tag";

export const GET_TRACKINGS = gql`
  query(
    $page: Int!
    $pageSize: Int!
    $status: String
    $search: String
    $type: String
  ) {
    systemTransportList(
      page: $page
      pageSize: $pageSize
      status: $status
      search: $search
      type: $type
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        transportId
        transportNumber
        amount
        ship_by
        payshipping_id
        payshipping_doc
        userId
        user {
          userId
          username
        }
        file_tracking
        in_order {
          orderId
          orderCode
        }
        typeofGoodsData {
          name
        }
        remarkPurchaseOrder
        userLevelPrice {
          userLevelDetailName
          userLevelDetailPriceKg
          userLevelDetailPriceCube
          userLevelDetailShipping
        }
        size {
          weight
          long
          height
          width
        }
        logisticsServicePrice {
          _id
          priceName
          priceValue
        }
        trackingPrice {
          priceName
          priceValue
          is_minimum
        }
        trackingWarehouse {
          _id
          name
          warehouseKg
          warehouseCube
        }
        lotId
        no
        timeline {
          send_to_warehouse_china
          send_out_china
          send_to_warehouse_th
          payshipping
          sent_out_to_customer
        }
        remark
        remarkForCustomer
        guarantee
        status
        statusShow
        created_at
      }
    }
  }
`;

export const GET_TRACKING = gql`
  query($id: Int!) {
    systemTransport(id: $id) {
      _id
      transportId
      transport_doc
      transportNumber
      amount
      ship_by
      payshipping_id
      payshipping_doc
      userId
      lotId
      file_tracking
      serviceCube
      serviceKg
      remarkPurchaseOrder
      userLevelPrice {
        userLevelDetailName
        userLevelDetailPriceKg
        userLevelDetailPriceCube
        userLevelDetailShipping
      }
      userId
      user {
        userId
        username
      }
      in_order {
        orderId
        orderCode
      }
      size {
        weight
        long
        height
        width
      }
      logisticsServicePrice {
        _id
        priceName
        priceValue
      }
      trackingPrice {
        priceName
        priceValue
        is_minimum
      }
      trackingWarehouse {
        _id
        name
        warehouseKg
        warehouseCube
      }
      timeline {
        send_to_warehouse_china
        send_out_china
        send_to_warehouse_th
        payshipping
        sent_out_to_customer
      }
      remark
      remarkForCustomer
      status
      statusShow
      guarantee
      price_wooden_box
      created_at
    }
  }
`;

export const GET_FIND_TRACKINGS = gql`
  query(
    $page: Int!
    $pageSize: Int!
    $type: String
    $search: String
    $type: String
  ) {
    systemTransportList(
      page: $page
      pageSize: $pageSize
      type: $type
      search: $search
      type: $type
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        transportId
        transportNumber
        amount
        ship_by
        user {
          userId
          username
        }
        in_order {
          orderId
          orderCode
        }
        userLevelPrice {
          userLevelDetailName
        }
        size {
          weight
          long
          height
          width
        }
        logisticsServicePrice {
          _id
          priceName
          priceValue
        }
        trackingPrice {
          priceName
          priceValue
          is_minimum
        }
        lotId
        no
        timeline {
          send_to_warehouse_china
          send_out_china
          send_to_warehouse_th
          payshipping
          sent_out_to_customer
        }
        remark
        remarkForCustomer
        guarantee
        status
        statusShow
        created_at
      }
    }
  }
`;

export const UPDATE_TRACKING = gql`
  mutation($input: TrackingInput) {
    systemUpdateTransport(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const CREATE_TRACKING = gql`
  mutation($input: TrackingInput) {
    createTransport(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_TRACKING = gql`
  mutation($id: Int) {
    systemRemoveTransport(id: $id) {
      isSuccess
      message
    }
  }
`;
