import gql from "graphql-tag";

export const GET_TRACKING_WAREHOUSE_LIST = gql`
  query {
    trackingWarehouses {
      recordsData {
        recordsFiltered
      }
      isSuccess
      message
      records {
        _id
        name
        warehouseKg
        warehouseCube
      }
    }
  }
`;
