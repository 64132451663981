<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" @submit.prevent="save">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">เพิ่มขนส่ง</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group row mb-2">
                <label
                  for="input-logisticsName"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ชื่อขนส่ง</label
                >
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-input
                    id="input-logisticsName"
                    v-model="logistics.logisticsName"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label class="col-form-label text-right col-md-3 col-sm-12"
                  >ประเทศ</label
                >
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-select
                    type="text"
                    class="form-control form-select"
                    v-model="logistics.country"
                    :state="validateState('country')"
                  >
                    <option
                      v-for="item in countryList"
                      v-bind:key="item.value"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </b-form-select>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-no"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ลำดับ</label
                >
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-input
                    id="input-no"
                    v-model="logistics.no"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-no"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ค่าแพ็คสินค้า
                </label>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-input
                    id="input-no"
                    v-model="logistics.logisticsServicePrice"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-transportNumber"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >เก็บเงินปลายทาง</label
                >
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <span class="switch switch-sm mt-1">
                    <label class="d-flex align-items-center">
                      <input type="checkbox" v-model="logistics.is_cod" />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body mt-4 mb-4">
        <table class="table table-sm table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="pl-4 w-50">
                ช่วงน้ำหนัก <span class="opacity-50">(กิโลกรัม)</span>
              </th>
              <th class="w-50">ราคา <span class="opacity-50">(บาท)</span></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in logistics.logisticsPriceList"
              v-bind:key="item._id"
            >
              <td>
                <div class="input-group double-input">
                  <input
                    type="number"
                    v-model="item.min"
                    :state="nameState"
                    :disabled="index > 0"
                    step="0.01"
                    placeholder="ต่ำสุด"
                    @change="changePrice()"
                    required
                    class="form-control"
                  />
                  <b-input-group-text class="radius-0">
                    <i class="la la-minus" />
                  </b-input-group-text>
                  <input
                    type="number"
                    v-model="item.max"
                    :state="nameState"
                    @change="changePrice()"
                    step="0.01"
                    placeholder="สูงสุด"
                    required
                    class="form-control"
                  />
                </div>
              </td>
              <td>
                <b-form-input
                  v-model="item.price"
                  :state="nameState"
                  required
                  placeholder=""
                ></b-form-input>
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-icon btn-light btn-hover-danger btn-sm"
                  @click="deleteRow(index)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg src="/media/svg/icons/General/Trash.svg" />
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          type="button"
          @click="addRow()"
          class="btn btn-block btn-dashed"
        >
          <i class="menu-icon la la-plus icon-xl"></i> เพิ่มช่วงน้ำหนัก
        </button>
      </div>
      <div class="card card-body">
        <div class="row">
          <div class="col-lg-10 ml-lg-auto">
            <button
              type="submit"
              class="btn btn-success mr-4"
              style="min-width: 140px"
            >
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/General/Save.svg" />
              </span>
              บันทึกขนส่ง
            </button>
            <router-link
              to="/admin/setting/thai-shipping"
              class="btn btn-secondary"
              style="width: 140px"
            >
              ยกเลิก
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import bigDecimal from "js-big-decimal";
import { WEBSITE } from "@/config";
import { CREATE_LOGISTICS } from "@/graphql/admin/delivery";
import uuidv4 from "uuid/v4";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `เพิ่มขนส่งภายในประเทศ | ${WEBSITE}`;
  },
  data() {
    return {
      nameState: null,
      messageError: "",
      countryList: [
        { name: "ไทย", value: "thai" },
        { name: "ลาว", value: "lao" }
      ],
      logistics: {
        logisticsName: "",
        logisticsServicePrice: 0,
        no: 0,
        is_cod: false,
        logisticsPriceList: [],
        country: "thai"
      }
    };
  },
  validations: {
    logistics: {
      logisticsName: {
        required
      },
      country: {
        required
      },
      logisticsServicePrice: {
        required
      },
      no: {
        required
      },
      logisticsPriceList: {},
      is_cod: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.logistics[name];
      return $dirty ? !$error : null;
    },
    addRow() {
      if (!this.logistics.logisticsPriceList) {
        this.logistics.logisticsPriceList = [];
      }
      this.logistics.logisticsPriceList.push({
        _id: uuidv4(),
        min: 0,
        max: 0,
        price: 0
      });
      this.changePrice();
    },
    changePrice() {
      for (let i = 0; i < this.logistics.logisticsPriceList.length; i++) {
        const item = this.logistics.logisticsPriceList[i];
        const NextItem = this.logistics.logisticsPriceList[i + 1];
        if (Number(item.min) >= Number(item.max)) {
          const num = bigDecimal.add(Number(item.min), 1);
          item.max = num;
        }
        if (NextItem) {
          const num = bigDecimal.add(Number(item.max), 0.01);
          NextItem.min = bigDecimal.round(Number(num), 2);
        }
      }
    },
    deleteRow(index) {
      this.logistics.logisticsPriceList.splice(index, 1);
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    async save() {
      if (!this.checkFormValidity()) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const logisticsName = this.$v.logistics.logisticsName.$model;
      const country = this.$v.logistics.country.$model;
      const logisticsId = this.logistics.logisticsId;
      const logisticsPriceList = this.$v.logistics.logisticsPriceList.$model;
      const logisticsServicePrice = this.$v.logistics.logisticsServicePrice
        .$model;
      const no = this.$v.logistics.no.$model;
      const is_cod = this.$v.logistics.is_cod.$model;

      const input = {
        logisticsName,
        country,
        logisticsId,
        logisticsServicePrice,
        logisticsPriceList,
        no,
        is_cod
      };

      input.no = Number(input.no);
      input.logisticsServicePrice = Number(input.logisticsServicePrice) || 0;
      input.logisticsPriceList = input.logisticsPriceList.map(x => ({
        min: Number(x.min),
        max: Number(x.max),
        price: Number(x.price)
      }));
      const {
        data: { createLogistics }
      } = await this.$apollo.mutate({
        mutation: CREATE_LOGISTICS,
        variables: {
          input: input
        }
      });
      const { message, is_error } = createLogistics;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$router.push("/admin/setting/thai-shipping");
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  }
};
</script>
