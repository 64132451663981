import { render, staticRenderFns } from "./ChatMobile.vue?vue&type=template&id=39a8f380"
import script from "./ChatMobile.vue?vue&type=script&lang=js"
export * from "./ChatMobile.vue?vue&type=script&lang=js"
import style0 from "./ChatMobile.vue?vue&type=style&index=0&id=39a8f380&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports