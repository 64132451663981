<template>
  <div v-frag>
    <b-dropdown
      :text="getText()"
      variant="white"
      block
      size="lg"
      class="border mb-4 d-lg-none"
      style="width: 100%"
    >
      <b-dropdown-item to="/admin/seller/category">
        <i class="menu-icon la la-box-open icon-xl mr-4"></i>
        <span class="menu-text">ประเภทสินค้า</span>
      </b-dropdown-item>
    </b-dropdown>
    <div class="d-flex">
      <div
        class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      >
        <!-- begin:: Aside Menu -->
        <div class="aside-menu-wrapper flex-column-fluid">
          <div
            class="aside-menu min-h-lg-800px"
            data-menu-vertical="1"
            data-menu-dropdown-timeout="500"
          >
            <!-- example static menu here -->
            <perfect-scrollbar
              class="aside-menu scroll"
              style="max-height: 90vh; position: relative"
            >
              <ul class="menu-nav">
                <router-link
                  to="/admin/seller/sale"
                  v-if="permisison.admin_exchange_rate"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-shopping-cart icon-xl"></i>
                      <span class="menu-text">รายการสั่งซื้อ</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/seller/category"
                  v-if="permisison.admin_exchange_rate"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-box-open icon-xl"></i>
                      <span class="menu-text">ประเภทสินค้า</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/seller/forwarder"
                  v-if="permisison.admin_exchange_rate"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-shipping-fast icon-xl"></i>
                      <span class="menu-text">ค่านำเข้า</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>

      <div class="content-wrapper flex-row-fluid ml-lg-4">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters(["currentPermission"])
  },
  mounted() {
    const permisison = {
      admin_exchange_rate: this.checkPermission("admin_exchange_rate", 1),
      admin_address: this.checkPermission("admin_address", 1),
      admin_delivery: this.checkPermission("admin_delivery", 0),
      admin_bank: this.checkPermission("admin_bank", 0),
      admin_china_thai: this.checkPermission("admin_china_thai", 0),
      setting_notification_bar: this.checkPermission(
        "setting_notification_bar",
        0
      ),
      admin_role: this.checkPermission("admin_role", 0),
      admin_address_china: this.checkPermission("admin_address_china", 1)
    };
    this.permisison = permisison;
  },
  data() {
    return {
      permisison: {}
    };
  },
  methods: {
    getText() {
      const path = this.$route.path;
      switch (path) {
        case "/admin/seller/category":
          return "ประเภทสินค้า";
        default:
          return "กรุณาเลือก";
      }
    },
    checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }
      const { permissionRole } = this.currentPermission;

      const find = permissionRole.find(
        x => x.permissionInfo && x.permissionInfo.permissionName === name
      );
      if (!find) {
        return false;
      }
      if (type === 0 && find.readableRole) {
        return true;
      }
      if (type === 1 && find.writeableRole) {
        return true;
      }
      return false;
    }
  }
};
</script>
