import ApiService from "../api.service";

// action types
export const UPDATE_PAYSHIPPING = "updatePayshipping";
export const PRINT_BILL = "printBill";
export const PRINT_BILL_SELLER = "printBillSeller";
export const PRINT_BILL_INVOICE = "printBillInvoice";
export const PRINT_BILL_ADDRESS = "printBillAddress";

// mutation types
export const SET_PAYSHIPPING = "setPayshipping";

const state = {
  items: localStorage.getItem("payshippingItems")
    ? JSON.parse(localStorage.getItem("payshippingItems"))
    : []
};

const getters = {
  getPayshippingItems(state) {
    return state.items;
  }
};

const actions = {
  [UPDATE_PAYSHIPPING](context, payload) {
    context.commit(SET_PAYSHIPPING, payload);
  },
  [PRINT_BILL](context, { label, id }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`bill`, `deliveryprint?label=${label}&id=${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [PRINT_BILL_SELLER](context, { label, id }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`seller`, `deliveryprint?label=${label}&id=${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [PRINT_BILL_INVOICE](context, { id }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`document`, `invoice?id=${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [PRINT_BILL_ADDRESS](context, { ids }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`bill`, `address?ids=${ids}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};

const mutations = {
  [SET_PAYSHIPPING](state, items) {
    localStorage.setItem("payshippingItems", JSON.stringify(items));
    state.items = items;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
