<template>
  <div class="card card-custom card-stretch" v-if="withdrawDetail">
    <div class="card-body">
      <div class="row" v-if="withdrawDetail">
        <div class="col-lg-8">
          <div class="col-md-6 offset-sm-3">
            <label class="option">
              <span class="option-label">
                <span class="option-head justify-content-start">
                  <div class="symbol symbol-40 flex-shrink-0 mr-4">
                    <div
                      class="symbol-label"
                      :style="
                        `background-image: url('${BANK_URL}${withdrawDetail.bank_img}')`
                      "
                    ></div>
                  </div>
                  <span class="option-title">{{
                    withdrawDetail.bank_name
                  }}</span>
                </span>
                <span class="option-body">
                  <div class="mb-1" style="font-size: 1.1rem">
                    เลขบัญชี {{ withdrawDetail.account_no }}
                  </div>
                  <div style="font-size: 1.1rem">
                    ชื่อบัญชี {{ withdrawDetail.account_name }}
                  </div>
                </span>
              </span>
            </label>
            <div class="fv-plugins-message-container"></div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-8">
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-3 col-sm-4 col-4">
              เลขที่เอกสาร
            </label>
            <div class="col-form-label col-lg-4 col-md-6 col-sm-6 col-8">
              {{ withdrawDetail.withdraw_no }}
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-3 col-sm-4 col-4">
              จำนวนเงิน
            </label>
            <div class="col-form-label col-lg-4 col-md-6 col-sm-6 col-8">
              {{ withdrawDetail.amount }}
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-3 col-sm-4 col-4">
              สถานะ
            </label>
            <div class="col-form-label col-lg-4 col-md-6 col-sm-6 col-8">
              <span
                class="label label-lg label-inline"
                v-bind:class="
                  `label-light-${getStatusColor(withdrawDetail.status)}`
                "
              >
                {{ withdrawDetail.statusShow }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-sm-4 col-4 col-form-label text-right">
              สลิปโอนเงิน
            </label>
            <div class="col-lg-5 col-md-6 col-sm-6 col-8">
              <div class="uppy" id="kt_uppy_5">
                <div class="uppy-list">
                  <div v-if="withdrawDetail.file_attachment">
                    <div
                      v-viewer
                      class="border symbol symbol-60 flex-shrink-0 mr-4"
                    >
                      <img
                        style="object-fit: cover"
                        :src="imageUrlApi(withdrawDetail.file_attachment)"
                        :key="withdrawDetail.file_attachment"
                        height="250"
                        width="250"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import { GET_WITHDRAW } from "@/graphql/withdraw";
import { getBankName } from "@/config";
import { imageUrlApi } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BANK_URL } from "@/config";

export default {
  name: "WithdrawDetail",
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `กระเป๋าเงิน`, route: "/wallet" },
      { title: `ถอนเงิน` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      BANK_URL,
      valid: true,
      id: Number(id),
      previewImage: null,
      file: null,
      fileValid: true,
      rules: {
        required: value => !!value || "กรุณากรอกข้อมูล"
      }
    };
  },
  methods: {
    getBankName,
    imageUrlApi,
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    withdrawDetail: {
      query: GET_WITHDRAW,
      variables() {
        return {
          id: this.id
        };
      }
    }
  }
};
</script>
