<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="users">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            ผู้ใช้ในระบบทั้งหมด
          </span>
          <span
            class="text-muted font-weight-bold font-size-sm"
            v-if="users.recordsData"
          >
            Total {{ users.recordsData.recordsFiltered }}+ users
          </span>
        </div>
      </h3>
    </div>
    <div
      class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
    >
      <div class="input-group input-group-solid mr-4" style="width: 280px">
        <input
          type="text"
          class="form-control"
          placeholder="ค้นหา"
          @input="debounceInput"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Search.svg" />
            </span>
          </span>
        </div>
      </div>
      <button
        @click="$apollo.queries.users.refetch()"
        class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      >
        <i class="ki ki-refresh"></i>
      </button>
      <router-link
        :to="`${$route.path}/create`"
        class="btn btn-success font-weight-bolder font-size-sm ml-4"
      >
        <span class="svg-icon svg-icon-md svg-icon-white">
          <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Communication/Add-user.svg-->
          <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
          <!--end::Svg Icon-->
        </span>
        เพิ่มผู้ใช้
      </router-link>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-8 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          v-if="users"
          class="table table-head-custom table-sm table-head-bg table-borderless table-vertical-center"
        >
          <thead>
            <tr>
              <th class="pl-4 text-center" style="width: 50px">
                <span class="text-dark-75 text-nowrap">รหัสผู้ใช้</span>
              </th>
              <th class="text-center">
                <span class="text-dark-75">แก้ไขล่าสุด</span>
              </th>
              <th class="text-center">
                <span class="text-dark-75">ชื่อผู้ใช้</span>
              </th>
              <th class="text-center">
                <span class="text-dark-75">ชื่อ - นามสกุล</span>
              </th>
              <th class="text-center">
                <span class="text-dark-75">อีเมล์</span>
              </th>
              <th class="text-center">
                <span class="text-dark-75">เบอร์โทรศัพท์</span>
              </th>
              <th class="text-center">
                <span class="text-dark-75">วันที่สมัคร</span>
              </th>
              <th class="text-center">
                <span class="text-dark-75">ยืนยันอีเมล์</span>
              </th>
              <th class="text-center text-nowrap">
                <span class="text-dark-75">แอดมิน</span>
              </th>
              <th style="min-width: 40px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in users.records" v-bind:key="i">
              <td class="pl-lg-0 text-center text-nowrap">
                <router-link :to="`${$route.path}/${item.userId}`">
                  {{ item.userNo }}
                </router-link>
              </td>
              <td class="text-center">
                <router-link
                  v-if="item.updated_id_info"
                  :to="`/admin/users/${item.updated_id_info.userId}`"
                >
                  {{ item.updated_id_info.username }}
                </router-link>
                <span v-if="!item.updated_id_info"> - </span>
              </td>
              <td class="pl-lg-0 text-center text-nowrap">
                <router-link :to="`${$route.path}/${item.userId}`">
                  {{ item.username }}
                </router-link>
              </td>
              <td class="pl-lg-0 text-center text-nowrap">
                {{ item.full_name }}
              </td>
              <td class="pl-lg-0 text-center">
                {{ item.email }}
              </td>
              <td class="pl-lg-0 text-center">
                {{ item.phone }}
              </td>
              <td class="pl-lg-0 text-center text-nowrap">
                {{ format_datetime(item.created_at) }}
              </td>
              <td class="pl-lg-0 text-center text-nowrap">
                <span
                  class="label label-lg label-inline"
                  v-bind:class="`label-light-${getStatusColor(item.status)}`"
                >
                  {{ item.statusShow }}
                </span>
              </td>
              <td class="pl-lg-0 text-center text-nowrap">
                <span v-if="item.is_admin" class="text-danger">แอดมิน</span>
              </td>
              <td class="text-right pr-0">
                <router-link
                  :to="`${$route.path}/${item.userId}`"
                  class="btn btn-icon btn-light btn-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                    <inline-svg
                      src="/media/svg/icons/Navigation/Arrow-right.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </router-link>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="users && users.records && !users.records.length"
            >
              <td colspan="14" class="pt-8">ไม่พบผู้ใช้</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      <b-pagination
        v-model="page"
        class="mt-4"
        size="md"
        v-if="users && users.recordsData"
        :total-rows="users.recordsData.recordsFiltered"
        :per-page="pageSize"
      ></b-pagination>
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import { GET_USERS } from "@/graphql/admin/user";
import debounce from "debounce";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";
import dayjs from "dayjs";

export default {
  created() {
    document.title = `ผู้ใช้งานทั้งหมด | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `ผู้ใช้ในระบบทั้งหมด` }]);
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      loading: false,
      search: ""
    };
  },
  methods: {
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    users: {
      query: GET_USERS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
