import gql from "graphql-tag";

export const CREATE_MESSAGE = gql`
  mutation($input: MessageInput) {
    createMessage(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query($page: Int, $pageSize: Int, $dept_id: Int) {
    messages(page: $page, pageSize: $pageSize, dept_id: $dept_id) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      dept {
        dept_id
        dept_name
      }
      records {
        _id
        sender_id
        sender {
          full_name
        }
        receiver_id
        receiver {
          full_name
        }
        body
        file_attachment
        created_at
      }
    }
  }
`;

export const GET_USER_DEPT = gql`
  query($page: Int, $pageSize: Int, $dept_id: Int, $search: String) {
    systemUserDeptList(
      page: $page
      pageSize: $pageSize
      dept_id: $dept_id
      search: $search
    ) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        userId
        photo_url
        username
        full_name
        noti
      }
    }
  }
`;

export const GET_USER_MESSAGES = gql`
  query($page: Int, $pageSize: Int, $dept_id: Int, $userId: Int) {
    systemUserMessageList(
      page: $page
      pageSize: $pageSize
      dept_id: $dept_id
      userId: $userId
    ) {
      message
      recordsData {
        recordsFiltered
      }
      user {
        userId
        full_name
        email
        username
      }
      dept {
        dept_id
        dept_name
      }
      records {
        _id
        sender_id
        sender {
          full_name
        }
        receiver_id
        receiver {
          full_name
        }
        body
        file_attachment
        created_at
      }
    }
  }
`;
