<template>
  <div>
    <b-alert v-if="messageError" variant="danger" show>{{
      messageError
    }}</b-alert>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="save">
        <div class="card">
          <div class="card-header">
            <h3 class="mb-0">ประกาศ</h3>
          </div>
          <div class="card-body" v-if="setting_notification_bar">
            <div class="row mt-4">
              <div class="col-lg-10">
                <div class="form-group row mb-2">
                  <label class="col-form-label text-right col-sm-3 col-4"
                    >ประกาศด้านบน</label
                  >
                  <div class="col-xl-6 col-lg-8 col-8">
                    <b-form-textarea
                      v-model="setting_notification_bar.data"
                      :state="validateState('data')"
                      rows="4"
                      placeholder=""
                    ></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 col-sm-9 col-12 ml-auto">
                <button
                  type="submit"
                  class="btn btn-success mr-4"
                  style="min-width: 130px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/General/Save.svg" />
                  </span>
                  บันทึก
                </button>
                <button
                  type="button"
                  @click="$apollo.queries.setting_notification_bar.refetch()"
                  class="btn btn-secondary"
                  style="min-width: 130px"
                >
                  รีเซ็ต
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { SETTING_NOTI, UPDATE_SETTING_NOTI } from "@/graphql/admin/setting";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `ตั้งค่าที่อยู่จีน | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ตั้งค่าที่อยู่จีน` }
    ]);
  },
  data() {
    return {
      messageError: "",
      text: "{{ code }}",
      setting_notification_bar: {
        data: ""
      }
    };
  },
  validations: {
    setting_notification_bar: {
      data: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.setting_notification_bar[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.setting_notification_bar.$touch();
      if (this.$v.setting_notification_bar.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { updateNotificationSetting }
      } = await this.$apollo.mutate({
        mutation: UPDATE_SETTING_NOTI,
        variables: {
          input: {
            setting_notification_bar: this.$v.setting_notification_bar.data
              .$model
          }
        }
      });
      const { isSuccess, message } = updateNotificationSetting;
      this.messageError = "";
      if (!isSuccess) {
        this.messageError = message;
      } else {
        this.$message.success(message);
        this.$apollo.queries.setting_notification_bar.refetch();
      }
    }
  },
  apollo: {
    setting_notification_bar: {
      query: SETTING_NOTI
    }
  }
};
</script>
