<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="d-md-flex">
      <div class="filterpanel">
        <h1 class="filter-head">
          ฟิลเตอร์
          <a class="clearall cursor-pointer" @click="getData()">
            <i class="ki ki-refresh"></i>
          </a>
        </h1>
        <div class="filter-pad">
          <div>
            <div class="card">
              <div class="card-header">
                <button
                  v-b-toggle.accordion-1
                  class="btn btn-link btn-block"
                  type="button"
                >
                  หมวดหมู่สินค้า
                  <i class="flaticon2-down icon-sm"></i>
                  <i class="flaticon2-next icon-sm"></i>
                </button>
              </div>
              <b-collapse
                id="accordion-1"
                visible
                accordion="my-accordion"
                role="filterpanel"
              >
                <div v-for="item in categoryList" :key="item._id" class="mb-4">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      :value="item.categoryId"
                      :checked="checkedCate(item)"
                      v-on:change="selectCate($event, item)"
                    />&nbsp;
                    <span> </span>
                    <div class="ml-3">
                      {{ item.originalName }} ({{ item.numOfItem }})
                    </div>
                  </label>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <div class="row rightpanel product-list">
        <div v-for="item in records" :key="item.productId" class="items">
          <div class="product-row-item">
            <router-link :to="`/product/${item.productId}`">
              <div class="bximg cursor-pointer">
                <div class="cursor-pointer">
                  <img :src="imageUrlApi(item.mainImage)" />
                </div>
              </div>
              <div class="bxdetail">
                <h2 class="cursor-pointer">
                  <span :title="item.productName">{{ item.productName }}</span>
                </h2>
                <div class="price-wrapper position-relative">
                  <div class="price with-discount">
                    <label class="offer">
                      <span class="number-font">{{ item.priceShow }}</span>
                    </label>
                    <label class="offer price-unit">/ชิ้น </label>
                    <!-- <div class="off-price post-discount ">
                  <label class="actual ml-1"
                    ><span class="number-font">฿1,590</span>
                    <span class="discount-text green-txt inline-block"
                      >ประหยัด ฿641
                    </span></label
                  >
                </div> -->
                  </div>
                </div>
                <div class="d-flex rating-content no-rating">
                  <div class="item-sold">
                    <span>ขายแล้ว</span> {{ item.sales || 0 }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <b-pagination
          v-model="page"
          class="mt-4 justify-content-center"
          size="md"
          v-if="recordsData"
          :total-rows="recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
    </div>
  </b-overlay>
</template>
<style lang="scss">
@import "@/assets/sass/product_styles.scss";
@import "@/assets/sass/product_list_style.scss";
</style>
<script>
import { API_URL, WEBSITE, imageUrlApi, encodeQueryData } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      search: "",
      page: 1,
      pageSize: 10,
      categoryList: [],
      cate: [],
      records: [],
      recordsData: {},
      loading: false
    };
  },
  mounted() {
    document.title = `สินค้าทั้งหมด | ${WEBSITE}`;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "สินค้าทั้งหมด" }]);
  },
  created() {
    this.getCategory();
    this.getData();
  },
  methods: {
    imageUrlApi,
    checkedCate(item) {
      return this.cate.find(x => x === item.categoryId);
    },
    selectCate(event, item) {
      const checked = event.target.checked;
      const found = this.cate.find(x => x === item.categoryId);
      if (!found && checked) {
        this.cate.push(item.categoryId);
      } else if (found) {
        const index = this.cate.indexOf(found);
        this.cate.splice(index, 1);
      }
      this.getData();
    },
    async getData() {
      const query = {
        q: this.search,
        cate: this.cate,
        page: this.page,
        pageSize: this.pageSize
      };
      const querystring = encodeQueryData(query);
      this.loading = true;
      const res = await fetch(`${API_URL}/seller/product?${querystring}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      this.loading = false;
      if (res.status === 200) {
        const data = await res.json();
        if (data.isSuccess) {
          this.records = data.records;
          this.recordsData = data.recordsData;
        } else {
          this.$message.error(data.message);
        }
      } else {
        this.$message.error("ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
      }
    },
    async getCategory() {
      const res = await fetch(`${API_URL}/seller/category`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.isSuccess) {
          this.categoryList = data.records;
        } else {
          this.$message.error(data.message);
        }
      } else {
        this.$message.error("ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
      }
    }
  }
};
</script>
