<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card card-custom card-stretch" v-if="systemBankList">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title flex-column">
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              รายการบัญชีธนาคาร
            </span>
          </div>
        </h3>
      </div>
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <router-link
          :to="`${$route.path}/create`"
          class="btn btn-success font-weight-bolder font-size-sm mr-4"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
          </span>
          เพิ่มบัญชีธนาคาร
        </router-link>
        <button
          @click="$apollo.queries.systemBankList.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
      </div>
      <div class="card-body pt-2 pb-0">
        <table
          class="table table-sm table-head-bg table-borderless table-vertical-center"
        >
          <thead>
            <tr>
              <th class="pl-4">ธนาคาร</th>
              <th>ชื่อบัญชี</th>
              <th>เลขบัญชี</th>
              <th>รูป QR</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in systemBankList.records" v-bind:key="i">
              <td class="pl-4">
                {{ getBankName(item.bank_name) }}
              </td>
              <td>
                {{ item.account_name }}
              </td>
              <td>
                {{ item.account_no }}
              </td>
              <td>
                <div class="symbol symbol-30 symbol-light">
                  <span class="symbol-label">
                    <img
                      v-viewer
                      :src="imageUrlApi(item.bank_qr)"
                      v-if="item.bank_qr"
                      class="h-100 align-self-center object-fit-contain pointer"
                      :alt="item.account_name"
                    />
                  </span>
                </div>
              </td>
              <td class="text-right pr-4 text-nowrap">
                <button
                  class="btn btn-icon btn-light btn-sm mr-2"
                  @click="toggleShow(item._id, !item.is_show)"
                >
                  <span v-if="item.is_show" class="la la-eye icon-lg" />
                  <span v-if="!item.is_show" class="la la-eye-slash icon-lg" />
                </button>
                <button
                  class="btn btn-icon btn-danger btn-sm"
                  @click="deleteBank(item._id, item.bank_name)"
                >
                  <span class="la la-trash icon-lg" />
                </button>
                <router-link
                  :to="`/admin/setting/bank/${item._id}`"
                  class="btn btn-icon btn-light btn-sm ml-2"
                >
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                    <inline-svg
                      src="/media/svg/icons/Navigation/Arrow-right.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </router-link>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="
                systemBankList &&
                  systemBankList.records &&
                  !systemBankList.records.length
              "
            >
              <td colspan="6" class="pt-8">ไม่พบบัญชีธนาคาร</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { GET_BANKS, DELETE_BANK, UPDATE_BANK } from "@/graphql/admin/bank";
import { getBankName, WEBSITE, imageUrlApi } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  created() {
    document.title = `บัญชีธนาคาร | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `บัญชีธนาคาร` }
    ]);
  },
  methods: {
    imageUrlApi,
    getBankName,
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    async toggleShow(bank_id, is_show) {
      const {
        data: { updateSystemBank }
      } = await this.$apollo.mutate({
        mutation: UPDATE_BANK,
        variables: {
          input: {
            _id: bank_id,
            is_show
          }
        }
      });
      if (!updateSystemBank.isSuccess) {
        this.$message.error(updateSystemBank.message);
      } else {
        this.$apollo.queries.systemBankList.refetch();
      }
    },
    async deleteBank(bank_id, bank_name) {
      const confirm = await Swal.fire({
        title: "ลบบัญชีธนาคาร ?",
        icon: "question",
        html: `ยืนยันการลบบัญชี ${bank_name} ใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { deleteSystemBank }
      } = await this.$apollo.mutate({
        mutation: DELETE_BANK,
        variables: { _id: bank_id }
      });
      if (!deleteSystemBank.isSuccess) {
        this.$message.error(deleteSystemBank.message);
      } else {
        this.$message.success(deleteSystemBank.message);
        this.$apollo.queries.systemBankList.refetch();
      }
    }
  },
  apollo: {
    systemBankList: {
      query: GET_BANKS
    }
  }
};
</script>
