<template>
  <div>
    <b-form class="form" @submit.stop.prevent="do_checkout">
      <div class="cart-pad">
        <div class="my-cart">
          <div
            class="card card-custom"
            v-for="vendor in profile_cart"
            v-bind:key="vendor._id"
          >
            <div class="card-body pb-0 px-0">
              <div class="table-responsive px-8">
                <table class="table table-borderless table-vertical-center">
                  <thead>
                    <tr>
                      <th class="pl-0 font-weight-bold text-muted d-flex">
                        <div class="text-overflow" style="width: 90px">
                          {{ vendor.vendor }}
                        </div>
                        <span class="ml-4 text-success" v-if="vendor.guarantee">
                          ตีลังไม้
                        </span>
                      </th>
                      <th class="text-center font-weight-bold text-muted">
                        จำนวน
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        ราคา({{ vendor.currencySign || "฿" }})
                      </th>
                      <th class="text-right pr-0 font-weight-bold text-muted">
                        รวม({{ vendor.currencySign || "฿" }})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="font-weight-boldest"
                      v-for="item in vendor.items"
                      v-bind:key="item._id"
                    >
                      <td
                        class="pl-0 pt-6 pb-6 align-items-center align-middle"
                      >
                        <div class="d-flex">
                          <!--begin::Symbol-->
                          <a :href="item.url" target="_blank">
                            <div
                              class="symbol symbol-40 flex-shrink-0 mr-4 bg-light"
                            >
                              <div
                                class="symbol-label"
                                :style="
                                  `background-image: url('${item.mainImage}')`
                                "
                              ></div>
                            </div>
                            <!--end::Symbol-->
                          </a>
                          <div>
                            <a :href="item.url" target="_blank">
                              {{ item.productName }}
                            </a>
                            <div
                              class="opacity-80 font-weight-bold d-block"
                              v-bind:key="c.id"
                              v-for="c in item.properties"
                            >
                              <span v-if="c.name">{{ c.name }} : </span>
                              <img
                                v-viewer
                                v-if="c.image"
                                :src="c.image"
                                style="height: 20px"
                                class="align-self-center pointer mr-2"
                                :alt="item.productName"
                              />
                              <span>{{ c.title }}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-center pt-0 align-middle">
                        {{ format_number(item.amount) }}
                      </td>
                      <td class="text-right pt-0 align-middle">
                        ฿{{ format_number(item.price, 2) }}
                      </td>
                      <td class="text-right pt-0 align-middle">
                        ฿{{ format_number(item.price * item.amount, 2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex row-shop-footer">
                <div class="d-flex align-items-center shop-remark pl-8">
                  <span class="text-nowrap">หมายเหตุ :</span>
                  <b-form-input
                    class="ml-4"
                    v-model="vendor.remark"
                    placeholder="(ไม่บังคับ) ฝากข้อความถึงผู้ขาย"
                  ></b-form-input>
                </div>
                <div class="d-flex shop-shipping-price">
                  <div>ค่าขนส่ง</div>
                  <div class="price">
                    ฿{{ format_number(shipping_price[vendor.sellerId]) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card card-custom"
            v-if="profile_cart && !profile_cart.length"
          >
            <div class="card-body">
              <div class="text-center h5 pb-4">ไม่มีข้อมูลสินค้า</div>
            </div>
          </div>
        </div>
        <div class="order-summary">
          <div class="card card-custom px-4 py-4 align-center">
            <div><h4>ที่อยู่ในการจัดส่ง</h4></div>
            <div class="address-title-container">
              <i class="la la-map-marker text-primary mr-1" />
              <span class="address-title">
                {{ this.form.address.name }}
              </span>
              <span class="address-edit" v-b-modal.address-modal> แก้ไข </span>
            </div>
            <div class="address-info-item address-value">
              {{ renderAddress(form.address) }}
            </div>
            <input-edit
              :value="form.address.phone"
              placeholder="โปรดป้อนโทรศัพท์ของคุณ"
              :onChange="onChangePhone"
            />
            <div
              class="address-title-container"
              :class="validateState('logistics')"
            >
              <i class="la la-truck text-primary mr-1" />
              <span class="address-title">
                {{
                  this.form.logistics
                    ? this.form.logistics.logisticsName
                    : "กรุณาเลือกขนส่ง"
                }}
              </span>
              <span class="address-edit" v-b-modal.logistics-modal>
                เปลี่ยนขนส่ง
              </span>
            </div>
            <div class="mt-4"><h4>สรุปข้อมูลคำสั่งซื้อ</h4></div>
            <div class="pt-3">
              <div class="total-items-price">
                <div>ยอดรวม ({{ count }} ชิ้น)</div>
                <div class="summary">฿{{ format_number(totalBaht, 2) }}</div>
              </div>
              <div class="total-items-price mt-4">
                <div>ค่าจัดส่ง</div>
                <div class="summary">
                  ฿{{ format_number(forwarder_price, 2) }}
                </div>
              </div>
              <div class="total-items-price mt-4" v-if="logistics_price">
                <div>ค่าจัดส่งในประเทศของท่าน</div>
                <div class="summary">
                  ฿{{ format_number(logistics_price, 2) }}
                </div>
              </div>
              <div class="order-summary-total mt-4">
                <label itemkey="totalPrice">ยอดรวมทั้งสิ้น</label>
                <label>
                  <span class="number-font order-total">
                    ฿{{ format_number(totalBahtAll, 2) }}
                  </span>
                </label>
              </div>
              <button
                class="btn btn-success btn-block btn-lg mt-4"
                type="submit"
              >
                สั่งซื้อ
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-form>
    <b-modal
      id="logistics-modal"
      v-if="logisticsList"
      centered
      size="lg"
      ok-title="ปิด"
      ok-only
      ok-variant=""
    >
      <template #modal-title>
        เลือกขนส่งในประเทศ
        <button
          @click="$apollo.queries.logisticsList.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
      </template>
      <modal-delivery
        :list="logisticsList.records"
        :onSelect="onSelectDelivery"
        :sumWeight="sumWeight"
      />
    </b-modal>
    <b-modal
      id="address-modal"
      centered
      size="lg"
      ok-title="ปิด"
      ok-only
      ok-variant=""
    >
      <template #modal-title>
        เลือกที่อยู่จัดส่ง
        <button
          @click="$apollo.queries.profile.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
        <span class="split">|</span>
        <router-link to="/profile?cat=2" target="_blank">
          แก้ไขที่อยู่
        </router-link>
      </template>
      <modal-address
        v-if="profile"
        :list="addressList"
        :onSelect="onSelectAddress"
      />
    </b-modal>
  </div>
</template>
<style lang="scss">
@import "./styles.scss";
</style>
<script>
import { mapGetters } from "vuex";
import bigDecimal from "js-big-decimal";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { CALCULATE_FORWARDER, CHECKOUT_SELLER } from "@/graphql/seller_cart";
import { GET_LOGISTICS_LIST_SELECT } from "@/graphql/delivery";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_CHECKOUT } from "@/core/services/store/seller_checkout.module";
import { WEBSITE } from "@/config";
import ModalAddress from "@/components/ModalAddress.vue";
import ModalDelivery from "./ModalDelivery.vue";
import { GET_PROFILE_ADDRESS } from "@/graphql/profile";
import InputEdit from "./InputEdit.vue";
import Swal from "sweetalert2";
import { NOTI } from "@/core/services/store/profile.module.js";

export default {
  mixins: [validationMixin],
  name: "seller_checkout",
  created() {
    document.title = `ยืนยันรายการสั่งซื้อ | ${WEBSITE}`;
  },
  components: {
    ModalAddress,
    ModalDelivery,
    InputEdit
  },
  mounted() {
    const profile_cart = [];
    const checkout = this.seller_checkout;
    const vendor = this.seller_vendor;

    Object.keys(checkout).map(key => {
      profile_cart.push({
        qc: false,
        ...vendor[key],
        items: checkout[key].map(x => ({
          _id: x._id,
          productName: x.productName,
          productId: x.productId,
          url: x.url,
          skuId: x.skuId,
          amount: x.amount,
          price: x.price,
          size: x.size,
          properties: x.properties,
          mainImage: x.mainImage,
          provider: x.provider,
          currency: x.currency,
          currencySign: x.currencySign,
          vendor: x.vendor,
          remark: x.remark
        }))
      });
    });
    if (profile_cart.length === 0) {
      this.$router.push({ name: "seller_cart" });
      return;
    }
    let total = 0;
    let count = 0;
    let sumWeight = 0;
    profile_cart.map(vendor => {
      count += vendor.items.reduce((total, val) => total + val.amount, 0);
      sumWeight += vendor.items.reduce(
        (total, val) => total + val.amount * val.size.weight,
        0
      );
      const sum = vendor.items.reduce(
        (total, val) => total + (!val.currency ? val.price : 0) * val.amount,
        0
      );
      total += sum;
    });
    const totalBaht = total;
    this.count = count;
    this.sumWeight = sumWeight;
    this.totalBaht = totalBaht;
    this.totalBahtAll = totalBaht + this.forwarder_price;
    this.profile_cart = profile_cart;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตะกร้าสินค้า`, route: "/cart/seller" },
      { title: `ยืนยันรายการสั่งซื้อ` }
    ]);
    this.calculate();
  },
  computed: {
    ...mapGetters(["seller_checkout", "seller_vendor"])
  },
  data() {
    return {
      total: 0,
      totalBaht: 0,
      totalBahtAll: 0,
      profile_cart: {},
      count: 0,
      addressList: [],
      shipping_price: {},
      forwarder_price: 0,
      logistics_price: 0,
      sumWeight: 0,
      form: {
        ship_by: "ek",
        remark: "",
        logistics: null,
        address: {
          name: "",
          address: "",
          province: "",
          amphoe: "",
          district: "",
          postcode: "",
          phone: ""
        }
      }
    };
  },
  validations: {
    form: {
      address: {
        name: { required },
        address: { required },
        province: { required },
        amphoe: { required },
        district: { required },
        postcode: { required },
        phone: { required }
      },
      ship_by: { required },
      logistics: { required },
      remark: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty
        ? !$error
          ? "is-valid"
          : "border rounded border-danger p-2"
        : "";
    },
    resetForm() {
      this.form = {
        ship_by: null,
        remark: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async do_checkout() {
      this.$v.form.$touch();
      const address = this.$v.form.address.$model;
      const ship_by = this.$v.form.ship_by.$model;
      const remark = this.$v.form.remark.$model;
      const logistics = this.$v.form.logistics.$model;

      if (!address.address) {
        this.$message.error("กรุณาเลือกที่อยู่");
      } else if (!logistics) {
        this.$message.error("กรุณาเลือกขนส่ง");
      }
      if (this.$v.form.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันคำสั่งซื้อ",
        icon: "question",
        html: `<div style="font-size: 18px;">ชำระเงิน ฿${this.format_number(
          this.totalBahtAll,
          2
        )} ?</div>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { checkout_seller }
      } = await this.$apollo.mutate({
        mutation: CHECKOUT_SELLER,
        variables: {
          input: {
            remark,
            ship_by,
            address: address,
            vendors: this.profile_cart,
            logistics
          }
        }
      });
      if (!checkout_seller.isSuccess) {
        this.$message.error(
          checkout_seller.message ||
            (checkout_seller.error_list &&
              checkout_seller.error_list.map(x => x.message).join())
        );
        return;
      } else {
        this.$store.dispatch(UPDATE_CHECKOUT, {
          selected: {},
          vendor: {}
        });
        this.$router.push({ name: "sale_order_list" });
        this.$store.dispatch(NOTI);
        this.$message.success(checkout_seller.message);
      }
    },
    format_number(val, decimal = 0) {
      return Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    },
    onChangePhone(val) {
      this.form.address.phone = val;
    },
    onSelectAddress(item) {
      this.form.address = item;
    },
    onSelectDelivery(item, price) {
      this.form.logistics = item;
      this.logistics_price = price + item.logisticsServicePrice;
      this.calculate();
    },
    renderAddress(address) {
      const isBankok = address.province === "กรุงเทพมหานคร";
      const district =
        address.district && `${isBankok ? "แขวง " : "ตำบล"}${address.district}`;
      const amphoe =
        address.amphoe && `${isBankok ? "เขต " : "อำเภอ"}${address.amphoe}`;
      const province = address.province;
      const postcode = address.postcode && `${address.postcode}`;
      const address1 = address.address;
      const arr = [];
      this.checkPush(arr, address1);
      this.checkPush(arr, district);
      this.checkPush(arr, amphoe);
      this.checkPush(arr, province);
      this.checkPush(arr, postcode);
      return arr.join(", ");
    },
    checkPush(arr, val) {
      if (val) {
        arr.push(val);
      }
    },
    async calculate() {
      const ship_by = this.$v.form.ship_by.$model;
      const {
        data: { calculate_forwarder }
      } = await this.$apollo.mutate({
        mutation: CALCULATE_FORWARDER,
        variables: {
          input: {
            ship_by,
            vendors: this.profile_cart
          }
        }
      });
      const { data, shipping_price } = calculate_forwarder;
      this.forwarder_price = data;
      shipping_price.map(x => {
        this.shipping_price[x.sellerId] = x.shipping_price;
      });
      this.totalBahtAll =
        this.totalBaht + this.forwarder_price + this.logistics_price;
    }
  },
  watch: {
    "form.ship_by": async function() {
      this.calculate();
    }
  },
  apollo: {
    profile: {
      query: GET_PROFILE_ADDRESS,
      update: data => data,
      result({ data, loading }) {
        if (!loading) {
          const { profile } = data;
          this.addressList = profile.address;
          const find = profile.address.find(x => x.default) ||
            profile.address[0] || {
              name: "",
              address: "",
              province: "",
              amphoe: "",
              district: "",
              postcode: "",
              phone: ""
            };
          this.form.address = find;
        }
      }
    },
    logisticsList: {
      query: GET_LOGISTICS_LIST_SELECT,
      variables() {
        return {
          country: "lao"
        };
      }
    }
  }
};
</script>
