<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="systemTransportList">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            รายการพัสดุ
          </span>
          <span
            class="text-muted font-weight-bold font-size-sm"
            v-if="systemTransportList.recordsData"
          >
            More than {{ systemTransportList.recordsData.recordsFiltered }}+
            transports
          </span>
        </div>
      </h3>
    </div>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <div class="input-group input-group-solid mr-4" style="width: 280px">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหา"
            @input="debounceInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </div>
        </div>
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer"
              data-toggle="tab"
              :class="{ active: this.type === 'china-thai' }"
              @click="pushCat('china-thai')"
              >จีน-ไทย</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 cursor-pointer"
              data-toggle="tab"
              :class="{ active: this.type === 'thai-lao' }"
              @click="pushCat('thai-lao')"
              >ไทย-ลาว</a
            >
          </li>
        </ul>
        <b-dropdown :text="getStatusText()" dropdown>
          <b-dropdown-item
            value=""
            @click="
              status = '';
              page = 1;
            "
          >
            ทั้งหมด
          </b-dropdown-item>
          <b-dropdown-item
            value="0"
            @click="
              status = '0';
              page = 1;
            "
          >
            รอสินค้าเข้าโกดัง
          </b-dropdown-item>
          <b-dropdown-item
            value="10"
            @click="
              status = '10';
              page = 1;
            "
          >
            สินค้าเข้าโกดังจีน
          </b-dropdown-item>
          <b-dropdown-item
            value="20"
            @click="
              status = '20';
              page = 1;
            "
          >
            กำลังส่งมาโกดังปลายทาง
          </b-dropdown-item>
          <b-dropdown-item
            value="30"
            @click="
              status = '30';
              page = 1;
            "
          >
            รอชำระเงิน
          </b-dropdown-item>
          <b-dropdown-item
            value="40"
            @click="
              status = '40';
              page = 1;
            "
          >
            เตรียมส่ง
          </b-dropdown-item>
          <b-dropdown-item
            value="99"
            @click="
              status = '99';
              page = 1;
            "
          >
            ส่งแล้ว
          </b-dropdown-item>
        </b-dropdown>
        <button
          @click="$apollo.queries.systemTransportList.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon ml-4"
        >
          <i class="ki ki-refresh"></i>
        </button>
        <router-link
          to="/admin/forwarder/create"
          class="btn btn-success font-weight-bolder font-size-sm ml-4"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Communication/Add-user.svg-->
            <inline-svg src="/media/svg/icons/Shopping/Box3.svg" />
            <!--end::Svg Icon-->
          </span>
          สร้างรายการขนส่ง
        </router-link>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-8 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-sm table-head-bg table-borderless table-vertical-center"
          >
            <thead>
              <tr>
                <th style="width: 50px">
                  <span class="text-dark-75 text-nowrap">
                    เลขพัสดุ
                  </span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">เลข PO</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ล๊อต</span>
                </th>
                <th class="text-center text-nowrap">
                  <span class="text-dark-75">ผู้ใช้งาน</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ราคา</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ประเภทสินค้า</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">เข้าโกดัง</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ออกโกดัง</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ถึงโกดังปลายทาง</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">จำนวน</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">KG</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ขนาด</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">CBM</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">รูป</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">สถานะ</span>
                </th>
                <th style="min-width: 40px"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in systemTransportList.records"
                v-bind:key="i"
                :class="{
                  'bg-blue-lighten':
                    item.trackingWarehouse &&
                    (item.trackingWarehouse.warehouseKg ||
                      item.trackingWarehouse.warehouseCube),
                  'bg-primary-lighten':
                    item.in_order && item.in_order.length > 0
                }"
              >
                <td>
                  <router-link :to="`/admin/forwarder/${item.transportId}`">
                    {{ item.transportNumber }}
                  </router-link>
                </td>
                <td class="pl-0 text-center">
                  {{ item.remarkPurchaseOrder }}
                </td>
                <td class="pl-0 text-center">
                  {{ item.ship_by === "ek" ? "รถ" : "เรือ" }}
                  {{ " " }}
                  {{ item.lotId || "-" }}
                </td>
                <td class="text-center text-nowrap">
                  <div class="font-weight-bold">
                    <router-link :to="`/admin/users/${item.userId}`">
                      {{ item.user ? item.user.username : "-" }}
                    </router-link>
                  </div>
                </td>
                <td
                  class="text-right text-nowrap"
                  :class="item.trackingPrice?.is_minimum ? 'text-danger' : ''"
                >
                  {{ item.trackingPrice ? item.trackingPrice.priceValue : "-" }}
                  <small>
                    {{
                      item.trackingPrice && item.trackingPrice.priceName
                        ? `(ราคา ${
                            item.trackingPrice.priceName === "cube"
                              ? "CBM"
                              : "KG"
                          })`
                        : ""
                    }}
                  </small>
                </td>
                <td class="text-center">
                  {{
                    item.userLevelPrice
                      ? item.userLevelPrice.userLevelDetailName
                      : "-"
                  }}
                </td>
                <td class="text-center">
                  {{ format_date(item.timeline.send_to_warehouse_china) }}
                </td>
                <td class="text-center">
                  {{ format_date(item.timeline.send_out_china) }}
                </td>
                <td class="text-center">
                  {{ format_date(item.timeline.send_to_warehouse_th) }}
                </td>
                <td class="text-center">
                  {{ item.amount }}
                </td>
                <td class="text-center">
                  {{
                    calculateWeight(
                      item.size && item.size.weight,
                      item.amount
                    ) || "-"
                  }}
                </td>
                <td class="text-center text-nowrap">
                  {{
                    `${item.size.width || 0} x ${item.size.long || 0} x ${item
                      .size.height || 0}`
                  }}
                </td>
                <td class="text-center">
                  {{ calculateCube(item.size, item.amount) }}
                </td>
                <td class="text-center">
                  <div class="symbol symbol-20 flex-shrink-0">
                    <img
                      v-if="item.file_tracking && item.file_tracking[0]"
                      :src="imageUrlApi(item.file_tracking[0])"
                      style="object-fit: cover"
                      class="symbol-label"
                      v-viewer
                    />
                  </div>
                </td>
                <td class="text-center text-nowrap">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-${getStatusColor(item.status)}`"
                  >
                    {{ item.statusShow }}
                  </span>
                </td>
                <td class="text-right pr-0">
                  <router-link
                    :to="`/admin/forwarder/${item.transportId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="
                  systemTransportList &&
                    systemTransportList.records &&
                    !systemTransportList.records.length
                "
              >
                <td colspan="14" class="pt-8">ไม่พบรายการขนส่ง</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="systemTransportList && systemTransportList.recordsData"
          :total-rows="systemTransportList.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Body-->
    </b-overlay>
  </div>
</template>

<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import { mapGetters } from "vuex";
import { calculateCube, calculateWeight, WEBSITE, imageUrlApi } from "@/config";
import { GET_TRACKINGS } from "@/graphql/admin/transport.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "transport_list",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "รายการพัสดุ" }]);
  },
  created() {
    document.title = `รายการพัสดุ | ${WEBSITE}`;
  },
  data() {
    const { type = "china-thai" } = this.$route.query;
    return {
      page: 1,
      pageSize: 10,
      systemTransportList: {},
      loading: false,
      status: "",
      search: "",
      type: type
    };
  },
  methods: {
    imageUrlApi,
    calculateCube,
    calculateWeight,
    pushCat(val) {
      const newpath = `${this.$route.path}?type=${val}`;
      if (this.$route.fullPath !== newpath) {
        this.$router.push({ name: "admin_forwarder", query: { type: val } });
      }
    },
    getStatusText() {
      switch (this.status) {
        case "":
          return "ทั้งหมด";
        case "0":
          return "รอสินค้าเข้าโกดัง";
        case "10":
          return "สินค้าเข้าโกดังจีน";
        case "20":
          return "กำลังส่งมาโกดังปลายทาง";
        case "30":
          return "รอชำระเงิน";
        case "40":
          return "เตรียมส่ง";
        case "99":
          return "ส่งแล้ว";
        default:
          return "";
      }
    },
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 40:
        case 99:
          return "success";
        default:
          return "primary";
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    }
  },
  watch: {
    "$route.query.type": function(type) {
      this.type = type;
    }
  },
  apollo: {
    systemTransportList: {
      query: GET_TRACKINGS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status,
          type: this.type
        };
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
