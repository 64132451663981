<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card card-custom card-stretch" v-if="systemUserLevelList">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title flex-column">
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              รายการขนส่งจากจีนมาไทย
            </span>
          </div>
        </h3>
      </div>
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <router-link
          :to="`${$route.path}/create`"
          class="btn btn-success font-weight-bolder font-size-sm mr-4"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
          </span>
          เพิ่มขนส่งจากจีนมาไทย
        </router-link>
        <button
          @click="$apollo.queries.systemUserLevelList.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
      </div>
      <div class="card-body pt-2 pb-0">
        <div class="table-responsive">
          <table
            class="table table-sm table-head-bg table-borderless table-vertical-center"
            aria-describedby="รายการขนส่งจากจีนมาไทย"
          >
            <thead>
              <tr>
                <th class="pl-4 text-nowrap">ชื่อราคา</th>
                <th class="pl-4 text-nowrap">ค่าบริการฝากสั่ง คิวละ</th>
                <th class="pl-4 text-nowrap">ค่าบริการฝากสั่ง กิโลละ</th>
                <th class="px-2 text-center" width="200">
                  ค่าเริ่มต้นตอนสมัคร
                </th>
                <th width="80"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in systemUserLevelList.records"
                v-bind:key="i"
              >
                <td class="pl-4 text-nowrap">
                  <router-link :to="`${$route.path}/${item.userLevelId}`">
                    {{ item.userLevelName }}
                  </router-link>
                </td>
                <td class="pl-4 text-nowrap">
                  {{ item.serviceCube }}
                </td>
                <td class="pl-4 text-nowrap">
                  {{ item.serviceKg }}
                </td>
                <td class="px-2 text-center">
                  <span class="switch switch-sm mt-1 justify-content-center">
                    <label class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        :checked="item.is_default"
                        v-model="item.is_default"
                        @change="toggleDefault(item._id, item.is_default)"
                      />
                      <span></span>
                    </label>
                  </span>
                </td>
                <td class="text-right pr-4 text-nowrap">
                  <button
                    class="btn btn-icon btn-danger btn-sm mr-2"
                    @click="deleteItem(item._id, item.userLevelName)"
                  >
                    <span class="la la-trash icon-lg" />
                  </button>
                  <router-link
                    :to="`${$route.path}/${item.userLevelId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                    </span>
                  </router-link>
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="
                  systemUserLevelList &&
                    systemUserLevelList.records &&
                    !systemUserLevelList.records.length
                "
              >
                <td colspan="6" class="pt-8">ไม่พบขนส่งจากจีนมาไทย</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import {
  GET_USER_LEVEL_LIST,
  DELETE_USER_LEVEL,
  UPDATE_USER_LEVEL
} from "@/graphql/admin/user_level";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  created() {
    document.title = `ขนส่งจากจีนมาไทย | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ขนส่งจากจีนมาไทย` }
    ]);
  },
  methods: {
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    async toggleDefault(_id, is_default) {
      const {
        data: { updateUserLevel }
      } = await this.$apollo.mutate({
        mutation: UPDATE_USER_LEVEL,
        variables: {
          input: {
            _id,
            is_default
          }
        }
      });
      if (!updateUserLevel.isSuccess) {
        this.$message.error(updateUserLevel.message);
      } else {
        this.$apollo.queries.systemUserLevelList.refetch();
      }
    },
    async deleteItem(_id, name) {
      const confirm = await Swal.fire({
        title: "ลบขนส่งจากจีนมาไทย ?",
        icon: "question",
        html: `ยืนยันการลบ ${name} ใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { deleteUserLevel }
      } = await this.$apollo.mutate({
        mutation: DELETE_USER_LEVEL,
        variables: { _id }
      });
      if (!deleteUserLevel.isSuccess) {
        this.$message.error(deleteUserLevel.message);
      } else {
        this.$message.success(deleteUserLevel.message);
        this.$apollo.queries.systemUserLevelList.refetch();
      }
    }
  },
  apollo: {
    systemUserLevelList: {
      query: GET_USER_LEVEL_LIST
    }
  }
};
</script>
