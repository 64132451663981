// action types
export const UPDATE_CHECKOUT = "updateCheckoutSeller";

// mutation types
export const SET_CHECKOUT = "setCheckoutSeller";

const state = {
  seller_checkout: {},
  seller_vendor: {}
};

const getters = {
  seller_checkout(state) {
    return state.seller_checkout;
  },

  seller_vendor(state) {
    return state.seller_vendor;
  }
};

const actions = {
  [UPDATE_CHECKOUT](context, payload) {
    context.commit(SET_CHECKOUT, payload);
  }
};

const mutations = {
  [SET_CHECKOUT](state, { selected, vendor }) {
    state.seller_checkout = selected;
    state.seller_vendor = vendor;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
