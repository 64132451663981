<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div
      class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style="background-image: url(/assets/progress-hd.png)"
    >
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column-fluid p-6 p-sm-10 pb-lg-20">
        <b-overlay :show="loading" rounded="sm">
          <div
            class="w-lg-500px w-sm-400px w-100 bg-white rounded shadow-sm mx-auto"
          >
            <div class="d-flex flex-column flex-column-fluid text-center p-10">
              <!--begin::Logo-->
              <router-link to="/login">
                <img alt="Logo" :src="loaderLogo" class="h-120px" />
              </router-link>
              <!--end::Logo-->
              <!--begin::Wrapper-->
              <div class="pt-lg-10">
                <!--begin::Logo-->
                <h1 class="fw-bolder fs-2qx text-dark mb-7">
                  Verify Your Email
                </h1>
                <!--end::Logo-->
                <!--begin::Message-->
                <div class="fs-3 fw-bold text-gray-400 mb-10">
                  We have sent an email to
                  <a href="#" class="link-primary fw-bolder">{{ email }}</a>
                  <br />pelase follow a link to verify your email.
                </div>
                <!--end::Message-->
                <!--begin::Action-->
                <div class="text-center mb-10">
                  <router-link
                    to="/login"
                    class="btn btn-lg btn-primary fw-bolder"
                    >Go to login</router-link
                  >
                </div>
                <!--end::Action-->
                <!--begin::Action-->
                <div class="fs-5">
                  <span class="fw-bold text-gray-700 mr-2"
                    >Did’t receive an email?</span
                  >
                  <a
                    @click="resend"
                    class="link-primary fw-bolder cursor-pointer"
                  >
                    Resend
                  </a>
                </div>
                <!--end::Action-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
<script>
import { mapGetters } from "vuex";
import { RESEND_MAIL } from "@/core/services/store/auth.module";

export default {
  data() {
    const { email } = this.$route.query;
    return {
      email,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated", "layoutConfig"]),
    loaderLogo() {
      return this.layoutConfig("loader.logo");
    }
  },
  methods: {
    resend(e) {
      e.preventDefault();
      if (this.email) {
        this.loading = true;
        this.$store
          .dispatch(RESEND_MAIL, {
            email: this.email
          })
          .then(
            ({ code, message }) => {
              this.loading = false;
              if (code === 200) {
                this.$message.success(message);
              } else {
                this.$message.error(message);
              }
            },
            error => {
              this.loading = false;
              this.$message.error(error.message);
            }
          );
      }
    }
  }
};
</script>
