var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body pt-0 pb-3"},[_c('b-overlay',{attrs:{"show":_vm.$apollo.loading,"rounded":"sm"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-head-bg table-borderless table-vertical-center"},[_c('thead',[_c('tr',{staticClass:"text-uppercase"},[_c('th',{staticClass:"pl-7",staticStyle:{"min-width":"100px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("รหัสธุรกรรม")])]),_c('th',{staticStyle:{"min-width":"100px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("วันที่")])]),_c('th',{staticStyle:{"min-width":"100px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("ประเภท")])]),_c('th',{staticStyle:{"min-width":"150px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("หมายเหตุ")])]),_c('th',{staticClass:"text-right pr-7",staticStyle:{"min-width":"120px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("ยอดเงิน")])]),_c('th',{staticClass:"text-right pr-7",staticStyle:{"min-width":"100px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("คงเหลือ")])])])]),(_vm.transactions)?_c('tbody',[_vm._l((_vm.transactions.records),function(item){return _c('tr',{key:item._id},[_c('td',{staticClass:"pl-7 py-8 font-weight-bolder font-size-lg"},[_vm._v(" "+_vm._s(item.transaction_no)+" ")]),_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.format_datetime(item.created_at))+" ")])]),_c('td',[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(item.transaction_type ? item.transaction_type.desc : "")+" ")])]),_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v(" "+_vm._s(item.reason)+" ")])]),_c('td',{staticClass:"text-right pr-7"},[_c('span',{staticClass:"font-weight-bolder d-block font-size-lg",class:{
                  'text-danger': item.amount < 0,
                  'text-success': item.amount >= 0
                }},[_vm._v(" "+_vm._s(_vm.format_number(item.amount, 2, true))+" ")])]),_c('td',{staticClass:"text-right pr-7"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.format_number(item.remain, 2, true))+" ")])])])}),(
              _vm.transactions &&
                _vm.transactions.records &&
                !_vm.transactions.records.length
            )?_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"pt-8",attrs:{"colspan":"7"}},[_vm._v("ไม่พบรายการ")])]):_vm._e()],2):_vm._e()])]),(_vm.transactions && _vm.transactions.recordsData)?_c('b-pagination',{staticClass:"mt-4",attrs:{"size":"md","total-rows":_vm.transactions.recordsData.recordsFiltered,"per-page":_vm.pageSize},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }