import gql from "graphql-tag";

export const APPROVE_WITHDRAW = gql`
  mutation($approve: ApproveInput) {
    approveWithdraw(approve: $approve) {
      isSuccess
      message
    }
  }
`;

export const REJECT_WITHDRAW = gql`
  mutation($id: Int!, $remark: String) {
    cancelWithdraw(id: $id, remark: $remark) {
      isSuccess
      message
    }
  }
`;

export const GET_WITHDRAWS = gql`
  query($page: Int!, $pageSize: Int!, $type: String, $search: String) {
    systemWithdraws(
      page: $page
      pageSize: $pageSize
      type: $type
      search: $search
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        withdraw_id
        withdraw_no
        amount
        userId
        user {
          userId
          username
        }
        bank_name
        bank_img
        file_attachment
        status
        statusShow
        approve_date
        created_at
      }
    }
  }
`;

export const GET_WITHDRAW = gql`
  query($id: Int!) {
    systemWithdraw(id: $id) {
      _id
      withdraw_id
      withdraw_no
      amount
      status
      statusShow
      user {
        userId
        username
      }
      transactions {
        _id
        transaction_id
        amount
        reason
        doc_id
        created_at
      }
      file_attachment
      cancel_remark
      remarkSystem
      bank_name
      account_name
      account_no
      branch_name
      bank_img
      approve_date
      created_at
    }
  }
`;
