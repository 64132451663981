<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="system_category_list">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            ประเภทสินค้า
          </span>
        </div>
      </h3>
    </div>
    <div
      class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
    >
      <div class="input-group input-group-solid mr-4" style="width: 280px">
        <input
          type="text"
          class="form-control"
          placeholder="ค้นหา"
          @input="debounceInput"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Search.svg" />
            </span>
          </span>
        </div>
      </div>
      <router-link
        :to="`${$route.path}/new`"
        class="btn btn-success font-weight-bolder font-size-sm mr-4"
      >
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
        </span>
        เพิ่มประเภทสินค้า
      </router-link>
      <button
        @click="$apollo.queries.system_category_list.refetch()"
        class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      >
        <i class="ki ki-refresh"></i>
      </button>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0" v-if="system_category_list">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-sm table-head-bg table-vertical-center">
          <thead>
            <tr>
              <th class="text-center">ลำดับ</th>
              <th>ชื่อประเภทสินค้า</th>
              <th>ค่าธรรมเนียม</th>
              <th class="text-center">รายละเอียด</th>
              <th style="min-width: 60px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in system_category_list.records">
              <tr v-bind:key="i">
                <td class="text-center">
                  {{ item.sequenceNumber }}
                </td>
                <td>
                  <router-link
                    :to="`/admin/seller/category/${item.categoryId}`"
                  >
                    <div class="d-flex">
                      <div class="d-flex">
                        <span class="my-auto">
                          {{ item.originalName }}
                        </span>
                      </div>
                    </div>
                  </router-link>
                </td>
                <td class="">
                  {{ item.fee_value || "0"
                  }}{{ item.fee_type === 0 ? "%" : "฿" }}
                </td>
                <td class="text-center">
                  {{ item.description }}
                </td>
                <td class="text-right pr-4">
                  <router-link
                    :to="`/admin/seller/category/${item.categoryId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                </td>
              </tr>
            </template>
            <tr
              class="text-center"
              v-if="
                system_category_list &&
                  system_category_list.records &&
                  !system_category_list.records.length
              "
            >
              <td colspan="4" class="pt-8">ไม่พบข้อมูล</td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="system_category_list && system_category_list.recordsData"
          :total-rows="system_category_list.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import dayjs from "dayjs";
import debounce from "debounce";
import { GET_CATEGORY_LIST } from "@/graphql/admin/seller_category";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi } from "@/config";

export default {
  created() {
    document.title = `ประเภทสินค้า | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ขายสินค้า`, route: "/admin/seller" },
      { title: `ประเภทสินค้า` }
    ]);
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      search: ""
    };
  },
  methods: {
    imageUrlApi,
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  },
  apollo: {
    system_category_list: {
      query: GET_CATEGORY_LIST,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
