import gql from "graphql-tag";

export const SETTING_RATE = gql`
  query {
    settingRate {
      rateAlipay
      rateBuy
      rateTaobao
    }
  }
`;

export const SETTING_ADDRESS = gql`
  query {
    settingAddress {
      sender_name
      sender_phone
      sender_address
      sender_province
      sender_amphoe
      sender_district
      sender_postcode
    }
  }
`;

export const SETTING_SERVICE_DROPSHIP = gql`
  query {
    serivcePriceDropship {
      price
    }
  }
`;

export const UPDATE_SETTING_SERVICE_DROPSHIP = gql`
  mutation($input: SettingPriceInput) {
    updateServicePriceDropshipSetting(input: $input) {
      isSuccess
      code
      message
    }
  }
`;

export const SETTING_NOTI = gql`
  query {
    setting_notification_bar {
      isSuccess
      data
    }
  }
`;

export const SETTING_ADDRESS_CHINA = gql`
  query {
    settingAddressChina {
      isSuccess
      message
      data {
        china_land
        china_sea
        china_address
        china_phone
        china_guarantee
      }
    }
  }
`;

export const UPDATE_SETTING_RATE = gql`
  mutation($input: SettingRateInput) {
    updateRate(input: $input) {
      isSuccess
      code
      message
    }
  }
`;

export const UPDATE_SETTING_ADDRESS = gql`
  mutation($input: SettingAddressInput) {
    updateAddressSetting(input: $input) {
      isSuccess
      code
      message
    }
  }
`;

export const UPDATE_SETTING_ADDRESS_CHINA = gql`
  mutation($input: SettingAddressChinaInput) {
    updateAddressChinaSetting(input: $input) {
      isSuccess
      code
      message
    }
  }
`;

export const UPDATE_SETTING_NOTI = gql`
  mutation($input: SettingNotificationInput) {
    updateNotificationSetting(input: $input) {
      isSuccess
      code
      message
    }
  }
`;

export const MINIMUM_PAYSHIPPING = gql`
  query {
    minimumPayshipping {
      minimum
      price
      trackingPrice
    }
  }
`;

export const UPDATE_MINIMUM_PAYSHIPPING = gql`
  mutation($minimum: Float, $price: Float, $trackingPrice: Float) {
    updateMinimumPayshipping(
      minimum: $minimum
      price: $price
      trackingPrice: $trackingPrice
    ) {
      isSuccess
      code
      message
    }
  }
`;
