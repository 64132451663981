import { gql } from "@apollo/client";

export const NOTICE_SEND_MAIL = gql`
  mutation($newsId: Int) {
    systemSendMailNews(newsId: $newsId) {
      message
      isSuccess
      number
    }
  }
`;

export const GET_NEWS = gql`
  query($id: Int) {
    systemNews(id: $id) {
      isSuccess
      message
      data {
        _id
        newsId
        news_number
        title
        text
        attachment
        is_show
        created_at
      }
    }
  }
`;

export const GET_NEWS_LIST = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    systemNewsList(page: $page, pageSize: $pageSize, search: $search) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        newsId
        news_number
        title
        is_show
        attachment
        created_at
      }
    }
  }
`;

export const CREATE_NEWS = gql`
  mutation($input: NoticeInput) {
    createNews(input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_NEWS = gql`
  mutation($input: NoticeInput) {
    updateNews(input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_NEWS = gql`
  mutation($id: ID) {
    deleteNews(_id: $id) {
      isSuccess
      message
    }
  }
`;
