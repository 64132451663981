import gql from "graphql-tag";

export const NOTIFICATION_LIST = gql`
  query {
    notificationList {
      isSuccess
      message
      line_client
      line_secret
      records {
        _id
        notiId
        name
        type
        no
        desc
        isEnabled
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_LIST = gql`
  mutation($input: SetNotificationListInput) {
    updateNotificationList(input: $input) {
      isSuccess
      message
    }
  }
`;
