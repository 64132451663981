<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" @submit.prevent="save">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">เพิ่มล็อต</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group row mb-2">
                <label
                  for="input-transportNumber"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                >
                  ชื่อล็อต
                </label>
                <div class="col-lg-6 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-lotName"
                    v-model="form.lotName"
                    :state="validateState('lotName')"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >เส้นทาง</label
                >
                <div class="col-lg-6 col-md-9 col-sm-12">
                  <b-form-select
                    v-model="form.type"
                    :state="validateState('type')"
                    required
                    :options="typeList"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="input-ship_by"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >เลือกขนส่งระหว่างประเทศ</label
                >
                <div class="col-lg-6 col-md-9 col-sm-12">
                  <b-dropdown
                    v-model="form.ship_by"
                    :state="validateState('ship_by')"
                    :text="
                      form.ship_by === 'ek'
                        ? 'ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)'
                        : 'ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน）'
                    "
                  >
                    <b-dropdown-item value="ek" @click="form.ship_by = 'ek'">
                      ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)
                    </b-dropdown-item>
                    <b-dropdown-item
                      value="ship"
                      @click="form.ship_by = 'ship'"
                    >
                      ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="input-lotUrl"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                >
                  ลิงค์ Google Drive
                </label>
                <div class="col-lg-6 col-md-9 col-sm-12">
                  <b-form-textarea
                    id="input-lotUrl"
                    v-model="form.lotUrl"
                    :state="validateState('lotUrl')"
                    rows="8"
                    placeholder=""
                  ></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-4"
                style="min-width: 140px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Save.svg" />
                </span>
                บันทึก
              </button>
              <router-link
                to="/admin/lot"
                class="btn btn-secondary"
                style="width: 140px"
              >
                ยกเลิก
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { CREATE_LOT } from "@/graphql/admin/lot";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `เพิ่มล็อต | ${WEBSITE}`;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการล็อต`, route: "/admin/lot" },
      { title: `เพิ่มล็อต` }
    ]);
  },
  data() {
    return {
      nameState: null,
      messageError: "",
      typeList: [
        { text: "จีน - ไทย", value: "china-thai" },
        { text: "ไทย - ลาว", value: "thai-lao" }
      ],
      form: {
        lotName: "",
        lotUrl: "",
        ship_by: "ek",
        type: "china-thai",
        send_out_china: null,
        send_to_warehouse_th: null
      }
    };
  },
  validations: {
    form: {
      lotName: {
        required
      },
      lotUrl: {},
      type: { required },
      ship_by: {
        required
      },
      send_out_china: {},
      send_to_warehouse_th: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const lotName = this.$v.form.lotName.$model;
      const lotUrl = this.$v.form.lotUrl.$model;
      const ship_by = this.$v.form.ship_by.$model;
      const type = this.$v.form.type.$model;
      const send_out_china = this.$v.form.send_out_china.$model;
      const send_to_warehouse_th = this.$v.form.send_to_warehouse_th.$model;

      const input = {
        lotName,
        lotUrl,
        ship_by,
        type,
        send_out_china,
        send_to_warehouse_th
      };

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { newLot }
      } = await this.$apollo.mutate({
        mutation: CREATE_LOT,
        variables: {
          input: input
        }
      });
      const { message, is_error } = newLot;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$router.push("/admin/lot");
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  }
};
</script>
