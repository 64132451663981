<template>
  <b-overlay :show="$apollo.loading || loading" rounded="sm">
    <div class="card-custom card-transparent" v-if="user">
      <div class="card-body p-0">
        <div
          class="wizard wizard-4"
          id="kt_wizard_user"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav">
            <div class="wizard-steps">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ข้อมูลลูกค้า</div>
                    <div class="wizard-desc">Infomation</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ข้อมูลที่อยู่</div>
                    <div class="wizard-desc">Address</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ราคาขนส่ง</div>
                    <div class="wizard-desc">Shipping price</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ประวัติการเงิน</div>
                    <div class="wizard-desc">Transaction</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card card-custom card-shadowless rounded-top-0 card-body">
            <div class="tab-content">
              <!--begin::Tab-->
              <div
                class="tab-pane show active px-7"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <b-form ref="form" @submit.prevent="save" v-if="user">
                  <!--begin::Row-->
                  <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-7 my-2">
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >ชื่อผู้ใช้งาน</label
                        >
                        <div class="col-9">
                          <input
                            v-model="user.username"
                            :state="nameState"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >ชื่อ - นามสกุล</label
                        >
                        <div class="col-9">
                          <input
                            v-model="user.full_name"
                            :state="nameState"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >เบอร์โทรศัพท์</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-phone"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="user.phone"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >อีเมล์</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-at"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="user.email"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >LINE ID</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="fab la-line"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="user.line"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >รหัสผ่าน</label
                        >
                        <div class="col-9">
                          <input
                            type="text"
                            class="form-control"
                            :state="nameState"
                            disabled
                            v-model="user.password"
                            required
                          />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >ตั้งเป็นผู้ดูแล</label
                        >
                        <div class="col-9">
                          <span class="switch switch-sm mt-1">
                            <label class="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="input-is_default"
                                v-model="user.is_admin"
                              />
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2" v-if="systemRoleSelect">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >สิทธิ์การใช้งาน</label
                        >
                        <div class="col-9">
                          <b-form-select
                            type="text"
                            class="form-control form-select"
                            v-model="user.roleId"
                            :state="validateState('roleId')"
                          >
                            <option
                              v-for="item in systemRoleSelect.records"
                              v-bind:key="item._id"
                              :value="item.roleId"
                            >
                              {{ item.role_name }}
                            </option>
                          </b-form-select>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div
                        class="form-group row mb-2"
                        v-if="admin_user_verify_file"
                      >
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                        >
                          สำเนาบัตรประชาชน
                        </label>
                        <div class="col-9 d-flex align-items-center">
                          <div
                            v-if="user.verify_file"
                            class="uppy"
                            id="kt_uppy_5"
                          >
                            <div class="uppy-list">
                              <div>
                                <div
                                  v-viewer
                                  class="border symbol symbol-60 flex-shrink-0 mr-4"
                                >
                                  <img
                                    style="object-fit: cover"
                                    :src="imageUrlApi(user.verify_file)"
                                    height="250"
                                    width="250"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="!user.verify_file">-</div>
                        </div>
                      </div>
                      <!--begin::Group-->
                      <div class="form-group row" v-if="systemRoleSelect">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >สถานะ</label
                        >
                        <div class="col-9">
                          <b-form-select
                            type="text"
                            class="form-control form-select"
                            v-model="user.status"
                            :state="validateState('status')"
                          >
                            <option :value="1">ยืนยันแล้ว</option>
                            <option :value="0">ยังไม่ได้ยืนยัน</option>
                            <option :value="-1">ยกเลิกใช้งาน</option>
                          </b-form-select>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row" v-if="user.status === 0">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                        ></label>
                        <div class="col-9">
                          <button
                            class="btn btn-success"
                            type="button"
                            @click="resend"
                          >
                            ส่งเมลยืนยัน
                          </button>
                        </div>
                      </div>
                      <!--end::Group-->
                    </div>
                  </div>
                  <div class="card-footer pb-0">
                    <div class="row">
                      <div class="col-xl-7 offset-xl-2">
                        <div class="row">
                          <div class="col-3"></div>
                          <div class="col-9">
                            <button
                              class="btn btn-light-primary font-weight-bold mr-4"
                            >
                              Save changes
                            </button>
                            <router-link
                              to="/admin/users"
                              class="btn btn-clean font-weight-bold"
                            >
                              Cancel
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Row-->
                </b-form>
              </div>
              <!--end::Tab-->
              <!--begin::Tab-->
              <div class="tab-pane px-7" data-wizard-type="step-content">
                <user-address :data="user" :refetch="onComplete" />
              </div>
              <!--end::Tab-->
              <!--begin::Tab-->
              <div class="tab-pane px-7" data-wizard-type="step-content">
                <!--begin::Body-->
                <user-price-shipping :data="user" :refetch="onComplete" />
                <!--end::Footer-->
              </div>
              <!--end::Tab-->
              <!--begin::Tab-->
              <div class="tab-pane px-7" data-wizard-type="step-content">
                <!--begin::Body-->
                <user-transaction :data="user" />
                <!--end::Footer-->
              </div>
              <!--end::Tab-->
            </div>
          </div>
          <!--begin::Card body-->
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>
<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import KTWizard from "@/assets/js/components/wizard";
import UserAddress from "./components/UserAddress";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_USER, UPDATE_USER } from "@/graphql/admin/user";
import { GET_ROLES_SELECT } from "@/graphql/admin/role";
import { WEBSITE, imageUrlApi } from "@/config";
import { RESEND_MAIL } from "@/core/services/store/auth.module";
import UserPriceShipping from "./components/UserPriceShipping";
import UserTransaction from "./components/UserTransaction.vue";

export default {
  name: "UserDetail",
  mixins: [validationMixin],
  created() {
    const { id } = this.$route.params;
    document.title = `ผู้ใช้งาน #${id} | ${WEBSITE}`;
  },
  components: {
    UserAddress,
    UserPriceShipping,
    UserTransaction
  },
  computed: {
    ...mapGetters(["currentPermission"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ผู้ใช้ในระบบทั้งหมด`, route: "/admin/users" },
      { title: `ผู้ใช้งาน #${this.$route.params.id}` }
    ]);

    new KTWizard("kt_wizard_user", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    this.admin_user_verify_file = this.checkPermission(
      "admin_user_verify_file",
      0
    );
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      nameState: null,
      loading: false,
      admin_user_verify_file: false,
      user: {
        username: "",
        full_name: "",
        email: "",
        phone: "",
        line: "",
        is_admin: false,
        userLevelId: 0,
        roleId: 0
      }
    };
  },
  validations: {
    user: {
      username: {
        required
      },
      full_name: {
        required
      },
      email: {
        required
      },
      phone: {
        required
      },
      is_admin: {
        required
      },
      line: {},
      userLevelId: {},
      roleId: {},
      status: {
        required
      }
    }
  },
  methods: {
    imageUrlApi,
    onComplete() {
      this.$apollo.queries.user.refetch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }
      const { permissionRole } = this.currentPermission;

      const find = permissionRole.find(
        x => x.permissionInfo && x.permissionInfo.permissionName === name
      );

      if (!find) {
        return false;
      }
      if (type === 0 && find.readableRole) {
        return true;
      }
      if (type === 1 && find.writeableRole) {
        return true;
      }
      return false;
    },
    async save() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันการบันทึกข้อมูล ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const input = {
        userId: this.user.userId,
        username: this.$v.user.username.$model,
        full_name: this.$v.user.full_name.$model,
        phone: this.$v.user.phone.$model,
        email: this.$v.user.email.$model,
        line: this.$v.user.line.$model,
        is_admin: this.$v.user.is_admin.$model,
        roleId: this.$v.user.roleId.$model,
        status: this.$v.user.status.$model
      };
      const {
        data: { updateUser }
      } = await this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          input: input
        }
      });
      if (!updateUser.isSuccess) {
        this.$message.error(updateUser.message);
      } else {
        this.$message.success(updateUser.message);
        this.$apollo.queries.user.refetch();
      }
    },
    resend(e) {
      e.preventDefault();
      if (this.user.email) {
        this.loading = true;
        this.$store
          .dispatch(RESEND_MAIL, {
            email: this.user.email
          })
          .then(
            ({ code, message }) => {
              this.loading = false;
              if (code === 200) {
                this.$message.success(message);
              } else {
                this.$message.error(message);
              }
            },
            error => {
              this.loading = false;
              this.$message.error(error.message);
            }
          );
      }
    }
  },
  apollo: {
    user: {
      query: GET_USER,
      variables() {
        return {
          id: this.id
        };
      }
    },
    systemRoleSelect: {
      query: GET_ROLES_SELECT
    }
  }
};
</script>
