<template>
  <b-form
    class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
    novalidate="novalidate"
    id="kt_sign_in_form"
    @submit.stop.prevent="onSubmit"
  >
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-dark mb-3">เข้าสู่ระบบ {{ WEBSITE_NAME }}</h1>
      <!--end::Title-->
      <!--begin::Link-->
      <div class="text-gray-400 fw-bold fs-4">
        ผู้ใช้ใหม่?
        <router-link :to="`/signup`" class="link-primary fw-bolder">
          สมัครสมาชิก
        </router-link>
      </div>
      <!--end::Link-->
    </div>
    <b-alert
      class="mb-6"
      v-for="error in errors"
      v-bind:key="error"
      show
      variant="danger"
    >
      {{ error }}
    </b-alert>
    <!--begin::Heading-->
    <!--begin::Input group-->
    <div class="fv-row mb-10 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="form-label fs-6 fw-bolder text-dark">Email</label>
      <!--end::Label-->
      <!--begin::Input-->
      <b-form-input
        class="form-control form-control-lg form-control-solid"
        type="text"
        name="email"
        autocomplete="off"
        :state="validateState('email')"
        v-model="form.email"
      />
      <!--end::Input-->
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group-->
    <!--begin::Input group-->
    <div class="fv-row mb-10 fv-plugins-icon-container">
      <!--begin::Wrapper-->
      <div class="d-flex flex-stack mb-2">
        <!--begin::Label-->
        <label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
        <!--end::Label-->
        <!--begin::Link-->
        <router-link
          :to="`/reset-password`"
          href="/metronic8/demo1/authentication/flows/basic/password-reset.html"
          class="link-primary fs-6 fw-bolder"
          >Forgot Password ?</router-link
        >
        <!--end::Link-->
      </div>
      <!--end::Wrapper-->
      <!--begin::Input-->
      <div class="position-relative mb-3">
        <b-form-input
          class="form-control form-control-lg form-control-solid"
          :type="!showPassword ? 'password' : 'text'"
          placeholder=""
          name="password"
          :state="validateState('password')"
          v-model="form.password"
          autocomplete="off"
        />
        <span
          class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-10 me-n2"
          @click="togglePassword"
        >
          <i
            class="la la-eye-slash fs-2"
            :class="{
              'd-none': showPassword
            }"
          ></i>
          <i
            class="la la-eye fs-2"
            :class="{
              'd-none': !showPassword
            }"
          ></i>
        </span>
      </div>
      <!--end::Input-->
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group-->
    <!--begin::Actions-->
    <div class="text-center">
      <!--begin::Submit button-->
      <button
        type="submit"
        id="kt_sign_in_submit"
        class="btn btn-lg btn-primary w-100 mb-5"
      >
        <span class="indicator-label" v-if="!loading">เข้าสู่ระบบ</span>
        <span class="indicator-progress" v-if="loading"
          >Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span
        ></span>
      </button>
      <!--end::Submit button-->
    </div>
    <!--end::Actions-->
    <div></div>
  </b-form>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { WEBSITE_NAME } from "@/config";

export default {
  mixins: [validationMixin],
  name: "LoginUser",
  data() {
    return {
      WEBSITE_NAME,
      showPassword: false,
      loading: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      this.loading = true;
      // send login request
      this.$store
        .dispatch(LOGIN, { username: email, password })
        // go to which page after successfully login
        .then(() => {
          this.loading = true;
          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
