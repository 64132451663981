import gql from "graphql-tag";

export const SELLER_ORDER_LIST = gql`
  query($pageSize: Int!, $page: Int!, $status: String, $search: String) {
    system_seller_order_list(
      pageSize: $pageSize
      page: $page
      status: $status
      search: $search
    ) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        sellerOrderId
        ship_by
        statusShow
        status
        total_pay
        vendor {
          vendor
          itemsLess {
            _id
            mainImage
            productName
            url
            amount_buy
            amount
            properties {
              id
              name
              title
              image
            }
          }
        }
        created_at
      }
    }
  }
`;

export const SELLER_ORDER = gql`
  query($id: Int) {
    system_seller_order(id: $id) {
      isSuccess
      message
      canCancel
      data {
        _id
        sellerOrderId
        ship_by
        total_product
        shipping_price
        forwarder_price
        total
        status
        statusShow
        address {
          name
          phone
          address
          province
          amphoe
          district
          postcode
        }
        vendors {
          _id
          vendor
          status
          statusShow
          total_product
          shipping_price
          forwarder_price
          total
          total_pay
          remark
          cancel_remark
          can_receive
          items {
            _id
            mainImage
            productName
            url
            amount_buy
            amount
            price
            properties {
              id
              name
              title
              image
            }
          }
          transports {
            _id
            transportId
            transportNumber
            status
            statusShow
            timeline {
              send_to_warehouse_china
              send_out_china
              send_to_warehouse_th
              payshipping
              sent_out_to_customer
            }
            timeline_list {
              name
              date
            }
          }
        }
        created_at
      }
    }
  }
`;

export const SELLER_ORDER_STATUS = gql`
  query {
    system_seller_order_status {
      isSuccess
      message
      records {
        status
        value
      }
    }
  }
`;
