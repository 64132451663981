<template>
  <div class="d-flex flex-column flex-lg-row">
    <!--begin::Sidebar-->
    <div
      class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0"
    >
      <!--begin::Contacts-->
      <div class="card-flush">
        <!--begin::Card body-->
        <div class="pb-5">
          <!--begin::List-->
          <div
            class="scroll-y mr-n5 pr-5 h-lg-auto"
            style="max-height: 687px"
            v-if="departments"
          >
            <div
              v-for="item in departments.records"
              :key="item._id"
              class="bg-white d-flex mb-4 flex-stack py-4 btn-active-light-primary px-4 rounded pointer"
              :class="{
                'border-3 border border-primary': d === item.dept_id
              }"
              @click="clickDept(item)"
            >
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle noti">
                  <span
                    class="symbol-label bg-light-danger text-danger fs-6 fw-bolder"
                    >{{ item.dept_name.substr(0, 2) }}
                    <span
                      v-if="item.noti"
                      class="badge badge-pill badge-danger"
                    >
                      {{ item.noti }}
                    </span>
                  </span>
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ml-5">
                  <div
                    class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                  >
                    {{ item.dept_name }}
                  </div>
                  <span class="text-muted font-weight-bold font-size-sm">
                    {{ item.dept_desc }}
                  </span>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
          </div>
          <!--end::List-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contacts-->
    </div>
    <!--end::Sidebar-->
    <!--begin::Content-->
    <div v-if="!isMobile" class="flex-lg-row-fluid ml-lg-7 ms-xl-10">
      <div class="card card-body text-center text-danger" v-if="!messages">
        กรุณาเลือกห้องสนทนา
      </div>
      <message-list
        v-if="messages"
        :list="listMessage"
        :data="messages"
        :refetch="onRefetchMessage"
        :fetchMore="fetchMore"
        :enabled="enabledScroll"
      />
    </div>
    <chat-mobile
      v-if="isMobile"
      :visible="visible"
      @change-visible="visible = $event"
      :list="listMessage"
      :data="messages"
      :refetch="onRefetchMessage"
      :fetchMore="fetchMore"
      :enabled="enabledScroll"
    />

    <!--end::Content-->
  </div>
</template>
<style lang="scss">
@import "src/assets/sass/_private_chat";
</style>
<script>
import debounce from "debounce";
import { mapGetters } from "vuex";
import { isMobile } from "@/lib/detectDevice";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_DEPARTMENTS } from "@/graphql/admin/department.js";
import { GET_MESSAGES } from "@/graphql/message.js";
import { WEBSITE } from "@/config";

const MessageList = () => import("./components/MessageList.vue");
const ChatMobile = () => import("./ChatMobile.vue");

export default {
  components: {
    MessageList,
    ChatMobile
  },
  created() {
    document.title = `ติดต่อเจ้าหน้าที่ | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `ติดต่อเจ้าหน้าที่` }]);
    this.poll();
  },
  data() {
    const { d = 0 } = this.$route.query;
    return {
      d: Number(d),
      isMobile: isMobile(),
      pageUser: 1,
      searchUser: "",
      pageMessage: 1,
      listMessage: [],
      visible: !!Number(d),
      enabledScroll: true
    };
  },
  methods: {
    fetchMore() {
      // this.pageMessage = this.pageMessage + 1;
    },
    debounceInput: debounce(function(e) {
      this.searchUser = e.target.value;
      this.pageUser = 1;
    }, 400),
    onRefetchMessage() {
      this.enabledScroll = true;
      this.$apollo.queries.messages.refetch();
    },
    clickDept(item) {
      this.enabledScroll = true;
      this.d = item.dept_id;
      this.pageMessage = 1;
      const path = `/messages?d=${this.d}`;
      this.visible = true;
      if (this.$route.fullPath !== path) {
        this.$router.push(path);
      }
    },
    poll() {
      setInterval(() => {
        this.enabledScroll = false;
        this.$apollo.queries.messages.refetch();
      }, 5000);
      setInterval(() => {
        this.$apollo.queries.departments.refetch();
      }, 5500);
    }
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  watch: {
    "$route.query.d": function(val) {
      this.d = Number(val) || 0;
    }
  },
  apollo: {
    departments: {
      query: GET_DEPARTMENTS
    },
    messages: {
      query: GET_MESSAGES,
      skip() {
        return !this || !this.d;
      },
      variables() {
        return {
          page: this.pageMessage,
          dept_id: this.d
        };
      },
      result({ loading, data }) {
        if (!loading && data) {
          const { messages } = data;
          if (this.pageMessage != 1) {
            this.listMessage = messages.records.concat(this.listMessage);
          } else {
            this.listMessage = messages.records;
            const container = this.$el.querySelector("#message_wrapper");
            if (container && this.enabledScroll) {
              this.$nextTick(() => {
                setTimeout(() => {
                  container.scrollTop = container.scrollHeight;
                }, 500);
              });
            }
          }
        }
      }
    }
  }
};
</script>
