<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card card-custom card-stretch">
      <div class="card-body p-0" v-if="orderDetail">
        <div class="row justify-content-center py-8 px-8 py-md-27 px-md-0">
          <div class="col-md-10">
            <div class="d-flex justify-content-end btn-space">
              <button
                v-if="orderDetail.record.status === 0"
                class="btn btn-default font-weight-bold"
                @click="cancelOrder(orderDetail.record.orderId)"
                style="min-width: 144px"
              >
                ยกเลิก
              </button>
              <button
                v-if="orderDetail.record.status === 10"
                class="btn btn-success font-weight-bold"
                style="min-width: 144px"
                @click="payOrder(orderDetail.record.orderId)"
              >
                ชำระเงิน
              </button>
            </div>
          </div>
          <div class="col-md-10">
            <div
              class="d-flex justify-content-between align-items-center py-5 py-md-10 flex-column flex-md-row"
            >
              <h1 class="d-flex font-weight-boldest">
                ข้อมูลการสั่งซื้อ {{ orderDetail.record.orderCode }}
              </h1>
              <div class="d-flex">
                <span
                  class="d-flex flex-column opacity-70"
                  v-if="orderDetail.record.user"
                >
                  <div class="h3">
                    <span class="font-weight-bolder mb-2 mr-2">ลูกค้า</span>
                    <span>{{ orderDetail.record.user.username }}</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="border-bottom w-100"></div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">วันที่สั่งซื้อ</span>
                <span class="opacity-70">
                  {{ format_datetime(orderDetail.record.created_at) }}
                </span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">อัตราแลกเปลี่ยน</span>
                <span class="opacity-70">{{ orderDetail.record.rate }}</span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">
                  ค่าส่งในจีน({{ orderDetail.record.currencySign || "¥" }})
                </span>
                <span class="opacity-70">
                  <span style="margin-right: 8px">
                    {{ orderDetail.record.shipping_price || 0 }}
                  </span>
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">ประเภทการขนส่ง</span>
                <span class="opacity-70">
                  {{ orderDetail.record.ship_by === "ek" ? "รถ" : "เรือ" }}
                </span>
              </div>
              <div class="flex-column flex-root">
                <div class="font-weight-bolder mb-2">สถานะ</div>
                <div
                  class="label label-lg label-inline"
                  v-bind:class="
                    `label-light-${getStatusColor(orderDetail.record.status)}`
                  "
                >
                  {{ orderDetail.record.statusShow }}
                </div>
              </div>
              <div class="d-flex flex-column flex-root text-danger">
                <span class="font-weight-bolder mb-2">หมายเหตุจากลูกค้า</span>
                <span class="opacity-70">
                  {{ orderDetail.record.remark || "-" }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">ค่าบริการฝากสั่ง</span>
                <div class="opacity-70">
                  <div>คิวละ {{ orderDetail.record.serviceCube || 0 }}</div>
                  <div>กิโลละ {{ orderDetail.record.serviceKg || 0 }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row justify-content-center pb-8 px-8 pb-md-10 px-md-0"
          v-if="orderDetail.record && orderDetail.record.transports.length"
        >
          <div class="col-md-10">
            <div class="table-responsive table-sm">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted">เลขพัสดุ</th>
                    <th class="text-center font-weight-bold text-muted">
                      ตีลัง
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      ขนส่งโดย
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      เข้าโกดังจีน
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      ออกโกดังจีน
                    </th>
                    <th class="text-center font-weight-bold text-muted">
                      ถึงไทย
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="font-weight-boldest"
                    v-for="item in orderDetail.record.transports"
                    v-bind:key="item._id"
                  >
                    <td class="align-middle">
                      {{ item.transportNumber }}
                    </td>
                    <td class="text-center align-middle">
                      <span class="text-success" v-if="item.guarantee"
                        >เปิด</span
                      >
                      <span class="text-danger" v-if="!item.guarantee"
                        >ปิด</span
                      >
                    </td>
                    <td class="text-center align-items-center align-middle">
                      <span v-if="item.ship_by === 'ek'">รถ</span>
                      <span v-if="item.ship_by !== 'ek'">เรือ</span>
                    </td>
                    <td class="text-center pl-0 align-middle">
                      {{ format_date(item.timeline.send_to_warehouse_china) }}
                    </td>
                    <td class="text-center pl-0 align-middle">
                      {{ format_date(item.timeline.send_out_china) }}
                    </td>
                    <td class="text-center pl-0 align-middle">
                      {{ format_date(item.timeline.send_to_warehouse_th) }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      orderDetail.record.transports &&
                        !orderDetail.record.transports.length
                    "
                  >
                    <td colspan="7" class="text-center p-4">ไม่มีข้อมูล</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
          <div class="col-md-10">
            <div class="table-responsive">
              <table class="table mb-8">
                <div
                  v-for="vendor in orderDetail.record.vendors"
                  v-bind:key="vendor._id"
                  v-frag
                >
                  <thead>
                    <tr>
                      <th class="pl-0 font-weight-bold text-muted">
                        <div
                          class="text-overflow"
                          style="width: 90px; display: inline-block"
                        >
                          {{ vendor.vendor }}
                        </div>
                        <span class="ml-4 text-success" v-if="vendor.guarantee">
                          ตีลังไม้
                        </span>
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        จำนวน
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        ราคา({{ vendor.currencySign || "¥" }})
                      </th>
                      <th class="text-right font-weight-bold text-muted">
                        รวม({{ vendor.currencySign || "¥" }})
                      </th>
                      <th class="text-right pr-0 font-weight-bold text-muted">
                        รวม
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="font-weight-boldest"
                      v-for="item in vendor.items"
                      v-bind:key="item._id"
                    >
                      <td
                        class="pl-0 pt-6 pb-6 align-items-center align-middle"
                      >
                        <div class="d-flex">
                          <!--begin::Symbol-->
                          <a :href="item.url" target="_blank">
                            <div
                              class="symbol symbol-40 flex-shrink-0 mr-4 bg-light"
                            >
                              <div
                                class="symbol-label"
                                :style="
                                  `background-image: url('${item.mainImage}')`
                                "
                              ></div>
                            </div>
                            <!--end::Symbol-->
                          </a>
                          <div>
                            <a :href="item.url" target="_blank">
                              {{ item.productName }}
                            </a>
                            <div
                              class="opacity-80 font-weight-bold d-block"
                              v-bind:key="c.id"
                              v-for="c in item.properties"
                            >
                              <span v-if="c.name">{{ c.name }} : </span>
                              <img
                                v-viewer
                                v-if="c.image"
                                :src="c.image"
                                style="height: 20px"
                                class="align-self-center pointer mr-2"
                                :alt="item.productName"
                              />
                              <span>{{ c.title }}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-right pt-0 align-middle">
                        {{ item.amount_buy }}
                      </td>
                      <td class="text-right pt-0 align-middle">
                        {{ format_number_pretty(item.price, 2)
                        }}{{ item.currencySign || "¥" }}
                      </td>
                      <td class="text-right pt-0 align-middle">
                        <span v-if="item.currencySign">
                          {{ format_number_pretty(item.price, 2)
                          }}{{ item.currencySign }}
                        </span>
                        <span v-if="!item.currencySign">
                          {{
                            format_number_pretty(
                              item.price * item.amount_buy,
                              2
                            )
                          }}¥
                        </span>
                      </td>
                      <td
                        class="text-primary pr-0 pt-0 text-right align-middle"
                      >
                        <span v-if="item.currencySign">
                          {{ format_number(item.price * item.amount_buy, 2)
                          }}{{ item.currencySign }}
                        </span>
                        <span v-if="!item.currencySign">
                          {{
                            format_number(
                              item.price *
                                orderDetail.record.rate *
                                item.amount_buy,
                              2
                            )
                          }}฿
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom card-stretch mt-8">
      <div class="card-body p-0" v-if="orderDetail">
        <div
          class="row justify-content-center pt-8 pb-8 px-8 pb-md-8 px-md-0"
          v-if="orderDetail && orderDetail.record.transactions.length"
        >
          <div class="col-md-10">
            <span
              class="font-weight-bolder text-dark mr-4"
              style="font-size: 16px"
            >
              จ่ายเงิน
            </span>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted">วันที่</th>
                    <th class="font-weight-bold text-muted">หมายเหตุ</th>
                    <th class="text-right font-weight-bold text-muted">
                      จำนวน
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="font-weight-boldest"
                    v-for="item in orderDetail.record.transactions"
                    v-bind:key="item._id"
                  >
                    <td class="pl-0 pt-7 align-items-center align-middle">
                      {{ format_datetime(item.created_at) }}
                    </td>
                    <td class="pt-7 align-middle">
                      {{ item.reason }}
                    </td>
                    <td class="text-right pt-7 align-middle">
                      <span
                        class="font-weight-bolder d-block font-size-lg"
                        v-bind:class="{
                          'text-danger': item.amount < 0,
                          'text-success': item.amount >= 0
                        }"
                      >
                        {{ format_number(item.amount, 2, true) }}฿
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pt-8 pb-8 px-8 pb-md-8 px-md-0">
          <div class="col-md-10">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted">
                      ประเภทการจ่ายเงิน
                    </th>
                    <th class="font-weight-bold text-muted">สถานะจ่ายเงิน</th>
                    <th class="font-weight-bold text-muted text-right">
                      ยอดทั้งหมด
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="font-weight-bolder">
                    <td class="pl-0">เงินในกระเป๋า</td>
                    <td>
                      {{
                        orderDetail.record.pay_date
                          ? "จ่ายเรียบร้อย"
                          : "ยังไม่ได้ชำระ"
                      }}
                    </td>
                    <td
                      class="text-primary font-size-h3 font-weight-boldest text-right"
                    >
                      {{ format_number(orderDetail.record.total_baht, 2) }}฿
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import bigDecimal from "js-big-decimal";
import { GET_ORDER, CANCEL_ORDER, PAY_ORDER } from "@/graphql/order";

export default {
  name: "order_detail",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "รายการสั่งซื้อ", route: "/shop/order" }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      guarantee: false,
      valid: false
    };
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 40:
        case 90:
          return "success";
        default:
          return "primary";
      }
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val, digits = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    },
    onCompleteTransport() {
      this.$apollo.queries.orderDetail.refetch();
    },
    async cancelOrder(orderId) {
      const confirm = await Swal.fire({
        title: "ยกเลิกรายการสั่งซื้อ ?",
        icon: "question",
        html: `ยืนยันการยกเลิกรายการ ${this.orderDetail.record.orderCode}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { orderDetailReject }
      } = await this.$apollo.mutate({
        mutation: CANCEL_ORDER,
        variables: {
          id: orderId
        }
      });
      if (!orderDetailReject.isSuccess) {
        this.$message.error(orderDetailReject.message);
      } else {
        this.$message.success(orderDetailReject.message);
        this.$apollo.queries.orderDetail.refetch();
      }
    },
    async payOrder(id) {
      const confirm = await Swal.fire({
        title: "ชำระเงินค่าสินค้า",
        icon: "question",
        html: `ชำระเงินค่าสินค้า จำนวน ${this.format_number(
          this.orderDetail.record.total_baht,
          2
        )}฿`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { payOrder }
      } = await this.$apollo.mutate({
        mutation: PAY_ORDER,
        variables: {
          id
        }
      });
      if (payOrder.is_error) {
        this.$message.error(payOrder.message);
      } else {
        this.$apollo.queries.orderDetail.refetch();
        this.$message.success(payOrder.message);
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    }
  },
  apollo: {
    orderDetail: {
      query: GET_ORDER,
      variables() {
        return {
          id: this.id
        };
      },
      result({ data, loading }) {
        if (data && !loading) {
          const { orderDetail } = data;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "รายการสั่งซื้อ", route: "/shop/order" },
            { title: `สั่งซื้อ ${orderDetail.record.orderCode}` }
          ]);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
