<template>
  <div>
    <!--begin::Dashboard-->

    <div class="row" v-if="overview">
      <div class="col-12 col-md-6 col-xxl-3" v-if="overview.balances">
        <router-link
          to="/wallet"
          class="card bg-primary hoverable card-xl-stretch mb-4"
        >
          <!--begin::Body-->
          <div class="card-body">
            <div class="d-flex align-items-center">
              <span class="svg-icon svg-icon-4x svg-icon-white mr-4">
                <span class="svg-icon svg-icon-4x">
                  <inline-svg src="/media/svg/icons/Shopping/Wallet.svg" />
                </span>
              </span>
              <div>
                <div class="text-white fs-4">ยอดเงินในกระเป๋าคงเหลือ</div>
                <div class="text-white font-weight-bolder fs-2">
                  {{ overview.balances.thb.toLocaleString() }}
                  บาท
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-xxl-3">
        <router-link
          to="/forwarder/payshipping/create"
          class="card bg-warning hoverable card-xl-stretch mb-4"
        >
          <!--begin::Body-->
          <div class="card-body">
            <div class="d-flex align-items-center">
              <span class="svg-icon svg-icon-4x svg-icon-white mr-4">
                <span class="svg-icon svg-icon-4x">
                  <inline-svg src="/media/svg/icons/Shopping/Calculator.svg" />
                </span>
              </span>
              <div>
                <div class="text-white fs-4">ที่ต้องชำระเงิน</div>
                <div v-if="overview" class="text-white font-weight-bolder fs-2">
                  {{ overview.paytransport }} รายการ
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-xxl-3" v-if="overview.rateBuy">
        <router-link
          to="/admin/shop/order"
          class="card bg-secondary hoverable card-xl-stretch mb-4"
        >
          <!--begin::Body-->
          <div class="card-body">
            <div class="d-flex align-items-center">
              <span class="svg-icon svg-icon-4x svg-icon-dark mr-4">
                <span class="svg-icon svg-icon-4x">
                  <inline-svg src="/media/svg/icons/Shopping/Bitcoin.svg" />
                </span>
              </span>
              <div>
                <div class="text-dark fs-4">เรทสั่งซื้อสินค้า</div>
                <div class="text-dark font-weight-bolder fs-2">
                  {{
                    overview.rateBuy.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}
                  <span class="fs-3">บาท/ 1 หยวน</span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-xxl-3" v-if="overview.rateAlipay">
        <router-link
          to="/admin/payments"
          class="card bg-secondary hoverable card-xl-stretch mb-4"
        >
          <!--begin::Body-->
          <div class="card-body">
            <div class="d-flex align-items-center">
              <span class="svg-icon svg-icon-4x svg-icon-dark mr-4">
                <span class="svg-icon svg-icon-4x">
                  <inline-svg src="/media/svg/icons/Shopping/Bitcoin.svg" />
                </span>
              </span>
              <div>
                <div class="text-dark fs-4">เรทโอนเงินไปจีน</div>
                <div class="text-dark font-weight-bolder fs-2">
                  {{
                    overview.rateAlipay.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}
                  <span class="fs-3">บาท/ 1 หยวน</span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </router-link>
      </div>
    </div>

    <div class="row mt-4" v-if="overview">
      <div class="col-xl-4 col-md-6">
        <UserLevelPrice
          v-if="overview && overview.userLevel"
          title="ประเภทการขนส่ง ทางรถ"
          title_class="la la-shipping-fast"
          bg_color="bg-light-success"
          icon_color="text-success"
          :list="overview.userLevel.shippingEk"
        />
      </div>
      <div class="col-xl-4 col-md-6">
        <UserLevelPrice
          v-if="overview && overview.userLevel"
          title="ประเภทการขนส่ง ทางเรือ"
          title_class="la la-ship"
          bg_color="bg-light-primary"
          icon_color="text-primary"
          :list="overview.userLevel.shippingShip"
        />
      </div>
      <div class="col-xl-4">
        <TrackingDashboard
          title="รายการที่ต้องชำระเงิน"
          :list="overview.paytransport_list"
        />
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { OVERVIEW } from "@/core/services/store/profile.module.js";
import UserLevelPrice from "./components/UserLevelPrice";
import TrackingDashboard from "./components/TrackingDashboard";
import { WEBSITE } from "@/config";

export default {
  name: "DashboardAdmin",
  components: {
    UserLevelPrice,
    TrackingDashboard
  },
  data() {
    return {
      loading: false,
      overview: {}
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "แดชบอร์ด" }]);
    document.title = `แดชบอร์ด | ${WEBSITE}`;
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$store.dispatch(OVERVIEW).then(
        ({ code, data }) => {
          this.loading = false;
          if (code === 200) {
            this.overview = data;
            this.isSuccess = true;
          } else {
            this.isSuccess = false;
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
