<template>
  <div>
    <table class="table table-sm table-borderless table-vertical-center">
      <thead>
        <tr>
          <th>ชื่อ-นามสกุล</th>
          <th>เบอร์ติดต่อ</th>
          <th>ที่อยู่</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in user.address" v-bind:key="item._id">
          <td>
            <button
              class="btn btn-link btn-sm"
              v-b-modal.address-edit-modal
              @click="setEdit(item)"
            >
              {{ item.name }}
            </button>
          </td>
          <td>
            {{ item.phone }}
          </td>
          <td>
            {{ item.address }}
          </td>
          <td class="text-center">
            <button
              type="button"
              class="btn btn-icon btn-light btn-hover-danger btn-sm"
              @click="deleteAddress(item)"
              v-if="!item.default"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </button>
          </td>
        </tr>
        <tr class="text-center" v-if="user.address && !user.address.length">
          <td colspan="4" class="pt-8">ไม่มีข้อมูล กรุณาเพิ่ม</td>
        </tr>
      </tbody>
    </table>
    <button
      type="button"
      v-b-modal.address-add-modal
      class="btn btn-block btn-dashed"
    >
      <i class="menu-icon la la-plus icon-xl"></i> เพิ่มที่อยู่
    </button>
    <b-modal
      id="address-edit-modal"
      centered
      size="lg"
      title="แก้ไขที่อยู่"
      @ok="handleOk"
    >
      <b-form ref="formEditAddress" @submit.prevent="updateAdress">
        <div class="row">
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ชื่อ - นามสกุล</label>
              <div class="col-12">
                <input
                  v-model="edit.name"
                  :state="nameAddressState"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เบอร์โทรศัพท์</label>
              <div class="col-12">
                <input
                  v-model="edit.phone"
                  :state="nameAddressState"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">บ้านเลขที่</label>
              <div class="col-12">
                <input
                  v-model="edit.address"
                  :state="nameAddressState"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">แขวง/ตำบล</label>
              <div class="col-12">
                <addressinput-subdistrict
                  v-model="edit.district"
                  placeholder="ตำบล"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เขต/อำเภอ</label>
              <div class="col-12">
                <addressinput-district
                  v-model="edit.amphoe"
                  placeholder="อำเภอ"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">จังหวัด</label>
              <div class="col-12">
                <addressinput-province
                  placeholder="จังหวัด"
                  v-model="edit.province"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">รหัสไปรษณีย์</label>
              <div class="col-12">
                <addressinput-zipcode
                  placeholder="รหัสไปรษณีย์"
                  v-model="edit.postcode"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ที่อยู่หลัก</label>
              <div class="col-12">
                <span class="switch switch-sm mt-1">
                  <label class="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="input-is_default"
                      v-model="edit.default"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="address-add-modal"
      centered
      size="lg"
      title="เพิ่มที่อยู่"
      @ok="handleOkAdd"
    >
      <b-form ref="formAddAddress" @submit.prevent="addAdress">
        <div class="row">
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ชื่อ - นามสกุล</label>
              <div class="col-12">
                <input
                  v-model="add.name"
                  :state="nameNewAddressState"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เบอร์โทรศัพท์</label>
              <div class="col-12">
                <input
                  v-model="add.phone"
                  :state="nameNewAddressState"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">บ้านเลขที่</label>
              <div class="col-12">
                <input
                  v-model="add.address"
                  :state="nameNewAddressState"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">แขวง/ตำบล</label>
              <div class="col-12">
                <addressinput-subdistrict
                  v-model="add.district"
                  placeholder="ตำบล"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">เขต/อำเภอ</label>
              <div class="col-12">
                <addressinput-district
                  v-model="add.amphoe"
                  placeholder="อำเภอ"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">จังหวัด</label>
              <div class="col-12">
                <addressinput-province
                  placeholder="จังหวัด"
                  v-model="add.province"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">รหัสไปรษณีย์</label>
              <div class="col-12">
                <addressinput-zipcode
                  placeholder="รหัสไปรษณีย์"
                  v-model="add.postcode"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="form-group row mb-2">
              <label class="col-form-label col-12 pb-0">ที่อยู่หลัก</label>
              <div class="col-12">
                <span class="switch switch-sm mt-1">
                  <label class="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="input-is_default"
                      v-model="add.default"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import {
  CREATE_PROFILE_ADDRESS,
  UPDATE_PROFILE_ADDRESS,
  DELETE_PROFILE_ADDRESS
} from "@/graphql/profile";
export default {
  mixins: [validationMixin],
  props: ["data", "refetch"],
  data() {
    return {
      user: this.data,
      nameNewAddressState: null,
      nameAddressState: null,
      add: {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      },
      edit: {
        name: "",
        phone: "",
        province: "",
        amphoe: "",
        district: "",
        postcode: "",
        address: "",
        default: false
      }
    };
  },
  validations: {
    edit: {
      name: {
        required
      },
      phone: {
        required
      },
      province: {},
      amphoe: {},
      district: {},
      postcode: {},
      address: {},
      default: {}
    }
  },
  methods: {
    setEdit(value) {
      this.edit = JSON.parse(JSON.stringify(value));
    },
    handleOkAdd(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.addAdress();
    },
    async addAdress() {
      const valid = this.$refs.formAddAddress.checkValidity();
      this.nameNewAddressState = valid;
      if (!valid) {
        return;
      }
      const {
        data: { address_create }
      } = await this.$apollo.mutate({
        mutation: CREATE_PROFILE_ADDRESS,
        variables: {
          ...this.add
        }
      });
      if (!address_create.isSuccess) {
        this.$message.error(address_create.message);
      } else {
        this.add = {
          name: "",
          phone: "",
          province: "",
          amphoe: "",
          district: "",
          postcode: "",
          address: "",
          default: false
        };
        this.$bvModal.hide("address-add-modal");
        this.$message.success(address_create.message);
        if (this.refetch) {
          this.refetch();
        }
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.updateAdress();
    },
    async updateAdress() {
      const valid = this.$refs.formEditAddress.checkValidity();
      this.nameAddressState = valid;
      if (!valid) {
        return;
      }
      const {
        data: { address_update }
      } = await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_ADDRESS,
        variables: {
          ...this.edit
        }
      });
      if (!address_update.isSuccess) {
        this.$message.error(address_update.message);
      } else {
        this.$message.success(address_update.message);
        if (this.refetch) {
          this.refetch();
        }
        this.$bvModal.hide("address-edit-modal");
      }
    },
    async deleteAddress(value) {
      const confirm = await Swal.fire({
        title: "ยืนยันการลบข้อมูล ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { address_delete }
      } = await this.$apollo.mutate({
        mutation: DELETE_PROFILE_ADDRESS,
        variables: {
          _id: value._id
        }
      });
      if (!address_delete.isSuccess) {
        this.$message.error(address_delete.message);
      } else {
        this.$message.success(address_delete.message);
        if (this.refetch) {
          this.refetch();
        }
      }
    }
  },
  watch: {
    data: function(newVal) {
      this.user = newVal;
    }
  }
};
</script>
