<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" class="form" @submit.stop.prevent="create">
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <h3 class="text-dark-75 font-weight-bold mb-8">สร้างรายการขนส่ง</h3>
          <div class="row mt-4">
            <div class="col-lg-10">
              <div class="form-group row">
                <label
                  for="input-transportNumber"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >เลข Tracking</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-transportNumber"
                    v-model="form.transportNumber"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="amount"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >จำนวน</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="amount"
                    type="number"
                    :state="nameState"
                    min="1"
                    required
                    v-model="form.amount"
                    class="mb-2"
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="amount"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >ตีลังไม้</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <span class="switch switch-sm mt-2">
                    <label class="d-flex align-items-center">
                      <input type="checkbox" v-model="form.guarantee" />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="input-ship_by"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >เลือกขนส่งระหว่างประเทศ</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-dropdown
                    v-model="form.ship_by"
                    :state="nameState"
                    :text="
                      form.ship_by === 'ek'
                        ? 'ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)'
                        : 'ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน）'
                    "
                  >
                    <b-dropdown-item value="ek" @click="form.ship_by = 'ek'">
                      ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)
                    </b-dropdown-item>
                    <b-dropdown-item
                      value="ship"
                      @click="form.ship_by = 'ship'"
                    >
                      ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน)
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="remark"
                  class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >บันทึกช่วยจำ</label
                >
                <div class="col-lg-5 col-md-9 col-sm-12">
                  <b-form-input
                    id="remark"
                    now-button
                    v-model="form.remark"
                    class="mb-2"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-4"
                style="width: 140px"
              >
                เพิ่มรายการขนส่ง
              </button>
              <router-link
                to="/forwarder"
                class="btn btn-secondary"
                style="width: 140px"
              >
                ยกเลิก
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_TRACKING } from "@/graphql/admin/transport.js";

export default {
  mixins: [validationMixin],
  name: "forwarder_create",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการพัสดุ`, route: "/forwarder" },
      { title: `สร้างรายการขนส่ง` }
    ]);
  },
  data() {
    return {
      nameState: null,
      form: {
        transportNumber: "",
        amount: 0,
        ship_by: "ek",
        remark: "",
        guarantee: false
      }
    };
  },
  validations: {
    form: {
      transportNumber: {
        required
      },
      amount: {
        required
      },
      ship_by: {
        required
      },
      remark: {},
      guarantee: {}
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    async create() {
      if (!this.checkFormValidity()) {
        return;
      }

      const transportNumber = this.$v.form.transportNumber.$model;
      const ship_by = this.$v.form.ship_by.$model;
      const amount = this.$v.form.amount.$model;
      const remark = this.$v.form.remark.$model;
      const guarantee = this.$v.form.guarantee.$model;

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        transportNumber,
        ship_by,
        amount: Number(amount),
        remark,
        guarantee
      };
      const {
        data: { createTransport }
      } = await this.$apollo.mutate({
        mutation: CREATE_TRACKING,
        variables: {
          input: input
        }
      });
      if (!createTransport.isSuccess) {
        this.$message.error(createTransport.message);
      } else {
        this.$message.success(createTransport.message);
        this.$router.push({ name: "forwarder" });
      }
    }
  }
};
</script>
