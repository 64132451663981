import Vue from "vue";
import axios from "axios";
import { API_URL } from "@/config/index";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    axios.defaults.baseURL = API_URL;
    axios.defaults.withCredentials = true;
  },

  query(resource, params) {
    return axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return axios
      .get(`${resource}/${slug}`, {
        headers: {
          "x-access-token": JwtService.getToken(),
          "x-refresh-token": JwtService.getRefreshToken()
        }
      })
      .catch(error => {
        // console.log(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(`${resource}`, params, {
      headers: {
        "x-access-token": JwtService.getToken(),
        "x-refresh-token": JwtService.getRefreshToken()
      }
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
