<template>
  <div class="card card-custom card-stretch">
    <div class="card-body">
      <div class="row" v-if="topup.bank">
        <div class="col-lg-8">
          <div class="col-md-6 offset-lg-3 col-lg-9">
            <label class="option">
              <span class="option-label">
                <span class="option-head justify-content-start">
                  <div class="symbol symbol-40 flex-shrink-0 mr-4">
                    <div
                      class="symbol-label"
                      :style="
                        `background-image: url('${BANK_URL}${topup.bank.bank_img}')`
                      "
                    ></div>
                  </div>
                  <span class="option-title">{{ topup.bank.bank_name }}</span>
                </span>
                <span class="option-body">
                  <div class="mb-1" style="font-size: 1.1rem">
                    เลขบัญชี {{ topup.bank.account_no }}
                  </div>
                  <div style="font-size: 1.1rem">
                    ชื่อบัญชี {{ topup.bank.account_name }}
                  </div>
                </span>
              </span>
            </label>
            <div class="fv-plugins-message-container"></div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-8">
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >ยอดเงินที่โอน</label
            >
            <div class="col-form-label col-lg-4 col-8">
              {{ format_number_pretty(topup.amount, 2) }}
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >วันที่โอน</label
            >
            <div class="col-form-label col-lg-4 col-8">
              {{ format_date(topup.date) }}
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >สถานะ</label
            >
            <div class="col-form-label col-lg-4 col-8">
              <span
                class="label label-lg label-inline"
                v-bind:class="`label-light-${getStatusColor(topup.status)}`"
              >
                {{ topup.statusShow }}
              </span>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4">
              สลิปโอนเงิน
            </label>
            <div class="col-lg-4 col-8">
              <div class="uppy" id="kt_uppy_5">
                <div class="uppy-list">
                  <div v-if="topup.file_attachment">
                    <div
                      v-viewer
                      class="border symbol symbol-60 flex-shrink-0 mr-4"
                    >
                      <img
                        style="object-fit: cover"
                        :src="imageUrlApi(topup.file_attachment)"
                        :key="topup.file_attachment"
                        height="250"
                        width="250"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import { GET_TOPUP } from "@/graphql/topup";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BANK_URL, imageUrlApi, WEBSITE } from "@/config";

export default {
  name: "TopupList",
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `กระเป๋าเงิน`, route: "/wallet?cat=2" },
      { title: `เติมเงิน` }
    ]);
    document.title = `เติมเงิน | ${WEBSITE}`;
  },
  data() {
    const { id } = this.$route.params;
    return {
      BANK_URL,
      valid: true,
      id: Number(id),
      amount: 0,
      date: new Date(),
      previewImage: null,
      file: null,
      fileValid: true,
      dateValid: true,
      bank: null
    };
  },
  methods: {
    imageUrlApi,
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val, digits = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    topup: {
      query: GET_TOPUP,
      variables() {
        return {
          id: this.id
        };
      }
    }
  }
};
</script>
