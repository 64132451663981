import gql from "graphql-tag";

export const CREATE_ORDER_ITEM_BALANCE = gql`
  mutation($input: OrderItemBalanceInput) {
    newOrderTransaction(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_TRANSACTIONS_WAIT = gql`
  query($orderId: Int) {
    getOrderTransactionWait(orderId: $orderId) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        transaction_id
        amount
        reason
        collection_id
        transaction_type_id
        doc_id
        created_at
      }
    }
  }
`;

export const CONFIRM_TRANSACTION = gql`
  mutation($_id: ID, $is_confirm: Boolean) {
    confirmOrderTransaction(_id: $_id, is_confirm: $is_confirm) {
      isSuccess
      message
    }
  }
`;
