import gql from "graphql-tag";

export const GET_LOGISTICS_LIST_SELECT = gql`
  query($country: String) {
    logisticsList(country: $country) {
      servicePriceDropship
      records {
        logisticsId
        logisticsName
        logisticsServicePrice
        is_other
        is_address
        logisticsPriceList {
          _id
          min
          price
        }
        type
      }
    }
  }
`;

export const GET_LOGISTICS = gql`
  query($id: Int) {
    logistics(id: $id) {
      _id
      no
      logisticsId
      logisticsNo
      logisticsName
      logisticsServicePrice
      price_extened
      logisticsPriceList {
        min
        max
        price
      }
      type
      is_address
      default
    }
  }
`;

export const GET_SELECT_LOGISTICS_LIST = gql`
  query($promotion_id: Int) {
    logisticsList(promotion_id: $promotion_id) {
      records {
        _id
        no
        logisticsId
        logisticsName
        logisticsServicePrice
        logisticsServicePrice_original
        logisticsPriceList {
          min
          max
          price
        }
        logisticsPriceList_original {
          min
          max
          price
        }
        type
        is_address
        default
      }
    }
  }
`;
