<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card-custom card-transparent" v-if="user">
      <div class="card-body p-0">
        <div
          class="wizard wizard-4"
          id="kt_wizard_user_create"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav">
            <div class="wizard-steps">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ข้อมูลลูกค้า</div>
                    <div class="wizard-desc">Infomation</div>
                  </div>
                </div>
              </div>
              <div
                class="wizard-step"
                data-wizard-type="step"
                v-bind:class="{
                  'bg-danger text-white':
                    dirtyAddress && !validateState('address')
                }"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ข้อมูลที่อยู่</div>
                    <div class="wizard-desc">Address</div>
                  </div>
                </div>
              </div>
              <div
                class="wizard-step"
                data-wizard-type="step"
                v-bind:class="{
                  'bg-danger text-white':
                    dirtyUserLevel && !validateState('userLevelId')
                }"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ราคาขนส่ง</div>
                    <div class="wizard-desc">Shipping price</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card card-custom card-shadowless rounded-top-0 card-body">
            <div class="tab-content">
              <!--begin::Tab-->
              <div
                class="tab-pane show active px-7"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <b-form
                  ref="user"
                  class="from"
                  @submit.stop.prevent="save"
                  v-if="user"
                >
                  <!--begin::Row-->
                  <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-7 my-2">
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >ชื่อ - นามสกุล</label
                        >
                        <div class="col-9">
                          <b-form-input
                            v-model="user.full_name"
                            :state="validateState('full_name')"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >เบอร์โทรศัพท์</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-phone"></i>
                              </span>
                            </div>
                            <b-form-input
                              type="text"
                              class="form-control"
                              :state="validateState('phone')"
                              v-model="user.phone"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >อีเมล์</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-at"></i>
                              </span>
                            </div>
                            <b-form-input
                              type="text"
                              class="form-control"
                              :state="validateState('email')"
                              v-model="user.email"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row mb-2">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >รหัสผ่าน</label
                        >
                        <div class="col-9">
                          <b-form-input
                            type="text"
                            class="form-control"
                            :state="validateState('password')"
                            v-model="user.password"
                            required
                          />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >ตั้งเป็นผู้ดูแล</label
                        >
                        <div class="col-9">
                          <span class="switch switch-sm mt-1">
                            <label class="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="input-is_default"
                                v-model="user.is_admin"
                              />
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <!--end::Group-->
                    </div>
                  </div>
                  <div class="card-footer pb-0">
                    <div class="row">
                      <div class="col-xl-7 offset-xl-2">
                        <div class="row">
                          <div class="col-3"></div>
                          <div class="col-9">
                            <button
                              class="btn btn-light-primary font-weight-bold mr-4"
                            >
                              Save changes
                            </button>
                            <button
                              href="#"
                              class="btn btn-clean font-weight-bold"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Row-->
                </b-form>
              </div>
              <!--end::Tab-->
              <!--begin::Tab-->
              <div class="tab-pane px-7" data-wizard-type="step-content">
                <user-address-create :data="user" :onChange="onChangeAddress" />
              </div>
              <!--end::Tab-->
              <!--begin::Tab-->
              <div class="tab-pane px-7" data-wizard-type="step-content">
                <!--begin::Body-->
                <user-price-shipping-create
                  :data="user"
                  :onChange="onSelectUserShippingPrice"
                />
                <!--end::Footer-->
              </div>
              <!--end::Tab-->
            </div>
          </div>
          <!--begin::Card body-->
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>
<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import KTWizard from "@/assets/js/components/wizard";
import UserAddressCreate from "./components/UserAddressCreate";
import UserPriceShippingCreate from "./components/UserPriceShippingCreate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_USER } from "@/graphql/admin/user";
import { WEBSITE } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `เพิ่มผู้ใช้งาน | ${WEBSITE}`;
  },
  components: {
    UserAddressCreate,
    UserPriceShippingCreate
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ผู้ใช้ในระบบทั้งหมด`, route: "/admin/users" },
      { title: `เพิ่มผู้ใช้` }
    ]);

    new KTWizard("kt_wizard_user_create", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });
  },
  data() {
    return {
      nameState: null,
      dirtyUserLevel: false,
      dirtyAddress: false,
      user: {
        full_name: "",
        email: "",
        password: "",
        phone: "",
        is_admin: false,
        userLevelId: "",
        address: []
      }
    };
  },
  validations: {
    user: {
      full_name: {
        required
      },
      email: {
        required
      },
      password: {
        required
      },
      phone: {
        required
      },
      is_admin: {
        required
      },
      userLevelId: {
        required
      },
      address: {}
    }
  },
  methods: {
    onSelectUserShippingPrice(val) {
      const { target } = val;
      this.dirtyUserLevel = true;
      this.user.userLevelId = target.value;
      this.$v.user.userLevelId.$model = Number(target.value);
    },
    onChangeAddress(val) {
      this.dirtyAddress = true;
      this.user.address = val;
      this.$v.user.address.$model = val;
    },
    validateState(name) {
      if (name === "userLevelId") {
        this.dirtyUserLevel = true;
      } else if (name === "address") {
        this.dirtyAddress = true;
      }
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.dirtyUserLevel = true;
      this.dirtyAddress = true;
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันการบันทึกข้อมูล ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const input = {
        userId: this.user.userId,
        full_name: this.$v.user.full_name.$model,
        phone: this.$v.user.phone.$model,
        email: this.$v.user.email.$model,
        password: this.$v.user.password.$model,
        is_admin: this.$v.user.is_admin.$model,
        userLevelId: Number(this.$v.user.userLevelId.$model),
        address: this.$v.user.address.$model
      };
      const {
        data: { createUser }
      } = await this.$apollo.mutate({
        mutation: CREATE_USER,
        variables: {
          input: input
        }
      });
      const { isSuccess, message, error_list } = createUser;
      if (!isSuccess) {
        this.$message.error({
          message:
            message ||
            (error_list && error_list.map(x => x.message).join("<br />")),
          supportHTML: true
        });
      } else {
        this.$message.success(message);
        this.$router.push("/admin/users");
      }
    }
  }
};
</script>
