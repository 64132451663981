var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('b-dropdown',{staticClass:"border mb-4 d-lg-none",staticStyle:{"width":"100%"},attrs:{"text":_vm.getText(),"variant":"white","block":"","size":"lg"}},[_c('b-dropdown-item',{attrs:{"to":"/admin/seller/category"}},[_c('i',{staticClass:"menu-icon la la-box-open icon-xl mr-4"}),_c('span',{staticClass:"menu-text"},[_vm._v("ประเภทสินค้า")])])],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"aside aside-left aside-fixed d-flex flex-column flex-row-auto"},[_c('div',{staticClass:"aside-menu-wrapper flex-column-fluid"},[_c('div',{staticClass:"aside-menu min-h-lg-800px",attrs:{"data-menu-vertical":"1","data-menu-dropdown-timeout":"500"}},[_c('perfect-scrollbar',{staticClass:"aside-menu scroll",staticStyle:{"max-height":"90vh","position":"relative"}},[_c('ul',{staticClass:"menu-nav"},[(_vm.permisison.admin_exchange_rate)?_c('router-link',{attrs:{"to":"/admin/seller/sale"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon la la-shopping-cart icon-xl"}),_c('span',{staticClass:"menu-text"},[_vm._v("รายการสั่งซื้อ")])])])]}}],null,false,3150751052)}):_vm._e(),(_vm.permisison.admin_exchange_rate)?_c('router-link',{attrs:{"to":"/admin/seller/category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon la la-box-open icon-xl"}),_c('span',{staticClass:"menu-text"},[_vm._v("ประเภทสินค้า")])])])]}}],null,false,1597787413)}):_vm._e(),(_vm.permisison.admin_exchange_rate)?_c('router-link',{attrs:{"to":"/admin/seller/forwarder"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon la la-shipping-fast icon-xl"}),_c('span',{staticClass:"menu-text"},[_vm._v("ค่านำเข้า")])])])]}}],null,false,1529537964)}):_vm._e()],1)])],1)])]),_c('div',{staticClass:"content-wrapper flex-row-fluid ml-lg-4"},[_c('router-view')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }