<template>
  <div class="row mt-4">
    <div class="col-lg-8">
      <div class="card card-custom card-stretch">
        <b-overlay :show="loading" rounded="sm">
          <div class="card-body" v-if="systemPayment && systemPayment.record">
            <div class="form-group row mb-0 align-items-center">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
                >ผู้ใช้</label
              >
              <div class="col-form-label col-lg-5 col-8">
                <router-link
                  :to="`/admin/users/${systemPayment.record.userId}`"
                >
                  {{
                    systemPayment.record.user
                      ? systemPayment.record.user.username
                      : "-"
                  }}
                </router-link>
              </div>
            </div>
            <div class="form-group row mb-0 align-items-center fs-4">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
                >เลขที่รายการ</label
              >
              <div class="col-form-label col-lg-5 col-8">
                {{ systemPayment.record.paymentDoc }}
              </div>
            </div>
            <div class="form-group row mb-0 align-items-center">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
                >ยอดเงินที่โอน</label
              >
              <div class="col-form-label col-lg-5 col-8 fs-2">
                {{ format_number(systemPayment.record.amount, 2, true, true) }}
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
                >รายละเอียดธนาคาร</label
              >
              <div class="col-form-label col-lg-5 col-8">
                {{ systemPayment.record.bank_name }}
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
                >วันที่สร้าง</label
              >
              <div class="col-form-label col-lg-5 col-8">
                {{ format_datetime(systemPayment.record.created_at) }}
              </div>
            </div>
            <div class="form-group row mb-2">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
                >สถานะ</label
              >
              <div class="col-form-label col-lg-5 col-8">
                <span
                  class="label label-lg label-inline"
                  v-bind:class="
                    `label-light-${getStatusColor(systemPayment.record.status)}`
                  "
                >
                  {{ systemPayment.record.statusShow }}
                </span>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4">
                รูปธนาคาร
              </label>
              <div class="col-lg-5 col-8">
                <div class="uppy" id="kt_uppy_5">
                  <div class="uppy-list">
                    <div v-if="systemPayment.record.file_attachment">
                      <div
                        v-viewer
                        class="border symbol symbol-200 flex-shrink-0 mr-4"
                      >
                        <img
                          style="object-fit: cover"
                          :src="
                            imageUrlApi(systemPayment.record.file_attachment)
                          "
                          :key="systemPayment.record.file_attachment"
                          height="250"
                          width="250"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-4 col-sm-3 col-4">
                หลักฐานการโอนเงิน
              </label>
              <div class="col-lg-5 col-8">
                <div class="uppy" id="kt_uppy_5">
                  <div class="uppy-wrapper">
                    <div class="uppy-Root uppy-FileInput-container">
                      <input
                        class="uppy-FileInput-input uppy-input-control"
                        ref="fileInput"
                        type="file"
                        accept="image/*"
                        name="files[]"
                        multiple=""
                        @input="pickFile"
                        id="file"
                        style=""
                      />
                      <label
                        class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                        for="file"
                      >
                        เลือก หลักฐานการโอนเงิน
                      </label>
                    </div>
                  </div>
                  <div class="uppy-list">
                    <div
                      class="uppy-list-item"
                      v-if="systemPayment.record.pay_slip || previewImage"
                    >
                      <div
                        v-viewer
                        class="border symbol symbol-200 flex-shrink-0 mr-4"
                      >
                        <img
                          style="object-fit: contain"
                          :src="
                            previewImage ||
                              imageUrlApi(systemPayment.record.pay_slip)
                          "
                          height="200"
                          width="200"
                        />
                      </div>
                      <div class="uppy-list-label">
                        {{ file && file.name }}
                      </div>
                      <span class="uppy-list-remove" @click="removeFile()">
                        <i class="flaticon2-cancel-music"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <span class="form-text text-muted">
                  อัพโหลดไฟล์ไม่เกิน 10MB.
                </span>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                class="col-form-label text-right col-xl-3 col-lg-2 col-sm-2 col-2"
              ></label>
              <div class="col-form-label col-xl-6 col-lg-7 col-sm-9 col-10">
                <div class="dashed"></div>
                <div class="my-4 text-right fs-4">
                  เรทเงิน (หยวน) :
                  {{ systemPayment.record.rate }}
                </div>
                <div class="bg-light p-2 mb-4 rounded text-right fs-2">
                  ยอดรวม :
                  {{ " " }}
                  {{
                    systemPayment.record.baht.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}
                  บาท
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer" v-if="systemPayment">
            <div class="row">
              <div class="col-sm-10 col-lg-10 ml-auto">
                <button
                  class="btn btn-success mr-2"
                  v-on:click="approve($event, systemPayment.record)"
                  style="width: 140px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                  </span>
                  Approve
                </button>
                <button
                  v-if="systemPayment.record.status === 0"
                  v-on:click="reject($event, systemPayment.record)"
                  class="btn btn-secondary"
                  style="width: 140px"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import {
  GET_TRANSFER,
  APPROVE_TRANSFER,
  REJECT_TRANSFER
} from "@/graphql/admin/payment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { imageUrlApi, WEBSITE } from "@/config";
import bigDecimal from "js-big-decimal";
import Swal from "sweetalert2";

export default {
  name: "PaymentDetail",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "โอนเงินไปจีน", route: "/admin/payments" },
      { title: `โอนเงินไปจีน #${this.id}` }
    ]);
  },
  created() {
    document.title = `โอนเงินไปจีน | ${WEBSITE}`;
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      previewImage: null,
      loading: false,
      file: null,
      fileValid: true,
      guarantee: false,
      valid: false
    };
  },
  methods: {
    imageUrlApi,
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        default:
          return "primary";
      }
    },
    async approve(e, value) {
      if (!this.file) {
        this.$message.error("กรุณาแนบไฟล์ หลักฐานการโอนเงิน");
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันอนุมัติโอนเงิน ?",
        icon: "question",
        html: `อนุมัติโอนเงิน ${value.paymentDoc}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      this.loading = true;
      const {
        data: { system_approve_payment }
      } = await this.$apollo.mutate({
        mutation: APPROVE_TRANSFER,
        variables: {
          approve: {
            _id: value._id,
            file: this.file
          }
        }
      });
      this.loading = false;
      if (system_approve_payment.is_error) {
        this.$message.error(system_approve_payment.message);
      } else {
        this.$message.success(system_approve_payment.message);
        this.$apollo.queries.systemPayment.refetch();
      }
    },
    async reject(e, value) {
      const confirm = await Swal.fire({
        title: "ยืนยันยกเลิกรายการ ?",
        icon: "question",
        html: `ยกเลิกรายการ ${value.paymentDoc}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      this.loading = true;
      const {
        data: { system_reect_payment }
      } = await this.$apollo.mutate({
        mutation: REJECT_TRANSFER,
        variables: {
          id: value._id
        }
      });
      this.loading = false;
      if (system_reect_payment.is_error) {
        this.$message.error(system_reect_payment.message);
      } else {
        this.$message.success(system_reect_payment.message);
        this.$apollo.queries.systemPayment.refetch();
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.systemPayment.record.pay_slip = null;
      this.previewImage = null;
    },
    format_number(val, decimal = 0, format = false, yuan = false) {
      const currency = format ? (yuan ? "¥ " : "฿ ") : "";
      if (format) {
        if (val >= 0)
          return `${currency}${Number(
            bigDecimal.round(val, decimal)
          ).toLocaleString(undefined, {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal
          })}`;
        return `-${currency}${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    systemPayment: {
      query: GET_TRANSFER,
      variables() {
        return {
          id: this.id
        };
      }
    }
  }
};
</script>
