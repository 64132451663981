<template>
  <div
    class="fab-wrapper"
    v-if="
      this.$route.name !== 'chat_seller' &&
        this.$route.name !== 'admin_chat' &&
        this.$route.name !== 'messages'
    "
  >
    <router-link to="/chat/seller" v-if="IS_SELLER">
      <button class="fab-main pointer shadow-sm noti">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Communication/Chat4.svg" />
          <span
            v-if="getCurrentNoti && getCurrentNoti.seller_message"
            class="badge badge-pill badge-danger"
          >
            {{ getCurrentNoti.seller_message }}
          </span>
        </span>
        <span class="fs-4 fw-bolder"> แชทกับร้านค้า </span>
      </button>
    </router-link>
    <router-link to="/messages" v-if="!IS_SELLER">
      <button class="fab-main pointer shadow-sm noti">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Communication/Chat4.svg" />
          <span
            v-if="getCurrentNoti && getCurrentNoti.msg"
            class="badge badge-pill badge-danger"
          >
            {{ getCurrentNoti.msg }}
          </span>
        </span>
        <span class="fs-4 fw-bolder"> แชทติดต่อเจ้าหน้าที่ </span>
      </button>
    </router-link>
  </div>
</template>
<style lang="scss">
@import "./_chat";
</style>
<script>
import { mapGetters } from "vuex";
import { IS_SELLER } from "@/config";

export default {
  computed: {
    ...mapGetters(["getCurrentNoti"])
  },
  data() {
    return {
      IS_SELLER
    };
  }
};
</script>
