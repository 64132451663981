<template>
  <div class="card card-custom card-stretch">
    <div class="card-body">
      <div class="row" v-if="systemTopup.bank">
        <div class="col-lg-8">
          <div class="col-md-6 offset-lg-3 col-lg-9">
            <label class="option">
              <span class="option-label">
                <span class="option-head justify-content-start">
                  <div class="symbol symbol-40 flex-shrink-0 mr-4">
                    <div
                      class="symbol-label"
                      :style="
                        `background-image: url('${BANK_URL}${systemTopup.bank.bank_img}')`
                      "
                    ></div>
                  </div>
                  <span class="option-title">{{
                    systemTopup.bank.bank_name
                  }}</span>
                </span>
                <span class="option-body">
                  <div class="mb-1" style="font-size: 1.1rem">
                    เลขบัญชี {{ systemTopup.bank.account_no }}
                  </div>
                  <div style="font-size: 1.1rem">
                    ชื่อบัญชี {{ systemTopup.bank.account_name }}
                  </div>
                </span>
              </span>
            </label>
            <div class="fv-plugins-message-container"></div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-8">
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >ผู้ใช้</label
            >
            <div class="col-form-label col-lg-4 col-8">
              <router-link :to="`/admin/users/${systemTopup.userId}`">
                {{ systemTopup.user ? systemTopup.user.username : "-" }}
              </router-link>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >ยอดเงินที่โอน</label
            >
            <div class="col-form-label col-lg-4 col-8">
              {{ format_number_pretty(systemTopup.amount, 2) }}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >วันที่โอน</label
            >
            <div class="col-form-label col-lg-4 col-8">
              {{ format_date(systemTopup.date) }}
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4"
              >สถานะ</label
            >
            <div class="col-form-label col-lg-4 col-8">
              <span
                class="label label-lg label-inline"
                v-bind:class="
                  `label-light-${getStatusColor(systemTopup.status)}`
                "
              >
                {{ systemTopup.statusShow }}
              </span>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-form-label text-right col-lg-4 col-sm-3 col-4">
              สลิปโอนเงิน
            </label>
            <div class="col-lg-4 col-8">
              <div class="uppy" id="kt_uppy_5">
                <div class="uppy-list">
                  <div v-if="systemTopup.file_attachment">
                    <div
                      v-viewer
                      class="border symbol symbol-60 flex-shrink-0 mr-4"
                    >
                      <img
                        style="object-fit: cover"
                        :src="imageUrlApi(systemTopup.file_attachment)"
                        :key="systemTopup.file_attachment"
                        height="250"
                        width="250"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-lg-10 ml-lg-auto">
          <button
            class="btn btn-success mr-2"
            v-if="systemTopup.status === 0"
            v-on:click="approve($event, systemTopup)"
            style="width: 140px"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
            Approve
          </button>
          <button
            v-if="systemTopup.status === 0"
            v-on:click="reject($event, systemTopup)"
            class="btn btn-secondary"
            style="width: 140px"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { GET_TOPUP, APPROVE_TOPUP, REJECT_TOPUP } from "@/graphql/admin/topup";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BANK_URL, imageUrlApi, WEBSITE } from "@/config";

export default {
  name: "TopupDetail",
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `กระเป๋าเงิน`, route: "/admin/wallet?cat=2" },
      { title: `เติมเงิน` }
    ]);
    document.title = `เติมเงิน | ${WEBSITE}`;
  },
  data() {
    const { id } = this.$route.params;
    return {
      BANK_URL,
      valid: true,
      id: Number(id),
      amount: 0,
      date: new Date(),
      previewImage: null,
      file: null,
      fileValid: true,
      dateValid: true,
      bank: null
    };
  },
  methods: {
    imageUrlApi,
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val, digits = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    async approve(e, value) {
      const confirm = await Swal.fire({
        title: "ต้องการอนุมัติเติมเงิน ?",
        icon: "question",
        html: `อนุมัติเติมเงิน ${value.topup_no}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { approveTopup }
      } = await this.$apollo.mutate({
        mutation: APPROVE_TOPUP,
        variables: {
          id: value.topup_id
        }
      });
      if (approveTopup.error) {
        this.$message.error(approveTopup.message);
      } else {
        this.$message.success(approveTopup.message);
        this.$apollo.queries.topup.refetch();
      }
    },
    async reject(e, value) {
      const confirm = await Swal.fire({
        title: "ยกเลิกการเติมเงิน ?",
        icon: "warning",
        html: `ยกเลิกเติมเงิน ${value.topup_no}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { cancelTopup }
      } = await this.$apollo.mutate({
        mutation: REJECT_TOPUP,
        variables: {
          id: value.topup_id
        }
      });
      if (cancelTopup.error) {
        this.$message.error(cancelTopup.message);
      } else {
        this.$message.success(cancelTopup.message);
        this.$apollo.queries.topup.refetch();
      }
    },
    getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    }
  },
  apollo: {
    systemTopup: {
      query: GET_TOPUP,
      variables() {
        return {
          id: this.id
        };
      }
    }
  }
};
</script>
