import Vue from "vue";
import VueApollo from "vue-apollo";
import {
  createApolloClient,
  restartWebsockets
} from "vue-cli-plugin-apollo/graphql-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { API_URL, API_URL_WS, API_URL_GQL } from "@/config/index";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "iovjdaiovajrwiotj28934u8923u8912u3";

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || API_URL_GQL;
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS || API_URL_WS;

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-access-token": JwtService.getToken(),
      "x-refresh-token": JwtService.getRefreshToken()
    }
  };
});

const link = onError(({ graphQLErrors }) => {
  console.log("graphQLErrors :>> ", graphQLErrors);
  if (graphQLErrors)
    graphQLErrors.map(({ extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        axios.defaults.baseURL = API_URL;
        axios.defaults.withCredentials = true;
        axios
          .post(
            "do_refresh_tokens",
            {},
            {
              headers: {
                "x-refresh-token": JwtService.getRefreshToken()
              }
            }
          )
          .catch(err => {
            console.log("err :>> ", err);
            ApiService.get("do_logout").then(() => {
              JwtService.destroyToken();
              window.location.href = "/login";
            });
          });
        return;
      }
    });
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: wsEndpoint,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: true,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: authLink.concat(link),
  cache: new InMemoryCache({
    addTypename: false
  }),

  httpLinkOptions: {
    credentials: "include"
  }

  // Override default cache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
      }
    }
    // errorHandler(error) {
    //   console.log("error :>> ", error.graphQLErrors);
    //   console.log("error.message :>> ", error.message);
    //   console.log("error.extensions :>> ", error.extensions);
    //   if (error.networkError && error.networkError.statusCode === 400) {
    //     this.$store.dispatch("auth/logout").then(() => {
    //       this.$router.push("/login");
    //     });
    //   }
    //   // eslint-disable-next-line no-console
    //   console.log(
    //     "%cError",
    //     "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
    //     error.message
    //   );
    // }
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
