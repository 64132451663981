import { gql } from "@apollo/client";

export const CREATE_TRANSFER = gql`
  mutation($input: TransferInput) {
    createPayment(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_TRANSFER = gql`
  query($id: Int!) {
    payment(id: $id) {
      isSuccess
      message
      record {
        _id
        paymentId
        paymentDoc
        bank_name
        amount
        baht
        rate
        file_attachment
        approve_remark
        pay_slip
        remark
        cancel_remark
        status
        statusShow
        transactions {
          _id
          transaction_id
          amount
          reason
          doc_id
          created_at
        }
        created_at
      }
    }
  }
`;

export const GET_TRANSFERS = gql`
  query($page: Int!, $pageSize: Int!, $search: String, $status: String) {
    paymentList(
      page: $page
      pageSize: $pageSize
      search: $search
      status: $status
    ) {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        paymentId
        paymentDoc
        bank_name
        baht
        amount
        rate
        file_attachment
        pay_slip
        remark
        cancel_remark
        status
        statusShow
        created_at
        approve_date
      }
    }
  }
`;
