<template>
  <div class="card card-custom card-stretch gutter-b card-shadowless">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        <div class="symbol symbol-60px mr-5">
          <span class="symbol-label" :class="bg_color">
            <i
              class="h-50 align-self-center icon-2x"
              :class="`${title_class} ${icon_color}`"
            />
          </span>
        </div>
        {{ title }}
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <table class="table table-borderless table-vertical-center">
        <tbody>
          <tr v-if="item.china_land">
            <td class="text-right font-weight-bolder pl-0">โค้ทจัดส่งทางรถ</td>
            <td class="px-0">
              {{ item.china_land }}
            </td>
          </tr>
          <tr v-if="item.china_sea">
            <td class="text-right font-weight-bolder pl-0">
              โค้ทจัดส่งทางเรือ
            </td>
            <td class="px-0">
              {{ item.china_sea }}
            </td>
          </tr>
          <template v-for="address in item.china_address">
            <template>
              <tr
                :key="`${address._id}-${address.name}`"
                style="border-top: 1px solid #999999;"
              >
                <td class="text-right font-weight-bolder pl-0">
                  ที่อยู่โกดังจีน {{ address.name }}
                </td>
                <td class="px-0">
                  {{ address.address }}
                </td>
              </tr>
              <tr v-if="address.addressPhone" :key="address._id">
                <td class="text-right font-weight-bolder pl-0">
                  เบอร์โทรโกดังจีน
                </td>
                <td class="px-0">
                  {{ address.addressPhone }}
                </td>
              </tr>
            </template>
          </template>
          <tr v-if="item.china_guarantee">
            <td class="text-right font-weight-bolder pl-0">
              ตีลังไม้ให้ระบุหน้ากล่อง
            </td>
            <td class="text-danger px-0">
              {{ item.china_guarantee }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["item", "title", "title_class", "icon_color", "bg_color"],
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>
