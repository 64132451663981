<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" @submit.prevent="save">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">เพิ่มโกดังพัสดุ</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group row mb-2">
                <label
                  for="input-name"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ชื่อโกดังพัสดุ</label
                >
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-input
                    id="input-name"
                    v-model="trackingWarehouse.name"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-no"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ราคาต่อกิโล
                </label>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-input
                    id="input-no"
                    v-model="trackingWarehouse.warehouseKg"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-no"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ราคาต่อคิว.
                </label>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-input
                    id="input-no"
                    v-model="trackingWarehouse.warehouseCube"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-no"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >ที่อยู่โกดีงจีน
                </label>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-textarea
                    id="input-no"
                    v-model="trackingWarehouse.address"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-textarea>
                  <div class="mt-1 mb-2">
                    พิมพ์ <code>{{ text }}</code> เพื่อแทรกรหัสผู้ใช้
                  </div>
                </div>
              </div>
              <div class="form-group row mb-2">
                <label
                  for="input-no"
                  class="col-form-label text-right col-md-3 col-sm-12"
                  >เบอร์โทรศัพท์โกดังจีน
                </label>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <b-form-input
                    id="input-no"
                    v-model="trackingWarehouse.addressPhone"
                    :state="nameState"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body">
        <div class="row">
          <div class="col-lg-10 ml-lg-auto">
            <button
              type="submit"
              class="btn btn-success mr-4"
              style="min-width: 140px"
            >
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/General/Save.svg" />
              </span>
              บันทึก
            </button>
            <router-link
              to="/admin/setting/tracking-warehouse"
              class="btn btn-secondary"
              style="width: 140px"
            >
              ยกเลิก
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { WEBSITE } from "@/config";
import { CREATE_TRACKING_WAREHOUSE } from "@/graphql/admin/tracking_warehouse";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `เพิ่มโกดังพัสดุ | ${WEBSITE}`;
  },
  data() {
    return {
      text: "{{ code }}",
      nameState: null,
      messageError: "",
      trackingWarehouse: {
        name: "",
        warehouseKg: 0,
        warehouseCube: 0,
        address: "",
        addressPhone: ""
      }
    };
  },
  validations: {
    trackingWarehouse: {
      name: {
        required
      },
      warehouseKg: {
        required
      },
      warehouseCube: {
        required
      },
      address: {
        required
      },
      addressPhone: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.trackingWarehouse[name];
      return $dirty ? !$error : null;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    async save() {
      if (!this.checkFormValidity()) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const name = this.$v.trackingWarehouse.name.$model;
      const _id = this.trackingWarehouse._id;
      const warehouseKg = this.$v.trackingWarehouse.warehouseKg.$model;
      const warehouseCube = this.$v.trackingWarehouse.warehouseCube.$model;
      const address = this.$v.trackingWarehouse.address.$model;
      const addressPhone = this.$v.trackingWarehouse.addressPhone.$model;

      const input = {
        name,
        _id,
        warehouseKg,
        warehouseCube,
        address,
        addressPhone
      };

      input.warehouseKg = Number(input.warehouseKg) || 0;
      input.warehouseCube = Number(input.warehouseCube) || 0;
      const {
        data: { createTrackingWarehouse }
      } = await this.$apollo.mutate({
        mutation: CREATE_TRACKING_WAREHOUSE,
        variables: {
          input: input
        }
      });
      const { message, is_error } = createTrackingWarehouse;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$router.push("/admin/setting/tracking-warehouse");
      }
    }
  }
};
</script>
