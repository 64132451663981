import gql from "graphql-tag";

export const SELLER_INFO = gql`
  query {
    seller {
      isSuccess
      message
      data {
        seller_name
        seller_logo
        seller_verify
        seller_address {
          address
          province
          amphoe
          district
          postcode
        }
        status
      }
    }
  }
`;

export const UPDATE_SELLER = gql`
  mutation($input: SellerInput) {
    updateSeller(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;
