import gql from "graphql-tag";

export const CREATE_TOPUP = gql`
  mutation($input: TopupInput) {
    createTopup(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const APPROVE_TOPUP = gql`
  mutation($id: Int!) {
    approveTopup(id: $id) {
      isSuccess
      message
    }
  }
`;

export const REJECT_TOPUP = gql`
  mutation($id: Int!, $remark: String) {
    cancelTopup(id: $id, remark: $remark) {
      isSuccess
      message
    }
  }
`;

export const GET_TOPUPS = gql`
  query($page: Int!, $pageSize: Int!, $type: String, $search: String) {
    systemTopupList(
      page: $page
      pageSize: $pageSize
      type: $type
      search: $search
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        topup_id
        topup_no
        amount
        userId
        user {
          userId
          username
        }
        bank {
          bank_name
          bank_img
          bank_type
        }
        file_attachment
        status
        statusShow
        approve_date
        created_at
      }
    }
  }
`;

export const GET_TOPUP = gql`
  query($id: Int!) {
    systemTopup(id: $id) {
      _id
      topup_id
      topup_no
      payshipping_id
      amount
      remark
      date
      cancel_remark
      file_attachment
      status
      statusShow
      user {
        userId
        username
      }
      transactions {
        _id
        transaction_id
        amount
        reason
        doc_id
        created_at
      }
      bank {
        bank_name
        account_name
        account_no
        branch_name
        bank_img
        bank_type
      }
      approve_date
      created_at
    }
  }
`;
