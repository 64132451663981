<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    class="position-sticky"
    style="top:190px;max-height: calc(100vh - 190px);overflow-y: auto;"
  >
    <div class="card">
      <!--begin::Card header-->
      <div class="card-header bs5">
        <!--begin::Title-->
        <div class="card-title">
          <!--begin::User-->
          <div class="d-flex justify-content-center flex-column me-3">
            <div
              class="fs-2 fw-bolder text-gray-900 text-hover-primary mr-1 lh-1"
            >
              {{ data.dept.dept_name }}
            </div>
          </div>
          <!--end::User-->
        </div>
        <!--end::Title-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body">
        <!--begin::Messages-->
        <div
          class="scroll-y mr-n5 pr-5"
          v-if="data"
          id="message_wrapper"
          v-chat-scroll
          @v-chat-scroll-top-reached="more"
        >
          <!--begin::Message(in)-->
          <div
            class="d-flex mb-10"
            v-for="item in list"
            :key="item._id"
            :class="{
              'justify-content-start': item.receiver_id,
              'justify-content-end': !item.receiver_id
            }"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column"
              :class="{
                'align-items-start': item.receiver_id,
                'align-items-end': !item.receiver_id
              }"
            >
              <!--begin::User-->
              <div class="d-flex align-items-center">
                <div
                  class="mr-3 text-right"
                  v-if="item.sender_id && !item.receiver_id"
                >
                  <div
                    class="fs-5 fw-bolder text-gray-900 text-hover-primary ml-2"
                  >
                    {{ item.sender.full_name }}
                  </div>
                  <div class="text-muted fs-7 mb-1">
                    {{ format_datetime(item.created_at) }}
                  </div>
                </div>
                <!--begin::Avatar-->
                <div class="symbol symbol-30px symbol-circle">
                  <span
                    class="symbol-label bg-light-danger text-danger fs-6 fw-bolder"
                    >{{
                      item.sender ? item.sender.full_name.substr(0, 2) : ""
                    }}</span
                  >
                </div>
                <!--end::Avatar-->
                <div class="ml-3" v-if="item.sender_id && item.receiver_id">
                  <div
                    class="fs-5 fw-bolder text-gray-900 text-hover-primary mr-2"
                  >
                    {{ item.sender ? item.sender.full_name : "" }}
                  </div>
                  <div class="text-muted fs-7 mb-1">
                    {{ format_datetime(item.created_at) }}
                  </div>
                </div>
              </div>
              <!--end::User-->
              <!--begin::Text-->
              <div
                class="p-4 rounded text-dark fw-bold mw-lg-400px text-start text-message mt-2"
                v-if="item.body"
                :class="{
                  'bg-light-info': item.receiver_id,
                  'bg-light-primary': !item.receiver_id
                }"
              >
                {{ item.body }}
              </div>
              <div
                v-if="item.file_attachment"
                class="p-4 rounded text-dark fw-bold mw-lg-400px text-start text-message mt-2"
                :class="{
                  'bg-light-info': !item.receiver_id,
                  'bg-light-primary': item.receiver_id
                }"
              >
                <img v-viewer :src="item.file_attachment" class="img-message" />
              </div>
              <!--end::Text-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Message(in)-->
        </div>
        <!--end::Messages-->
      </div>
      <!--end::Card body-->
      <!--begin::Card footer-->
      <div class="card-footer pt-4">
        <!--begin::Input-->
        <b-form-textarea
          class="form-control form-control-flush mb-3"
          rows="1"
          max-rows="6"
          v-model="message"
          placeholder="เขียนข้อความ"
        />
        <!--end::Input-->
        <!--begin:Toolbar-->
        <div class="d-flex flex-stack">
          <!--begin::Actions-->
          <div class="d-flex align-items-center mr-2">
            <div class="uppy">
              <div class="uppy-wrapper">
                <div class="uppy-Root uppy-FileInput-container">
                  <input
                    class="uppy-FileInput-input uppy-input-control"
                    ref="fileInput"
                    type="file"
                    name="files[]"
                    accept="image/*"
                    multiple=""
                    @input="pickFile"
                    id="file"
                    style=""
                  />
                  <label
                    class="btn btn-sm btn-icon btn-active-light-primary mr-1 mb-0"
                    for="file"
                  >
                    <span class="la la-paperclip icon-lg" />
                  </label>
                </div>
              </div>
            </div>
            <span class="form-text text-muted"> อัพโหลดไฟล์ไม่เกิน 20MB. </span>
          </div>
          <!--end::Actions-->
          <!--begin::Send-->
          <button class="btn btn-primary" type="button" @click="save">
            <span class="la la-send mr-1" />
            Send
          </button>
          <!--end::Send-->
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="uppy">
              <div class="uppy-list">
                <div class="uppy-list-item" v-if="previewImage">
                  <div
                    v-viewer
                    class="border symbol symbol-60 flex-shrink-0 mr-4"
                  >
                    <img
                      style="object-fit: contain"
                      :src="previewImage"
                      height="200"
                      width="200"
                    />
                  </div>
                  <div class="uppy-list-label">
                    {{ file.name }}
                  </div>
                  <span class="uppy-list-remove" @click="removeFile()">
                    <i class="flaticon2-cancel-music"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Card footer-->
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { CREATE_MESSAGE } from "@/graphql/message.js";

export default {
  props: ["list", "data", "refetch", "fetchMore", "enabled"],
  data() {
    return {
      file: null,
      previewImage: null,
      message: "",
      loading: false
    };
  },
  methods: {
    more() {
      if (this.fetchMore) {
        this.fetchMore();
      }
    },
    async save() {
      if (!this.message && !this.file) {
        return;
      }
      this.loading = true;
      const {
        data: { createMessage }
      } = await this.$apollo.mutate({
        mutation: CREATE_MESSAGE,
        variables: {
          input: {
            dept_id: this.data.dept.dept_id,
            file: this.file,
            body: this.message
          }
        }
      });
      this.loading = false;
      const { message, is_error } = createMessage;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.message = "";
        this.removeFile();
        if (this.refetch) {
          this.refetch();
        }
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
    }
  }
};
</script>
