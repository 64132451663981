<template>
  <div v-frag>
    <b-dropdown
      :text="getText()"
      variant="white"
      block
      size="lg"
      class="border mb-4 d-lg-none"
      menu-class="w-100"
      style="width: 100%"
    >
      <b-dropdown-item
        v-if="permisison.admin_exchange_rate"
        to="/admin/setting/exchange-rate"
      >
        <i class="menu-icon la la-exchange icon-xl mr-4"></i>
        <span class="menu-text">อัตราแลกเปลี่ยน</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.admin_address"
        to="/admin/setting/address"
      >
        <i class="menu-icon la la-map-marked icon-xl mr-4"></i>
        <span class="menu-text">ตั้งค่าที่อยู่ผู้ส่ง</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.admin_delivery"
        to="/admin/setting/thai-shipping"
      >
        <i class="menu-icon la la-shipping-fast icon-xl mr-4"></i>
        <span class="menu-text">ขนส่งภายในประเทศ</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="permisison.admin_bank" to="/admin/setting/bank">
        <i class="menu-icon la la-bank icon-xl mr-4"></i>
        <span class="menu-text">บัญชีธนาคาร</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.admin_china_thai"
        to="/admin/setting/china-to-thai"
      >
        <i class="menu-icon la la-ship icon-xl mr-4"></i>
        <span class="menu-text">ขนส่งจากจีนมาไทย</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.setting_notification_bar"
        to="/admin/setting/news"
      >
        <i class="menu-icon la la-bullhorn icon-xl mr-4"></i>
        <span class="menu-text">ประกาศ</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.setting_notification_bar"
        to="/admin/setting/news_list"
      >
        <i class="menu-icon la la-newspaper icon-xl mr-4"></i>
        <span class="menu-text">ข่าวสาร</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.admin_address_china"
        to="/admin/setting/china"
      >
        <i class="menu-icon la la-warehouse icon-xl mr-4"></i>
        <span class="menu-text">ตั้งค่าที่อยู่จีน</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="permisison.admin_role" to="/admin/setting/role">
        <i class="menu-icon la la-shield-alt icon-xl mr-4"></i>
        <span class="menu-text">สิทธิ์การใช้งาน</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.admin_role"
        to="/admin/setting/notification"
      >
        <i class="menu-icon la la-bullhorn icon-xl mr-4"></i>
        <span class="menu-text">ตั้งค่าแจ้งเตือน </span>
      </b-dropdown-item>
      <b-dropdown-item v-if="permisison" to="/admin/setting/dropship">
        <i class="menu-icon la la-money-bill-alt icon-xl mr-4"></i>
        <span class="menu-text">ค่าบริการ Dropship</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.admin_minimum_payshipping"
        to="/admin/setting/minimum-payshipping"
      >
        <i class="menu-icon la la-money-check icon-xl mr-4"></i>
        <span class="menu-text">กำหนดค่าส่งขั้นต่ำจีน - ไทย</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="permisison.admin_tracking_warehouse"
        to="/admin/setting/tracking-warehouse"
      >
        <i class="menu-icon la la-money-check icon-xl mr-4"></i>
        <span class="menu-text">โกดังพัสดุ</span>
      </b-dropdown-item>
    </b-dropdown>
    <div class="d-flex">
      <div
        class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      >
        <!-- begin:: Aside Menu -->
        <div class="aside-menu-wrapper flex-column-fluid">
          <div
            class="aside-menu min-h-lg-800px"
            data-menu-vertical="1"
            data-menu-dropdown-timeout="500"
          >
            <!-- example static menu here -->
            <perfect-scrollbar
              class="aside-menu scroll"
              style="max-height: 90vh; position: relative"
            >
              <ul class="menu-nav">
                <router-link
                  to="/admin/setting/exchange-rate"
                  v-if="permisison.admin_exchange_rate"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-exchange icon-xl"></i>
                      <span class="menu-text">อัตราแลกเปลี่ยน</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/address"
                  v-if="permisison.admin_address"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-map-marked icon-xl"></i>
                      <span class="menu-text">ตั้งค่าที่อยู่ผู้ส่ง</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/thai-shipping"
                  v-if="permisison.admin_delivery"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-shipping-fast icon-xl"></i>
                      <span class="menu-text">ขนส่งภายในประเทศ</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/bank"
                  v-if="permisison.admin_bank"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-bank icon-xl"></i>
                      <span class="menu-text">บัญชีธนาคาร</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/china-to-thai"
                  v-if="permisison.admin_china_thai"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-ship icon-xl"></i>
                      <span class="menu-text">ขนส่งจากจีนมาไทย</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/news"
                  v-if="permisison.setting_notification_bar"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-bullhorn icon-xl"></i>
                      <span class="menu-text">ประกาศ</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/news_list"
                  v-if="permisison.setting_notification_bar"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-newspaper icon-xl"></i>
                      <span class="menu-text">ข่าวสาร</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/china"
                  v-if="permisison.admin_address_china"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-warehouse icon-xl"></i>
                      <span class="menu-text">ตั้งค่าที่อยู่จีน</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/role"
                  v-if="permisison.admin_role"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-shield-alt icon-xl"></i>
                      <span class="menu-text">สิทธิ์การใช้งาน</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/notification"
                  v-if="permisison.admin_notification"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-bell icon-xl"></i>
                      <span class="menu-text">ตั้งค่าแจ้งเตือน</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/dropship"
                  v-if="permisison.admin_notification"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-money-bill-alt icon-xl"></i>
                      <span class="menu-text">ค่าบริการ Dropship</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/minimum-payshipping"
                  v-if="permisison.admin_minimum_payshipping"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-money-check icon-xl"></i>
                      <span class="menu-text">กำหนดค่าส่งขั้นต่ำจีน - ไทย</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/admin/setting/tracking-warehouse"
                  v-if="permisison.admin_tracking_warehouse"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon la la-money-check icon-xl"></i>
                      <span class="menu-text">โกดังพัสดุ</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>

      <div class="content-wrapper flex-row-fluid ml-lg-4">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {},
  computed: {
    ...mapGetters(["currentPermission"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `ตั้งค่า` }]);
    const permisison = {
      admin_exchange_rate: this.checkPermission("admin_exchange_rate", 1),
      admin_address: this.checkPermission("admin_address", 1),
      admin_delivery: this.checkPermission("admin_delivery", 0),
      admin_bank: this.checkPermission("admin_bank", 0),
      admin_china_thai: this.checkPermission("admin_china_thai", 0),
      admin_notification: this.checkPermission("admin_notification", 1),
      setting_notification_bar: this.checkPermission(
        "setting_notification_bar",
        0
      ),
      admin_role: this.checkPermission("admin_role", 0),
      admin_address_china: this.checkPermission("admin_address_china", 1),
      admin_minimum_payshipping: this.checkPermission(
        "admin_minimum_payshipping",
        0
      ),
      admin_tracking_warehouse: this.checkPermission(
        "admin_tracking_warehouse",
        0
      )
    };
    this.permisison = permisison;
  },
  data() {
    return {
      permisison: {}
    };
  },
  methods: {
    getText() {
      const path = this.$route.path;
      switch (path) {
        case "/admin/setting/exchange-rate":
          return "อัตราแลกเปลี่ยน";
        case "/admin/setting/address":
          return "ตั้งค่าที่อยู่ผู้ส่ง";
        case "/admin/setting/thai-shipping":
          return "ขนส่งภายในประเทศ";
        case "/admin/setting/bank":
          return "บัญชีธนาคาร";
        case "/admin/setting/china-to-thai":
          return "ขนส่งจากจีนมาไทย";
        case "/admin/setting/role":
          return "สิทธิ์การใช้งาน";
        default:
          return "กรุณาเลือก";
      }
    },
    checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }
      const { permissionRole } = this.currentPermission;

      const find = permissionRole.find(
        x => x.permissionInfo && x.permissionInfo.permissionName === name
      );
      if (!find) {
        return false;
      }
      if (type === 0 && find.readableRole) {
        return true;
      }
      if (type === 1 && find.writeableRole) {
        return true;
      }
      return false;
    }
  }
};
</script>
