<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card-custom card-transparent" v-if="profile">
      <div class="card-body p-0">
        <div
          class="wizard wizard-4"
          id="kt_wizard_profile"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav">
            <div class="wizard-steps">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ข้อมูลลูกค้า</div>
                    <div class="wizard-desc">Infomation</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">ข้อมูลที่อยู่</div>
                    <div class="wizard-desc">Address</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">บัญชีธนาคาร</div>
                    <div class="wizard-desc">Bank Account</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card card-custom card-shadowless rounded-top-0 card-body">
            <div class="tab-content">
              <!--begin::Tab-->
              <div
                class="tab-pane show active px-7"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <b-form ref="profile" @submit.prevent="save" v-if="profile">
                  <!--begin::Row-->
                  <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-7 my-2">
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >ชื่อผู้ใช้งาน</label
                        >
                        <div class="col-9">
                          <div class="m-desc">{{ profile.username }}</div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >ชื่อ - นามสกุล</label
                        >
                        <div class="col-9">
                          <input
                            v-model="profile.full_name"
                            required
                            class="form-control"
                          />
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >เบอร์โทรศัพท์</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-phone"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="profile.phone"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >อีเมล์</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="la la-at"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="profile.email"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >LINE ID</label
                        >
                        <div class="col-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="fab la-line"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="profile.line"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end::Group-->
                      <!--begin::Group-->
                      <div class="form-group row">
                        <label
                          class="col-form-label col-3 text-lg-right text-left"
                          >LINE NOTIFY</label
                        >
                        <div class="col-9">
                          <a
                            class="btn btn-success"
                            v-if="!profile.line_token"
                            :href="urlLine"
                            target="_blank"
                          >
                            <i class="fab la-line"></i>
                            เชื่อมต่อ Line Notify
                          </a>
                          <button
                            class="btn btn-secondary"
                            v-if="profile.line_token"
                            type="button"
                            @click="cancelLine"
                          >
                            <i class="fab la-line"></i>
                            ตัดการเชื่อมต่อ
                          </button>
                        </div>
                      </div>
                      <!--end::Group-->
                      <div class="form-group row">
                        <label
                          class="col-lg-3 col-sm-4 col-4 col-form-label text-right"
                        >
                          สำเนาบัตรประชาชน
                        </label>
                        <div class="col-lg-5 col-md-6 col-sm-6 col-8">
                          <div class="uppy" id="kt_uppy_5">
                            <div class="uppy-wrapper">
                              <div class="uppy-Root uppy-FileInput-container">
                                <input
                                  class="uppy-FileInput-input uppy-input-control"
                                  ref="fileInput"
                                  type="file"
                                  accept="image/*"
                                  name="files[]"
                                  multiple=""
                                  @input="pickFile"
                                  id="file"
                                  style=""
                                />
                                <label
                                  class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                                  for="file"
                                >
                                  เลือก รูป
                                </label>
                              </div>
                            </div>
                            <div class="uppy-list">
                              <div
                                class="uppy-list-item"
                                v-if="profile.verify_file || previewImage"
                              >
                                <div
                                  v-viewer
                                  class="border symbol symbol-60 flex-shrink-0 mr-4"
                                >
                                  <img
                                    style="object-fit: contain"
                                    :src="
                                      previewImage ||
                                        imageUrlApi(profile.verify_file)
                                    "
                                    height="200"
                                    width="200"
                                  />
                                </div>
                                <div class="uppy-list-label">
                                  {{ file && file.name }}
                                </div>
                                <span
                                  class="uppy-list-remove"
                                  v-if="!profile.verify_file"
                                  @click="removeFile()"
                                >
                                  <i class="flaticon2-cancel-music"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <span class="form-text text-muted">
                            อัพโหลดไฟล์ไม่เกิน 10MB.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pb-0">
                    <div class="row">
                      <div class="col-xl-7 offset-xl-2">
                        <div class="row">
                          <div class="col-3"></div>
                          <div class="col-9">
                            <button
                              class="btn btn-light-primary font-weight-bold mr-4"
                            >
                              Save changes
                            </button>
                            <button
                              type="button"
                              @click="onComplete"
                              class="btn btn-clean font-weight-bold"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Row-->
                </b-form>
              </div>
              <!--end::Tab-->
              <!--begin::Tab-->
              <!--begin::Tab-->
              <div class="tab-pane px-7" data-wizard-type="step-content">
                <profile-address :data="profile" :refetch="onComplete" />
              </div>
              <!--end::Tab-->
              <!--end::Tab-->
              <!--begin::Tab-->
              <div class="tab-pane px-7" data-wizard-type="step-content">
                <profile-bank />
              </div>
              <!--end::Tab-->
            </div>
          </div>
          <!--begin::Card body-->
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>
<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import KTWizard from "@/assets/js/components/wizard";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import { API_URL, IMAGE_URL, imageUrlApi } from "@/config";
import { GET_PROFILE, UPDATE_PROFILE, CANCEL_LINE } from "@/graphql/profile";
import ProfileAddress from "./components/ProfileAddress.vue";
import ProfileBank from "./components/ProfileBank.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "ProfileDetail",
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "โปรไฟล์" }]);

    const { cat = 1 } = this.$route.query;

    new KTWizard("kt_wizard_profile", {
      startStep: cat, // initial active step number
      clickableSteps: true // allow step clicking
    });

    this.getLineUrl();
  },
  components: { ProfileAddress, ProfileBank },
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      previewImage: null,
      file: null,
      urlLine: "",
      profile: {
        full_name: "",
        email: "",
        phone: "",
        line: "",
        verify_filename: ""
      }
    };
  },
  validations: {
    profile: {
      full_name: {
        required
      },
      email: {
        required
      },
      phone: {
        required
      },
      line: {},
      verify_filename: {}
    }
  },
  methods: {
    async cancelLine() {
      const {
        data: { cancelLine }
      } = await this.$apollo.mutate({
        mutation: CANCEL_LINE
      });
      if (!cancelLine.isSuccess) {
        this.$message.error(cancelLine.message);
      } else {
        this.$message.success(cancelLine.message);
        this.profile.line_token = "";
      }
    },
    async getLineUrl() {
      const { data } = await ApiService.get(
        "line",
        `url?user=${this.currentUser.userId}`
      );
      const { url } = data;
      this.urlLine = url;
    },
    imageUrlApi,
    onComplete() {
      this.$apollo.queries.profile.refetch();
    },
    async save() {
      const confirm = await Swal.fire({
        title: "ยืนยันการบันทึกข้อมูล ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const input = {
        full_name: this.$v.profile.full_name.$model,
        phone: this.$v.profile.phone.$model,
        email: this.$v.profile.email.$model,
        line: this.$v.profile.line.$model,
        verify_filename: this.$v.profile.verify_filename.$model
      };
      const {
        data: { editProfileUser }
      } = await this.$apollo.mutate({
        mutation: UPDATE_PROFILE,
        variables: { ...input }
      });
      if (!editProfileUser.isSuccess) {
        this.$message.error(editProfileUser.message);
      } else {
        this.$message.success(editProfileUser.message);
        this.$apollo.queries.profile.refetch();
      }
    },
    upload(file, onUploadProgress) {
      let formData = new FormData();

      formData.append("file", file);

      return axios.post(`${API_URL}/upload/temp2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        this.upload(file[0], event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(res => {
          const { data } = res;
          if (data) {
            this.previewImage = `${IMAGE_URL}${data.data}`;
            this.profile.verify_filename = data.data;
          }
        });
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.profile.verify_filename = null;
      this.file = null;
      this.previewImage = null;
    }
  },
  apollo: {
    profile: {
      query: GET_PROFILE
    }
  }
};
</script>
