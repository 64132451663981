<template>
  <div class="card">
    <!--begin::Body-->
    <div class="card-body p-lg-12">
      <!--begin::Layout-->
      <b-overlay :show="$apollo.loading" rounded="sm">
        <div class="d-flex flex-column flex-xl-row" v-if="systemPayshipping">
          <!--begin::Content-->
          <div class="col-lg-8 col-md-12 col-12 pl-0">
            <!--begin::Invoice 2 content-->
            <div class="mt-n1">
              <div class="pb-10">
                <button
                  class="btn btn-sm btn-secondary fs-5 mr-2 mt-2"
                  style="min-width: 144px"
                  v-if="systemPayshipping.status < 100"
                  @click="cancel"
                >
                  เปลี่ยนสถานะ : ยกเลิก
                </button>
                <button
                  class="btn btn-sm btn-success fs-5 mr-2 mt-2"
                  style="min-width: 144px"
                  v-if="
                    systemPayshipping.status > 20 &&
                      systemPayshipping.status < 30
                  "
                  @click="alreadySend"
                >
                  เปลี่ยนสถานะ : ส่งแล้ว
                </button>
                <button
                  class="btn btn-sm btn-primary fs-5 mr-2 mt-2"
                  style="min-width: 144px"
                  v-if="systemPayshipping.status === 12"
                  @click="waitingToSend"
                >
                  เปลี่ยนสถานะ : เตรียมส่ง
                </button>
                <button
                  class="btn btn-sm btn-primary fs-5 mr-2 mt-2"
                  style="min-width: 144px"
                  v-if="systemPayshipping.status === 12"
                  @click="waitingReceive"
                >
                  เปลี่ยนสถานะ : มารับที่โกดัง
                </button>
                <button
                  v-b-modal.transaction-modal
                  @click="refund()"
                  class="btn btn-danger font-weight-bold mr-2 mt-2"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/Shopping/Money.svg" />
                  </span>
                  คืนเงิน
                </button>
                <button
                  v-b-modal.transaction-modal
                  @click="addition()"
                  class="btn btn-primary font-weight-bold mt-2"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/Shopping/Dollar.svg" />
                  </span>
                  เก็บเงิน
                </button>
              </div>
              <!--begin::Wrapper-->
              <div class="m-0">
                <!--begin::Label-->
                <div class="row">
                  <div class="col-6">
                    <div class="fw-bolder fs-3 text-gray-800 mb-0">
                      เลขที่ใบส่งพัสดุ {{ systemPayshipping.payshipping_doc }}
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <button
                      @click="
                        openPrintInvoice(systemPayshipping.payshipping_id)
                      "
                      class="btn btn-secondary font-weight-bold mr-2"
                    >
                      <span class="svg-icon svg-icon-md">
                        <inline-svg
                          src="/media/svg/icons/Devices/Printer.svg"
                        />
                      </span>
                      พิมพ์ใบแจ้งหนี้
                    </button>
                    <b-dropdown text="พิมพ์ใบจัดส่ง">
                      <b-dropdown-item
                        @click="openPrint(1, systemPayshipping.payshipping_id)"
                      >
                        กระกาษ A4 ต่อ 1 รายการ
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="openPrint(3, systemPayshipping.payshipping_id)"
                      >
                        กระกาษ A4 ต่อ 2 รายการ (แนวนอน)
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <!--end::Label-->
                <!--begin::Row-->
                <div class="row g-5 mb-6">
                  <!--end::Col-->
                  <div class="col-6">
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      วันที่สร้าง:
                    </div>
                    <!--end::Label-->
                    <!--end::Col-->
                    <div class="fw-bolder fs-6 text-gray-800">
                      {{ format_datetime(systemPayshipping.created_at) }}
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Col-->
                  <!--end::Col-->
                  <div class="col-6 text-right">
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      วันที่ส่ง:
                    </div>
                    <!--end::Label-->
                    <!--end::Info-->
                    <div class="fw-bolder fs-6 text-gray-800">
                      <span>{{
                        format_datetime(systemPayshipping.customer_received)
                      }}</span>
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="row g-5 mb-8">
                  <!--end::Col-->
                  <div class="col-sm-6">
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      รายละเอียด:
                    </div>
                    <!--end::Label-->
                    <!--end::Text-->
                    <div class="fw-bolder fs-5 text-gray-800">
                      รหัสลูกค้า:
                      <router-link
                        :to="`/admin/users/${systemPayshipping.user.userId}`"
                        >{{ systemPayshipping.user.username }}</router-link
                      >
                    </div>
                    <!--end::Text-->
                    <!--end::Description-->
                    <div class="fs-5 text-gray-800">
                      ชื่อ-สกุล: {{ systemPayshipping.user.full_name }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      อีเมล: {{ systemPayshipping.user.email }}
                    </div>
                    <div class="fs-5 text-danger">
                      หมายเหตุ: {{ systemPayshipping.remark }}
                    </div>
                    <!--end::Description-->
                  </div>
                  <!--end::Col-->
                  <!--end::Col-->
                  <div
                    class="col-sm-6 text-right"
                    v-if="systemPayshipping.address"
                  >
                    <!--end::Label-->
                    <div class="fw-bold fs-5 text-gray-600 mb-1">
                      ที่อยู่จัดส่ง:
                    </div>
                    <!--end::Label-->
                    <!--end::Text-->
                    <div class="fw-bolder fs-6 text-gray-800">
                      ผู้รับ {{ systemPayshipping.address.name }}
                    </div>
                    <!--end::Text-->
                    <!--end::Description-->
                    <div class="fs-5 text-gray-800">
                      โทร. {{ systemPayshipping.address.phone }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      {{ systemPayshipping.address.address }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      ตำบล {{ systemPayshipping.address.district }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      อำเภอ {{ systemPayshipping.address.amphoe }}
                    </div>
                    <div class="fs-5 text-gray-800">
                      {{ systemPayshipping.address.province }}
                      {{ systemPayshipping.address.postcode }}
                    </div>
                    <!--end::Description-->
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Content-->
                <div class="flex-grow-1">
                  <!--begin::Table-->
                  <div class="table-responsive border-bottom mb-9">
                    <table class="table mb-3">
                      <thead>
                        <tr class="border-bottom fs-6 fw-bolder text-gray-400">
                          <th class="pb-2">เลขพัสดุ (จีน)</th>
                          <th class="text-center pb-2">จำนวน</th>
                          <th class="text-center pb-2 text-nowrap">ขนส่งโดย</th>
                          <th class="text-center pb-2">ขนาด(cm)</th>
                          <th class="text-center pb-2 text-nowrap">
                            น้ำหนัก(kg)
                          </th>
                          <th class="text-center pb-2 text-nowrap">
                            ปริมาตร(cbm)
                          </th>
                          <th class="text-center pb-2 text-nowrap">
                            เรทค่าส่ง
                          </th>
                          <th class="text-center pb-2 text-nowrap">คิดตาม</th>
                          <th class="text-center pb-2 text-nowrap">
                            ค่าส่งจีน
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="fw-bolder text-gray-700 fs-6"
                          v-for="item in systemPayshipping.transports"
                          :key="item._id"
                        >
                          <td class="pt-6 px-1">
                            <router-link
                              :to="`/admin/forwarder/${item.transportId}`"
                            >
                              {{ item.transportNumber }}
                            </router-link>
                          </td>
                          <td class="pt-6 px-1 text-center">
                            {{ item.amount }}
                          </td>
                          <td class="pt-6 px-1 text-center">
                            {{ item.ship_by === "ek" ? "รถ" : "เรือ" }}
                          </td>
                          <td class="pt-6 px-1 text-center text-nowrap">
                            {{
                              `${item.size.width || 0} x ${item.size.long ||
                                0} x ${item.size.height || 0}`
                            }}
                          </td>
                          <td class="pt-6 px-1 text-center">
                            {{ format_comma(item.size.weight * item.amount) }}
                          </td>
                          <td class="pt-6 px-1 text-center">
                            {{ calculateCube(item.size, item.amount) }}
                          </td>
                          <td class="pt-6 px-1 text-center">
                            {{
                              item.trackingPrice.priceName === "kg"
                                ? item.userLevelPrice.userLevelDetailPriceKg
                                : item.userLevelPrice.userLevelDetailPriceCube
                            }}
                          </td>
                          <td class="pt-6 px-1 text-center text-nowrap">
                            {{
                              item.trackingPrice.priceName === "kg"
                                ? "น้ำหนัก"
                                : "ปริมาตร"
                            }}
                          </td>
                          <td
                            class="pt-6 pl-1 text-right text-dark fw-boldest pr-0"
                          >
                            {{ format_comma(item.trackingPrice.priceValue) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--end::Table-->
                  <!--begin::Container-->
                  <div class="d-flex justify-content-end">
                    <!--begin::Section-->
                    <div class="mw-300px">
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountname-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          ค่าขนส่งไทย
                        </div>
                        <!--end::Accountname-->
                        <!--begin::Label-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(systemPayshipping.logistics.price) }}
                          บาท
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountname-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          ค่าบริการแพ็คขนส่ง
                        </div>
                        <!--end::Accountname-->
                        <!--begin::Label-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{
                            format_comma(
                              systemPayshipping.logistics.logisticsServicePrice
                            )
                          }}
                          บาท
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountnumber-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          รวมค่าขนส่งจีน
                        </div>
                        <!--end::Accountnumber-->
                        <!--begin::Number-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(importPrice) }} บาท
                        </div>
                        <!--end::Number-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack mb-3">
                        <!--begin::Accountnumber-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          รวมค่าตีลังไม้
                        </div>
                        <!--end::Accountnumber-->
                        <!--begin::Number-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(price_wooden_box) }} บาท
                        </div>
                        <!--end::Number-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div
                        v-if="systemPayshipping.minimumPrice"
                        class="d-flex flex-stack mb-3"
                      >
                        <!--begin::Accountnumber-->
                        <div class="fw-bold pr-10 text-gray-600 fs-7">
                          ขั้นต่ำ {{ systemPayshipping.minimum }} บาท/บิล :
                        </div>
                        <!--end::Accountnumber-->
                        <!--begin::Number-->
                        <div class="text-right fw-bolder fs-6 text-gray-800">
                          {{ format_comma(systemPayshipping.minimumPrice) }} บาท
                        </div>
                        <!--end::Number-->
                      </div>
                      <!--end::Item-->
                      <!--begin::Item-->
                      <div class="d-flex flex-stack">
                        <!--begin::Code-->
                        <div class="fw-bolder pr-10 text-gray-800 fs-5">
                          รวมเป็นเงิน
                        </div>
                        <!--end::Code-->
                        <!--begin::Label-->
                        <div class="text-right fw-bolder fs-5 text-gray-800">
                          {{ format_comma(systemPayshipping.amount) }} บาท
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Item-->
                    </div>
                    <!--end::Section-->
                  </div>
                  <!--end::Container-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::Invoice 2 content-->
          </div>
          <!--end::Content-->
          <!--begin::Sidebar-->
          <div class="col-lg-4 col-md-12 col-12 pr-0">
            <!--begin::Invoice 2 sidebar-->
            <div
              class="border-width-1 border-dashed border-gray-300 mt-md-8 card-rounded min-w-md-350px p-6 bg-lighten"
            >
              <!--begin::Labels-->
              <div class="mb-8">
                <span class="mr-2 fw-boldest text-gray-800"> สถานะ </span>
                <span
                  class="label label-lg label-inline"
                  v-bind:class="
                    `label-light-${getStatusColor(systemPayshipping.status)}`
                  "
                >
                  {{ systemPayshipping.statusShow }}
                </span>
              </div>
              <!--end::Labels-->
              <!--begin::Title-->
              <h6 class="mb-4 fw-boldest text-gray-600 text-hover-primary">
                ข้อมูลขนส่งไทย
              </h6>
              <!--end::Title-->
              <!--begin::Item-->
              <div class="mb-6">
                <div class="fw-bold text-gray-600 fs-6">บริษัทขนส่ง:</div>
                <div class="fw-bolder text-gray-800 fs-5">
                  {{ systemPayshipping.logistics.logisticsName }}
                </div>
                <div v-if="systemPayshipping.file_provider">
                  <div class="fw-bold text-gray-600 fs-6 mt-6">
                    QR Code Shopee, Lazada:
                  </div>
                  <div class="border symbol symbol-60 flex-shrink-0 mt-2">
                    <img
                      style="object-fit: cover"
                      v-viewer
                      :src="imageUrlApi(systemPayshipping.file_provider)"
                      :key="systemPayshipping.file_provider"
                      :alt="systemPayshipping.file_provider"
                      height="250"
                      width="250"
                    />
                  </div>
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="mb-2">
                <div class="fw-bold text-gray-600 fs-6">เลขพัสดุไทย:</div>
                <div class="fw-bolder text-gray-800 fs-5 mt-2 d-flex">
                  <input class="form-control" v-model="transportNumberThai" />
                  <button
                    type="button"
                    @click="saveTrackTh"
                    class="btn btn-success ml-2"
                  >
                    save
                  </button>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="fw-bold text-gray-600 fs-6">ไฟล์แนบ:</div>
                <div class="col-12 mt-1">
                  <div class="uppy">
                    <div class="uppy-wrapper">
                      <div
                        class="uppy-Root uppy-FileInput-container d-flex align-items-center"
                        style="gap: 6px;"
                      >
                        <input
                          class="uppy-FileInput-input uppy-input-control d-none"
                          ref="fileInput"
                          type="file"
                          name="files[]"
                          accept="image/*"
                          multiple=""
                          @input="pickFile"
                          id="file"
                          style=""
                        />
                        <label
                          class="uppy-input-label btn btn-light-primary btn-sm btn-bold mb-0"
                          for="file"
                        >
                          เลือกไฟล์
                        </label>
                        <span class="form-text text-muted mt-0">
                          Max file size is 20MB.
                        </span>
                      </div>
                    </div>
                    <div class="uppy-list">
                      <div
                        class="uppy-list-item"
                        v-if="previewImage || systemPayshipping.file_attachment"
                      >
                        <div
                          v-viewer
                          class="border symbol symbol-60 flex-shrink-0 mr-4"
                        >
                          <img
                            style="object-fit: contain"
                            :src="
                              previewImage ||
                                imageUrlApi(systemPayshipping.file_attachment)
                            "
                            height="200"
                            width="200"
                          />
                        </div>
                        <div
                          class="uppy-list-label"
                          v-if="file && !systemPayshipping.file_attachment"
                        >
                          {{ file.name }}
                        </div>
                        <div
                          class="uppy-list-label"
                          v-if="systemPayshipping.file_attachment"
                        >
                          {{ getFileName(systemPayshipping.file_attachment) }}
                        </div>
                        <span class="uppy-list-remove" @click="removeFile()">
                          <i class="flaticon2-cancel-music"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Title-->
              <h6 class="mb-4 mt-8 fw-boldest text-gray-600 text-hover-primary">
                ข้อมูลการจัดส่งจีน
              </h6>
              <!--end::Title-->
              <!--begin::Item-->
              <div class="mb-6">
                <div class="fw-bold text-gray-600 fs-6">รวมน้ำหนัก:</div>
                <div class="fw-bolder fs-5 text-gray-800">
                  {{ format_comma(all_kg) }} Kg
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="mb-6">
                <div class="fw-bold text-gray-600 fs-6">รวมปริมาตร:</div>
                <div class="fw-bolder text-gray-800 fs-5">
                  {{ format_comma(all_cube, 4) }} cbm
                </div>
              </div>
              <!--end::Item-->
            </div>
            <div
              class="border-width-1 border-dashed border-gray-300 mt-md-8 card-rounded min-w-md-350px p-6 bg-lighten"
              v-if="systemPayshipping.transactions.length > 0"
            >
              <div class="table-responsive mb-0">
                <table class="table">
                  <thead>
                    <tr>
                      <th colspan="3" class="fs-4 py-2 px-0">ธุรกรรมการเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in systemPayshipping.transactions"
                      :key="item._id"
                    >
                      <td class="px-1 text-nowrap">
                        {{ format_date(item.created_at) }}
                      </td>
                      <td class="px-1 text-nowrap">{{ item.reason }}</td>
                      <td class="text-right px-1 text-nowrap">
                        <span
                          class="font-weight-bolder d-block font-size-lg"
                          v-bind:class="{
                            'text-danger': item.amount < 0,
                            'text-success': item.amount >= 0
                          }"
                        >
                          {{ format_number(item.amount, 2, true) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--end::Invoice 2 sidebar-->
          </div>
          <!--end::Sidebar-->
        </div>
      </b-overlay>
      <!--end::Layout-->
    </div>
    <!--end::Body-->
    <modal-transaction
      v-if="systemPayshipping"
      :_id="systemPayshipping._id"
      :refetch="onCompleteTransport"
      :is_refund="is_refund"
      :title="is_refund ? 'คืนเงิน' : 'เก็บเงิน'"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import {
  GET_PAYMENT,
  TO_RECEIVE_PAYMENT,
  APPROVE_PAYMENT,
  SHIPPING_PAYMENT,
  CANCEL_PAYMENT,
  SAVE_TRACH_TH
} from "@/graphql/admin/payshipping";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { calculateCube, WEBSITE, imageUrlApi } from "@/config";
import bigDecimal from "js-big-decimal";
import ModalTransaction from "./components/ModalTransaction.vue";
import {
  PRINT_BILL,
  PRINT_BILL_INVOICE
} from "@/core/services/store/payshipping.module";

export default {
  components: { ModalTransaction },
  name: "shippay_bill_detail",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "รายการใบส่งพัสดุ" }]);
  },
  created() {
    document.title = `ใบส่งพัสดุ | ${WEBSITE}`;
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      is_refund: false,
      transportNumberThai: "",
      importPrice: 0,
      all_kg: 0,
      all_cube: 0,
      previewImage: null,
      file: null
    };
  },
  methods: {
    calculateCube,
    imageUrlApi,
    onCompleteTransport() {
      this.$apollo.queries.systemPayshipping.refetch();
    },
    refund() {
      this.is_refund = true;
    },
    addition() {
      this.is_refund = false;
    },
    async waitingToSend() {
      const confirm = await Swal.fire({
        title: "ยืนยัน เตรียมส่ง ?",
        icon: "question",
        html: `ยืนยันการเปลี่ยนสถานะ ${this.systemPayshipping.payshipping_doc}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: {
          payshippingToWaitingSend: { isSuccess, message }
        }
      } = await this.$apollo.mutate({
        mutation: APPROVE_PAYMENT,
        variables: {
          id: this.systemPayshipping.payshipping_id
        }
      });
      if (isSuccess) {
        this.$apollo.queries.systemPayshipping.refetch();
        this.$message.success(message);
      } else {
        this.$message.error(message);
      }
    },
    async waitingReceive() {
      const confirm = await Swal.fire({
        title: "ยืนยัน มารับที่โกดัง ?",
        icon: "question",
        html: `ยืนยันการเปลี่ยนสถานะ ${this.systemPayshipping.payshipping_doc}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: {
          payshippingToWaitingReceive: { isSuccess, message }
        }
      } = await this.$apollo.mutate({
        mutation: TO_RECEIVE_PAYMENT,
        variables: {
          id: this.systemPayshipping.payshipping_id
        }
      });
      if (isSuccess) {
        this.$apollo.queries.systemPayshipping.refetch();
        this.$message.success(message);
      } else {
        this.$message.error(message);
      }
    },
    async cancel() {
      const confirm = await Swal.fire({
        title: "ยืนยัน ยกเลิก ?",
        icon: "question",
        html: `ยืนยันการเปลี่ยนสถานะ ${this.systemPayshipping.payshipping_doc}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: {
          systemCancelPayshipping: { isSuccess, message }
        }
      } = await this.$apollo.mutate({
        mutation: CANCEL_PAYMENT,
        variables: {
          id: this.systemPayshipping.payshipping_id
        }
      });
      if (isSuccess) {
        this.$apollo.queries.systemPayshipping.refetch();
        this.$message.success(message);
      } else {
        this.$message.error(message);
      }
    },
    async alreadySend() {
      const confirm = await Swal.fire({
        title: "ยืนยัน ส่งแล้ว ?",
        icon: "question",
        html: `ยืนยันการเปลี่ยนสถานะ ${this.systemPayshipping.payshipping_doc}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: {
          shippingPayment: { isSuccess, message }
        }
      } = await this.$apollo.mutate({
        mutation: SHIPPING_PAYMENT,
        variables: {
          id: this.systemPayshipping.payshipping_id
        }
      });
      if (isSuccess) {
        this.$apollo.queries.systemPayshipping.refetch();
        this.$message.success(message);
      } else {
        this.$message.error(message);
      }
    },
    async saveTrackTh() {
      const {
        data: {
          systemTrackTh: { isSuccess, message }
        }
      } = await this.$apollo.mutate({
        mutation: SAVE_TRACH_TH,
        variables: {
          _id: this.systemPayshipping._id,
          transportNumberThai: this.transportNumberThai,
          file: this.file,
          file_attachment: this.systemPayshipping.file_attachment
        }
      });
      if (isSuccess) {
        this.file = null;
        this.$apollo.queries.systemPayshipping.refetch();
        this.$message.success(message);
      } else {
        this.$message.error(message);
      }
    },
    getFileName(file) {
      return file.split("/").pop();
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    },
    format_comma(val, decimal = 2) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    },
    format_number_4(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
      });
    },
    priceLogistics() {
      this.price_wooden_box = this.systemPayshipping.transports.reduce(
        (total, x) => total + x.price_wooden_box ?? 0,
        0
      );
      this.importPrice = this.systemPayshipping.transports.reduce(
        (total, x) => total + (x.trackingPrice?.priceValue ?? 0),
        0
      );
      const kg = this.systemPayshipping.transports.reduce(
        (total, x) => total + (x.size?.weight ?? 0) * x.amount,
        0
      );
      const cube = this.systemPayshipping.transports.reduce(
        (total, x) => total + Number(calculateCube(x.size, x.amount)),
        0
      );
      this.all_kg = kg;
      this.all_cube = cube;
    },
    getStatusColor(status) {
      switch (status) {
        case -10:
        case -1:
          return "default";
        case 0:
          return "warning";
        case 22:
          return "primary";
        case 21:
        case 11:
        case 12:
          return "warning";
        case 101:
        case 102:
          return "success";
        default:
          return "primary";
      }
    },
    openPrint(label, id) {
      this.$store
        .dispatch(PRINT_BILL, { label, id })
        .then(data => {
          const myWindow = window.open("");
          myWindow.document.write(data);
          myWindow.document.close();
        })
        .catch(() => {
          this.$message.error("ไม่สามารถปริ้นได้ กรุณาลองใหม่อีกครั้ง");
        });
    },
    openPrintInvoice(id) {
      this.$store
        .dispatch(PRINT_BILL_INVOICE, { id })
        .then(data => {
          const myWindow = window.open("");
          myWindow.document.write(data);
          myWindow.document.close();
        })
        .catch(() => {
          this.$message.error("ไม่สามารถปริ้นได้ กรุณาลองใหม่อีกครั้ง");
        });
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
      this.systemPayshipping.file_attachment = null;
    }
  },
  apollo: {
    systemPayshipping: {
      query: GET_PAYMENT,
      variables() {
        return {
          id: this.id
        };
      },
      update: ({ systemPayshipping }) => systemPayshipping,
      result({ data, loading }) {
        if (!loading && data) {
          const { systemPayshipping } = data;
          if (!systemPayshipping) {
            return;
          }
          const { payshipping_doc, transportNumberThai } = systemPayshipping;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: `รายการใบส่งพัสดุ`, route: "/admin/shippay/bill" },
            { title: `ใบส่งพัสดุ ${payshipping_doc}` }
          ]);
          this.priceLogistics();
          this.transportNumberThai = transportNumberThai[0] || "";
          document.title = `ใบส่งพัสดุ ${payshipping_doc} | ${WEBSITE}`;
        }
      }
    }
  }
};
</script>
