import gql from "graphql-tag";

export const GET_TRACKING_WAREHOUSE_LIST = gql`
  query($page: Int!, $pageSize: Int!, $search: String) {
    adminTrackingWarehouses(page: $page, pageSize: $pageSize, search: $search) {
      recordsData {
        recordsFiltered
      }
      isSuccess
      message
      records {
        _id
        name
        warehouseKg
        warehouseCube
      }
    }
  }
`;

export const GET_TRACKING_WAREHOUSE = gql`
  query($id: ID) {
    adminTrackingWarehouse(id: $id) {
      isSuccess
      message
      data {
        _id
        name
        warehouseKg
        warehouseCube
        address
        addressPhone
      }
    }
  }
`;

export const CREATE_TRACKING_WAREHOUSE = gql`
  mutation($input: TrackingWarehouseInput) {
    createTrackingWarehouse(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_TRACKING_WAREHOUSE = gql`
  mutation($input: TrackingWarehouseInput) {
    updateTrackingWarehouse(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_TRACKING_WAREHOUSE = gql`
  mutation($input: TrackingWarehouseInput) {
    deleteTrackingWarehouse(input: $input) {
      isSuccess
      message
      is_error
    }
  }
`;
