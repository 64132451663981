<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" class="form" @submit.stop.prevent="save">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">ตั้งค่าแจ้งเตือน</h3>
        </div>
        <div class="card-body">
          <div class="row mt-4">
            <div class="col-lg-10">
              <b-form ref="form" class="form" @submit.stop.prevent="save">
                <!--begin::Scroll-->
                <div class="d-flex flex-column scroll-y mr-n7 pr-7">
                  <!--begin::Input group-->
                  <b-alert v-if="messageError" variant="danger" show>{{
                    messageError
                  }}</b-alert>
                  <div class="form-group row">
                    <label
                      for="input-transportNumber"
                      class="col-form-label text-right col-md-3 col-4"
                      >Line Client</label
                    >
                    <div class="col-lg-5 col-md-9 col-6">
                      <b-form-input
                        placeholder=""
                        v-model="notificationList.line_client"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="input-transportNumber"
                      class="col-form-label text-right col-md-3 col-4"
                      >Line Secret</label
                    >
                    <div class="col-lg-5 col-md-9 col-6">
                      <b-form-textarea
                        placeholder=""
                        v-model="notificationList.line_secret"
                      ></b-form-textarea>
                    </div>
                  </div>
                  <!--end::Input group-->
                  <!--begin::Permissions-->
                  <div class="fv-row" v-if="notificationList">
                    <div class="card gutter-b">
                      <div class="card-header card-header-tabs-line">
                        <ul
                          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                          role="tablist"
                          ref="builder-tab"
                        >
                          <li
                            class="nav-item"
                            v-for="(tab, i) in tabs"
                            :key="tab"
                          >
                            <a
                              class="nav-link"
                              :class="[i === 0 && 'active']"
                              v-on:click="setActiveTab"
                              :data-tab="i"
                              data-toggle="tab"
                              href="#"
                              role="tab"
                            >
                              {{ tab }}
                            </a>
                          </li>
                        </ul>
                        <b-tabs class="hide-tabs" v-model="tabIndex">
                          <b-tab v-for="type in list" :key="type.key">
                            <!--begin::Table wrapper-->
                            <div class="table-responsive">
                              <!--begin::Table-->
                              <table
                                class="table align-middle table-row-dashed fs-6 gy-5"
                              >
                                <!--begin::Table body-->
                                <tbody class="text-gray-600 fw-bold">
                                  <!--begin::Table row-->
                                  <tr v-for="item in type.list" :key="item._id">
                                    <td class="text-gray-800 pt-4">
                                      {{ item.desc }}
                                    </td>
                                    <td style="width: 120px">
                                      <!--begin::Wrapper-->
                                      <div class="d-flex">
                                        <!--begin::Checkbox-->
                                        <span class="switch switch-sm mt-1">
                                          <label
                                            class="d-flex align-items-center"
                                          >
                                            <input
                                              type="checkbox"
                                              v-model="item.isEnabled"
                                            />
                                            <span></span>
                                          </label>
                                        </span>
                                        <!--end::Checkbox-->
                                      </div>
                                      <!--end::Wrapper-->
                                    </td>
                                  </tr>
                                  <!--end::Table row-->
                                </tbody>
                                <!--end::Table body-->
                              </table>
                              <!--end::Table-->
                            </div>
                            <!--end::Table wrapper-->
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </div>
                  <!--end::Permissions-->
                </div>
                <!--end::Scroll-->
                <div></div>
              </b-form>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-2"
                style="min-width: 130px"
              >
                <span class="svg-icon svg-icon-md" v-if="!loading">
                  <inline-svg src="/media/svg/icons/General/Save.svg" />
                </span>
                <span class="indicator-label" v-if="!loading">บันทึก</span>
                <span class="indicator-progress" v-if="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ml-2"
                  >
                  </span>
                </span>
              </button>
              <button
                type="button"
                @click="$apollo.queries.notificationList.refetch()"
                class="btn btn-secondary"
                style="min-width: 130px"
              >
                รีเซ็ต
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import { validationMixin } from "vuelidate";
import { WEBSITE } from "@/config";
import {
  NOTIFICATION_LIST,
  UPDATE_NOTIFICATION_LIST
} from "@/graphql/admin/notification_list";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mixins: [validationMixin],
  created() {
    document.title = `ตั้งค่าแจ้งเตือน | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ตั้งค่าแจ้งเตือน` }
    ]);
  },
  data() {
    return {
      messageError: "",
      loading: false,
      tabs: [],
      list: [],
      tabIndex: 0,
      notificationList: {
        line_client: "",
        line_secret: "",
        records: []
      }
    };
  },
  validations: {
    notificationList: {
      line_client: {},
      line_secret: {},
      records: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.notificationList[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.notificationList.$touch();
      if (this.$v.notificationList.$anyError) {
        return;
      }
      this.loading = true;
      const records = this.$v.notificationList.records.$model;
      const line_client = this.$v.notificationList.line_client.$model;
      const line_secret = this.$v.notificationList.line_secret.$model;
      const input = {
        line_client: line_client,
        line_secret: line_secret,
        notification_list: records
      };
      const {
        data: { updateNotificationList }
      } = await this.$apollo.mutate({
        mutation: UPDATE_NOTIFICATION_LIST,
        variables: {
          input: input
        }
      });
      this.loading = false;
      const { message, isSuccess } = updateNotificationList;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.notificationList.refetch();
      }
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    }
  },
  apollo: {
    notificationList: {
      query: NOTIFICATION_LIST,
      result({ loading, data }) {
        if (!loading && data) {
          const { notificationList } = data;
          const tabs = Array.from(
            new Set(notificationList.records.map(x => x.type))
          );
          const list = [];
          tabs.map(tab => {
            list.push({
              key: tab,
              list: notificationList.records.filter(x => x.type === tab)
            });
          });
          this.list = list;
          this.tabs = tabs;
        }
      }
    }
  }
};
</script>
