<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <router-link to="/">
      <img alt="Logo" :src="headerLogo" class="logo-default max-w-40px" />
    </router-link>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin: My Cart -->
      <router-link
        to="/cart/seller"
        class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg noti"
        v-if="IS_SELLER"
      >
        <span class="svg-icon svg-icon-xl">
          <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
          <span
            v-if="getCurrentNoti && getCurrentNoti.seller_cart"
            class="badge badge-pill badge-danger"
          >
            {{ getCurrentNoti.seller_cart }}
          </span>
        </span>
      </router-link>
      <router-link
        to="/cart"
        class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg noti"
      >
        <span class="svg-icon svg-icon-xl">
          <inline-svg src="/media/svg/icons/Shopping/Cart3.svg" />
          <span
            v-if="getCurrentNoti && getCurrentNoti.cart"
            class="badge badge-pill badge-danger"
          >
            {{ getCurrentNoti.cart }}
          </span>
        </span>
      </router-link>
      <!--end: My Cart -->
      <router-link
        to="/messages"
        class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg noti"
      >
        <span class="svg-icon svg-icon-xl">
          <inline-svg src="/media/svg/icons/Communication/Group-chat.svg" />
          <span
            v-if="getCurrentNoti && getCurrentNoti.msg"
            class="badge badge-pill badge-danger"
          >
            {{ getCurrentNoti.msg }}
          </span>
        </span>
      </router-link>
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="topbar-item text-decoration-none"
        no-caret
        right
        no-flip
        ref="dropdown"
        @mouseover="onOver"
        @mouseleave="onLeave"
      >
        <template v-slot:button-content>
          <button
            class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          >
            <div class="d-flex flex-column text-right">
              <span
                class="text-white opacity-50 font-weight-bold font-size-sm d-none d-md-inline"
                >{{ currentUser.full_name }}</span
              >
              <span
                class="text-white font-weight-bolder font-size-sm d-none d-md-inline"
                >{{ currentUser.username }}</span
              >
            </div>
            <span class="symbol symbol-35">
              <span
                class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"
                >{{
                  currentUser.full_name ? currentUser.full_name[0] : "-"
                }}</span
              >
            </span>
          </button>
        </template>
        <b-dropdown-item to="/profile">
          <span class="la la-user icon-md mr-1" />โปรไฟล์
        </b-dropdown-item>
        <b-dropdown-item to="/password-management">
          <span class="la la-lock icon-md mr-1" />เปลี่ยนรหัสผ่าน
        </b-dropdown-item>
        <b-dropdown-item @click="onLogout">
          <span class="text-danger">
            <span class="la la-sign-out-alt icon-md mr-1" />ออกจากระบบ
          </span>
        </b-dropdown-item>
      </b-dropdown>
      <!--begin::Header Menu Mobile Toggle-->
      <button
        class="btn p-0 burger-icon burger-icon-left ml-4 noti"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span></span>
        <div
          v-if="getCurrentNoti && totalNoti()"
          class="badge badge-pill badge-danger"
        >
          {{ totalNoti() }}
        </div>
      </button>
      <!--end::Header Menu Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import { IS_SELLER } from "@/config";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  data() {
    return {
      IS_SELLER: IS_SELLER
    };
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUser",
      "getCurrentNoti"
    ]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return this.layoutConfig("self.logo.sticky");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  },
  methods: {
    totalNoti() {
      let total = 0;
      for (let key in this.getCurrentNoti) {
        if (key !== "cart") {
          total += this.getCurrentNoti[key] || 0;
        }
      }
      return total;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    }
  }
};
</script>
