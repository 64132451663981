<template>
  <div class="mb-8">
    <div class="my-2 d-flex justify-content-between">
      <div>
        <h2>เลือกสินค้าที่ <span class="text-promo">ต้องการได้เลย</span></h2>
      </div>
      <router-link to="/product/all">
        <span class="font-size-lg mr-2">ดูสินค้าทั้งหมด</span>
        <i class="flaticon2-right-arrow icon-1x" />
      </router-link>
    </div>
    <VueSlickCarousel
      v-bind="settings"
      class="row product-carousel"
      @swipe="setCarouselSwiping(true)"
      @mouseup.native="setCarouselSwiping(false)"
      @touchend.native="setCarouselSwiping(false)"
      :class="{ '--swiping': swiping === true }"
    >
      <div v-for="item in product_list" :key="item.productId">
        <div class="product-row-item">
          <router-link :to="`/product/${item.productId}`">
            <div class="bximg cursor-pointer">
              <div class="cursor-pointer">
                <img :src="imageUrlApi(item.mainImage)" />
              </div>
            </div>
            <div class="bxdetail">
              <h2 class="cursor-pointer">
                <span :title="item.productName">{{ item.productName }}</span>
              </h2>
              <div class="price-wrapper position-relative">
                <div class="price with-discount">
                  <label class="offer">
                    <span class="number-font">{{ item.priceShow }}</span>
                  </label>
                  <label class="offer price-unit">/ชิ้น </label>
                  <!-- <div class="off-price post-discount ">
                  <label class="actual ml-1"
                    ><span class="number-font">฿1,590</span>
                    <span class="discount-text green-txt inline-block"
                      >ประหยัด ฿641
                    </span></label
                  >
                </div> -->
                </div>
              </div>
              <div class="d-flex rating-content no-rating">
                <div class="item-sold">
                  <span>ขายแล้ว</span> {{ item.sales || 0 }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/product_styles.scss";
</style>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { imageUrlApi } from "@/config";

export default {
  props: ["product_list"],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      swiping: false,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    imageUrlApi,
    setCarouselSwiping(state) {
      this.swiping = state;
    }
  }
};
</script>
