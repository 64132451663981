<template>
  <div>
    <b-alert v-if="messageError" variant="danger" show>{{
      messageError
    }}</b-alert>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="save">
        <div class="card">
          <div class="card-header">
            <h3 class="mb-0">กำหนดค่าส่งขั้นต่ำจีน - ไทย</h3>
          </div>
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-lg-10">
                <div class="form-group row mb-2">
                  <label class="col-form-label text-right col-sm-3 col-4"
                    >ค่าส่งขั้นต่ำจีน - ไทย (ต่อบิล)</label
                  >
                  <div class="col-xl-6 col-lg-8 col-8">
                    <b-form-input
                      v-model="minimumPayshipping.minimum"
                      :state="validateState('minimum')"
                      required
                      placeholder=""
                      aria-describedby="minimumPayshipping-minimum"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="minimumPayshipping-minimum"
                      v-if="!$v.minimumPayshipping.minimum.integer"
                      >กรุณากรอกตัวเลข</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label class="col-form-label text-right col-sm-3 col-4"
                    >ค่าบริการ (ต่อบิล)</label
                  >
                  <div class="col-xl-6 col-lg-8 col-8">
                    <b-form-input
                      v-model="minimumPayshipping.price"
                      :state="validateState('price')"
                      required
                      placeholder=""
                      aria-describedby="minimumPayshipping-price"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="minimumPayshipping-price"
                      v-if="!$v.minimumPayshipping.price.integer"
                      >กรุณากรอกตัวเลข</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label class="col-form-label text-right col-sm-3 col-4"
                    >ค่าขนส่งขั้นต่ำ (ต่อรายการ)</label
                  >
                  <div class="col-xl-6 col-lg-8 col-8">
                    <b-form-input
                      v-model="minimumPayshipping.trackingPrice"
                      :state="validateState('trackingPrice')"
                      required
                      placeholder=""
                      aria-describedby="minimumPayshipping-trackingPrice"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="minimumPayshipping-trackingPrice"
                      v-if="!$v.minimumPayshipping.trackingPrice.integer"
                      >กรุณากรอกตัวเลข</b-form-invalid-feedback
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 col-sm-9 col-12 ml-auto">
                <button
                  type="submit"
                  class="btn btn-success mr-4"
                  style="min-width: 130px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/General/Save.svg" />
                  </span>
                  บันทึก
                </button>
                <button
                  type="button"
                  @click="$apollo.queries.minimumPayshipping.refetch()"
                  class="btn btn-secondary"
                  style="min-width: 130px"
                >
                  รีเซ็ต
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>
<script>
import { required, integer } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import {
  MINIMUM_PAYSHIPPING,
  UPDATE_MINIMUM_PAYSHIPPING
} from "@/graphql/admin/setting";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `กำหนดค่าส่งขั้นต่ำจีน - ไทย | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `กำหนดค่าส่งขั้นต่ำจีน - ไทย` }
    ]);
  },
  data() {
    return {
      messageError: "",
      minimumPayshipping: {
        minimum: 0,
        price: 0,
        trackingPrice: 0
      }
    };
  },
  validations: {
    minimumPayshipping: {
      minimum: {
        required,
        integer
      },
      price: {
        required,
        integer
      },
      trackingPrice: {
        required,
        integer
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.minimumPayshipping[name];

      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.minimumPayshipping.$touch();
      if (this.$v.minimumPayshipping.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { updateMinimumPayshipping }
      } = await this.$apollo.mutate({
        mutation: UPDATE_MINIMUM_PAYSHIPPING,
        variables: {
          minimum: Number(this.$v.minimumPayshipping.minimum.$model),
          price: Number(this.$v.minimumPayshipping.price.$model),
          trackingPrice: Number(this.$v.minimumPayshipping.trackingPrice.$model)
        }
      });
      const { isSuccess, message } = updateMinimumPayshipping;
      this.messageError = "";
      if (!isSuccess) {
        this.messageError = message;
      } else {
        this.$message.success(message);
        this.$apollo.queries.minimumPayshipping.refetch();
      }
    }
  },
  apollo: {
    minimumPayshipping: {
      query: MINIMUM_PAYSHIPPING
    }
  }
};
</script>
