import gql from "graphql-tag";

export const GET_TRANSACTIONS = gql`
  query($page: Int!, $pageSize: Int!, $type: String, $search: String) {
    systemTransactions(
      page: $page
      pageSize: $pageSize
      type: $type
      search: $search
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        transaction_id
        transaction_no
        amount
        userId
        user {
          userId
          username
        }
        remain
        reason
        collection_id
        transaction_type_id
        doc_id
        prefix {
          link_admin
        }
        transaction_type {
          desc
          url
        }
        created_at
        created_id_info {
          userId
          username
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_USER = gql`
  query(
    $page: Int!
    $pageSize: Int!
    $type: String
    $search: String
    $userId: Int
  ) {
    transactionsUser(
      page: $page
      pageSize: $pageSize
      type: $type
      search: $search
      userId: $userId
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        transaction_id
        transaction_no
        amount
        userId
        user {
          userId
          username
        }
        remain
        reason
        collection_id
        transaction_type_id
        doc_id
        prefix {
          link_admin
        }
        transaction_type {
          desc
          url
        }
        created_at
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation($input: TransactionInput) {
    createTransaction(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_TRANSACTION_TYPE_LIST = gql`
  query {
    transactionTypeOptionList {
      records {
        text
        value
      }
    }
  }
`;
