import gql from "graphql-tag";

export const GET_INVENTORY_LIST = gql`
  query {
    inventory_list {
      isSuccess
      message
      recordsData {
        recordsFiltered
      }
      records {
        _id
        productId
        product {
          productName
          mainImage
        }
        description
        amount
        created_at
      }
    }
  }
`;
