import gql from "graphql-tag";

export const CREATE_DEPARTMENT = gql`
  mutation($input: DepartmentInput) {
    createDepartment(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation($input: DepartmentInput) {
    updateDepartment(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation($id: ID) {
    deleteDepartment(_id: $id) {
      isSuccess
      message
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query {
    departments {
      isSuccess
      message
      records {
        _id
        dept_id
        dept_name
        dept_desc
        no
        is_show
        noti
      }
    }
  }
`;

export const GET_ADMIN_DEPARTMENTS = gql`
  query {
    systemDepartmentList {
      isSuccess
      message
      records {
        _id
        dept_id
        dept_name
        dept_desc
        no
        is_show
        noti
        last_message {
          created_at
        }
      }
    }
  }
`;

export const GET_ADMIN_DEPARTMENT = gql`
  query($_id: ID) {
    systemDepartment(_id: $_id) {
      isSuccess
      message
      data {
        _id
        dept_id
        dept_name
        dept_desc
        no
      }
    }
  }
`;
