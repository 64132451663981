import { gql } from "@apollo/client";

export const DELETE_ROLE = gql`
  mutation($id: ID!) {
    deleteRole(_id: $id) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation($input: RoleInput) {
    updateRole(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation($input: RoleInput) {
    createRole(input: $input) {
      isSuccess
      message
      error_list {
        path
        message
      }
    }
  }
`;

export const GET_ROLE_MASTERS = gql`
  query {
    permissionList {
      records {
        _id
        permissionId
        permissionName
        permissionTitle
      }
    }
  }
`;

export const GET_ROLE = gql`
  query($_id: ID) {
    systemRole(_id: $_id) {
      isSuccess
      message
      data {
        _id
        roleId
        role_no
        role_name
        permissionRole {
          _id
          permissionId
          permissionInfo {
            permissionTitle
          }
          readableRole
          writeableRole
        }
      }
    }
  }
`;

export const GET_ROLES = gql`
  query($page: Int!, $pageSize: Int!, $search: String) {
    systemRoleList(page: $page, pageSize: $pageSize, search: $search) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        roleId
        role_no
        role_name
        permissionRole {
          _id
          permissionInfo {
            permissionTitle
          }
          readableRole
          writeableRole
        }
        total_role_used
        moreNumber
      }
    }
  }
`;

export const GET_ROLES_SELECT = gql`
  query {
    systemRoleSelect {
      records {
        _id
        roleId
        role_name
      }
    }
  }
`;
