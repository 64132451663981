import Vue from "vue";
import VueRouter from "vue-router";
import JwtService from "@/core/services/jwt.service";
import routerUser from "./router";
import routerAdmin from "./routerAdmin";

Vue.use(VueRouter);

// https://serversideup.net/vuejs-route-permissions-security-and-admin-section/

const router = new VueRouter({
  mode: "history",
  routes: [
    ...routerUser,
    {
      path: "/admin",
      name: "admin",
      meta: {
        authRequired: true,
        admin: true
      },
      component: () => import("@/view/layout/LayoutAdmin.vue"),
      children: routerAdmin
    }
  ]
});

const hasAccess = (name, meta, admin) => {
  const storagePer =
    localStorage.getItem("permissions") &&
    localStorage.getItem("permissions") !== "undefined"
      ? localStorage.getItem("permissions")
      : "{permissionRole:[]}";

  const permissions = JSON.parse(storagePer);

  const storageUser =
    localStorage.getItem("user") && localStorage.getItem("user") !== "undefined"
      ? localStorage.getItem("user")
      : "{}";

  const user = JSON.parse(storageUser);

  if (admin) {
    return { access: !!user.is_admin, is_admin: user.is_admin };
  }

  const { permissionRole } = permissions;

  let find = null;

  if (Array.isArray(name)) {
    for (let i = 0; i < name.length; i++) {
      const n = name[i];
      const access = hasAccess(n, meta, admin);
      if (access.access) {
        return access;
      }
    }
    return { access: false, is_admin: user.is_admin };
  } else {
    find = permissionRole.find(
      x => x.permissionInfo && x.permissionInfo.permissionName === name
    );
  }

  if (name === "user") {
    return { access: true, is_admin: user.is_admin };
  }
  if (!user.is_admin) {
    return { access: false, is_admin: user.is_admin };
  }
  if (!find) {
    return { access: false, is_admin: user.is_admin };
  }
  if (meta.readable && find.readableRole) {
    return { access: true, is_admin: user.is_admin };
  }
  if (meta.writeable && find.writeableRole) {
    return { access: true, is_admin: user.is_admin };
  }
  return { access: false, is_admin: user.is_admin };
};

router.beforeEach((to, from, next) => {
  //A Logged-in user can't go to login page again
  if (to.name === "login" && JwtService.getToken()) {
    router.push({
      name: "dashboard"
    });

    //the route requires authentication
  } else if (to.meta.authRequired) {
    if (!JwtService.getToken() || JwtService.tokenIsExpired()) {
      JwtService.destroyToken();
      //user not logged in, send them to login page
      router.push({
        name: "login",
        query: {
          to: to.name
        }
      });
    } else {
      const { is_admin, access } = hasAccess(
        to.meta.permission,
        to.meta,
        to.meta.admin
      );
      if (!is_admin && !access) {
        if (router.history.current.name != "page-not-authorized") {
          router.push({
            name: "page-not-authorized"
          });
          return;
        }
      } else if (is_admin && !access) {
        if (router.history.current.name != "admin-page-not-authorized") {
          router.push({
            name: "admin-page-not-authorized"
          });
        }
        return;
      }
    }
  }

  return next();
});

export default router;
