<template>
  <div>
    <b-alert v-if="messageError" variant="danger" show>{{
      messageError
    }}</b-alert>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="save">
        <div class="card">
          <div class="card-header">
            <h3 class="mb-0">ตั้งค่าค่าบริการ Dropship</h3>
          </div>
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-lg-10">
                <div class="form-group row mb-2">
                  <label class="col-form-label text-right col-sm-3 col-4"
                    >ค่าบริการ Dropship</label
                  >
                  <div class="col-xl-6 col-lg-8 col-8">
                    <b-form-input
                      v-model="serivcePriceDropship.price"
                      :state="validateState('price')"
                      required
                      placeholder=""
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 col-sm-9 col-12 ml-auto">
                <button
                  type="submit"
                  class="btn btn-success mr-4"
                  style="min-width: 130px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/General/Save.svg" />
                  </span>
                  บันทึก
                </button>
                <button
                  type="button"
                  @click="$apollo.queries.serivcePriceDropship.refetch()"
                  class="btn btn-secondary"
                  style="min-width: 130px"
                >
                  รีเซ็ต
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import {
  SETTING_SERVICE_DROPSHIP,
  UPDATE_SETTING_SERVICE_DROPSHIP
} from "@/graphql/admin/setting";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `ค่าบริการ Dropship | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `ค่าบริการ Dropship` }
    ]);
  },
  data() {
    return {
      messageError: "",
      serivcePriceDropship: {
        price: ""
      }
    };
  },
  validations: {
    serivcePriceDropship: {
      price: {
        required
      }
    }
  },
  methods: {
    validateStateAddressClassName(name) {
      const { $dirty, $error } = this.$v.serivcePriceDropship[name];
      return $dirty ? (!$error ? "is-valid" : "is-invalid") : "";
    },
    validateStateAddress(name) {
      const { $dirty, $error } = this.$v.serivcePriceDropship[name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.serivcePriceDropship[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.serivcePriceDropship.$touch();
      if (this.$v.serivcePriceDropship.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { updateServicePriceDropshipSetting }
      } = await this.$apollo.mutate({
        mutation: UPDATE_SETTING_SERVICE_DROPSHIP,
        variables: {
          input: {
            price: this.$v.serivcePriceDropship.price.$model
          }
        }
      });
      const { isSuccess, message } = updateServicePriceDropshipSetting;
      this.messageError = "";
      if (!isSuccess) {
        this.messageError = message;
      } else {
        this.$message.success(message);
        this.$apollo.queries.serivcePriceDropship.refetch();
      }
    }
  },
  apollo: {
    serivcePriceDropship: {
      query: SETTING_SERVICE_DROPSHIP
    }
  }
};
</script>
