<template>
  <!--begin::Body-->
  <div class="card-body pt-0 pb-3">
    <b-overlay :show="$apollo.loading" rounded="sm">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-head-custom table-head-bg table-sm table-borderless table-vertical-center"
        >
          <thead>
            <tr class="text-uppercase">
              <th style="min-width: 100px" class="pl-7">
                <span class="text-dark-75">รหัสธุรกรรม</span>
              </th>
              <th style="min-width: 100px">
                <span class="text-dark-75">ผู้ทำรายการ</span>
              </th>
              <th style="min-width: 100px">
                <span class="text-dark-75">ลูกค้า</span>
              </th>
              <th style="min-width: 100px">
                <span class="text-dark-75">วันที่</span>
              </th>
              <th style="min-width: 100px">
                <span class="text-dark-75">ประเภท</span>
              </th>
              <th style="min-width: 150px">
                <span class="text-dark-75">หมายเหตุ</span>
              </th>
              <th style="min-width: 120px" class="text-right pr-7">
                <span class="text-dark-75">ยอดเงิน</span>
              </th>
              <th style="min-width: 100px" class="text-right pr-7">
                <span class="text-dark-75">คงเหลือ</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="systemTransactions">
            <tr
              v-for="item in systemTransactions.records"
              v-bind:key="item._id"
            >
              <td class="pl-7 py-8 font-weight-bolder font-size-lg">
                {{ item.transaction_no }}
              </td>
              <td>
                <span class="text-dark-75 d-block font-size-lg">
                  <router-link
                    :to="`/admin/users/${item.created_id_info.userId}`"
                  >
                    {{
                      item.created_id_info ? item.created_id_info.username : "-"
                    }}
                  </router-link>
                </span>
              </td>
              <td>
                <span class="text-dark-75 d-block font-size-lg">
                  <router-link :to="`/admin/users/${item.userId}`">
                    {{ item.user ? item.user.username : "-" }}
                  </router-link>
                </span>
              </td>
              <td>
                <span class="text-dark-75 d-block font-size-lg">
                  {{ format_datetime(item.created_at) }}
                </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.transaction_type ? item.transaction_type.desc : "" }}
                </span>
              </td>
              <td>
                <span class="text-dark-75 d-block font-size-lg">
                  {{ item.reason }}
                </span>
              </td>

              <td class="text-right pr-7">
                <span
                  class="font-weight-bolder d-block font-size-lg"
                  v-bind:class="{
                    'text-danger': item.amount < 0,
                    'text-success': item.amount >= 0
                  }"
                >
                  {{ format_number(item.amount, 2, true) }}
                </span>
              </td>
              <td class="text-right pr-7">
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ format_number(item.remain, 2, true) }}
                </span>
              </td>
            </tr>
            <tr
              class="text-center"
              v-if="
                systemTransactions &&
                  systemTransactions.records &&
                  !systemTransactions.records.length
              "
            >
              <td colspan="7" class="pt-8">ไม่พบรายการ</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      <b-pagination
        v-model="page"
        class="mt-4"
        size="md"
        v-if="systemTransactions && systemTransactions.recordsData"
        :total-rows="systemTransactions.recordsData.recordsFiltered"
        :per-page="pageSize"
      ></b-pagination>
    </b-overlay>
  </div>
  <!--end::Body-->
</template>
<script>
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import { imageUrlApi, WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_TRANSACTIONS } from "@/graphql/admin/transaction.js";

export default {
  name: "TransactionList",
  props: ["search"],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "กระเป๋าเงิน" }]);
  },
  created() {
    document.title = `กระเป๋าเงิน | ${WEBSITE}`;
  },
  data() {
    const { page = 1 } = this.$route.query;
    return {
      page: Number(page) || 1,
      pageSize: 10,
      loading: false,
      status: "",
      cat: 1
    };
  },
  methods: {
    imageUrlApi,
    getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "success";
        default:
          return "primary";
      }
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_number(val, decimal = 0, format = false) {
      if (format) {
        if (val >= 0)
          return `฿${Number(bigDecimal.round(val, decimal)).toLocaleString(
            undefined,
            {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal
            }
          )}`;
        return `-฿${Number(
          bigDecimal.round(Math.abs(val), decimal)
        ).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        })}`;
      }
      return bigDecimal.round(val, decimal);
    }
  },
  watch: {
    page: function(val) {
      const newpath = `${this.$route.path}?cat=${this.cat}&page=${val}`;
      if (newpath !== this.$route.fullPath) {
        this.$router.push(newpath);
      }
    },
    "$route.query.page": function(val) {
      this.page = Number(val) || 1;
    }
  },
  apollo: {
    systemTransactions: {
      query: GET_TRANSACTIONS,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
