import gql from "graphql-tag";

export const CREATE_LOGISTICS = gql`
  mutation($input: LogisticsInput) {
    createLogistics(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const UPDATE_LOGISTICS = gql`
  mutation($input: LogisticsInput) {
    updateLogistics(input: $input) {
      isSuccess
      message
      is_error
      error_list {
        path
        message
      }
    }
  }
`;

export const DELETE_LOGISTICS = gql`
  mutation($id: Int) {
    deleteLogistics(id: $id) {
      isSuccess
      message
      is_error
    }
  }
`;

export const UPDATE_NO_LOGISTICS = gql`
  mutation($id: Int, $no: Int) {
    updateNoLogistics(id: $id, no: $no) {
      isSuccess
      message
      is_error
    }
  }
`;

export const GET_LOGISTICS_LIST = gql`
  query($page: Int!, $pageSize: Int!, $search: String, $sorter: Sorter) {
    systemLogisticsList(
      page: $page
      pageSize: $pageSize
      sorter: $sorter
      search: $search
    ) {
      recordsData {
        recordsFiltered
      }
      records {
        _id
        no
        logisticsId
        logisticsNo
        logisticsName
        logisticsServicePrice
        image
        logisticsPriceList {
          min
          max
          price
        }
        country
        type
        is_address
        default
        created_at
      }
    }
  }
`;

export const GET_LOGISTICS_LIST_SELECT = gql`
  query {
    logisticsList {
      records {
        logisticsId
        logisticsName
        logisticsServicePrice
        is_other
        is_address
        logisticsPriceList {
          _id
          max
          min
          price
        }
        type
      }
    }
  }
`;

export const GET_LOGISTICS = gql`
  query($id: Int) {
    logistics(id: $id) {
      _id
      no
      logisticsId
      logisticsNo
      logisticsName
      logisticsServicePrice
      image
      logisticsPriceList {
        _id
        min
        max
        price
      }
      country
      is_cod
      is_address
      default
    }
  }
`;

export const GET_SELECT_LOGISTICS_LIST = gql`
  query($promotion_id: Int) {
    logisticsList(promotion_id: $promotion_id) {
      records {
        _id
        no
        logisticsId
        logisticsName
        logisticsServicePrice
        logisticsPriceList {
          min
          max
          price
        }
        type
        is_address
        created_at
      }
    }
  }
`;
