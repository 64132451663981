import { gql } from "@apollo/client";

export const APPROVE_TRANSFER = gql`
  mutation($approve: ApproveInput) {
    system_approve_payment(approve: $approve) {
      isSuccess
      message
      is_error
    }
  }
`;

export const REJECT_TRANSFER = gql`
  mutation($id: ID, $remark: String) {
    system_reect_payment(id: $id, remark: $remark) {
      isSuccess
      message
      is_error
    }
  }
`;

export const GET_TRANSFER = gql`
  query($id: Int!) {
    systemPayment(id: $id) {
      message
      isSuccess
      record {
        _id
        paymentId
        paymentDoc
        userId
        user {
          userId
          username
        }
        bank_name
        amount
        baht
        rate
        file_attachment
        pay_slip
        approve_remark
        remark
        cancel_remark
        status
        statusShow
        transactions {
          _id
          transaction_id
          amount
          reason
          doc_id
          created_at
        }
        created_at
        approve_by_info {
          username
        }
      }
    }
  }
`;

export const GET_TRANSFERS = gql`
  query($page: Int!, $pageSize: Int!, $search: String, $status: String) {
    systemPaymentList(
      page: $page
      pageSize: $pageSize
      search: $search
      status: $status
    ) {
      message
      isSuccess
      recordsData {
        recordsFiltered
      }
      records {
        _id
        paymentId
        paymentDoc
        userId
        user {
          userId
          username
        }
        bank_name
        amount
        baht
        rate
        file_attachment
        pay_slip
        remark
        cancel_remark
        status
        statusShow
        created_at
        approve_date
      }
    }
  }
`;
