<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <b-form class="form" @submit.stop.prevent="do_save">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <h3 class="text-dark-75 font-weight-bold mb-8">เพิ่มสินค้าใหม่</h3>
            <div class="row mt-4">
              <div class="font-size-lg mb-4">1. ข้อมูลพื้นฐาน</div>
              <div class="col-lg-8">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                  label="ชื่อสินค้า"
                  label-for="input-productName"
                >
                  <b-form-input
                    id="input-productName"
                    :state="validateState('productName')"
                    v-model="form.productName"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-8">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                  label="ประเภทสินค้า"
                  label-for="input-category"
                >
                  <v-select
                    searchable
                    labelTitle="กรุณาเลือกประเภทสินค้า"
                    data-style="btn-primary"
                    required
                    :state="validateState('category')"
                    :options="categoryList"
                    valueProp="categoryId"
                    v-model="form.category"
                  />
                  <div
                    v-if="
                      !validateState('category') &&
                        validateState('category') != null
                    "
                    class="text-danger mt-4"
                  >
                    กรุณาเลือกประเภทสินค้า
                  </div>
                </b-form-group>
              </div>
              <div class="col-lg-12">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="9"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="รูปภาพสินค้า"
                  label-for="input-category"
                >
                  <b-overlay :show="loadingImageList">
                    <vue-upload-multiple-image
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      @mark-is-primary="markIsPrimary"
                      dragText="ลากรูปภาพ (หลายภาพ)"
                      browseText="(หรือ) เลือก"
                      primaryText="ค่าเริ่มต้น"
                      markIsPrimaryText="ตั้งเป็นค่าเริ่มต้น"
                      popupText="ภาพนี้จะแสดงเป็นค่าเริ่มต้น"
                      dropText="วางไฟล์ของคุณที่นี่..."
                      :data-images="form.imageList"
                    ></vue-upload-multiple-image>
                  </b-overlay>
                </b-form-group>
              </div>
              <div class="col-lg-12">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="9"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="รายละเอียดสินค้า"
                  label-for="input-category"
                >
                  <b-overlay :show="loadingImage">
                    <quill-editor
                      ref="myQuillEditor"
                      :options="editorOption"
                      v-model="form.desc"
                    />
                  </b-overlay>
                  <input
                    type="file"
                    accept="image/*"
                    class="d-none"
                    id="getFile"
                    @change="uploadFunction($event)"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom card-stretch mt-4">
          <div class="card-body">
            <div class="row mt-4">
              <div class="font-size-lg mb-4">2. ข้อมูลการขาย</div>
              <div class="col-lg-8" v-if="form.type == 'one'">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="ราคา"
                  label-for="input-price"
                >
                  <b-form-input
                    id="input-price"
                    :state="validateState('price')"
                    v-model="form.price"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-8" v-if="form.type == 'one'">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="คลัง"
                  label-for="input-price"
                >
                  <b-form-input
                    id="input-stock"
                    :state="validateState('stock')"
                    v-model="form.stock"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-8" v-if="form.type == 'one'">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="ตัวเลือกสินค้า"
                  label-for="input-price"
                >
                  <button
                    type="button"
                    class="btn btn-block btn-dashed"
                    @click="toggleType"
                  >
                    <i class="la la-plus" />
                    เปิดใช้งานตัวเลือกสินค้า
                  </button>
                </b-form-group>
              </div>
              <div class="col-lg-12" v-if="form.type == 'sku'">
                <b-form-group
                  v-for="(prop, index) in form.properties"
                  :key="prop.id"
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  :label="`ตัวเลือก ${index + 1}`"
                  label-for="input-price"
                >
                  <div class="options-panel">
                    <span class="options-close-btn" @click="removeSku(index)">
                      <i class="la la-close" />
                    </span>
                    <div class="row">
                      <div class="col-lg-12">
                        <b-form-group
                          label-cols="4"
                          label-cols-sm="2"
                          label-cols-lg="2"
                          label-align="right"
                          content-cols="8"
                          content-cols-sm="7"
                          content-cols-lg="10"
                          class="mb-3"
                          label="ชื่อ"
                        >
                          <b-form-input
                            v-model="form.properties[index].title"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-for="(item, i) in form.properties[index].list"
                          :key="item.id"
                          label-cols="4"
                          label-cols-sm="2"
                          label-cols-lg="2"
                          label-align="right"
                          content-cols="8"
                          content-cols-sm="7"
                          content-cols-lg="10"
                          class="mb-3"
                          :label="i == 0 ? 'ตัวเลือก' : ''"
                        >
                          <div class="d-flex">
                            <b-form-input v-model="item.title"></b-form-input>
                            <button
                              class="btn btn-icon btn-sm"
                              type="button"
                              @click="removeRow(index, i)"
                              v-if="form.properties[index].list.length > 1"
                            >
                              <i class="la la-trash icon-lg" />
                            </button>
                          </div>
                        </b-form-group>
                        <b-form-group
                          label-cols="4"
                          label-cols-sm="2"
                          label-cols-lg="2"
                          label-align="right"
                          content-cols="8"
                          content-cols-sm="7"
                          content-cols-lg="10"
                          class="mb-0"
                          label=""
                        >
                          <button
                            class="btn btn-dashed btn-block"
                            type="button"
                            @click="addRow(index)"
                          >
                            <i class="la la-plus" />
                            เพิ่มตัวเลือก
                          </button>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  v-if="form.properties.length < 2"
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="ตัวเลือก 2"
                  label-for="input-price"
                >
                  <button
                    type="button"
                    class="btn btn-block btn-dashed"
                    @click="addSku"
                  >
                    <i class="la la-plus" />
                    เพิ่ม
                  </button>
                </b-form-group>
              </div>
              <div class="col-lg-12" v-if="form.type == 'sku'">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="9"
                  content-cols-xl="9"
                  label="รายการตัวเลือกสินค้า"
                >
                  <table class="table table-bordered table-sku">
                    <thead>
                      <tr>
                        <th
                          v-for="prop in form.properties"
                          :key="prop.id"
                          class="text-center"
                        >
                          {{ prop.title || "ชื่อ" }}
                        </th>
                        <th class="text-center">ราคา ฿</th>
                        <th class="text-center">คลัง</th>
                      </tr>
                    </thead>
                    <tbody v-if="form.properties.length === 1">
                      <tr
                        v-for="prop1 in form.properties[0].list"
                        :key="prop1.id"
                        class="text-center"
                      >
                        <td style="width: 33%" class="align-middle">
                          {{ prop1.title }}
                        </td>
                        <td style="width: 33%" class="p-0">
                          <input
                            class="input-sku"
                            v-model="form.skus[`${prop1.id}`].price"
                            required
                            type="number"
                          />
                        </td>
                        <td style="width: 33%" class="p-0">
                          <input
                            class="input-sku"
                            v-model="form.skus[`${prop1.id}`].stock"
                            required
                            type="number"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="form.properties.length > 1">
                      <template v-for="prop1 in form.properties[0].list">
                        <tr
                          v-for="(prop2, index) in form.properties[1].list"
                          :key="prop1.id + prop2.id"
                          class="text-center"
                        >
                          <td
                            :rowspan="form.properties[1].list.length"
                            v-if="index === 0"
                            class="align-middle w-25"
                          >
                            {{ prop1.title }}
                          </td>
                          <td class="w-25">{{ prop2.title }}</td>
                          <td class="w-25 p-0">
                            <input
                              class="input-sku"
                              required
                              v-model="
                                form.skus[`${prop1.id};${prop2.id}`].price
                              "
                              type="number"
                            />
                          </td>
                          <td class="w-25 p-0">
                            <input
                              class="input-sku"
                              required
                              v-model="
                                form.skus[`${prop1.id};${prop2.id}`].stock
                              "
                              type="number"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom card-stretch mt-4">
          <div class="card-body">
            <div class="row mt-4">
              <div class="font-size-lg mb-4">3. การจัดส่ง</div>
              <div class="col-lg-8">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                  label="ค่าขนส่งในประเทศ (฿)"
                >
                  <b-form-input v-model="form.shipping_price"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-8">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                  label="น้ำหนัก"
                >
                  <b-form-input v-model="form.size.weight"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-8">
                <b-form-group
                  label-cols="4"
                  label-cols-sm="2"
                  label-cols-lg="3"
                  label-align="right"
                  content-cols="8"
                  content-cols-sm="7"
                  content-cols-lg="7"
                  content-cols-xl="6"
                  label="ขนาดพัสดุ (กxยxส)"
                >
                  <div class="d-flex" style="gap: 8px">
                    <b-form-input
                      v-model="form.size.width"
                      placeholder="กว้าง"
                    ></b-form-input>
                    <b-form-input
                      v-model="form.size.long"
                      placeholder="ยาว"
                    ></b-form-input>
                    <b-form-input
                      v-model="form.size.height"
                      placeholder="สูง"
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 col-sm-10 ml-auto">
                <button
                  type="submit"
                  class="btn btn-success mr-4"
                  style="width: 136px"
                >
                  บันทึก
                </button>
                <router-link
                  to="/seller/product"
                  class="btn btn-secondary"
                  style="width: 136px"
                >
                  ยกเลิก
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Quill from "quill";
import { quillEditor } from "vue-quill-editor";
import ImageResize from "quill-image-resize-module";
import uuidv4 from "uuid/v4";
import VSelect from "@alfsnd/vue-bootstrap-select";
import Swal from "sweetalert2";
import axios from "axios";
import { GET_CATEGORY_LIST } from "@/graphql/seller_category";
import { CREATE_PRODUCT } from "@/graphql/seller_product";
import { API_URL, IMAGE_URL, WEBSITE } from "@/config";
import VueUploadMultipleImage from "vue-upload-multiple-image";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

// ref: https://programmerall.com/article/80311358098/

Quill.register("modules/imageResize", ImageResize);

export default {
  mixins: [validationMixin],
  components: {
    quillEditor,
    VSelect,
    VueUploadMultipleImage
  },
  created() {
    document.title = `เพิ่มสินค้าใหม่ | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `สินค้าของฉัน`, route: "/seller/product" },
      { title: `เพิ่มสินค้าใหม่` }
    ]);
  },
  data() {
    const toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"] // remove formatting button
    ];
    return {
      loadingImage: false,
      loadingImageList: false,
      editorOption: {
        placeholder: "Add product descriptions and edit text message",
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function() {
                document.getElementById("getFile").click();
              }
            }
          },
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white"
            },
            modules: ["Resize", "DisplaySize", "Toolbar"]
          }
        }
      },
      loading: false,
      categoryList: [{ categoryId: 1, text: "เสื้อ" }],
      form: {
        productName: "",
        category: "",
        desc: "",
        price: 0,
        stock: 0,
        type: "one",
        shipping_price: 0,
        size: {
          weight: 0,
          width: 0,
          long: 0,
          height: 0
        },
        skus: {},
        imageList: [],
        properties: [
          {
            title: "",
            id: uuidv4(),
            list: [{ id: uuidv4(), title: "" }]
          }
        ]
      }
    };
  },
  validations: {
    form: {
      productName: {
        required
      },
      desc: {},
      price: {},
      stock: {},
      category: {
        required
      },
      skus: {},
      properties: {},
      shipping_price: {},
      size: {},
      type: {},
      imageList: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    beforeRemove(index, done) {
      done();
      if (this.form.imageList[index].default) {
        this.form.imageList.splice(index, 1);
        if (this.form.imageList.length > 0) {
          this.form.imageList[0].default = 1;
        }
      } else {
        this.form.imageList.splice(index, 1);
      }
      if (this.form.imageList[index]) {
        this.form.imageList[index].highlight = 1;
      }
    },
    markIsPrimary(index) {
      const newArray = [this.form.imageList[index]];
      for (let i = 0; i < this.form.imageList.length; i++) {
        const image = this.form.imageList[i];
        image.default = index === i ? 1 : 0;
        image.highlight = index === i ? 1 : 0;
        if (index !== i) {
          newArray.push(image);
        }
      }
      this.form.imageList = newArray;
    },
    uploadImageSuccess(formData) {
      this.loadingImageList = true;
      axios
        .post(`${API_URL}/upload/temp2`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          const { data } = res;
          this.loadingImageList = false;
          if (data) {
            for (let i = 0; i < this.form.imageList.length; i++) {
              const image = this.form.imageList[i];
              image.highlight = 0;
            }
            this.form.imageList.push({
              path: `${IMAGE_URL}${data.data}`,
              default: this.form.imageList.length === 0 ? 1 : 0,
              highlight: 1
            });
          }
        });
    },
    addSku() {
      this.form.properties.push({
        title: "",
        id: uuidv4(),
        list: [{ id: uuidv4(), title: "" }]
      });
      this.checkSku();
    },
    uploadFunction(e) {
      let file = e.target.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        this.loadingImage = true;
        this.upload(file[0], event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(res => {
          this.loadingImage = false;
          const { data } = res;
          if (data) {
            document.getElementById("getFile").value = "";
            const urlImage = data.data;
            let quill = this.$refs.myQuillEditor.quill;
            let length = quill.getSelection().index; // Get the cursor position
            quill.insertEmbed(length, "image", urlImage); // Insert image res.url for the image address returned by the server
            quill.setSelection(length + 1); // adjust the cursor to the end
          }
        });
      }
    },
    upload(file, onUploadProgress) {
      let formData = new FormData();
      formData.append("file", file);

      return axios.post(`${API_URL}/upload/cloud`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    removeSku(index) {
      if (this.form.properties.length === 1) {
        this.form.properties.splice(index, 1);
        this.form.type = "one";
      } else {
        this.form.properties.splice(index, 1);
      }
    },
    addRow(index) {
      this.form.properties[index].list.push({ id: uuidv4(), title: "" });
      this.checkSku();
    },
    removeRow(index, i) {
      this.form.properties[index].list.splice(i, 1);
    },
    checkSku() {
      if (this.form.properties.length === 1) {
        for (let i = 0; i < this.form.properties[0].list.length; i++) {
          const prop1 = this.form.properties[0].list[i];
          if (!this.form.skus[`${prop1.id}`]) {
            this.form.skus[`${prop1.id}`] = {
              price: 0,
              stock: 0
            };
          }
        }
      } else if (this.form.properties.length > 1) {
        for (let i = 0; i < this.form.properties[0].list.length; i++) {
          const prop1 = this.form.properties[0].list[i];
          for (let j = 0; j < this.form.properties[1].list.length; j++) {
            const prop2 = this.form.properties[1].list[j];
            if (!this.form.skus[`${prop1.id};${prop2.id}`]) {
              this.form.skus[`${prop1.id};${prop2.id}`] = {
                price: 0,
                stock: 0
              };
            }
          }
        }
      }
    },
    toggleType() {
      if (this.form.type === "one") {
        this.form.type = "sku";
        this.form.properties = [
          {
            title: "",
            id: uuidv4(),
            list: [{ id: uuidv4(), title: "" }]
          }
        ];
        this.checkSku();
      } else {
        this.form.type = "one";
      }
    },
    async do_save() {
      this.$v.form.$touch();
      const skus_model = this.form.skus;
      if (this.$v.form.$anyError) {
        return;
      }
      const confirm = await Swal.fire({
        title: "เพิ่มสินค้าใหม่ ?",
        icon: "question",
        html: `ยืนยันเพิ่มสินค้าใหม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      // const skus_model = this.$v.form.skus.$model;
      const skus = [];

      for (let key in skus_model) {
        const data = skus_model[key];
        skus.push({
          skuId: uuidv4(),
          skuName: key,
          price: Number(data.price),
          stock: Number(data.stock)
        });
      }
      const imageList = this.form.imageList.map(x => x.path);

      const input = {
        productName: this.$v.form.productName.$model,
        price: this.$v.form.price.$model,
        stock: this.$v.form.stock.$model,
        shipping_price: this.$v.form.shipping_price.$model,
        size: this.$v.form.size.$model,
        properties: this.$v.form.properties.$model,
        skus: skus,
        categoryId: this.$v.form.category.$model.categoryId,
        desc: this.$v.form.desc.$model,
        type: this.$v.form.type.$model,
        imageList
      };
      input.shipping_price = Number(input.shipping_price);
      input.price = Number(input.price);
      input.stock = Number(input.stock);

      input.size.height = Number(input.size.height);
      input.size.width = Number(input.size.width);
      input.size.long = Number(input.size.long);
      input.size.weight = Number(input.size.weight);

      this.loading = true;

      const {
        data: { createSellerProduct }
      } = await this.$apollo.mutate({
        mutation: CREATE_PRODUCT,
        variables: {
          input: input
        }
      });
      this.loading = false;

      const { message, is_error, isSuccess } = createSellerProduct;
      if (is_error || !isSuccess) {
        this.$message.error(
          createSellerProduct.message ||
            (createSellerProduct.error_list &&
              createSellerProduct.error_list.map(x => x.message).join())
        );
      } else {
        this.$message.success(message);
        this.$router.push("/seller/product");
      }
    }
  },
  apollo: {
    category_list: {
      query: GET_CATEGORY_LIST,
      result({ loading, data }) {
        if (!loading && data) {
          this.categoryList = data.category_list.records.map(x => ({
            categoryId: x.categoryId,
            text: x.originalName
          }));
        }
      }
    }
  }
};
</script>
