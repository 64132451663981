<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <b-form ref="form" @submit.prevent="save">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">แก้ไขบัญชีธนาคาร #{{ bank_id }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group row">
                <label
                  for="input-transportNumber"
                  class="col-form-label text-lg-right text-md-right col-md-3 col-sm-12"
                >
                  ธนาคาร
                </label>
                <div class="col-lg-6 col-md-9 col-sm-12">
                  <v-select
                    searchable
                    labelTitle="กรุณาเลือกธนาคาร"
                    data-style="btn-primary"
                    required
                    :state="validateState('bank_name')"
                    :options="banks"
                    disabled
                    valueProp="value"
                    v-model="form.bank_name"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="input-account_name"
                  class="col-form-label text-lg-right text-md-right col-md-3 col-sm-12"
                >
                  ชื่อบัญชี
                </label>
                <div class="col-lg-6 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-account_name"
                    v-model="form.account_name"
                    :state="validateState('account_name')"
                    required
                    disabled
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="input-account_no"
                  class="col-form-label text-lg-right text-md-right col-md-3 col-sm-12"
                >
                  เลขบัญชี
                </label>
                <div class="col-lg-6 col-md-9 col-sm-12">
                  <b-form-input
                    id="input-account_no"
                    v-model="form.account_no"
                    :state="validateState('account_no')"
                    required
                    disabled
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-form-label text-lg-right text-md-right col-md-3 col-sm-12"
                >
                  รูป QR
                </label>
                <div class="col-md-6 col-12">
                  <div class="uppy">
                    <div class="uppy-wrapper">
                      <div class="uppy-Root uppy-FileInput-container">
                        <input
                          class="uppy-FileInput-input uppy-input-control"
                          ref="fileInput"
                          type="file"
                          name="files[]"
                          accept="image/*"
                          multiple=""
                          @input="pickFile"
                          id="file"
                          style=""
                        />
                        <label
                          class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                          for="file"
                        >
                          เลือก รูป QR
                        </label>
                      </div>
                    </div>
                    <div class="uppy-list">
                      <div
                        class="uppy-list-item"
                        v-if="previewImage || form.bank_qr"
                      >
                        <div
                          v-viewer
                          class="border symbol symbol-60 flex-shrink-0 mr-4"
                        >
                          <img
                            style="object-fit: contain"
                            :src="previewImage || imageUrlApi(form.bank_qr)"
                            height="200"
                            width="200"
                          />
                        </div>
                        <div class="uppy-list-label">
                          {{ file && file.name }}
                        </div>
                        <span class="uppy-list-remove" @click="removeFile()">
                          <i class="flaticon2-cancel-music"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <span class="form-text text-muted">
                    อัพโหลดไฟล์ไม่เกิน 20MB.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-4"
                style="min-width: 140px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Save.svg" />
                </span>
                บันทึก
              </button>
              <router-link
                to="/admin/setting/bank"
                class="btn btn-secondary"
                style="width: 140px"
              >
                ยกเลิก
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import VSelect from "@alfsnd/vue-bootstrap-select";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { GET_BANK, UPDATE_BANK } from "@/graphql/admin/bank";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi } from "@/config";

export default {
  mixins: [validationMixin],
  created() {
    document.title = `แก้ไขบัญชีธนาคาร | ${WEBSITE}`;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `รายการบัญชีธนาคาร`, route: "/admin/setting/bank" },
      { title: `แก้ไขบัญชีธนาคาร` }
    ]);
  },
  components: {
    VSelect
  },
  data() {
    const { id } = this.$route.params;
    return {
      nameState: null,
      messageError: "",
      id: id,
      file: null,
      previewImage: null,
      form: {
        account_no: "",
        bank_name: null,
        account_name: "",
        bank_qr: ""
      },
      banks: [
        { text: "ธนาคารกสิกรไทย - Kasikornbank", value: "kbank" },
        { text: "ธนาคารกรุงเทพ - Bangkok Bank", value: "bbl" },
        { text: "ธนาคารไทยพาณิชย์ - Siam Commercial Bank", value: "scb" },
        { text: "ธนาคารกรุงไทย - Krungthai Bank", value: "ktb" },
        {
          text: "ธนาคารกรุงศรีอยุธยา - Bank of Ayudhya (Krungsri)",
          value: "bay"
        },
        { text: "ธนาคารซิตี้แบงค์ - Citibank", value: "citi" },
        { text: "ธนาคารออมสิน - Government Savings Bank", value: "gsb" },
        { text: "ธนาคารทหารไทย - TMB Bank", value: "tmb" },
        { text: "ธนาคารซีไอเอ็มบีไทย - CIMB Thai Bank", value: "cimb" },
        {
          text: "ธนาคารอาคารสงเคราะห์ - Government Housing Bank",
          value: "ghb"
        }
      ]
    };
  },
  validations: {
    form: {
      account_no: { required },
      bank_name: { required },
      account_name: { required }
    }
  },
  methods: {
    imageUrlApi,
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const account_no = this.$v.form.account_no.$model;
      const bank_name = this.$v.form.bank_name.$model;
      const account_name = this.$v.form.account_name.$model;

      if (!bank_name) {
        this.$message.error("กรุณาเลือกธนาคาร");
        return;
      }

      const input = {
        _id: this.id,
        account_no,
        bank_name: bank_name.value,
        account_name,
        bank_qr: this.form.bank_qr,
        file_qr: this.file
      };

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { updateSystemBank }
      } = await this.$apollo.mutate({
        mutation: UPDATE_BANK,
        variables: {
          input: input
        }
      });
      const { message, is_error } = updateSystemBank;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$router.push("/admin/setting/bank");
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.form.bank_qr = "";
      this.file = null;
      this.previewImage = null;
      this.$refs.fileInput.value = null;
    }
  },
  apollo: {
    systemBank: {
      query: GET_BANK,
      variables() {
        return {
          id: this.id
        };
      },
      result({ loading, data }) {
        if (!loading && data?.systemBank) {
          const { systemBank } = data;
          this.bank_id = systemBank.bank_id;
          this.form.account_no = systemBank.account_no;
          this.form.account_name = systemBank.account_name;
          this.form.bank_name = systemBank.bank_name;
          this.form.bank_qr = systemBank.bank_qr;
        }
      }
    }
  }
};
</script>
